import {
  Amount,
  AssetType,
  LocationType,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  BelongingType,
  Event as BelongingEvent,
  Belonging,
  OtherCollectableType,
} from "./belongings";
import { EventEnvelope } from "./event";
import Decimal from "decimal.js";
import {
  AllowedDecimalPlaces,
  addDecimal,
  calculatePercentage,
  OmitKeys,
  isPurchasedLM,
} from "../utils";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  BelongingSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import { Encrypted } from "../database/encryption";

export type Event = EventEnvelope<BelongingEvent>;
export interface BelongingSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.BelongingSummary, 2>;
  id: AssetType.Belonging | AssetType.OtherCollectables;
  items: {
    [id: string]: {
      subtype: OtherCollectableType | BelongingType | string;
      brand: string;
      value: Amount;
      number: number;
      liability: Amount;
      ownedPercentage: number;
      locationId: string;
      locationType: LocationType;
      purchasePrice: Amount;
      purchaseDate: Date;
      mainImage?: string;
      updateAt: Date;
    };
  };
}
export interface SubtypeItem {
  label: string;
  assetNumber: number;
  itemNumber: number;
  value: Amount;
  liability: Amount;
  percentage: number;
  mainImage?: { assetId: string; imageId: string };
}

export namespace BelongingSummary {
  export function assureVersion(
    input: BelongingSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      BelongingSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    purchasedLM: number;
    brandNumber: number;
    typesNumber: number;
    totalNumber: number;
    subtypeItems: SubtypeItem[];
  }

  export interface LocationInfo {
    id: string;
    locationType: LocationType;
    itemNumber: number;
    value: Amount;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<BelongingSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(snapshot.data(), assureVersion, () =>
      defaultValue(docRef.id as BelongingSummary["id"])
    );
    convertDate(summary);
    return new AggregateRoot(new BelongingSummaryAggregate(summary));
  }

  export function defaultValue(
    assetType: AssetType.OtherCollectables | AssetType.Belonging
  ): BelongingSummary {
    return {
      "@type": BelongingSummaryTypeVersion,
      id: assetType,
      items: {},
      version: 0,
    };
  }

  export function convertDate(input: BelongingSummary) {
    Object.values(input.items).forEach((item) => {
      item.purchaseDate = (item.purchaseDate as any).toDate();
    });
  }

  export function toDisplay(
    input: BelongingSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalNumber = 0;
    let purchasedLM = 0;
    let totalAssets = new Decimal(0);
    let totalLiabilities = new Decimal(0);
    const subtypesMap: { [key: string]: SubtypeItem } = {};
    const brandSet = new Set<string>();
    const subtypeMainImageMap: {
      [key: string]: { assetId: string; imageId: string; value: Amount };
    } = {};

    Object.entries(input.items).forEach(([id, item]) => {
      const value = new Decimal(item.value.value)
        .mul(item.ownedPercentage)
        .div(100)
        .mul(exchangeRate.rates[item.value.currency].rate)
        .toDecimalPlaces(AllowedDecimalPlaces);
      const liability = new Decimal(item.liability.value)
        .mul(exchangeRate.rates[item.liability.currency].rate)
        .toDecimalPlaces(AllowedDecimalPlaces);
      totalAssets = totalAssets.add(value);
      totalLiabilities = totalLiabilities.add(liability);
      if (isPurchasedLM(item.purchaseDate)) purchasedLM += item.number;
      totalNumber += item.number;
      brandSet.add(item.brand);

      const subtypeItem: SubtypeItem = {
        label: item.subtype,
        assetNumber: 1,
        itemNumber: item.number,
        liability: {
          currency,
          value: liability.toNumber(),
        },
        value: { currency, value: value.toNumber() },
        percentage: 0,
      };
      if (subtypesMap[item.subtype]) {
        subtypesMap[item.subtype].assetNumber += 1;
        subtypesMap[item.subtype].itemNumber += item.number;
        subtypesMap[item.subtype].value.value = addDecimal(
          subtypesMap[item.subtype].value.value,
          value
        );
      } else {
        subtypesMap[item.subtype] = subtypeItem;
      }
      if (item.mainImage) {
        const currentMainImage = {
          assetId: id,
          imageId: item.mainImage,
          value: {
            currency,
            value: value.toNumber(),
          },
        };
        if (subtypeMainImageMap[item.subtype]) {
          if (
            subtypeMainImageMap[item.subtype].value.value <
            currentMainImage.value.value
          )
            subtypeMainImageMap[item.subtype] = currentMainImage;
        } else subtypeMainImageMap[item.subtype] = currentMainImage;
      }
    });
    Object.entries(subtypeMainImageMap).forEach(([subtype, data]) => {
      if (!subtypesMap[subtype]) throw new Error("subtype not found");
      subtypesMap[subtype].mainImage = {
        assetId: data.assetId,
        imageId: data.imageId,
      };
    });
    const result: OmitKeys<Display, "typesNumber" | "subtypeItems"> = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: totalLiabilities.toNumber(),
      },
      netValue: {
        currency,
        value: totalAssets.sub(totalLiabilities).toNumber(),
      },
      purchasedLM,
      brandNumber: brandSet.size,
      totalNumber,
    };
    const subtypeItems: SubtypeItem[] = Object.values(subtypesMap).map(
      (item) => {
        item.percentage = calculatePercentage(
          item.value.value,
          result.assets.value
        );
        return item;
      }
    );
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    return { ...result, typesNumber: subtypeItems.length, subtypeItems };
  }

  export type RelatedUpdates = never;
}

const RelatedObjKeys: (keyof Encrypted<Belonging> & string)[] = [
  "subtype",
  "brand",
  "value",
  "location",
  "purchasePrice",
  "purchaseDate",
  "number",
  // "ownership", //#NOTE update this manually
];

export class BelongingSummaryAggregate extends AggregateBase<
  BelongingSummary,
  never,
  BelongingEvent
> {
  state: BelongingSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: BelongingSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case BelongingEvent.Kind.AssetCreated: {
        const asset = event.data.asset;
        //#HACK: Using bang (!)
        this.state.items[event.aggregateId] = {
          subtype: asset.subtype!,
          brand: asset.brand!,
          value: asset.value as Amount,
          number: asset.number!,
          liability: Amount.defaultValue(),
          ownedPercentage: asset.ownership?.myOwnership || 100,
          locationId: asset.location!.locationId!,
          locationType: asset.location!.locationType!,
          purchasePrice: asset.purchasePrice as Amount,
          purchaseDate: asset.purchaseDate!,
          updateAt: event.time,
        };
        if (asset.mainImage)
          this.state.items[event.aggregateId].mainImage = asset.mainImage;
        break;
      }
      case BelongingEvent.Kind.AssetUpdated: {
        const updates = event.data.asset;
        Object.keys(updates)
          .filter((key) => RelatedObjKeys.includes(<any>key))
          .forEach((key) => {
            const value = updates[key as keyof typeof updates];
            const typedKey: keyof (typeof this.state.items)[string] =
              key as any;
            if (value === null) {
              delete this.state.items[event.aggregateId][typedKey];
            } else if (value !== undefined) {
              (<any>this.state.items[event.aggregateId])[typedKey] = value;
            }
          });
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      }
      case BelongingEvent.Kind.AssetDeleted:
        delete this.state.items[event.aggregateId];
        break;
      case BelongingEvent.Kind.ShareholderUpdated:
        this.state.items[event.aggregateId].ownedPercentage =
          event.data.current?.myOwnership || 100;
        break;
      case BelongingEvent.Kind.LocationUpdated:
        this.state.items[event.aggregateId].locationId =
          event.data.current.locationId;
        this.state.items[event.aggregateId].locationType =
          event.data.current.locationType;
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      case BelongingEvent.Kind.ValueUpdated:
        this.state.items[event.aggregateId].value = event.data.current;
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      case BelongingEvent.Kind.MainImageSet:
        if (event.data.current)
          this.state.items[event.aggregateId].mainImage = event.data.current;
        else delete this.state.items[event.aggregateId].mainImage;
        break;
    }
    return this;
  }
}
