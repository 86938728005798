import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Amount, AssetType, AttachmentKind, Currency } from 'core/remodel/types/common'
import { assetTypeOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, FormInput, FormPriceInput, FormSelect, FormTextarea, SelectItem } from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, StarIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

interface Attachment {
  key: string
  type: AttachmentKind
  name: string
}

export type WishlistValues = {
  name: string
  type: AssetType
  brand: string
  location: string
  store: string
  price: Amount
  notes: string
  mainImage: string | null
  attachments?: Attachment[]
}

const getDefaultValues = (currency: Currency = fallbackCurrency): WishlistValues => ({
  name: '',
  type: AssetType.Art,
  brand: '',
  location: '',
  store: '',
  price: { currency, value: 0 },
  notes: '',
  mainImage: null,
  attachments: undefined
})

interface BelongingFormProps {
  assetId: string | null
  values?: WishlistValues
  submitLabel?: string
  onCancel: () => void
  onSubmit: SubmitHandler<WishlistValues>
}

export function WishlistForm({ assetId, values, submitLabel, onCancel, onSubmit }: BelongingFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm<WishlistValues>({ values, defaultValues })
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form
        className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormInput control={control} name={'name'} label={t('Field.AssetName')} rules={{ required: true }} />
                <FormSelect control={control} name={'type'} label={t('Field.AssetType')}>
                  {assetTypeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {t(`AssetTypeOptions.${label}`)}
                    </SelectItem>
                  ))}
                </FormSelect>
                <FormInput control={control} name={'brand'} label={t('Field.BrandManufactureCreator')} />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormInput control={control} name={'location'} label={t('Field.Location')} />
                <FormInput control={control} name={'store'} label={t('wishlist:Field.Store')} />
                <FormPriceInput
                  control={control}
                  name={{ currency: 'price.currency', value: 'price.value' }}
                  label={t('Field.ItemPrice')}
                  format={preferences.numberFormat}
                />
              </div>
            </div>

            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            {assetId && (
              <AttachmentPanel
                assetId={assetId}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                widgetOptions={[AttachmentKind.PrimaryDetails]}
              />
            )}
          </TabPanel.Section>
        </div>
        <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{submitLabel ?? t('Create')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function WishlistFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
