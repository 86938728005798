import { useState, type DragEvent, type ReactNode } from 'react'

import { cn } from '@/utils/classnames'

interface DropAreaProps {
  className?: string
  children: ReactNode
  onDrop: (files: File[]) => void
}

export default function DropArea({ className, children, onDrop }: DropAreaProps) {
  const [isOver, setIsOver] = useState(false)

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsOver(true)
  }

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsOver(false)
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsOver(false)

    if (e.dataTransfer) {
      const files = Array.from(e.dataTransfer.files)
      onDrop(files)
    }
  }

  return (
    <div
      className={cn('border border-dashed p-4 transition-colors', isOver && 'border-primary bg-grey/10', className)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  )
}
