import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  Unsubscribe,
} from "../../coreFirebase";
import { Refs } from "../refs";
import { resolveObject } from "../utils";
import {
  AggregateRoot,
  Domain,
  EventConsumer,
  IAggregateData,
  SummaryAggregateConstructor,
  SummaryEventConsumerBase,
  catchupEventsInTransaction,
  getAssetEventCollectionPath,
  loopCatchupEvents,
} from "./aggregate";
import { Optional } from "./common";
import { EventEnvelope } from "./event";

type SummaryUtils<Summary, SummaryAggregate, TEvent> = {
  ref: DocumentReference<Summary>;
  aggregateConstructor: SummaryAggregateConstructor<Summary, SummaryAggregate>;
  newAggregateRoot: (
    transaction: Transaction
  ) => Promise<AggregateRoot<Summary, never, TEvent>>;
  consumer?: SummaryEventConsumerBase<Summary, never, TEvent, SummaryAggregate>;
};

export class SummaryLoader<
  Summary extends IAggregateData,
  SummaryAggregate,
  TEvent = any
> {
  loaded = false;
  refs: Refs;

  loadPromise: Optional<Promise<void>> = undefined;
  domain: Domain;
  seqRef: DocumentReference;
  hasWritePermission: boolean;
  summaryUtils: SummaryUtils<Summary, SummaryAggregate, TEvent>;

  sequence: number = 0;
  summary: Optional<AggregateRoot<Summary, never, TEvent>>;
  private _unsubscribe: Optional<Unsubscribe>;
  private _lastSync: Date = new Date(0);

  constructor(
    refs: Refs,
    domain: Domain,
    hasWritePermission: boolean,
    seqRef: DocumentReference,
    summaryUtils: SummaryUtils<Summary, SummaryAggregate, TEvent>
  ) {
    this.refs = refs;
    this.domain = domain;

    this.seqRef = seqRef;
    this.summaryUtils = summaryUtils;

    this.hasWritePermission = hasWritePermission;
  }

  async load(transaction: Transaction): Promise<void> {
    //#NOTE this don't need to be retried if transaction fails
    if (this._unsubscribe === undefined) {
      this._unsubscribe = CoreFirestore.onSnapshot(
        this.seqRef,
        (doc) => {
          const data = doc.data();
          if (data === undefined) return;
          if (data.sequence > this.sequence) this.sequence = data.sequence;
        },
        //#HACK this prevents "permission-denied" errors from being thrown, most of the time caused by message arrived after logout
        (e) => {
          if (e.code != "permission-denied") {
            console.error("onSnapshot", e);
            return;
          }
        }
      );
    }
    if (this.summary === undefined) {
      this.summary = await this.summaryUtils.newAggregateRoot(transaction);
    }
    this.loaded = true;
    this._lastSync = new Date();
  }

  unsubscribe(): void {
    if (this._unsubscribe) this._unsubscribe();
  }

  version(): Optional<number> {
    return this.summary?.version();
  }

  isSynced(): boolean {
    return (
      this.summary !== undefined && this.summary.version() === this.sequence
    );
  }

  private buildConsumer(): Record<string, EventConsumer<any, any, TEvent>> {
    if (this.summaryUtils.consumer === undefined) {
      this.summaryUtils.consumer = new SummaryEventConsumerBase(
        this.summaryUtils.ref,
        this.summaryUtils.newAggregateRoot,
        this.summaryUtils.aggregateConstructor,
        this.hasWritePermission
      );
    }
    const consumers: Record<string, EventConsumer<any, any, TEvent>> = {
      summary: this.summaryUtils.consumer,
    };
    return consumers;
  }
  private async doSync(): Promise<void> {
    if (!this.loaded) await CoreFirestore.runTransaction(this.load.bind(this));
    const consumers = this.buildConsumer();

    const result = await loopCatchupEvents(
      this.refs.userId,
      this.domain,
      consumers
    );

    this.summary = result.summary;
    this._lastSync = new Date();

    this.sequence = this.summary.version();
  }

  async keepSyncedInTransaction(
    transaction: Transaction,
    skipIfRecentlySynced: boolean
  ): Promise<(() => void)[]> {
    if (!this.loaded) await this.load(transaction);
    else if (
      skipIfRecentlySynced &&
      new Date().getTime() - this._lastSync.getTime() < 2000
    ) {
      return [];
    }

    const eventCollectionRef = CoreFirestore.collection(
      getAssetEventCollectionPath(this.refs.userId, this.domain)
    ) as CollectionReference<EventEnvelope<TEvent>>;
    const consumers = this.buildConsumer();
    const promiseObj: Record<
      string,
      Promise<AggregateRoot<any, any, TEvent>>
    > = {};

    Object.entries(consumers).forEach(([k, v]) => {
      promiseObj[k] = v.getAggregateRoot(transaction);
    });
    const aggregateRoots: Record<
      string,
      AggregateRoot<any, any, TEvent>
    > = await resolveObject(promiseObj);

    const {
      setStates,
      aggregateRoots: { summary: newSummary },
    } = await catchupEventsInTransaction(
      transaction,
      eventCollectionRef,
      this.refs.userId,
      this.domain,
      aggregateRoots,
      consumers
    );
    if (
      newSummary &&
      (this.summary === undefined ||
        newSummary.version() > this.summary.version())
    ) {
      this.summary = newSummary;
      this._lastSync = new Date();
    }
    return setStates;
  }

  getData(): {
    summary: Summary | undefined;
  } {
    return {
      summary: this.summary?.state(),
    };
  }

  async syncAndGetData(force: boolean = false): Promise<{
    summary: Summary;
  }> {
    if (force || !this.isSynced()) {
      if (this.loadPromise === undefined) {
        this.loadPromise = this.doSync().then(() => {
          this.loadPromise = undefined;
        });
      }
      await this.loadPromise;
    }
    return this.getData() as {
      summary: Summary;
    };
  }
}
