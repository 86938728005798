import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20480%20480%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230f889b%22%20d%3D%22M177.525%2072.994c-3.006.355-5.901-1.096-7.548-3.635C161.058%2055.608%20145.572%2046.5%20128%2046.5h-3.67c-27.843%200-50.291%2022.555-50.25%2050.31l.41%20336.69h8.238c4.262%200%207.982%203.218%208.255%207.471A7.999%207.999%200%200%201%2083%20449.5H8.272c-4.262%200-7.982-3.218-8.255-7.471A7.999%207.999%200%200%201%208%20433.5h50.49l-.41-336.67C58.039%2060.2%2087.661%2030.5%20124.33%2030.5H128c23.309%200%2043.838%2012.148%2055.585%2030.442%202.973%204.631.459%2011.281-6.06%2012.052zM186%2088.5c-30.88%200-56%2025.12-56%2056v14c0%204.42%203.58%208%208%208h96c4.42%200%208-3.58%208-8v-14c0-30.88-25.12-56-56-56zm194.059%20352.766c-.214-7.007-.079-22.009-.061-58.76a8.002%208.002%200%200%200-8-8.006H214.002a8.002%208.002%200%200%200-8%208.006c.019%2036.755.154%2051.755-.06%2058.761-.138%204.51%203.484%208.234%207.996%208.234h158.125c4.512-.001%208.133-3.725%207.996-8.235zM444.13%20308.5h-12.26c-19.78%200-35.87%2016.09-35.87%2035.87v97.13c0%204.42%203.58%208%208%208h40.13c19.78%200%2035.87-16.09%2035.87-35.87v-69.26c0-19.78-16.09-35.87-35.87-35.87zm-290%200h-12.26c-19.78%200-35.87%2016.09-35.87%2035.87v69.26c0%2019.78%2016.09%2035.87%2035.87%2035.87H182c4.42%200%208-3.58%208-8v-97.13c0-19.78-16.09-35.87-35.87-35.87zm122.87-95h-94.16c-23.66%200-42.84%2019.18-42.84%2042.84v28.16a7.998%207.998%200%200%200%207.994%208l6.128.001c28.65%200%2051.878%2023.224%2051.878%2051.874v6.126a8%208%200%200%200%208%208h63a8%208%200%200%200%208-8v-129a8%208%200%200%200-8-8.001zm124.16%200H309a8%208%200%200%200-8%208v129a8%208%200%200%200%208%208h63a8%208%200%200%200%208-8v-6.13c0-28.576%2023.243-51.87%2051.87-51.87H436a8%208%200%200%200%208-8v-28.16c0-23.66-19.18-42.84-42.84-42.84z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SnugIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 480 480'} width={size} height={size} {...props}>
      <path
        fill={'#0f889b'}
        d={
          'M177.525 72.994c-3.006.355-5.901-1.096-7.548-3.635C161.058 55.608 145.572 46.5 128 46.5h-3.67c-27.843 0-50.291 22.555-50.25 50.31l.41 336.69h8.238c4.262 0 7.982 3.218 8.255 7.471A7.999 7.999 0 0 1 83 449.5H8.272c-4.262 0-7.982-3.218-8.255-7.471A7.999 7.999 0 0 1 8 433.5h50.49l-.41-336.67C58.039 60.2 87.661 30.5 124.33 30.5H128c23.309 0 43.838 12.148 55.585 30.442 2.973 4.631.459 11.281-6.06 12.052zM186 88.5c-30.88 0-56 25.12-56 56v14c0 4.42 3.58 8 8 8h96c4.42 0 8-3.58 8-8v-14c0-30.88-25.12-56-56-56zm194.059 352.766c-.214-7.007-.079-22.009-.061-58.76a8.002 8.002 0 0 0-8-8.006H214.002a8.002 8.002 0 0 0-8 8.006c.019 36.755.154 51.755-.06 58.761-.138 4.51 3.484 8.234 7.996 8.234h158.125c4.512-.001 8.133-3.725 7.996-8.235zM444.13 308.5h-12.26c-19.78 0-35.87 16.09-35.87 35.87v97.13c0 4.42 3.58 8 8 8h40.13c19.78 0 35.87-16.09 35.87-35.87v-69.26c0-19.78-16.09-35.87-35.87-35.87zm-290 0h-12.26c-19.78 0-35.87 16.09-35.87 35.87v69.26c0 19.78 16.09 35.87 35.87 35.87H182c4.42 0 8-3.58 8-8v-97.13c0-19.78-16.09-35.87-35.87-35.87zm122.87-95h-94.16c-23.66 0-42.84 19.18-42.84 42.84v28.16a7.998 7.998 0 0 0 7.994 8l6.128.001c28.65 0 51.878 23.224 51.878 51.874v6.126a8 8 0 0 0 8 8h63a8 8 0 0 0 8-8v-129a8 8 0 0 0-8-8.001zm124.16 0H309a8 8 0 0 0-8 8v129a8 8 0 0 0 8 8h63a8 8 0 0 0 8-8v-6.13c0-28.576 23.243-51.87 51.87-51.87H436a8 8 0 0 0 8-8v-28.16c0-23.66-19.18-42.84-42.84-42.84z'
        }
      />
    </svg>
  )
}
