import { CoreFirestore } from "../../../coreFirebase";
import { Encryption } from "../../database/encryption";
import {
  OmitKeys,
  OptionalSimpleTypeKeysOf,
  SimpleTypeKeysOf,
  UpdateObject,
  buildObjectUpdate,
  optionalDateEqual,
  validateStringNotEmpty,
} from "../../utils";
import { ActionType, ItemActions } from "./base";
import { Amount, PathsOfDateField } from "../common";
import {
  EncryptedType,
  EncryptionField,
  doRemoveEncryptedFields,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";
import { InvalidInput } from "../error";

export interface SoldInfo extends ItemActions {
  actionType: ActionType.MarkAsSold;
  title: string;
  detail: AuctionSoldDetail | DirectSoldDetail;
  saleDate: Date;
  taxPayable: Amount;
  // @Encrypted
  buyer?: string; //not an Id
  invoiceNumber?: string;
  invoiceDate?: Date;
  inventoryNumber?: string;
}
export namespace SoldInfo {
  export const datePaths: readonly PathsOfDateField<SoldInfo>[] = [
    "createAt",
    "updateAt",
    "saleDate",
    "invoiceDate",
    //#HACK
    <PathsOfDateField<SoldInfo>>"detail.auctionDate",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<SoldInfo> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }

  export type CreateFields = OmitKeys<
    SoldInfo,
    ItemActions.CreateFieldsExcludeKeys
  >;

  export type EncryptedKeys = ItemActions.EncryptedKeys | "buyer";

  export type Encrypted = EncryptedType<SoldInfo, EncryptedKeys>;
  export type EncryptedPart = Pick<SoldInfo, EncryptedKeys>;
  export const encryptedKeysArray: readonly (keyof EncryptedPart)[] = [
    //ItemActions
    "notes",
    "file",
    //SoldInfo
    "buyer",
  ];
  export type Update = CreateFields;
  export type UpdateEncrypted = EncryptedType<Update, EncryptedKeys>;

  export function fromCreate(
    createFields: CreateFields,
    ownerId: string
  ): SoldInfo {
    return ItemActions.fromCreate(createFields, ownerId, ActionType.MarkAsSold);
  }

  const NonOptionalSimpleTypeUpdatableKeys: SimpleTypeKeysOf<Update>[] = [
    "title",
  ];
  const OptionalSimpleTypeUpdatableKeys: OptionalSimpleTypeKeysOf<Update>[] = [
    "buyer",
    "invoiceNumber",
    "inventoryNumber",
    "notes",
  ];
  export function intoUpdate(
    current: SoldInfo,
    update: Update
  ): UpdateObject<Update> {
    const result: UpdateObject<Update> = {
      ...ItemActions.intoUpdate(current, update),
      ...buildObjectUpdate(
        current,
        update,
        NonOptionalSimpleTypeUpdatableKeys,
        OptionalSimpleTypeUpdatableKeys
      ),
    };

    if (current.saleDate.getTime() !== update.saleDate.getTime())
      result.saleDate = update.saleDate;
    if (!optionalDateEqual(current.invoiceDate, update.invoiceDate))
      if (update.invoiceDate) result.invoiceDate = update.invoiceDate;
      else result.invoiceDate = null;
    if (!Amount.equal(current.taxPayable, update.taxPayable)) {
      result.taxPayable = update.taxPayable;
    }
    if (current.detail.saleType !== update.detail.saleType) {
      result.detail = update.detail;
    } else if (current.detail.saleType == SaleType.DirectSale) {
      if (
        !directSoldDetailEqual(current.detail, <DirectSoldDetail>update.detail)
      ) {
        result.detail = update.detail;
      }
    } else if (
      !auctionSoldDetailEqual(current.detail, <AuctionSoldDetail>update.detail)
    ) {
      result.detail = update.detail;
    }
    return result;
  }

  export function validateEncryptedPart(data: EncryptedPart) {
    ItemActions.validateEncryptedPart(data);
  }
  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    if (isCreate && !data.saleDate)
      throw new InvalidInput("Sale date is required");
    if ((isCreate || data.title) && !validateStringNotEmpty(data.title)) {
      throw new InvalidInput("Title cannot be empty");
    }
    if (isCreate || data.detail) {
      if (data.detail!.saleType === SaleType.AuctionSale) {
        Amount.validate("totalHammerPrice", data.detail!.totalHammerPrice);
        if (data.detail!.totalHammerPrice.value < 0) {
          throw new InvalidInput("Hammer price cannot be negative");
        }
        if (!validateStringNotEmpty(data.detail!.auctionName)) {
          throw new InvalidInput("Auction name cannot be empty");
        }
        if (!validateStringNotEmpty(data.detail!.lotNumber)) {
          throw new InvalidInput("Lot number cannot be empty");
        }
        if (!validateStringNotEmpty(data.detail!.paddleNumber)) {
          throw new InvalidInput("Paddle number cannot be empty");
        }
      } else {
        Amount.validate("salePrice", data.detail!.salePrice);
        if (data.detail!.salePrice.value < 0) {
          throw new InvalidInput("Sale price cannot be negative");
        }
        if (data.detail!.discount) {
          Amount.validate("discount", data.detail!.discount);
          if (data.detail!.discount.value < 0) {
            throw new InvalidInput("Discount cannot be negative");
          }
        }
      }
    }
    if (isCreate || data.taxPayable) {
      Amount.validate("taxPayable", data.taxPayable!);
      if (data.taxPayable!.value < 0) {
        throw new InvalidInput("Tax payable cannot be negative");
      }
    }
  }

  export function removeEncryptedFields<
    T extends SoldInfo | UpdateObject<SoldInfo>
  >(data: T): OmitKeys<T, EncryptedKeys> {
    return doRemoveEncryptedFields(data, encryptedKeysArray);
  }

  export async function encrypt(
    rawData: SoldInfo,
    encryption: Encryption
  ): Promise<Encrypted> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryption(rawData, encryptedKeysArray, encryption);
  }
  export const decrypt = fullObjectDecryption<SoldInfo, EncryptedKeys>;
}

export enum SaleType {
  DirectSale = "DirectSale",
  AuctionSale = "AuctionSale",
}

export const saleTypeValues = Object.values(SaleType);

export interface DirectSoldDetail {
  saleType: SaleType.DirectSale;
  salePrice: Amount;
  discount?: Amount;
}
function directSoldDetailEqual(
  a: DirectSoldDetail,
  b: DirectSoldDetail
): boolean {
  return (
    Amount.equal(a.salePrice, b.salePrice) &&
    Amount.optionalEqual(a.discount, b.discount)
  );
}

export interface AuctionSoldDetail {
  saleType: SaleType.AuctionSale;
  totalHammerPrice: Amount;
  auctionName: string;
  auctionDate: Date;
  lotNumber: string;
  paddleNumber: string;
}
function auctionSoldDetailEqual(
  a: AuctionSoldDetail,
  b: AuctionSoldDetail
): boolean {
  return (
    Amount.equal(a.totalHammerPrice, b.totalHammerPrice) &&
    a.auctionDate.getTime() === b.auctionDate.getTime() &&
    a.auctionName === b.auctionName &&
    a.lotNumber === b.lotNumber &&
    a.paddleNumber === b.paddleNumber
  );
}
