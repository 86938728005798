import { useCallback, useEffect, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { Controller, useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import useSWR from 'swr'

import { AssetType, AttachmentKind, Currency } from 'core/remodel/types/common'
import type { Cryptocurrency } from 'core/remodel/types/cryptocurrencies'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { cn } from '@/utils/classnames'
import { resolveNumberFormat } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, FormCreatableAutocomplete, FormInput, FormPercentInput, Input, Separator } from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type CryptoValues = Cryptocurrency

const defaultValues: Partial<CryptoValues> = {
  assetType: AssetType.Cryptocurrency,
  subtype: '-',
  // primary details
  name: '',
  coins: [],
  // ownership
  ownership: { myOwnership: 100, shareholder: [] },
  beneficiary: [],
  // attachments
  attachments: [],
  mainImage: undefined
}

interface CryptoFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  values?: CryptoValues
  onSubmit: SubmitHandler<CryptoValues>
  onCancel: () => void
}

export function CryptoForm({ mode = 'Create', assetId, values, onSubmit, onCancel }: CryptoFormProps) {
  const { t } = useTranslation()
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset
  } = useForm<CryptoValues>({ defaultValues })
  const myOwnership = watch('ownership.myOwnership')
  const shareholder = watch('ownership.shareholder')
  const beneficiary = watch('beneficiary')
  const coinArray = useFieldArray({ control, name: 'coins' })
  const shareholderArray = useFieldArray({
    control,
    name: 'ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  // preferences and options
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const formatConfig = resolveNumberFormat(preferences.numberFormat)
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [{ key: 'ownership', label: t('Ownership') }]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon name={'attachment'} />
    }
  ]

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  useEffect(() => {
    if (mode === 'Create' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form
        className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <FormInput
              control={control}
              name={'name'}
              label={t('finances:Field.AccountName')}
              rules={{ required: true }}
            />
            <div className={'col-span-2'}>
              <Button
                className={'group justify-start gap-x-3 justify-self-start py-2'}
                onClick={() =>
                  coinArray.append({
                    id: database!.genAssetId(),
                    coinName: '',
                    unit: '0',
                    investedValue: { currency: Currency.USD, value: 0 },
                    createAt: new Date(),
                    updateAt: new Date()
                  })
                }
              >
                <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                  <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                </div>
                <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                  {t('finances:Field.Coins')}
                </span>
              </Button>
            </div>
            {coinArray.fields.length > 0 && (
              <div className={'col-span-2 flex flex-col gap-y-2'}>
                <div className={'grid grid-cols-[100px_1fr_1fr_60px] gap-x-2 md:grid-cols-[200px_1fr_1fr_40px]'}>
                  <p className={'text-[13px]'}>
                    {t('finances:Field.CoinName')}
                    <span className={'text-[#C3626E]'}>{' *'}</span>
                  </p>
                  <p className={'text-[13px]'}>{t('finances:Field.Unit')}</p>
                  <p className={'text-[13px]'}>{t('finances:Field.CurrentPrice')}</p>
                </div>
                <Separator />
                {coinArray.fields.map((field, index) => (
                  <div
                    key={field.id}
                    className={'grid grid-cols-[100px_1fr_1fr_60px] gap-x-2 md:grid-cols-[200px_1fr_1fr_40px]'}
                  >
                    {/* TODO should use `FormAutocomplete` to search coin, and auto fill `investedValue` */}
                    <FormInput
                      control={control}
                      name={`coins.${index}.coinName`}
                      placeholder={t('Search')}
                      rules={{ required: true }}
                    />
                    <Controller
                      control={control}
                      name={`coins.${index}.unit`}
                      render={({ field }) => {
                        const { ref, onChange, ...props } = field
                        const { decimalCharacter, digitGroupSeparator } = formatConfig
                        return (
                          <NumericFormat
                            className={'h-[38px] border text-right'}
                            customInput={Input}
                            getInputRef={ref}
                            onValueChange={({ value }) => onChange(value)}
                            decimalScale={2}
                            decimalSeparator={decimalCharacter}
                            thousandSeparator={digitGroupSeparator}
                            isAllowed={({ floatValue }) => {
                              if (floatValue === undefined) return true
                              return floatValue <= 999999999999
                            }}
                            {...props}
                          />
                        )
                      }}
                    />
                    {/* TODO should get current price from api */}
                    <div className={'flex h-[38px] items-center justify-between rounded border bg-grey-input px-3'}>
                      <p className={'text-sm text-[#414554] opacity-50'}>{'USD'}</p>
                      <p className={'text-sm text-[#414554] opacity-50'}>{'0'}</p>
                    </div>
                    <Button
                      className={'rounded-full transition-colors hover:bg-grey/20'}
                      onClick={() => coinArray.remove(index)}
                    >
                      <XIcon className={'text-primary'} />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </TabPanel.Section>
          <TabPanel.Section value={'additional.ownership'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'space-y-4'}>
                <div className={'grid gap-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Ownership')}</span>
                    {remainingOwnership === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingOwnership > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingOwnership })}
                      </span>
                    )}
                    {remainingOwnership < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                  </div>
                  <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                    <Input className={'h-[38px] border'} defaultValue={t('Field.MyOwnership')} disabled={true} />
                    <FormPercentInput control={control} name={`ownership.myOwnership`} />
                  </div>
                </div>

                <div className={'grid gap-1'}>
                  <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                  <ul className={'space-y-2 empty:hidden'}>
                    {shareholderArray.fields.map((shareholder, index) => (
                      <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`ownership.shareholder.${index}.contactId`}
                          options={ShareholderUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`ownership.shareholder.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => shareholderArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddShareholders')}
                    </span>
                  </Button>
                </div>
              </div>
              <div className={'space-y-1'}>
                <div className={'flex items-center justify-between pr-10'}>
                  <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                  {remainingBeneficiary === 0 && (
                    <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                  )}
                  {remainingBeneficiary > 0 && (
                    <span className={'text-xs text-[#d89423]'}>
                      {t('RemainingPercent', { percent: remainingBeneficiary })}
                    </span>
                  )}
                  {remainingBeneficiary < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                </div>
                <ul className={'space-y-2 empty:hidden'}>
                  {beneficiaryArray.fields.map((beneficiary, index) => (
                    <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <FormCreatableAutocomplete
                        control={control}
                        name={`beneficiary.${index}.contactId`}
                        options={beneficiaryUnselectContact(index)}
                        onCreate={addNewContact}
                        isLoading={loadingContacts}
                        placeholder={t('SearchOrCreate')}
                      />
                      <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
                      <Button
                        className={'rounded-full transition-colors hover:bg-grey/20'}
                        onClick={() => beneficiaryArray.remove(index)}
                      >
                        <XIcon className={'text-primary'} />
                      </Button>
                    </li>
                  ))}
                </ul>
                <Button
                  className={'group justify-start gap-x-3 justify-self-start py-2'}
                  onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                >
                  <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                    <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                  </div>
                  <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                    {t('AddBeneficiaries')}
                  </span>
                </Button>
              </div>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            {assetId && (
              <AttachmentPanel
                assetId={assetId}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                widgetOptions={[AttachmentKind.PrimaryDetails]}
              />
            )}
          </TabPanel.Section>
        </div>
        <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
          <Button
            id={'finances_add_crypto_cancel'}
            className={'min-w-[130px]'}
            variant={'outline'}
            size={'md'}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          <Button
            id={'finances_add_crypto_create'}
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function CryptoFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
