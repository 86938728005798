import { Encrypted } from "../../database/encryption";
import { OmitKeys, UpdateObject } from "../../utils";
import {
  Activity,
  ActivityKind,
  EventToActivitiesFunction,
  activitySortKeyMap,
  validateActMapFuncKnowEventKind,
} from "../activities";
import { genActivityLogs } from "../activityUtils";
import { SharedCommand, ValuationCommand } from "../command";
import { SupportActivityType } from "../common";
import { SharedEvent, ValuationEvent } from "../event";
import { Belonging } from "./belonging";
import { BelongingsUtils } from "./belongingsUtils";

//#NOTES: Move Command and Event code to this file

export namespace Command {
  export type Kind = SharedCommand.Kind | ValuationCommand.Kind;
  export const Kind = { ...SharedCommand.Kind, ...ValuationCommand.Kind };

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Encrypted<Belonging>> {}
  export const createAsset = SharedCommand.createAsset<Encrypted<Belonging>>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Encrypted<Belonging>>> {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Encrypted<Belonging>>
  >;
  export interface RelocateAsset extends SharedCommand.RelocateAsset {}
  export const relocateAsset = SharedCommand.relocateAsset;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
  export interface AddInsurance extends SharedCommand.AddInsurance {}
  export const addInsurance = SharedCommand.addInsurance;
  export interface RemoveInsurance extends SharedCommand.RemoveInsurance {}
  export const removeInsurance = SharedCommand.removeInsurance;

  export interface AddValuation extends ValuationCommand.AddValuation {}
  export const addValuation = ValuationCommand.addValuation;
  export interface UpdateValuation extends ValuationCommand.UpdateValuation {}
  export const updateValuation = ValuationCommand.updateValuation;
  export interface DeleteValuation extends ValuationCommand.DeleteValuation {}
  export const deleteValuation = ValuationCommand.deleteValuation;
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.RelocateAsset
  | Command.DeleteAsset
  | Command.AddInsurance
  | Command.RemoveInsurance
  | Command.AddValuation
  | Command.UpdateValuation
  | Command.DeleteValuation;

export namespace Event {
  export type Kind = SharedEvent.Kind | ValuationEvent.Kind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...ValuationEvent.Kind,
  };

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Encrypted<Belonging>> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Encrypted<Belonging>>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface LocationUpdated extends SharedEvent.LocationUpdated {}
  export interface ValueUpdated extends SharedEvent.ValueUpdated {}
  export interface InsuranceUpdated extends SharedEvent.InsuranceUpdated {}
  export interface ImageAdded extends SharedEvent.ImageAdded {}
  export interface MainImageSet extends SharedEvent.MainImageSet {}
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface ValuationAdded extends ValuationEvent.ValuationAdded {}
  export interface ValuationUpdated extends ValuationEvent.ValuationUpdated {}
  export interface ValuationDeleted extends ValuationEvent.ValuationDeleted {}
  export const toActivities: EventToActivitiesFunction<Event> = (
    event: Event,
    assetType: SupportActivityType,
    assetId: string,
    ownerId: string,
    time: Date
  ) => {
    const baseFields: OmitKeys<Activity.Base, "activityKind"> = {
      assetId,
      ownerId,
      sortKey: 0,
      executerId: event.executerId,
      time,
    };
    validateActMapFuncKnowEventKind(assetType, [event]);
    const needProcessEventKinds: Event.Kind[] = [
      Event.Kind.AssetDeleted,
      Event.Kind.AssetUpdated,
      Event.Kind.ValueUpdated,
      Event.Kind.ShareholderUpdated,
      Event.Kind.BeneficiaryUpdated,
      Event.Kind.LocationUpdated,
      Event.Kind.ImageAdded,
      Event.Kind.ImageRemoved,
      Event.Kind.MainImageSet,
      Event.Kind.GroupsUpdated,
    ];

    let activities: Encrypted<Activity>[] = [];
    switch (event.kind) {
      case Event.Kind.AssetCreated: {
        const activity: Encrypted<Activity> = {
          ...baseFields,
          activityKind: ActivityKind.AssetCreated,
          detail: {
            assetName: event.asset.name,
            value: event.asset.value,
            // for summary logs
            items: event.asset.number,
            subtype: event.asset.subtype,
          },
        };
        activities = [activity];
        break;
      }
      default:
        return genActivityLogs(
          needProcessEventKinds,
          event,
          assetType,
          assetId,
          ownerId,
          time,
          BelongingsUtils.primaryDetailKeys,
          BelongingsUtils.attributeKeys
        );
    }
    return activities.map((activity) => ({
      ...activity,
      sortKey: activitySortKeyMap[activity.activityKind!],
    }));
  };
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.LocationUpdated
  | Event.ValuationAdded
  | Event.ValuationUpdated
  | Event.ValuationDeleted
  | Event.ValueUpdated
  | Event.InsuranceUpdated
  | Event.ImageAdded
  | Event.MainImageSet
  | Event.GroupsUpdated;
