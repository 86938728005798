import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M2%2031.93h3V12.753a6.77%206.77%200%200%201%205.465-6.643%205.776%205.776%200%200%201%206.694%204.176l.065.243-5.568%203.214a3%203%200%200%200-1.098%204.098l1.5%202.599%2018.187-10.5-1.5-2.599a3%203%200%200%200-4.098-1.098l-4.748%202.742A8.776%208.776%200%200%200%209.886%203.166%209.77%209.77%200%200%200%202%2012.753V31.93Zm27.147-17.892a1%201%200%200%200-1.732%201l4%206.928a1%201%200%201%200%201.732-1l-4-6.928Zm-6.996%202.884a1%201%200%200%201%201.366.366l4%206.928a1%201%200%200%201-1.732%201l-4-6.928a1%201%200%200%201%20.366-1.366Zm5.532%202.58a1%201%200%200%201%201.366.366l4%206.928a1%201%200%200%201-1.733%201l-4-6.928a1%201%200%200%201%20.367-1.366Zm-11.16.67a1%201%200%200%201%201.365.366l4%206.928a1%201%200%201%201-1.732%201l-4-6.928a1%201%200%200%201%20.366-1.366Zm6.463%203.196a1%201%200%201%200-1.732%201l4%206.928a1%201%200%200%200%201.732-1l-4-6.928Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ShowerRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M2 31.93h3V12.753a6.77 6.77 0 0 1 5.465-6.643 5.776 5.776 0 0 1 6.694 4.176l.065.243-5.568 3.214a3 3 0 0 0-1.098 4.098l1.5 2.599 18.187-10.5-1.5-2.599a3 3 0 0 0-4.098-1.098l-4.748 2.742A8.776 8.776 0 0 0 9.886 3.166 9.77 9.77 0 0 0 2 12.753V31.93Zm27.147-17.892a1 1 0 0 0-1.732 1l4 6.928a1 1 0 1 0 1.732-1l-4-6.928Zm-6.996 2.884a1 1 0 0 1 1.366.366l4 6.928a1 1 0 0 1-1.732 1l-4-6.928a1 1 0 0 1 .366-1.366Zm5.532 2.58a1 1 0 0 1 1.366.366l4 6.928a1 1 0 0 1-1.733 1l-4-6.928a1 1 0 0 1 .367-1.366Zm-11.16.67a1 1 0 0 1 1.365.366l4 6.928a1 1 0 1 1-1.732 1l-4-6.928a1 1 0 0 1 .366-1.366Zm6.463 3.196a1 1 0 1 0-1.732 1l4 6.928a1 1 0 0 0 1.732-1l-4-6.928Z'
        }
      />
    </svg>
  )
}
