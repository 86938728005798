import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M33%200h-9v11h9V0Zm-8%201h7v9h-7V1Zm5%207v1h1V3a3%203%200%200%200-2.713%201.718A3.995%203.995%200%200%201%2030%208Zm-16.654%208A17.096%2017.096%200%200%201%2013%2012.5C13%208.358%2014.343%205%2016%205s3%203.358%203%207.5a17.09%2017.09%200%200%201-.346%203.5h-5.308ZM26.001%205H26v4h3V7.999q0-.147-.014-.294-.015-.147-.044-.291-.028-.145-.071-.286-.043-.14-.1-.277-.056-.136-.125-.266-.07-.13-.151-.252-.082-.123-.176-.237-.093-.113-.197-.218-.105-.104-.218-.197-.114-.094-.237-.176-.122-.081-.252-.15-.13-.07-.266-.127-.136-.056-.277-.099-.141-.043-.286-.071-.144-.03-.291-.044Q26.148%205%2026.001%205ZM10%209H4v7h6V9Zm-5%201h4v5H5v-5Zm25.354%2012h-.707l.335-5.046a2.5%202.5%200%201%200-1.002-.008l-.207%203.127a2%202%200%201%200-2.246%202.87L25%2035h7l-1.646-13ZM2%2017h21v2h-1v5.001q0%20.098-.02.195-.018.096-.056.187-.038.091-.092.173-.055.082-.125.151-.07.07-.151.125-.082.054-.173.092-.09.038-.187.057Q21.099%2025%2021%2025v10h-2V25H6v10H4V25h-.001q-.098%200-.195-.02-.096-.018-.187-.056-.091-.038-.173-.092-.082-.055-.151-.125-.07-.07-.125-.151-.054-.082-.092-.173-.038-.09-.057-.187Q3%2024.099%203%2024v-5H2v-2Zm11.5%205a1%201%200%201%201-2%200%201%201%200%200%201%202%200Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function HallIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M33 0h-9v11h9V0Zm-8 1h7v9h-7V1Zm5 7v1h1V3a3 3 0 0 0-2.713 1.718A3.995 3.995 0 0 1 30 8Zm-16.654 8A17.096 17.096 0 0 1 13 12.5C13 8.358 14.343 5 16 5s3 3.358 3 7.5c0 1.264-.125 2.455-.346 3.5h-5.308ZM26.001 5H26v4h3V7.999q0-.147-.014-.294-.015-.147-.044-.291-.028-.145-.071-.286-.043-.14-.1-.277-.056-.136-.125-.266-.07-.13-.151-.252-.082-.123-.176-.237-.093-.113-.197-.218-.105-.104-.218-.197-.114-.094-.237-.176-.122-.081-.252-.15-.13-.07-.266-.127-.136-.056-.277-.099-.141-.043-.286-.071-.144-.03-.291-.044Q26.148 5 26.001 5ZM10 9H4v7h6V9Zm-5 1h4v5H5v-5Zm25.354 12h-.707l.335-5.046a2.5 2.5 0 1 0-1.002-.008l-.207 3.127a2 2 0 1 0-2.246 2.87L25 35h7l-1.646-13ZM2 17h21v2h-1v5.001q0 .098-.02.195-.018.096-.056.187-.038.091-.092.173-.055.082-.125.151-.07.07-.151.125-.082.054-.173.092-.09.038-.187.057Q21.099 25 21 25H21v10h-2V25H6v10H4V25h-.001q-.098 0-.195-.02-.096-.018-.187-.056-.091-.038-.173-.092-.082-.055-.151-.125-.07-.07-.125-.151-.054-.082-.092-.173-.038-.09-.057-.187Q3 24.099 3 24V19H2v-2Zm11.5 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z'
        }
      />
    </svg>
  )
}
