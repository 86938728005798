import { useCallback, useEffect, useMemo, useState } from 'react'
import { add } from 'date-fns'
import { Loader2Icon } from 'lucide-react'
import {
  useFieldArray,
  useForm,
  type Control,
  type FieldValues,
  type Path,
  type PathValue,
  type SubmitHandler
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import {
  AssetType,
  AttachmentKind,
  Currency,
  MeasurementUnits,
  measurementUnitValues,
  Period,
  periodValues
} from 'core/remodel/types/common'
import { AcquisitionType, acquisitionTypeValues } from 'core/remodel/types/common/acquisition'
import { CountryOptions, ownershipTypeOptions } from 'core/remodel/types/options'
import {
  BathroomType,
  bathroomTypeValues,
  OtherRoomType,
  otherRoomTypeValues,
  Property,
  RentalAgreementType,
  rentalAgreementTypeValues,
  type Bathroom,
  type Bedroom,
  type CarPark,
  type OtherRoom
} from 'core/remodel/types/properties'
import * as PropertyNameSpace from 'core/remodel/types/properties'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { sqFtToSqM, sqMToSqFt } from '@/constants/unit'
import { cn } from '@/utils/classnames'
import { formatDate, formatNumber, makeOptions } from '@/utils/formatter'
import { isOwnerDetail } from '@/utils/predicate'
import { useManualEditTracker } from '@/hooks/useManualEditTracker'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCheckbox,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormInsurancePicker,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  Input,
  Modal,
  QuantityInput,
  SelectItem,
  Separator,
  TypePicker
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import TruncatedText from '@/components/base/TruncatedText'
import Confirm from '@/components/Confirm'
import {
  AttachmentIcon,
  AtticIcon,
  BallroomIcon,
  BarIcon,
  BasementIcon,
  BathroomIcon,
  BootRoomIcon,
  CarParkIcon,
  CellarIcon,
  CloakRoomIcon,
  ConservatoryIcon,
  CouchBedIcon,
  DanceRoomIcon,
  DiningRoomIcon,
  DoubleBedIcon,
  DressingRoomIcon,
  ElectricalRoomIcon,
  FitnessRoomIcon,
  GamesRoomIcon,
  GearIcon,
  GenkanIcon,
  HallIcon,
  KitchenIcon,
  LandingIcon,
  LarderIcon,
  LaundryRoomIcon,
  LibraryIcon,
  LivingRoomIcon,
  MovieTheatreIcon,
  MusicRoomIcon,
  OfficeIcon,
  OtherRoomIcon,
  ParlourIcon,
  PlusIcon,
  SaunaIcon,
  ShowerRoomIcon,
  SingleBedIcon,
  SnugIcon,
  StairwellIcon,
  StarIcon,
  StoreRoomIcon,
  SwimmingPoolIcon,
  WCIcon,
  WorkshopIcon,
  XIcon
} from '@/components/icon'
import { PlacesAutocomplete, type Place } from '@/components/PlacesAutocomplete'
import { TabPanel, type Tab } from '@/components/TabPanel'

type RoomType = 'bedroom' | 'bathroom' | 'otherRoom' | 'carPark'

const defaultBedroom = {
  kingBedNum: 0,
  bunkBedNum: 0,
  queenBedNum: 0,
  cotBedNum: 0,
  doubleBedNum: 0,
  couchBedNum: 0,
  singleBedNum: 0,
  position: [],
  size: 0
}
const defaultBathroom = {
  bathroomType: BathroomType.Bathroom,
  position: [],
  size: 0
}
const defaultOtherRoom = {
  otherRoomType: OtherRoomType.Attic,
  position: [],
  size: 0
}
const defaultCarPark = {
  parkingLotNum: 1,
  position: [],
  size: 0
}

export type PropertyValues = Property

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<PropertyValues> => ({
  assetType: AssetType.Property,
  // primary details
  subtype: PropertyNameSpace.Type.House,
  ownershipType: PropertyNameSpace.OwnershipType.Own,
  personalRefNo: '',
  name: '',
  startDate: new Date(),
  price: { currency, value: 0 },
  totalCost: { currency, value: 0 },
  value: { currency, value: 0 },
  groupIds: [],
  notes: '',
  detail: {
    // acquisition
    acquisition: {
      acquisitionType: AcquisitionType.Direct,
      auctionDetail: {
        hammerPrice: { currency, value: 0 },
        taxPayable: { currency, value: 0 },
        buyerPremium: { currency, value: 0 }
      },
      otherCost: { currency, value: 0 },
      insuranceIds: [],
      sellerId: '',
      priceAsValue: false,
      notes: '',
      initialValuationAtPurchase: { currency, value: 0 }
    },
    // ownership
    ownership: { myOwnership: 100, shareholder: [] },
    beneficiary: [],
    // rental details
    agreementType: RentalAgreementType.FixedTerm,
    monthlyRental: { currency, value: 0 },
    securityDeposit: { currency, value: 0 },
    landlordId: '',
    otherFees: '',
    notes: '',
    term: { period: Period.Month, num: 1 }
  },
  // location
  location: {
    '@type': 'com.myassets/ContactLocation/v2',
    address: '',
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    town: '',
    zipCode: '',
    center: { lat: 0, lng: 0 }
  },
  // configuration
  configuration: {
    unit: MeasurementUnits.Metric,
    bedroom: [],
    bathroom: [],
    otherRoom: [],
    carPark: [],
    landArea: 0,
    internalArea: 0,
    patiosArea: 0
  },
  // attachments
  attachments: [],
  mainImage: undefined
})

const preprocessValues = (values: PropertyValues) => {
  const { detail, value, totalCost } = values
  const priceAsValueConditions = [
    isOwnerDetail(detail) && detail.acquisition.priceAsValue,
    value.currency === totalCost.currency,
    value.value === totalCost.value
  ]
  const newPriceAsValue = priceAsValueConditions.every(Boolean)

  return {
    ...values,
    detail: isOwnerDetail(detail)
      ? {
          ...detail,
          acquisition: {
            ...detail.acquisition,
            priceAsValue: newPriceAsValue
          }
        }
      : detail
  }
}

interface PropertyFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  values?: PropertyValues
  tabsAttachment?: boolean
  onSubmit: SubmitHandler<PropertyValues>
  onCancel: () => void
}

export function PropertyForm({
  mode = 'Create',
  assetId,
  values,
  tabsAttachment = false,
  onCancel,
  onSubmit
}: PropertyFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  const preprocessedValues = values && preprocessValues(values)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<PropertyValues>({
    defaultValues: values
      ? {
          ...defaultValues,
          ...preprocessedValues,
          detail: {
            ...defaultValues.detail,
            ...preprocessedValues!.detail
          }
        }
      : defaultValues
  })
  // State for tracking user edits
  const { trackManualEdit, isManuallyEdited } = useManualEditTracker<PropertyValues>()
  const shouldUpdateCurrentValue = !isManuallyEdited('value') && mode === 'Create'
  const shouldUpdateInitialValue =
    !isManuallyEdited('detail.acquisition.initialValuationAtPurchase') && mode === 'Create'

  // Price-related fields
  const price = watch('price.value')
  const currentValuation = watch('value.value')
  const initialValuationAtPurchase = watch('detail.acquisition.initialValuationAtPurchase.value')
  const hammerPrice = watch('detail.acquisition.auctionDetail.hammerPrice.value')
  const otherCost = watch('detail.acquisition.otherCost.value')
  const taxPayable = watch('detail.acquisition.auctionDetail.taxPayable.value')
  const buyerPremium = watch('detail.acquisition.auctionDetail.buyerPremium.value')
  const totalCostValue = watch('totalCost.value')
  const totalCostCurrency = watch('totalCost.currency')
  const priceAsValue = watch('detail.acquisition.priceAsValue')
  // Date and term-related fields
  const startDate = watch('startDate')
  const termPeriod = watch('detail.term.period')
  const termNum = watch('detail.term.num')
  // Ownership and acquisition type
  const ownershipType = watch('ownershipType')
  const isOwnerType = ownershipType === PropertyNameSpace.OwnershipType.Own
  const isRentType = ownershipType === PropertyNameSpace.OwnershipType.Rent
  const acquisitionType = watch('detail.acquisition.acquisitionType')
  const isDirect = acquisitionType === AcquisitionType.Direct
  const isAuction = acquisitionType === AcquisitionType.Auction
  // Measurement and area fields
  const measurementUnits = watch('configuration.unit')
  const internalArea = watch('configuration.internalArea')
  const patiosArea = watch('configuration.patiosArea')
  const landArea = watch('configuration.landArea')
  // Ownership details
  const myOwnership = watch('detail.ownership.myOwnership')
  const shareholder = watch('detail.ownership.shareholder')
  const beneficiary = watch('detail.beneficiary')
  // Field arrays for room configuration
  const bedrooms = useFieldArray({ control, name: 'configuration.bedroom', keyName: 'key' })
  const bathrooms = useFieldArray({ control, name: 'configuration.bathroom', keyName: 'key' })
  const otherRooms = useFieldArray({ control, name: 'configuration.otherRoom', keyName: 'key' })
  const carParks = useFieldArray({ control, name: 'configuration.carPark', keyName: 'key' })
  const shareholderArray = useFieldArray({
    control,
    name: 'detail.ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners?.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners?.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'detail.beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners?.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners?.every((owner) => owner.contactId !== '')
      }
    }
  })
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const unit = useMemo(() => (measurementUnits === MeasurementUnits.Metric ? 'm²' : 'ft²'), [measurementUnits])
  const endDate = useMemo(() => {
    if (isNaN(termNum)) return
    switch (termPeriod) {
      case Period.Day:
        return add(startDate, { days: termNum })
      case Period.Week:
        return add(startDate, { weeks: termNum })
      case Period.Month:
        return add(startDate, { months: termNum })
      case Period.Quarter:
        return add(startDate, { months: termNum * 3 })
      case Period.Year:
        return add(startDate, { years: termNum })
    }
  }, [startDate, termNum, termPeriod])
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  // preferences and options
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const propertyTypeOptions = makeOptions(
    PropertyNameSpace.typeValues,
    (key) => `properties:PropertyTypeOptions.${key}`
  )
  const bathroomOptions = makeOptions(bathroomTypeValues, (key) => `properties:BathroomTypeOptions.${key}`)
  const otherRoomOptions = makeOptions(otherRoomTypeValues, (key) => `properties:OtherRoomTypeOptions.${key}`)
  const rentalAgreementOptions = makeOptions(
    rentalAgreementTypeValues,
    (key) => `properties:RentalAgreementTypeOptions.${key}`
  )
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  const measurementUnitOptions = makeOptions(measurementUnitValues, (key) => `MeasurementUnits.${key}`)
  const acquisitionTypeOptions = makeOptions(acquisitionTypeValues, (key) => `AcquisitionTypeOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'acquisition', label: t('Acquisition'), hidden: isRentType },
        {
          key: 'rental',
          label: t('properties:RentalDetails'),
          hidden: isOwnerType
        },
        { key: 'location', label: t('Location') },
        { key: 'configuration', label: t('Configuration') },
        { key: 'ownership', label: t('Ownership'), hidden: isRentType }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]
  const defaultTab = tabsAttachment ? 'attachments' : tabs[0].key

  const quantityInputs = [
    { label: t('Field.Bedrooms'), value: bedrooms.fields.length, type: 'bedroom' },
    { label: t('Field.Bathrooms'), value: bathrooms.fields.length, type: 'bathroom' },
    { label: t('Field.OtherRooms'), value: otherRooms.fields.length, type: 'otherRoom' },
    { label: t('Field.CarParks'), value: carParks.fields.length, type: 'carPark' }
  ] as const

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  const handleSelectPlace = (place: Place) => {
    setValue('location.address', place.address)
    setValue('location.center', place.center)
    setValue('location.addressLine1', place.addressLine1)
    setValue('location.addressLine2', place.addressLine2)
    setValue('location.town', place.town)
    setValue('location.state', place.state)
    setValue('location.zipCode', place.zipCode)
    setValue('location.country', place.country)
  }

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const onUnitChanged = (prev: string, next: string) => {
    if (prev === MeasurementUnits.Metric && next === MeasurementUnits.Imperial) {
      setValue('configuration.internalArea', internalArea * sqMToSqFt)
      setValue('configuration.patiosArea', patiosArea * sqMToSqFt)
      setValue('configuration.landArea', landArea * sqMToSqFt)
      bedrooms.replace(bedrooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqMToSqFt })))
      bathrooms.replace(bathrooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqMToSqFt })))
      otherRooms.replace(otherRooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqMToSqFt })))
      carParks.replace(carParks.fields.map((carPark) => ({ ...carPark, size: (carPark.size ?? 0) * sqMToSqFt })))
    }
    if (prev === MeasurementUnits.Imperial && next === MeasurementUnits.Metric) {
      setValue('configuration.internalArea', internalArea * sqFtToSqM)
      setValue('configuration.patiosArea', patiosArea * sqFtToSqM)
      setValue('configuration.landArea', landArea * sqFtToSqM)
      bedrooms.replace(bedrooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqFtToSqM })))
      bathrooms.replace(bathrooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqFtToSqM })))
      otherRooms.replace(otherRooms.fields.map((room) => ({ ...room, size: (room.size ?? 0) * sqFtToSqM })))
      carParks.replace(carParks.fields.map((carPark) => ({ ...carPark, size: (carPark.size ?? 0) * sqFtToSqM })))
    }
  }

  const [roomIds, setRoomIds] = useState<string[]>([])

  const getNewRooms = (currentRoomCount: number, newRoomCount: number, label: string, defaultRoom: any) => {
    const rooms = Array.from({ length: newRoomCount }).map((_, i) => ({
      id: database!.genAssetId(),
      name: `${label} ${currentRoomCount + i + 1}`,
      ...defaultRoom
    }))

    return rooms
  }

  const handleRoomsChange = (value: number, type: RoomType) => {
    switch (type) {
      case 'bedroom': {
        const length = bedrooms.fields.length
        const diff = Math.abs(value - length)
        if (diff === 0) break
        if (value > length) {
          const newRooms = getNewRooms(bedrooms.fields.length, diff, t('Field.Bedroom'), defaultBedroom)

          bedrooms.append(newRooms)
        } else {
          const lastIndex = length - 1 // 2
          const startIndex = lastIndex - diff // 1
          const indexes = Array.from({ length: lastIndex - startIndex }, (_, index) => lastIndex - index)
          bedrooms.remove(indexes)
        }
        break
      }
      case 'bathroom': {
        const length = bathrooms.fields.length
        const diff = Math.abs(value - length)
        if (diff === 0) break
        if (value > length) {
          const newRooms = getNewRooms(
            bathrooms.fields.length,
            diff,
            t(`properties:BathroomTypeOptions.${defaultBathroom.bathroomType}`),
            defaultBathroom
          )
          bathrooms.append(newRooms)
        } else {
          const lastIndex = length - 1 // 2
          const startIndex = lastIndex - diff // 1
          const indexes = Array.from({ length: lastIndex - startIndex }, (_, index) => lastIndex - index)
          bathrooms.remove(indexes)
        }
        break
      }
      case 'otherRoom': {
        const length = otherRooms.fields.length
        const diff = Math.abs(value - length)
        if (diff === 0) break
        if (value > length) {
          const newRooms = getNewRooms(
            otherRooms.fields.length,
            diff,
            t(`properties:OtherRoomTypeOptions.${defaultOtherRoom.otherRoomType}`),
            defaultOtherRoom
          )
          otherRooms.append(newRooms)
        } else {
          const lastIndex = length - 1 // 2
          const startIndex = lastIndex - diff // 1
          const indexes = Array.from({ length: lastIndex - startIndex }, (_, index) => lastIndex - index)
          otherRooms.remove(indexes)
        }
        break
      }
      case 'carPark': {
        const length = carParks.fields.length
        const diff = Math.abs(value - length)
        if (diff === 0) break
        if (value > length) {
          const newRooms = getNewRooms(carParks.fields.length, diff, t('Field.CarPark'), defaultCarPark)
          carParks.append(newRooms)
        } else {
          const lastIndex = length - 1 // 2
          const startIndex = lastIndex - diff // 1
          const indexes = Array.from({ length: lastIndex - startIndex }, (_, index) => lastIndex - index)
          carParks.remove(indexes)
        }
        break
      }
    }
  }

  const handleRoomTypeChange = (
    label: string,
    index: number,
    currentRooms: { name: string }[],
    onTypeChange: (type: string) => void
  ) => {
    const checker = new RegExp(`^${label}`, 'i')
    const newNumber =
      [...currentRooms.slice(0, index), ...currentRooms.slice(index + 1)].filter(({ name }) => checker.test(name))
        .length + 1

    onTypeChange(`${label} ${newNumber}`)
  }

  const handleFieldValueChange = useCallback(
    (fieldName: Path<PropertyValues>, value: number | PropertyNameSpace.OwnershipType) => {
      switch (fieldName) {
        case 'price.value':
          setValue('detail.monthlyRental.value', value as number)
          setValue('detail.acquisition.auctionDetail.hammerPrice.value', value as number)
          shouldUpdateCurrentValue && setValue('value.value', value as number)
          shouldUpdateInitialValue && setValue('detail.acquisition.initialValuationAtPurchase.value', value as number)
          break
        case 'detail.acquisition.auctionDetail.hammerPrice.value':
          shouldUpdateInitialValue && setValue('detail.acquisition.initialValuationAtPurchase.value', value as number)
          break
        case 'detail.monthlyRental.value':
          setValue('price.value', value as number)
          setValue('detail.acquisition.auctionDetail.hammerPrice.value', value as number)
          setValue('value.value', 0) // rental asset should not have valuation
          setValue('detail.acquisition.initialValuationAtPurchase.value', 0)
          break
        case 'ownershipType':
          if ((value as PropertyNameSpace.OwnershipType) === PropertyNameSpace.OwnershipType.Rent) {
            setValue('value.value', 0) // rental asset should not have valuation
            setValue('detail.acquisition.initialValuationAtPurchase.value', 0)
          }
          if ((value as PropertyNameSpace.OwnershipType) === PropertyNameSpace.OwnershipType.Own) {
            shouldUpdateCurrentValue && setValue('value.value', price)
            shouldUpdateInitialValue && setValue('detail.acquisition.initialValuationAtPurchase.value', price)
          }
          break
      }
    },
    [shouldUpdateCurrentValue, shouldUpdateInitialValue, setValue, price]
  )

  const handleFieldCurrencyChange = useCallback(
    (_fieldName: Path<PropertyValues>, currency: Currency) => {
      const currencyFields: Path<PropertyValues>[] = [
        'price.currency',
        'totalCost.currency',
        'detail.monthlyRental.currency',
        'detail.acquisition.otherCost.currency',
        'detail.acquisition.auctionDetail.hammerPrice.currency',
        'detail.acquisition.auctionDetail.taxPayable.currency',
        'detail.acquisition.auctionDetail.buyerPremium.currency'
      ]
      shouldUpdateCurrentValue && currencyFields.push('value.currency')
      shouldUpdateInitialValue && currencyFields.push('detail.acquisition.initialValuationAtPurchase.currency')

      currencyFields.forEach((field) => setValue(field, currency))
    },
    [setValue, shouldUpdateCurrentValue, shouldUpdateInitialValue]
  )

  useEffect(() => {
    const basePrice = isDirect ? price : hammerPrice
    const additionalCosts = isDirect ? otherCost : taxPayable + buyerPremium + otherCost
    setValue('totalCost.value', basePrice + additionalCosts)
  }, [acquisitionType, buyerPremium, hammerPrice, isDirect, otherCost, price, setValue, taxPayable])

  useEffect(() => {
    if (priceAsValue) {
      setValue('value.value', totalCostValue)
      setValue('value.currency', totalCostCurrency)
    }
  }, [priceAsValue, totalCostCurrency, totalCostValue, setValue])

  return (
    <TabPanel defaultValue={defaultTab}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[95vh] grow flex-col gap-4 md:h-[700px]  '} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4 '}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormInput
                  control={control}
                  name={'name'}
                  label={t('properties:Field.PropertyName')}
                  type={'text'}
                  maxLength={50}
                  rules={{ required: true }}
                />
                <FormAutocomplete
                  control={control}
                  name={'subtype'}
                  label={t('properties:Field.PropertyType')}
                  options={propertyTypeOptions}
                  rules={{ required: true }}
                />
                <FormSelect
                  control={control}
                  name={'ownershipType'}
                  label={t('properties:Field.OwnershipType')}
                  onChanged={(value) => handleFieldValueChange('ownershipType', value)}
                >
                  {ownershipTypeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {t(`properties:OwnershipTypeOptions.${label as string}`)}
                    </SelectItem>
                  ))}
                </FormSelect>
                <FormInput
                  control={control}
                  name={'personalRefNo'}
                  label={t('Field.PersonalRefNo')}
                  type={'text'}
                  placeholder={assetId ?? ''}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <PlacesAutocomplete
                  control={control}
                  name={'location.address'}
                  label={t('properties:Field.PropertyAddress')}
                  onSelected={handleSelectPlace}
                  rules={{ required: t('validation:Required') }}
                />
                <FormDatePicker
                  control={control}
                  name={'startDate'}
                  label={isOwnerType ? t('Field.PurchaseDate') : t('Field.StartDate')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                />
                {isOwnerType ? (
                  <FormPriceInput
                    control={control}
                    key={'owner'}
                    name={{ currency: 'price.currency', value: 'price.value' }}
                    label={t('Field.PurchasePrice')}
                    rules={{
                      value: {
                        required: isOwnerType,
                        validate: {
                          greaterThanZero: (value) => !isOwnerType || value > 0
                        }
                      }
                    }}
                    format={preferences.numberFormat}
                    digits={2}
                    onChangedValue={(value) => handleFieldValueChange('price.value', value)}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('price.currency', value)}
                  />
                ) : (
                  <FormPriceInput
                    control={control}
                    key={'rental'}
                    name={{ currency: 'detail.monthlyRental.currency', value: 'detail.monthlyRental.value' }}
                    label={t('Field.MonthlyRent')}
                    rules={{
                      value: {
                        required: !isOwnerType,
                        validate: {
                          greaterThanZero: (value) => isOwnerType || value > 0
                        }
                      }
                    }}
                    format={preferences.numberFormat}
                    digits={2}
                    onChangedValue={(value) => handleFieldValueChange('detail.monthlyRental.value', value)}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('detail.monthlyRental.currency', value)}
                  />
                )}
                {isOwnerType ? (
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'value.currency', value: 'value.value' }}
                    label={t('Field.CurrentValuation')}
                    info={t('UsedForValuation')}
                    format={preferences.numberFormat}
                    digits={2}
                    onFocus={() => trackManualEdit('value')}
                  />
                ) : (
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    placeholder={t('TypeToSearch')}
                    options={groupOptions}
                    isMulti={true}
                    isLoading={loadingGroups}
                  />
                )}
              </div>
            </div>

            <div className={'col-span-1 flex flex-col gap-4'}>
              {isOwnerType && (
                <FormAutocomplete
                  control={control}
                  name={'groupIds'}
                  label={t('Field.Groups')}
                  placeholder={t('TypeToSearch')}
                  options={groupOptions}
                  isMulti={true}
                  isLoading={loadingGroups}
                />
              )}
              <FormTextarea control={control} name={'notes'} label={t('Field.PrimaryNotes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.acquisition'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormSelect
                  control={control}
                  name={'detail.acquisition.acquisitionType'}
                  label={t('Field.AcquisitionType')}
                >
                  {acquisitionTypeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </FormSelect>

                <FormCreatableAutocomplete
                  control={control}
                  name={'detail.acquisition.sellerId'}
                  label={t('Field.Seller')}
                  placeholder={t('SearchOrCreate')}
                  options={contactOptions}
                  onCreate={addNewContact}
                  isLoading={loadingContacts}
                />
                <FormInsurancePicker
                  control={control}
                  name={'detail.acquisition.insuranceIds'}
                  label={t('Field.Insurances')}
                />
                <FormPriceInput
                  control={control}
                  name={{
                    currency: 'detail.acquisition.initialValuationAtPurchase.currency',
                    value: 'detail.acquisition.initialValuationAtPurchase.value'
                  }}
                  label={t('Field.InitialValuationAtPurchase')}
                  format={preferences.numberFormat}
                  digits={2}
                  onFocus={() => trackManualEdit('detail.acquisition.initialValuationAtPurchase')}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormDatePicker
                  control={control}
                  name={'startDate'}
                  label={isOwnerType ? t('Field.PurchaseDate') : t('Field.StartDate')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                />
                {isDirect && (
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'price.currency', value: 'price.value' }}
                    label={t('Field.PurchasePrice')}
                    format={preferences.numberFormat}
                    digits={2}
                    rules={{
                      value: {
                        required: isOwnerType || undefined,
                        validate: {
                          greaterThanZero: (value) => isRentType || value > 0
                        }
                      }
                    }}
                    onChangedValue={(value) => handleFieldValueChange('price.value', value)}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('price.currency', value)}
                  />
                )}
                {isAuction && (
                  <>
                    <FormPriceInput
                      control={control}
                      name={{
                        currency: 'detail.acquisition.auctionDetail.hammerPrice.currency',
                        value: 'detail.acquisition.auctionDetail.hammerPrice.value'
                      }}
                      label={t('Field.HammerPrice')}
                      format={preferences.numberFormat}
                      digits={2}
                      onChangedCurrency={(value) =>
                        handleFieldCurrencyChange('detail.acquisition.auctionDetail.hammerPrice.currency', value)
                      }
                      onChangedValue={(value) =>
                        handleFieldValueChange('detail.acquisition.auctionDetail.hammerPrice.value', value)
                      }
                    />
                    <FormPriceInput
                      control={control}
                      name={{
                        currency: 'detail.acquisition.auctionDetail.taxPayable.currency',
                        value: 'detail.acquisition.auctionDetail.taxPayable.value'
                      }}
                      label={t('Field.TaxPayable')}
                      format={preferences.numberFormat}
                      digits={2}
                      disabled={{ currency: true }}
                    />
                    <FormPriceInput
                      control={control}
                      name={{
                        currency: 'detail.acquisition.auctionDetail.buyerPremium.currency',
                        value: 'detail.acquisition.auctionDetail.buyerPremium.value'
                      }}
                      label={t('Field.BuyerPremium')}
                      format={preferences.numberFormat}
                      digits={2}
                      disabled={{ currency: true }}
                    />
                  </>
                )}
                <FormPriceInput
                  control={control}
                  name={{
                    currency: 'detail.acquisition.otherCost.currency',
                    value: 'detail.acquisition.otherCost.value'
                  }}
                  label={t('Field.OtherCosts')}
                  format={preferences.numberFormat}
                  digits={2}
                  disabled={{ currency: true }}
                />
                <FormPriceInput
                  control={control}
                  name={{ currency: 'totalCost.currency', value: 'totalCost.value' }}
                  label={t('Field.TotalCost')}
                  format={preferences.numberFormat}
                  digits={2}
                  disabled={{ currency: true, value: true }}
                />
                <FormCheckbox
                  control={control}
                  name={'detail.acquisition.priceAsValue'}
                  label={t('TotalCostAsAssetValue')}
                />
              </div>
            </div>
            <div className={'col-span-1 flex flex-col gap-4'}>
              <FormTextarea control={control} name={'detail.acquisition.notes'} label={t('Field.AcquisitionNotes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.location'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <PlacesAutocomplete
                  control={control}
                  name={'location.address'}
                  label={t('properties:Field.PropertyAddress')}
                  onSelected={handleSelectPlace}
                  rules={{ required: t('validation:Required') }}
                />
                <FormInput
                  control={control}
                  name={'location.addressLine1'}
                  label={t('Field.AddressLine1')}
                  type={'text'}
                />
                <FormInput
                  control={control}
                  name={'location.addressLine2'}
                  label={t('Field.AddressLine2')}
                  type={'text'}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormInput control={control} name={'location.town'} label={t('Field.TownCity')} type={'text'} />
                <FormInput control={control} name={'location.state'} label={t('Field.StateCounty')} type={'text'} />
                <FormInput
                  control={control}
                  name={'location.zipCode'}
                  label={t('Field.ZipCodePostcode')}
                  type={'text'}
                />
                <FormAutocomplete
                  control={control}
                  name={'location.country'}
                  label={t('Field.Country')}
                  options={CountryOptions}
                  placeholder={t('account:Placeholder.ChooseCountry')}
                />
              </div>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.configuration'}>
            <div className={'w-[250px]'}>
              <TypePicker
                control={control}
                name={'configuration.unit'}
                label={t('Field.MeasurementUnits')}
                options={measurementUnitOptions}
                onChanged={onUnitChanged}
              />
            </div>
            <div className={'col-span-2 flex gap-4'}>
              <FormNumberInput
                control={control}
                name={'configuration.internalArea'}
                label={t('Field.InternalAreas')}
                prefix={unit}
                format={preferences.numberFormat}
                digits={2}
              />
              <FormNumberInput
                control={control}
                name={'configuration.patiosArea'}
                label={t('Field.PatiosTerraces')}
                prefix={unit}
                format={preferences.numberFormat}
                digits={2}
              />
              <FormNumberInput
                control={control}
                name={'configuration.landArea'}
                label={t('Field.LandPlot')}
                prefix={unit}
                format={preferences.numberFormat}
                digits={2}
              />
            </div>
            <div className={'col-span-2 flex max-w-[500px] flex-col gap-4 md:flex-row'}>
              {quantityInputs.map((input) => (
                <div key={input.type} className={'grid w-[180px] grid-cols-1 gap-y-1'}>
                  <label className={cn('text-xs text-[#414554]')} htmlFor={input.type}>
                    {input.label}
                  </label>
                  <QuantityInput
                    id={input.type}
                    value={input.value}
                    onChange={(value) => handleRoomsChange(value, input.type)}
                  />
                </div>
              ))}
            </div>
            <div className={'col-span-2 space-y-2'}>
              <Separator />
              {/* Bedrooms */}
              {bedrooms.fields.length > 0 && (
                <div className={'grid gap-1 pb-2'}>
                  <label className={'text-xs'}>{t('Field.Bedrooms')}</label>
                  <div className={'grid grid-cols-5 gap-2'}>
                    {bedrooms.fields.map((bedroom, index) => {
                      const update = (value: Bedroom) => {
                        bedrooms.update(index, value)
                        setRoomIds((ids) => [...ids, bedroom.id])
                      }
                      const remove = () => bedrooms.remove(index)

                      return (
                        <ConfigurationModal
                          key={bedroom.key}
                          data={bedroom}
                          shouldConfigure={!roomIds.includes(bedroom.id)}
                          title={t('EditBedroomConfiguration')}
                          components={{
                            Card: ({ data }) => {
                              const { label, icon, total } = getBedroomDetails(data)
                              const IconComp = icon
                              return (
                                <div className={'flex grow flex-col items-center justify-center text-xs'}>
                                  {data.size !== undefined && (
                                    <span className={'line-clamp-1'}>
                                      {`${formatNumber(data.size, preferences.numberFormat, { digits: 1 })} ${unit}`}
                                    </span>
                                  )}
                                  <div className={'text-center'}>
                                    <IconComp className={'mr-1 inline-block'} />
                                    {total > 1 && (
                                      <span className={'text-primary'}>
                                        {formatNumber(total, preferences.numberFormat, { digits: 0 })}
                                      </span>
                                    )}
                                  </div>
                                  {<span className={'uppercase text-primary'}>{t(`BedSizeOptions.${label}`)}</span>}
                                </div>
                              )
                            },
                            Form: ({ control, onSubmit, onClose }) => (
                              <div className={'grid grid-cols-2 gap-4'}>
                                <FormNumberInput
                                  control={control}
                                  name={'kingBedNum'}
                                  label={t('Field.KingBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'bunkBedNum'}
                                  label={t('Field.BunkBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'queenBedNum'}
                                  label={t('Field.QueenBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'cotBedNum'}
                                  label={t('Field.CotBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'doubleBedNum'}
                                  label={t('Field.DoubleBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'couchBedNum'}
                                  label={t('Field.CouchBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'singleBedNum'}
                                  label={t('Field.SingleBeds')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <div className={'col-span-2 space-y-4'}>
                                  <FormInput control={control} name={'name'} label={t('Field.BedroomName')} />
                                  <FormNumberInput
                                    control={control}
                                    name={'size'}
                                    label={t('Field.BedroomSize')}
                                    prefix={unit}
                                    format={preferences.numberFormat}
                                    digits={2}
                                  />
                                </div>
                                <div className={'col-span-2 flex items-center gap-2 [&>button]:flex-1'}>
                                  <Button variant={'outline'} size={'md'} onClick={onClose}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button variant={'solid'} size={'md'} onClick={onSubmit}>
                                    {t('SaveAndClose')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }}
                          onUpdate={update}
                          onRemove={remove}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
              {/* Bathrooms */}
              {bathrooms.fields.length > 0 && (
                <div className={'grid gap-1 border-t py-2'}>
                  <label className={'text-xs'}>{t('Field.Bathrooms')}</label>
                  <div className={'grid grid-cols-5 gap-2'}>
                    {bathrooms.fields.map((bathroom, index) => {
                      const update = (value: Bathroom) => {
                        bathrooms.update(index, value)
                        setRoomIds((ids) => [...ids, bathroom.id])
                      }
                      const remove = () => bathrooms.remove(index)
                      return (
                        <ConfigurationModal
                          key={bathroom.key}
                          data={bathroom}
                          shouldConfigure={!roomIds.includes(bathroom.id)}
                          title={t('EditBathroomConfiguration')}
                          components={{
                            Card: ({ data }) => (
                              <div className={'flex grow flex-col items-center justify-center gap-1 text-xs'}>
                                {data.size !== undefined && (
                                  <span className={'line-clamp-1'}>
                                    {`${formatNumber(data.size, preferences.numberFormat, { digits: 1 })} ${unit}`}
                                  </span>
                                )}
                                {getBathroomIcon(data.bathroomType)}
                                {
                                  <span className={'line-clamp-1 text-primary'}>
                                    {t(`properties:BathroomTypeOptions.${data.bathroomType}`)}
                                  </span>
                                }
                              </div>
                            ),
                            Form: ({ control, onSubmit, onClose, onTypeChange }) => (
                              <div className={'space-y-4'}>
                                <FormAutocomplete
                                  control={control}
                                  name={'bathroomType'}
                                  label={t('Field.RoomType')}
                                  options={bathroomOptions}
                                  onChanged={(newValue) =>
                                    handleRoomTypeChange(newValue.label, index, bathrooms.fields, onTypeChange)
                                  }
                                />
                                <FormInput control={control} name={'name'} label={t('Field.RoomName')} />
                                <FormNumberInput
                                  control={control}
                                  name={'size'}
                                  label={t('Field.RoomSize')}
                                  prefix={unit}
                                  format={preferences.numberFormat}
                                  digits={2}
                                />
                                <div className={'flex items-center gap-x-2 [&>button]:flex-1'}>
                                  <Button variant={'outline'} size={'md'} onClick={onClose}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button variant={'solid'} size={'md'} onClick={onSubmit}>
                                    {t('SaveAndClose')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }}
                          onUpdate={update}
                          onRemove={remove}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
              {/* Other Rooms */}
              {otherRooms.fields.length > 0 && (
                <div className={'grid gap-1 border-t py-2'}>
                  <label className={'text-xs'}>{t('Field.OtherRooms')}</label>
                  <div className={'grid grid-cols-5 gap-2'}>
                    {otherRooms.fields.map((otherRoom, index) => {
                      const update = (value: OtherRoom) => {
                        otherRooms.update(index, value)
                        setRoomIds((ids) => [...ids, otherRoom.id])
                      }
                      const remove = () => otherRooms.remove(index)
                      return (
                        <ConfigurationModal
                          key={otherRoom.key}
                          data={otherRoom}
                          shouldConfigure={!roomIds.includes(otherRoom.id)}
                          title={t('EditOtherRoomConfiguration')}
                          components={{
                            Card: ({ data }) => {
                              return (
                                <div className={'flex grow flex-col items-center justify-center gap-1 text-xs'}>
                                  {data.size !== undefined && (
                                    <span className={'line-clamp-1'}>
                                      {`${formatNumber(data.size, preferences.numberFormat, { digits: 1 })} ${unit}`}
                                    </span>
                                  )}
                                  {getOtherRoomIcon(data.otherRoomType)}
                                  <span className={'line-clamp-1 text-primary'}>
                                    {t(`properties:OtherRoomTypeOptions.${data.otherRoomType}`)}
                                  </span>
                                </div>
                              )
                            },
                            Form: ({ control, onSubmit, onClose, onTypeChange }) => (
                              <div className={'space-y-4'}>
                                <FormAutocomplete
                                  control={control}
                                  name={'otherRoomType'}
                                  label={t('Field.RoomType')}
                                  options={otherRoomOptions}
                                  onChanged={(newValue) =>
                                    handleRoomTypeChange(newValue.label, index, otherRooms.fields, onTypeChange)
                                  }
                                />
                                <FormInput control={control} name={'name'} label={t('Field.RoomName')} />
                                <FormNumberInput
                                  control={control}
                                  name={'size'}
                                  label={t('Field.RoomSize')}
                                  prefix={unit}
                                  format={preferences.numberFormat}
                                  digits={2}
                                />
                                <div className={'flex items-center gap-x-2 [&>button]:flex-1'}>
                                  <Button variant={'outline'} size={'md'} onClick={onClose}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button variant={'solid'} size={'md'} onClick={onSubmit}>
                                    {t('SaveAndClose')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }}
                          onUpdate={update}
                          onRemove={remove}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
              {/* Car Parks */}
              {carParks.fields.length > 0 && (
                <div className={'grid gap-1 border-t py-2'}>
                  <label className={'text-xs'}>{t('Field.CarParks')}</label>
                  <div className={'grid grid-cols-5 gap-2'}>
                    {carParks.fields.map((carPark, index) => {
                      const update = (value: CarPark) => {
                        carParks.update(index, value)
                        setRoomIds((ids) => [...ids, carPark.id])
                      }
                      const remove = () => carParks.remove(index)
                      return (
                        <ConfigurationModal
                          key={carPark.key}
                          data={carPark}
                          shouldConfigure={!roomIds.includes(carPark.id)}
                          title={t('EditCarParkConfiguration')}
                          components={{
                            Card: ({ data }) => (
                              <div className={'flex grow flex-col items-center justify-center gap-1 text-xs'}>
                                {data.size !== undefined && (
                                  <span className={'line-clamp-1'}>
                                    {`${formatNumber(data.size, preferences.numberFormat, { digits: 1 })} ${unit}`}
                                  </span>
                                )}
                                <p className={'text-center'}>
                                  <CarParkIcon className={'mr-1 inline-block'} />
                                  {data.parkingLotNum > 1 && (
                                    <span className={'text-primary'}>
                                      {formatNumber(data.parkingLotNum, preferences.numberFormat, { digits: 0 })}
                                    </span>
                                  )}
                                </p>
                              </div>
                            ),
                            Form: ({ control, onSubmit, onClose }) => (
                              <div className={'space-y-4'}>
                                <FormInput control={control} name={'name'} label={t('Field.CarParkName')} />
                                <FormNumberInput
                                  control={control}
                                  name={'parkingLotNum'}
                                  label={t('Field.NumberOfParkingLots')}
                                  placeholder={'0'}
                                  format={preferences.numberFormat}
                                />
                                <FormNumberInput
                                  control={control}
                                  name={'size'}
                                  label={t('Field.CarParkSize')}
                                  prefix={unit}
                                  format={preferences.numberFormat}
                                  digits={2}
                                />
                                <div className={'flex items-center gap-x-2 [&>button]:flex-1'}>
                                  <Button variant={'outline'} size={'md'} onClick={onClose}>
                                    {t('Cancel')}
                                  </Button>
                                  <Button variant={'solid'} size={'md'} onClick={onSubmit}>
                                    {t('SaveAndClose')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }}
                          onUpdate={update}
                          onRemove={remove}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.rental'}>
            <div className={'flex flex-col gap-4'}>
              <FormSelect control={control} name={'detail.agreementType'} label={t('Field.AgreementType')}>
                {rentalAgreementOptions.map(({ label, value }) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </FormSelect>
              <FormPriceInput
                control={control}
                name={{ currency: 'detail.monthlyRental.currency', value: 'detail.monthlyRental.value' }}
                label={t('Field.MonthlyRent')}
                rules={{
                  value: {
                    required: !isOwnerType,
                    validate: {
                      greaterThanZero: (value) => isOwnerType || value > 0
                    }
                  }
                }}
                format={preferences.numberFormat}
                digits={2}
                onChangedValue={(value) => handleFieldValueChange('detail.monthlyRental.value', value)}
                onChangedCurrency={(value) => handleFieldCurrencyChange('detail.monthlyRental.currency', value)}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'detail.securityDeposit.currency', value: 'detail.securityDeposit.value' }}
                label={t('Field.SecurityDeposit')}
                format={preferences.numberFormat}
                digits={2}
              />
              <FormDatePicker
                control={control}
                name={'startDate'}
                label={t('Field.StartDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
              />
              <div className={'flex gap-1'}>
                <div className={'grid flex-[2] gap-1'}>
                  <label className={'text-xs'}>{t('Field.Term')}</label>
                  <div className={'flex gap-1'}>
                    <FormSelect className={'shrink-0'} control={control} name={'detail.term.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                    <FormNumberInput
                      control={control}
                      name={'detail.term.num'}
                      format={preferences.numberFormat}
                      rules={{ validate: { positive: (val) => val > 0 } }}
                    />
                  </div>
                </div>
                <div className={'grid flex-1 gap-1'}>
                  <label className={'text-xs'}>{t('Field.EndDate')}</label>
                  <p
                    className={
                      'h-[38px] rounded border-primary bg-primary text-center text-sm leading-[38px] text-white'
                    }
                  >
                    {formatDate(endDate, preferences.dateFormat, preferences.timeZone)}
                  </p>
                </div>
              </div>
              <FormCreatableAutocomplete
                control={control}
                name={'detail.landlordId'}
                label={t('Field.Landlord')}
                placeholder={t('SearchOrCreate')}
                options={contactOptions}
                onCreate={addNewContact}
                isLoading={loadingContacts}
              />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormTextarea control={control} name={'detail.otherFees'} label={t('Field.OtherFeesCharged')} />
              <FormTextarea control={control} name={'detail.notes'} label={t('Field.RentalNotes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.ownership'} className={'items-start'}>
            <div className={'grid-col-1 grid md:grid-cols-2'}>
              <div className={'space-y-4 '}>
                <div className={'grid gap-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Ownership')}</span>
                    {remainingOwnership === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingOwnership > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingOwnership })}
                      </span>
                    )}
                    {remainingOwnership < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                  </div>
                  <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                    <Input className={'h-[38px] border'} defaultValue={t('Field.MyOwnership')} disabled={true} />
                    <FormPercentInput control={control} name={`detail.ownership.myOwnership`} />
                  </div>
                </div>
                <div className={'grid gap-1'}>
                  <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                  <ul className={'space-y-2 empty:hidden'}>
                    {shareholderArray.fields.map((shareholder, index) => (
                      <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`detail.ownership.shareholder.${index}.contactId`}
                          options={ShareholderUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`detail.ownership.shareholder.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => shareholderArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddShareholders')}
                    </span>
                  </Button>
                  {/* TODO: Treat Asset Value As */}
                </div>
              </div>
              <div className={'space-y-1'}>
                <div className={'flex items-center justify-between pr-10'}>
                  <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                  {remainingBeneficiary === 0 && (
                    <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                  )}
                  {remainingBeneficiary > 0 && (
                    <span className={'text-xs text-[#d89423]'}>
                      {t('RemainingPercent', { percent: remainingBeneficiary })}
                    </span>
                  )}
                  {remainingBeneficiary < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                </div>
                <ul className={'space-y-2 empty:hidden'}>
                  {beneficiaryArray.fields.map((beneficiary, index) => (
                    <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <FormCreatableAutocomplete
                        control={control}
                        name={`detail.beneficiary.${index}.contactId`}
                        options={beneficiaryUnselectContact(index)}
                        onCreate={addNewContact}
                        isLoading={loadingContacts}
                        placeholder={t('SearchOrCreate')}
                      />
                      <FormPercentInput control={control} name={`detail.beneficiary.${index}.percent`} />
                      <Button
                        className={'rounded-full transition-colors hover:bg-grey/20'}
                        onClick={() => beneficiaryArray.remove(index)}
                      >
                        <XIcon className={'text-primary'} />
                      </Button>
                    </li>
                  ))}
                </ul>
                <Button
                  className={'group justify-start gap-x-3 justify-self-start py-2'}
                  onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                >
                  <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                    <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                  </div>
                  <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                    {t('AddBeneficiaries')}
                  </span>
                </Button>
              </div>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            {assetId && (
              <AttachmentPanel
                assetId={assetId}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                isRentalAsset={isRentType}
                widgetOptions={[
                  AttachmentKind.AssetImage,
                  AttachmentKind.PrimaryDetails,
                  AttachmentKind.Acquisition,
                  AttachmentKind.Location,
                  AttachmentKind.Attributes,
                  AttachmentKind.Ownership
                ]}
              />
            )}
          </TabPanel.Section>
        </div>

        <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
          <Button
            id={'myproperties_add_property_cancel'}
            className={'min-w-[130px]'}
            variant={'outline'}
            size={'md'}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          <Button
            id={'myproperties_add_property_create'}
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

interface ConfigurationModalProps<T extends FieldValues> {
  title: string
  data: T
  components: {
    Card: (props: { data: T }) => JSX.Element
    Form: (props: {
      control: Control<T>
      onSubmit: () => void
      onClose: () => void
      onTypeChange: (type: PathValue<T, Path<T>>) => void
    }) => JSX.Element
  }
  shouldConfigure: boolean
  onUpdate: (data: T) => void
  onRemove: () => void
}

function ConfigurationModal<T extends FieldValues>({
  title,
  data,
  components,
  shouldConfigure,
  onUpdate,
  onRemove
}: ConfigurationModalProps<T>) {
  const [isOpen, setIsOpen] = useState(false)
  const [isEscape, setIsEscape] = useState(false)
  const { control, handleSubmit, reset, setValue } = useForm<T>({ values: data })
  const { t } = useTranslation()

  const openModal = () => setIsOpen(true)
  const closeModal = () => {
    setIsOpen(false)
    setIsEscape(false)
    reset(data)
  }
  const onSubmit = (data: T) => {
    onUpdate(data)
    closeModal()
  }
  const onTypeChange = (type: PathValue<T, Path<T>>) => {
    setValue('name' as Path<T>, type)
  }

  return (
    <>
      <div
        className={cn('group relative aspect-square rounded-sm border shadow-none transition-shadow hover:shadow-lg', {
          'bg-gray-300': shouldConfigure
        })}
      >
        <Button
          className={cn('h-full w-full flex-col items-stretch', shouldConfigure ? 'text-white' : 'text-text')}
          onClick={openModal}
        >
          <TruncatedText className={'h-6 border-b px-1 text-center text-xs leading-6'}>{data.name}</TruncatedText>
          {shouldConfigure ? (
            <div className={'flex grow flex-col items-center justify-center text-xs'}>
              <GearIcon className={cn('transition-transform group-hover:rotate-45')} size={32} />
              <p className={'text-[10px] text-white'}>{'CONFIGURE'}</p>
            </div>
          ) : (
            <components.Card data={data} />
          )}
        </Button>
        <Button
          className={
            'absolute -right-2 -top-2 rounded-full border-2 border-error text-error opacity-0 transition-opacity group-hover:opacity-100'
          }
          onClick={onRemove}
        >
          <XIcon size={16} />
        </Button>
      </div>

      {isOpen && (
        <>
          <Modal className={'max-w-sm'} onBackdropClick={() => setIsEscape(true)}>
            <Modal.Header>
              <label className={'text-sm font-medium uppercase text-primary'}>{title}</label>
              <Modal.CloseButton className={'text-primary'} onClose={closeModal} />
            </Modal.Header>
            <Modal.Content className={'pt-0'}>
              <components.Form
                control={control}
                onSubmit={handleSubmit(onSubmit)}
                onClose={closeModal}
                onTypeChange={onTypeChange}
              />
            </Modal.Content>
          </Modal>

          {isEscape && (
            <Confirm
              title={t('UnsavedChanges')}
              content={t('CloseDialog')}
              onCancel={() => setIsEscape(false)}
              cancelLabel={t('KeepEditing')}
              onConfirm={closeModal}
              confirmLabel={t('DiscardChanges')}
            />
          )}
        </>
      )}
    </>
  )
}

export function getBedroomDetails(data: Bedroom & { id?: string; key?: string }) {
  const { name, position, size, id, key, ...beds } = data
  const total = Object.values(beds).reduce((acc, curr) => acc + curr, 0)
  if (total > 1) {
    return { label: 'Other', icon: SingleBedIcon, total }
  }
  const bed = Object.entries(beds).find((pred) => pred[1] > 0)
  if (bed) {
    switch (bed[0]) {
      case 'kingBedNum':
        return { label: 'King', icon: DoubleBedIcon, total }
      case 'bunkBedNum':
        return { label: 'Bunk', icon: DoubleBedIcon, total }
      case 'queenBedNum':
        return { label: 'Queen', icon: DoubleBedIcon, total }
      case 'doubleBedNum':
        return { label: 'Double', icon: DoubleBedIcon, total }
      case 'cotBedNum':
        return { label: 'Cot', icon: SingleBedIcon, total }
      case 'singleBedNum':
        return { label: 'Single', icon: SingleBedIcon, total }
      case 'couchBedNum':
        return { label: 'Couch', icon: CouchBedIcon, total }
    }
  }
  return { label: 'Single', icon: SingleBedIcon, total }
}

export function getBathroomIcon(type: BathroomType) {
  switch (type) {
    case BathroomType.Bathroom:
    case BathroomType.EnSuite:
    case BathroomType.Powder:
      return <BathroomIcon />
    case BathroomType.Shower:
      return <ShowerRoomIcon />
    case BathroomType.WC:
      return <WCIcon />
  }
}

export function getOtherRoomIcon(type: OtherRoomType) {
  switch (type) {
    case OtherRoomType.Attic:
      return <AtticIcon />
    case OtherRoomType.Ballroom:
      return <BallroomIcon />
    case OtherRoomType.Bar:
      return <BarIcon />
    case OtherRoomType.Basement:
      return <BasementIcon />
    case OtherRoomType.BootRoom:
      return <BootRoomIcon />
    case OtherRoomType.Cellar:
    case OtherRoomType.WineCellar:
      return <CellarIcon />
    case OtherRoomType.Cloakroom:
      return <CloakRoomIcon />
    case OtherRoomType.Conservatory:
      return <ConservatoryIcon />
    case OtherRoomType.DanceYogaRoom:
      return <DanceRoomIcon />
    case OtherRoomType.DiningRoom:
      return <DiningRoomIcon />
    case OtherRoomType.DrawingRoom:
    case OtherRoomType.LivingRoom:
      return <LivingRoomIcon />
    case OtherRoomType.DressingRoom:
      return <DressingRoomIcon />
    case OtherRoomType.ElectricalPlantRoom:
      return <ElectricalRoomIcon />
    case OtherRoomType.GamesRoom:
      return <GamesRoomIcon />
    case OtherRoomType.Genkan:
      return <GenkanIcon />
    case OtherRoomType.GymFitnessRoom:
      return <FitnessRoomIcon />
    case OtherRoomType.Hall:
      return <HallIcon />
    case OtherRoomType.Kitchen:
      return <KitchenIcon />
    case OtherRoomType.Landing:
      return <LandingIcon />
    case OtherRoomType.Larder:
    case OtherRoomType.Pantry:
      return <LarderIcon />
    case OtherRoomType.LaundryRoomUtility:
      return <LaundryRoomIcon />
    case OtherRoomType.Library:
      return <LibraryIcon />
    case OtherRoomType.MovieTheatre:
      return <MovieTheatreIcon />
    case OtherRoomType.MusicRoom:
      return <MusicRoomIcon />
    case OtherRoomType.Office:
      return <OfficeIcon />
    case OtherRoomType.Parlour:
      return <ParlourIcon />
    case OtherRoomType.SaunaSteam:
      return <SaunaIcon />
    case OtherRoomType.Snug:
      return <SnugIcon />
    case OtherRoomType.SpareRoom:
      return <DoubleBedIcon />
    case OtherRoomType.Stairwell:
      return <StairwellIcon />
    case OtherRoomType.StoreBoxRoom:
      return <StoreRoomIcon />
    case OtherRoomType.SwimmingPool:
      return <SwimmingPoolIcon />
    case OtherRoomType.Workshop:
      return <WorkshopIcon />
    case OtherRoomType.OtherRoom:
      return <OtherRoomIcon />
  }
}

export function PropertyFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
