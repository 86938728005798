import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2035%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M17.846%202.104C18.69%201.82%2019.301.985%2019.301%200h-4.183c0%201.055.7%201.937%201.637%202.156v1.346l-3.994%206.984a2.395%202.395%200%200%200-.239-.012c-1.317%200-2.384%201.081-2.384%202.414v1.995c0%20.769-.615%201.391-1.372%201.391a1.375%201.375%200%200%201-1.323-1.024%201.808%201.808%200%200%200%201.258-1.727H7.395v-.996a.509.509%200%200%200-.506-.511.509.509%200%200%200-.507.51v.997H5.116c0%20.825.547%201.521%201.295%201.739a2.394%202.394%200%200%200%202.355%202.035c1.318%200%202.385-1.082%202.385-2.414v-1.995c0-.77.614-1.392%201.371-1.392a1.38%201.38%200%200%201%201.359%201.2h-1.309c0%202.456%201.829%204.48%204.183%204.753v.407q0%20.054.01.106.011.053.032.102.02.05.05.095.03.044.067.082.038.038.083.068.045.03.094.05.05.02.102.031.053.01.107.01h.002q.053%200%20.106-.01.052-.01.102-.03.05-.021.094-.051.045-.03.083-.068.038-.038.067-.082.03-.045.05-.095.021-.05.032-.102.01-.052.01-.106v-.407c2.355-.274%204.183-2.297%204.183-4.753H20.72c.091-.678.665-1.2%201.358-1.2.757%200%201.372.623%201.372%201.392v1.995c0%201.332%201.067%202.414%202.384%202.414%201.19%200%202.175-.883%202.355-2.036a1.808%201.808%200%200%200%201.295-1.738h-1.266v-.996a.509.509%200%200%200-.506-.511.509.509%200%200%200-.507.51v.997H25.9c0%20.811.53%201.498%201.258%201.727a1.376%201.376%200%200%201-1.323%201.024%201.382%201.382%200%200%201-1.372-1.391v-1.995c0-1.333-1.067-2.414-2.384-2.414-.08%200-.16.004-.24.012l-3.993-6.984V2.104Zm-1.091%203.443-3.012%205.267a2.417%202.417%200%200%201%201.156%201.883h1.856v-7.15Zm1.091%207.15v-7.15l3.012%205.267a2.417%202.417%200%200%200-1.156%201.883h-1.856ZM24.762%2035H9.78v-8.687L8.27%2035H4l1.625-12.842h23.292L30.542%2035h-4.27l-1.51-8.687V35Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BallroomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M17.846 2.104C18.69 1.82 19.301.985 19.301 0h-4.183c0 1.055.7 1.937 1.637 2.156v1.346l-3.994 6.984a2.395 2.395 0 0 0-.239-.012c-1.317 0-2.384 1.081-2.384 2.414v1.995c0 .769-.615 1.391-1.372 1.391a1.375 1.375 0 0 1-1.323-1.024 1.808 1.808 0 0 0 1.258-1.727H7.395v-.996a.509.509 0 0 0-.506-.511.509.509 0 0 0-.507.51v.997H5.116c0 .825.547 1.521 1.295 1.739a2.394 2.394 0 0 0 2.355 2.035c1.318 0 2.385-1.082 2.385-2.414v-1.995c0-.77.614-1.392 1.371-1.392a1.38 1.38 0 0 1 1.359 1.2h-1.309c0 2.456 1.829 4.48 4.183 4.753v.407q0 .054.01.106.011.053.032.102.02.05.05.095.03.044.067.082.038.038.083.068.045.03.094.05.05.02.102.031.053.01.107.01h.002q.053 0 .106-.01.052-.01.102-.03.05-.021.094-.051.045-.03.083-.068.038-.038.067-.082.03-.045.05-.095.021-.05.032-.102.01-.052.01-.106v-.407c2.355-.274 4.183-2.297 4.183-4.753H20.72c.091-.678.665-1.2 1.358-1.2.757 0 1.372.623 1.372 1.392v1.995c0 1.332 1.067 2.414 2.384 2.414 1.19 0 2.175-.883 2.355-2.036a1.808 1.808 0 0 0 1.295-1.738h-1.266v-.996a.509.509 0 0 0-.506-.511.509.509 0 0 0-.507.51v.997H25.9c0 .811.53 1.498 1.258 1.727a1.376 1.376 0 0 1-1.323 1.024 1.382 1.382 0 0 1-1.372-1.391v-1.995c0-1.333-1.067-2.414-2.384-2.414-.08 0-.16.004-.24.012l-3.993-6.984V2.104Zm-1.091 3.443-3.012 5.267a2.417 2.417 0 0 1 1.156 1.883h1.856v-7.15Zm1.091 7.15v-7.15l3.012 5.267a2.417 2.417 0 0 0-1.156 1.883h-1.856ZM24.762 35H9.78v-8.687L8.27 35H4l1.625-12.842h23.292L30.542 35h-4.27l-1.51-8.687V35Z'
        }
      />
    </svg>
  )
}
