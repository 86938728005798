import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M17%205.17c1.165.413%202%201.524%202%202.83v8h-6V8c0-1.306.835-2.417%202-2.83V2h2v3.17ZM9.5%204A2.5%202.5%200%200%201%2012%206.5V16H7V6.5A2.5%202.5%200%200%201%209.5%204ZM29%209h-5v7h5V9ZM5.5%2017h24a1.5%201.5%200%200%201%200%203h-24a1.5%201.5%200%200%201%200-3Zm7.5%204H7v9h6v-9Zm16%205a4%204%200%201%200-8%200%204%204%200%200%200%208%200Zm-15-3h5v7h-5v-7Zm15.5%208h-24a1.5%201.5%200%200%200%200%203h24a1.5%201.5%200%200%200%200-3Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function LarderIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M17 5.17c1.165.413 2 1.524 2 2.83v8h-6V8c0-1.306.835-2.417 2-2.83V2h2v3.17ZM9.5 4A2.5 2.5 0 0 1 12 6.5V16H7V6.5A2.5 2.5 0 0 1 9.5 4ZM29 9h-5v7h5V9ZM5.5 17h24a1.5 1.5 0 0 1 0 3h-24a1.5 1.5 0 0 1 0-3Zm7.5 4H7v9h6v-9Zm16 5a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm-15-3h5v7h-5v-7Zm15.5 8h-24a1.5 1.5 0 0 0 0 3h24a1.5 1.5 0 0 0 0-3Z'
        }
      />
    </svg>
  )
}
