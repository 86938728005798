import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { Exhibition } from 'core/remodel/types/actions/exhibition'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, FormDateRangePicker, FormInput, FormTextarea } from '@/components/base'
import { PlacesAutocomplete, type Place } from '@/components/PlacesAutocomplete'
import UploadFiles from '@/components/UploadFiles'

export type ExhibitionValues = Exhibition.CreateFields

const defaultValues: Partial<ExhibitionValues> = {
  title: '',
  startDate: new Date(),
  endDate: new Date(),
  exhibitionLocation: '',
  website: '',
  file: [],
  notes: ''
}

interface ExhibitionFormProps {
  assetId: string | null
  mode?: 'Create' | 'Edit'
  values?: ExhibitionValues
  onSubmit: SubmitHandler<ExhibitionValues>
  onCancel: () => void
}

export function ExhibitionForm({ assetId, mode = 'Create', values, onCancel, onSubmit }: ExhibitionFormProps) {
  const { t } = useTranslation()
  // form
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm<ExhibitionValues>({ values, defaultValues })
  // preferences and options
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))

  const handleSelectPlace = (place: Place) => {
    setValue('exhibitionLocation', place.address)
  }

  return (
    <form className={'flex h-[95vh] flex-col gap-y-4 bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-x-4 overflow-y-auto px-4 pt-4 md:flex-row'}>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormInput control={control} name={'title'} label={t('Field.ExhibitionTitle')} rules={{ required: true }} />
          <FormDateRangePicker
            control={control}
            name={{
              startDate: 'startDate',
              endDate: 'endDate'
            }}
            label={t('Field.StartToEndDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
        </div>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <PlacesAutocomplete
            control={control}
            name={'exhibitionLocation'}
            label={t('Field.ExhibitionLocation')}
            onSelected={handleSelectPlace}
          />
          <FormInput control={control} name={'website'} label={t('Field.ExhibitionWebsite')} />
          <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
          <UploadFiles assetId={assetId} control={control} name={'file'} />
        </div>
      </div>
      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
          disabled={!isValid}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
