import {
  Amount,
  AssetType,
  AssetV2,
  Deletable,
  MultiCurrencyAmount,
  Optional,
  PathsOfDateField,
  Attachment,
  ExchangeRateData,
  PathsOfAmountField,
} from "./common";
import {
  EncryptedType,
  EncryptionField,
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  fullObjectDecryption,
  fullObjectEncryption,
  fullObjectEncryptionNotStrict,
  doRemoveEncryptedFields,
  IVSaltFieldKey,
  IVSaltFieldDefaultValue,
} from "../encryption/utils";
import {
  OmitKeys,
  UpdateObject,
  applyUpdateToObject,
  removeItemsFromArray,
  validateValueInEnum,
  mulAmount,
  subDecimal,
} from "../utils";
import {
  AggregateRoot,
  Domain,
  IAggregate,
  IAggregateStateWriter,
  RepoAndAggregates,
  setObjectDeleted,
  stateIsDeleted,
} from "./aggregate";
import { CommandBase, SharedCommand, TxCommand } from "./command";
import {
  EventBase,
  EventWithTime,
  preSealEvent,
  SharedEvent,
  TxEvent,
} from "./event";
import { DataPoisoned, InvalidInput } from "./error";
import { Cash } from "./cashAndBanking/cash";
import { CreditCard } from "./cashAndBanking/creditCard";
import { CurrentAccount } from "./cashAndBanking/currentAccount";
import { SavingAccount } from "./cashAndBanking/savingAccount";
import { Allocation, Loan } from "./cashAndBanking/loan";
import { Mortgage } from "./cashAndBanking/mortgage";
import {
  AccountMin,
  Institution,
  SubAccount,
} from "./cashAndBanking/institution";
import { Encryption } from "../database/encryption";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  Transaction,
  WithFieldValue,
} from "../../coreFirebase";
import { RelationsOfAsset, buildCashAndBankingRelation } from "./relations";

export type Account =
  | Cash
  | CreditCard
  | Loan
  | Mortgage
  | CurrentAccount
  | SavingAccount;
export namespace Account {
  export function assureVersion(
    input: Account | Encrypted,
    errorOnCoreOutDated: boolean = true
  ) {
    switch (input.subtype) {
      case Type.Cash:
        return Cash.assureVersion(<Cash>input, errorOnCoreOutDated);
      case Type.CreditCardAccount:
        return CreditCard.assureVersion(<CreditCard>input, errorOnCoreOutDated);
      case Type.CurrentAccount:
        return CurrentAccount.assureVersion(
          <CurrentAccount>input,
          errorOnCoreOutDated
        );
      case Type.SavingAccount:
        return SavingAccount.assureVersion(
          <SavingAccount>input,
          errorOnCoreOutDated
        );
      case Type.LoanAccount:
        return Loan.assureVersion(<Loan>input, errorOnCoreOutDated);
      case Type.MortgageAccount:
        return Mortgage.assureVersion(<Mortgage>input, errorOnCoreOutDated);
      default:
        throw new Error("Invalid subtype");
    }
  }
  export function handleOutDated(input: Account | Encrypted) {
    switch (input.subtype) {
      case Type.Cash:
        return Cash.handleOutDated();
      case Type.CreditCardAccount:
        return CreditCard.handleOutDated();
      case Type.CurrentAccount:
        return CurrentAccount.handleOutDated();
      case Type.SavingAccount:
        return SavingAccount.handleOutDated();
      case Type.LoanAccount:
        return Loan.handleOutDated();
      case Type.MortgageAccount:
        return Mortgage.handleOutDated();
    }
  }

  export enum Type {
    Cash = "Cash",
    CreditCardAccount = "CreditCardAccount",
    CurrentAccount = "CurrentAccount",
    LoanAccount = "LoanAccount",
    MortgageAccount = "MortgageAccount",
    SavingAccount = "SavingAccount",
  }

  export const typeValues = Object.values(Type);

  export function typeDefaultIsLiability(type: Type): boolean {
    return (
      type === Type.LoanAccount ||
      type === Type.MortgageAccount ||
      type === Type.CreditCardAccount
    );
  }

  export interface Base extends AssetV2 {
    assetType: AssetType.CashAndBanking;
    extId?: string; //maybe?
    extSource?: AccountSource; //maybe?
    country?: string;
    institution: string;
  }

  export enum AccountType {
    SingleCurrency = "Single-Currency Account",
    MultiCurrency = "Multi-Currency Account",
  }

  export const datePaths: readonly (
    | PathsOfDateField<Cash>
    | PathsOfDateField<CreditCard>
    | PathsOfDateField<CurrentAccount>
    | PathsOfDateField<SavingAccount>
    | PathsOfDateField<Loan>
    | PathsOfDateField<Mortgage>
  )[] = [
    "startDate",
    "createAt",
    "updateAt",
    "validateFrom",
    "expiresEnd",
    "paymentDate",
    "openingDate",
  ] as const;
  export type EncryptedKeys =
    | Cash.EncryptedKeys
    | CreditCard.EncryptedKeys
    | CurrentAccount.EncryptedKeys
    | SavingAccount.EncryptedKeys
    | Loan.EncryptedKeys
    | Mortgage.EncryptedKeys;
  export type Encrypted =
    | Cash.Encrypted
    | CreditCard.Encrypted
    | CurrentAccount.Encrypted
    | SavingAccount.Encrypted
    | Loan.Encrypted
    | Mortgage.Encrypted;
  export type EncryptedPart =
    | Cash.EncryptedPart
    | CreditCard.EncryptedPart
    | CurrentAccount.EncryptedPart
    | SavingAccount.EncryptedPart
    | Loan.EncryptedPart
    | Mortgage.EncryptedPart;
  export type Create =
    | Cash.Create
    | CreditCard.Create
    | CurrentAccount.Create
    | SavingAccount.Create
    | Loan.Create
    | Mortgage.Create;
  export type Update =
    | Cash.Update
    | CreditCard.Update
    | CurrentAccount.Update
    | SavingAccount.Update
    | Loan.Update
    | Mortgage.Update;
  export type UpdateEncrypted =
    | Cash.UpdateEncrypted
    | CreditCard.UpdateEncrypted
    | CurrentAccount.UpdateEncrypted
    | SavingAccount.UpdateEncrypted
    | Loan.UpdateEncrypted
    | Mortgage.UpdateEncrypted;
  export const encryptedKeysArray: readonly EncryptedKeys[] = [
    "notes",
    "cardLastFourDigits",
    "nameOnCard",
    "accountNumber",
    "SWIFT_BIC",
  ] as const;
  export async function decryptAndConvertDate(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Account> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestoreNotStrict(decrypted, datePaths);
    return decrypted;
  }

  export function removeEncryptedFields<
    T extends Account | UpdateObject<Account>
  >(data: T): Omit<T, EncryptedKeys | "attachments"> {
    const result = doRemoveEncryptedFields(data, encryptedKeysArray) as Omit<
      T,
      EncryptedKeys | "attachments"
    >;
    delete (<any>result).attachments;
    return result;
  }
  export async function encrypt(
    input: Account,
    encryption: Encryption
  ): Promise<Encrypted> {
    const { attachments, ...rest } = (await fullObjectEncryptionNotStrict(
      input,
      encryptedKeysArray,
      encryption
    )) as OmitKeys<Encrypted, "attachments"> & {
      attachments?: Attachment[];
    };
    const result = rest as Encrypted;
    if (attachments) {
      result.attachments = await Attachment.encryptArray(
        attachments,
        encryption
      );
    }
    return result;
  }
  export async function encryptPartial<T extends Account.EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryptionNotStrict(
      rawData,
      encryptedKeysArray,
      encryption
    );
  }
  export async function decrypt(
    input: Encrypted,
    encryption: Encryption
  ): Promise<Account> {
    const decrypted = (await fullObjectDecryption(
      input,
      encryption
    )) as Account;
    decrypted.attachments = await Attachment.decryptArray(
      decrypted.attachments as Optional<Attachment.Encrypted[]>,
      encryption
    );
    return decrypted;
  }

  export function newAggregateRoot(state: AccountState) {
    return new AggregateRoot(new AccountAggregate(state));
  }

  export function validateEncryptedObj(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    switch (data.subtype) {
      case Account.Type.Cash:
        Cash.validateEncryptedObj(data, isCreate);
        break;
      case Account.Type.CreditCardAccount:
        CreditCard.validateEncryptedObj(data, isCreate);
        break;
      case Account.Type.LoanAccount:
        Loan.validateEncryptedObj(data, isCreate);
        break;
      case Account.Type.MortgageAccount:
        Mortgage.validateEncryptedObj(data, isCreate);
        break;
      case Account.Type.CurrentAccount:
        CurrentAccount.validateEncryptedObj(data, isCreate);
        break;
      case Account.Type.SavingAccount:
        SavingAccount.validateEncryptedObj(data, isCreate);
        break;
    }
  }

  export function fromCreateChecked(from: Create, userId: string): Account {
    switch (from.subtype) {
      case Account.Type.Cash:
        Cash.validateEncryptedPart(from, true);
        return Cash.fromCreate(from, userId);
      case Account.Type.CreditCardAccount:
        CreditCard.validateEncryptedPart(from, true);
        return CreditCard.fromCreate(from, userId);
      case Account.Type.LoanAccount:
        Loan.validateEncryptedPart(from, true);
        return Loan.fromCreate(from, userId);
      case Account.Type.MortgageAccount:
        Mortgage.validateEncryptedPart(from, true);
        return Mortgage.fromCreate(from, userId);
      case Account.Type.CurrentAccount:
        CurrentAccount.validateEncryptedPart(from, true);
        return CurrentAccount.fromCreate(from, userId);
      case Account.Type.SavingAccount:
        SavingAccount.validateEncryptedPart(from, true);
        return SavingAccount.fromCreate(from, userId);
    }
  }

  export function intoUpdate(current: Account, req: Update) {
    let result: {
      updates: UpdateObject<Update>;
      metadata: {
        addedToGroup: AssetV2["groupIds"];
        removedFromGroup: AssetV2["groupIds"];
      };
    };
    switch (current.subtype) {
      case Account.Type.Cash:
        Cash.validateEncryptedPart(req);
        result = Cash.intoUpdate(current, <Cash.Update>req);
        break;
      case Account.Type.CreditCardAccount:
        CreditCard.validateEncryptedPart(req);
        result = CreditCard.intoUpdate(current, <CreditCard.Update>req);
        break;
      case Account.Type.LoanAccount:
        Loan.validateEncryptedPart(req);
        result = Loan.intoUpdate(current, <Loan.Update>req);
        break;
      case Account.Type.MortgageAccount:
        Mortgage.validateEncryptedPart(req);
        result = Mortgage.intoUpdate(current, <Mortgage.Update>req);
        break;
      case Account.Type.CurrentAccount:
        CurrentAccount.validateEncryptedPart(req);
        result = CurrentAccount.intoUpdate(current, <CurrentAccount.Update>req);
        break;
      case Account.Type.SavingAccount:
        SavingAccount.validateEncryptedPart(req);
        result = SavingAccount.intoUpdate(current, <SavingAccount.Update>req);
        break;
    }
    return result;
  }

  export interface RelatedUpdates {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
    // addedPropertyIds?: string[];
    // removedPropertyIds?: string[];
  }

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
    institution?: RepoAndAggregates<any, any, any>;
  }
}

interface TransactionAccountRef {
  accountType: Account.Type;
  accountId: string;
  subAccountId?: string;
}

export enum TransactionType {
  Credit = "Credit",
  Debit = "Debit",
}

export enum Category {
  //both
  SystemAccountCreation = "SystemAccountCreation",
  BalanceUpdate = "BalanceUpdate",
  Transfer = "Transfer",
  //credit
  CashAdvance = "CashAdvance",
  Interest = "Interest",
  OtherIncome = "OtherIncome",
  RentalIncome = "RentalIncome",
  //debit
  BankFees = "BankFees",
  Community = "Community",
  FoodAndDrink = "FoodAndDrink",
  Healthcare = "Healthcare",
  Payment = "Payment",
  Recreation = "Recreation",
  Service = "Service",
  Shops = "Shops",
  Tax = "Tax",
  Travel = "Travel",
  OtherExpense = "OtherExpense",
}
export type CreditCategory = Extract<
  Category,
  | Category.SystemAccountCreation
  | Category.BalanceUpdate
  | Category.Transfer
  | Category.CashAdvance
  | Category.Interest
  | Category.OtherIncome
  | Category.RentalIncome
>;

export type DebitCategory = Extract<
  Category,
  | Category.SystemAccountCreation
  | Category.BalanceUpdate
  | Category.Transfer
  | Category.BankFees
  | Category.Community
  | Category.FoodAndDrink
  | Category.Healthcare
  | Category.Payment
  | Category.Recreation
  | Category.Service
  | Category.Shops
  | Category.Tax
  | Category.Travel
  | Category.OtherExpense
>;

// finance - cash and banking
export type AccountTransaction = {
  id: string;
  ownerId: string;
  accountId: string;
  subAccountId?: string;

  transactionType: TransactionType;
  category: Category;

  date: Date;
  fromAccount?: TransactionAccountRef;
  toAccount?: TransactionAccountRef;
  sellerOrBuyer: string;
  amount?: Amount;
  // single-currency: transfer to account currency
  // multi-currency: transfer to sub account currency
  amountInAccountCurrency: Amount;
  exchangeRate?: ExchangeRateData;
  relatedAsset?: { assetType: AssetType; assetId: string }; //Assert store ref string maybe

  notes?: string;

  createAt: Date;
};
export namespace AccountTransaction {
  export const datePaths: readonly PathsOfDateField<AccountTransaction>[] = [
    "createAt",
    "exchangeRate.date",
    "date",
  ] as const;
  export const amountPaths: readonly PathsOfAmountField<AccountTransaction>[] =
    ["amount", "amountInAccountCurrency"] as const;
  export type Create = OmitKeys<AccountTransaction, "ownerId" | "createAt">;

  export type EncryptedKeys = "notes";
  export type Encrypted = EncryptedType<AccountTransaction, EncryptedKeys>;
  export type EncryptedPart = Pick<AccountTransaction, EncryptedKeys>;
  export type Update = Pick<
    AccountTransaction,
    | "category"
    | "date"
    | "relatedAsset"
    | "amount"
    | "amountInAccountCurrency"
    | "exchangeRate"
    | "notes"
  >;
  //#NOTE value should be negative for liability accounts
  export type UpdateBalance = Pick<
    AccountTransaction,
    "id" | "subAccountId"
  > & { value: number };
  export type UpdateEncrypted = EncryptedType<
    AccountTransaction.Update,
    EncryptedKeys
  >;

  export async function decryptAndConvertDate(
    input: AccountTransaction.Encrypted,
    encryption: Encryption
  ): Promise<AccountTransaction> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestore(decrypted, datePaths);
    return decrypted;
  }
  export async function encrypt(
    input: AccountTransaction,
    encryption: Encryption
  ): Promise<AccountTransaction.Encrypted> {
    return fullObjectEncryption(input, ["notes"], encryption);
  }
  export async function encryptPartial<T extends EncryptedPart>(
    rawData: T,
    encryption: Encryption
  ): Promise<EncryptionField> {
    return fullObjectEncryptionNotStrict(rawData, ["notes"], encryption);
  }
  export const decrypt = fullObjectDecryption<
    AccountTransaction,
    EncryptedKeys
  >;

  export function intoUpdate(
    current: AccountTransaction,
    update: Update
  ): UpdateObject<AccountTransaction> {
    const baseUpdateFields: UpdateObject<Update> = {};

    if (current.category !== update.category) {
      baseUpdateFields.category = update.category;
    }
    if (current.date.getTime() !== update.date.getTime()) {
      baseUpdateFields.date = update.date;
    }
    if (current.exchangeRate && update.exchangeRate) {
      if (
        current.exchangeRate.rate !== update.exchangeRate.rate ||
        current.exchangeRate.date.getTime() !==
          update.exchangeRate.date.getTime()
      ) {
        baseUpdateFields.exchangeRate = update.exchangeRate;
      }
    } else if (current.exchangeRate != update.exchangeRate)
      if (update.exchangeRate)
        baseUpdateFields.exchangeRate = update.exchangeRate;
      else baseUpdateFields.exchangeRate = null;

    if (current.relatedAsset && update.relatedAsset) {
      if (
        current.relatedAsset.assetId !== update.relatedAsset.assetId ||
        current.relatedAsset.assetType !== update.relatedAsset.assetType
      ) {
        baseUpdateFields.relatedAsset = update.relatedAsset;
      }
    } else if (current.relatedAsset != update.relatedAsset)
      if (update.relatedAsset)
        baseUpdateFields.relatedAsset = update.relatedAsset;
      else baseUpdateFields.relatedAsset = null;

    if (!Amount.optionalEqual(current.amount, update.amount))
      if (update.amount) baseUpdateFields.amount = update.amount;
      else baseUpdateFields.amount = null;
    if (
      !Amount.equal(
        current.amountInAccountCurrency,
        update.amountInAccountCurrency
      )
    )
      baseUpdateFields.amountInAccountCurrency = update.amountInAccountCurrency;

    if (current.notes && update.notes) {
      if (current.notes != update.notes) {
        baseUpdateFields.notes = update.notes;
      }
    } else if (current.notes != update.notes)
      if (update.notes) baseUpdateFields.notes = update.notes;
      else baseUpdateFields.notes = null;

    return baseUpdateFields;
  }
  export function updateBalanceCreation(
    accountType: Account.Type,
    currentValue: Amount,
    accountId: string,
    ownerId: string,
    req: UpdateBalance
  ) {
    const valueChange = subDecimal(req.value, currentValue.value);
    const transactionType =
      valueChange > 0 ? TransactionType.Credit : TransactionType.Debit;
    const amountChange = Amount.toAbsolute({
      currency: currentValue.currency,
      value: valueChange,
    });
    const newTx: AccountTransaction = {
      id: req.id,
      ownerId,
      accountId,
      transactionType,
      category: Category.BalanceUpdate,
      date: <any>CoreFirestore.serverTimestamp(),
      sellerOrBuyer: "system",
      amount: amountChange,
      amountInAccountCurrency: amountChange,
      createAt: <any>CoreFirestore.serverTimestamp(),
    };
    let addSubAccountId = false;
    if (req.subAccountId) {
      newTx.subAccountId = req.subAccountId;
      addSubAccountId = true;
    }
    if (transactionType === TransactionType.Debit) {
      newTx.fromAccount = {
        accountType,
        accountId,
      };
      if (addSubAccountId) newTx.fromAccount.subAccountId = req.subAccountId;
    } else {
      newTx.toAccount = {
        accountType,
        accountId,
      };
      if (addSubAccountId) newTx.toAccount.subAccountId = req.subAccountId;
    }
    return newTx;
  }

  export function getNumericSignedValue(tx: AccountTransaction): Amount {
    return {
      currency: tx.amountInAccountCurrency.currency,
      value:
        tx.transactionType === TransactionType.Credit
          ? tx.amountInAccountCurrency.value
          : -tx.amountInAccountCurrency.value,
    };
  }

  export function systemAccountCreation(
    accountType: Account.Type,
    id: string,
    accountId: string,
    subAccountId: Optional<string>,
    ownerId: string,
    numericSignedAmount: Amount,
    encodedIVSalt: string,
    exchangeRate?: ExchangeRateData
  ): Encrypted {
    const transactionType =
      numericSignedAmount.value > 0
        ? TransactionType.Credit
        : numericSignedAmount.value < 0
        ? TransactionType.Debit
        : accountType == Account.Type.Cash ||
          accountType == Account.Type.SavingAccount ||
          accountType == Account.Type.CurrentAccount
        ? TransactionType.Credit
        : TransactionType.Debit;
    const rate = exchangeRate ? exchangeRate.rate : 1;
    const tx: WithFieldValue<Encrypted> = {
      id,
      ownerId,
      accountId,
      transactionType,
      category: Category.SystemAccountCreation,
      date: new Date(),
      sellerOrBuyer: "system",
      amount: Amount.toAbsolute(numericSignedAmount),
      amountInAccountCurrency: Amount.toAbsolute({
        currency: numericSignedAmount.currency,
        value: mulAmount(numericSignedAmount.value, rate),
      }),
      createAt: CoreFirestore.serverTimestamp(),
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue,
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
    let addSubAccountId = false;
    if (subAccountId && subAccountId !== accountId) {
      tx.subAccountId = subAccountId;
      addSubAccountId = true;
    }
    if (exchangeRate) {
      tx.exchangeRate = exchangeRate;
    }
    if (transactionType === TransactionType.Debit) {
      tx.fromAccount = {
        accountId,
        accountType,
      };
      if (addSubAccountId) tx.fromAccount.subAccountId = subAccountId;
    } else {
      tx.toAccount = {
        accountId,
        accountType,
      };
      if (addSubAccountId) tx.toAccount.subAccountId = subAccountId;
    }

    return tx as Encrypted;
  }
  export function calculateValueChange(
    tx: AccountTransaction,
    update: UpdateObject<UpdateEncrypted>
  ): Amount {
    if (update.amountInAccountCurrency) {
      return {
        currency: update.amountInAccountCurrency.currency,
        value:
          tx.transactionType === TransactionType.Credit
            ? subDecimal(
                update.amountInAccountCurrency.value,
                tx.amountInAccountCurrency.value
              )
            : subDecimal(
                tx.amountInAccountCurrency.value,
                update.amountInAccountCurrency.value
              ),
      };
    } else {
      return {
        currency: tx.amountInAccountCurrency.currency,
        value: 0,
      };
    }
  }
  export function validate(
    data: UpdateObject<Encrypted>,
    isCreate: boolean = false
  ) {
    for (const key of amountPaths) {
      if (data[key]) Amount.validate(key, data[key]!);
    }
    if (isCreate) {
      if (!data.transactionType)
        throw new Error("Transaction type is required");
      if (!validateValueInEnum(data.transactionType!, TransactionType))
        throw new Error("Invalid transaction type");
      if (data.transactionType === TransactionType.Debit && !data.fromAccount)
        throw new Error("From account is required");
      if (data.transactionType === TransactionType.Credit && !data.toAccount)
        throw new Error("To account is required");
    }
    if (
      (isCreate || data.category) &&
      !validateValueInEnum(data.category!, Category)
    ) {
      throw new Error("Invalid category");
    }
    if (
      (isCreate || data.amountInAccountCurrency) &&
      data.amountInAccountCurrency!.value <= 0
    ) {
      throw new Error("Amount in account currency must be positive");
    }
    // optional fields
    if (data.amount && data.amount.value < 0) {
      throw new Error("Amount must be positive");
    }
    if (data.relatedAsset) {
      if (!validateValueInEnum(data.relatedAsset.assetType!, AssetType))
        throw new Error("Invalid asset type");
      if (!data.relatedAsset.assetId)
        throw new Error("Related asset id is required");
    }
  }
}

export namespace Command {
  enum CustomKind {
    OverwriteValue = "OverwriteValue",
  }
  export type Kind = SharedCommand.Kind | TxCommand.Kind | CustomKind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...TxCommand.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends CommandBase {
    kind: Kind;
  }

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Account.Encrypted> {
    exchangeRates?: { [subAccountId: string]: ExchangeRateData };
  }
  export function createAsset(
    executerId: string,
    asset: Account.Encrypted
  ): CreateAsset {
    return {
      kind: Kind.CreateAsset,
      executerId,
      asset,
    };
  }

  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Account.UpdateEncrypted>> {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Account.UpdateEncrypted>
  >;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
  export interface CloseAsset extends SharedCommand.CloseAsset {}
  export const closeAsset = SharedCommand.closeAsset;

  export interface AddTransaction
    extends TxCommand.AddTransaction<AccountTransaction.Encrypted> {}
  export const addTransaction = TxCommand.addTransaction;
  export interface UpdateTransaction
    extends TxCommand.UpdateTransaction<
      UpdateObject<AccountTransaction.UpdateEncrypted>
    > {}
  export const updateTransaction = TxCommand.updateTransaction;
  export interface DeleteTransaction extends TxCommand.DeleteTransaction {}
  export const deleteTransaction = TxCommand.deleteTransaction;

  export interface OverwriteValue extends BaseExtended {
    kind: CustomKind.OverwriteValue;
    primaryValue?: Amount;
    subAccountValues?: { id: string; value: Amount }[];
  }
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset
  | Command.CloseAsset
  | Command.AddTransaction
  | Command.UpdateTransaction
  | Command.DeleteTransaction
  | Command.OverwriteValue;

export namespace Event {
  enum CustomKind {
    AccountClosed = "AccountClosed",
    LinkedPropertyIdUpdated = "LinkedPropertyIdUpdated",
    ValueUpdated = "ValueUpdated",
    AllocationsUpdated = "AllocationsUpdated",
  }
  export type Kind = SharedEvent.Kind | TxEvent.Kind | CustomKind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...TxEvent.Kind,
    ...CustomKind,
  };
  interface BaseExtended extends EventBase {
    kind: Kind;
  }

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Account.Encrypted> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Account.UpdateEncrypted>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface AccountClosed extends BaseExtended {
    kind: CustomKind.AccountClosed;
  }
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}

  export interface TransactionAdded
    extends TxEvent.TransactionAdded<AccountTransaction.Encrypted> {
    subAccountId?: string;
    valueChange: Amount;
  }
  export interface TransactionUpdated
    extends TxEvent.TransactionUpdated<
      UpdateObject<AccountTransaction.UpdateEncrypted>
    > {
    subAccountId?: string;
    valueChange: Amount;
  }
  export interface TransactionDeleted extends TxEvent.TransactionDeleted {
    subAccountId?: string;
    valueChange: Amount;
  }

  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface LinkedPropertyIdUpdated extends BaseExtended {
    kind: CustomKind.LinkedPropertyIdUpdated;
    previous?: string;
    current?: string;
  }
  export interface AllocationsUpdated extends BaseExtended {
    kind: CustomKind.AllocationsUpdated;
    data: Allocation[];
    added?: Allocation[];
    removed?: Allocation[];
    changed?: Allocation[];
  }

  export interface SubAccountUpdate {
    id: string;
    value: Amount;
  }
  //#NOTE only external source can do this update
  export interface ValueUpdated extends BaseExtended {
    kind: CustomKind.ValueUpdated;
    primaryValue?: Amount;
    subAccountValues?: SubAccountUpdate[];
    //#TODO check if this is needed
    valueChange: MultiCurrencyAmount;
  }
}

export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.AccountClosed
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.GroupsUpdated
  | Event.LinkedPropertyIdUpdated
  | Event.AllocationsUpdated
  | Event.TransactionAdded
  | Event.TransactionUpdated
  | Event.TransactionDeleted
  | Event.ValueUpdated;

export enum AccountSource {
  Plaid = "Plaid",
}

export interface AccountState<T extends Account.Encrypted = Account.Encrypted> {
  account: T;
  institution: {
    [name: string]: Optional<Institution>;
  };
  transactions: {
    [id: string]: Deletable<Optional<AccountTransaction>>;
  };
}

export class AccountAggregate
  implements IAggregate<AccountState, Command, Event>
{
  state: AccountState;
  kind: string;
  relatedUpdates: Account.RelatedUpdates = {};

  constructor(state: AccountState) {
    this.state = state;
    this.kind = Domain.CashAndBanking;
  }

  id(): string {
    return this.state.account.id;
  }

  version(): number {
    return this.state.account.version;
  }

  incrementVersion(): void {
    this.state.account.version++;
  }

  handle(command: Command): EventWithTime<Event>[] {
    if (command.kind == Command.Kind.DeleteAsset) {
      if (this.state.account.closedWith) {
        throw new InvalidInput("Account is already closed");
      }
      const event: Event.AssetDeleted = {
        kind: Event.Kind.AssetDeleted,
        executerId: command.executerId,
      };
      return [preSealEvent(event)];
    }
    const subtype =
      command.kind == Command.Kind.CreateAsset
        ? command.asset.subtype
        : this.state.account.subtype;
    switch (subtype) {
      case Account.Type.Cash:
        return Cash.handle(
          <AccountState<Cash.Encrypted>>this.state,
          command
        ).map(preSealEvent);
      case Account.Type.CreditCardAccount:
        return CreditCard.handle(
          <AccountState<CreditCard.Encrypted>>this.state,
          command
        ).map(preSealEvent);
      case Account.Type.LoanAccount:
        return Loan.handle(
          <AccountState<Loan.Encrypted>>this.state,
          command
        ).map(preSealEvent);
      case Account.Type.MortgageAccount:
        return Mortgage.handle(
          <AccountState<Mortgage.Encrypted>>this.state,
          command
        ).map(preSealEvent);
      case Account.Type.CurrentAccount:
        return CurrentAccount.handle(
          <AccountState<CurrentAccount.Encrypted>>this.state,
          command
        ).map(preSealEvent);
      case Account.Type.SavingAccount:
        return SavingAccount.handle(
          <AccountState<SavingAccount.Encrypted>>this.state,
          command
        ).map(preSealEvent);
    }
  }

  // | Event.AssetCreated
  // | Event.AssetUpdated
  // | Event.TransactionAdded
  // | Event.TransactionUpdated
  // | Event.TransactionDeleted;
  apply({ data: event, time }: EventWithTime<Event>): this {
    let valueChange: MultiCurrencyAmount = {};
    let subAccountUpdates: Event.SubAccountUpdate[] = [];
    switch (event.kind) {
      case Event.Kind.AssetCreated: {
        this.state.account = event.asset;
        if ((<Loan.Encrypted>this.state.account).allocations)
          (<Loan.Encrypted>this.state.account).allocations = [];
        if (this.state.account.value) this.state.account.value.value = 0;
        const account: AccountMin = {
          id: event.asset.id,
          subtype: event.asset.subtype,
          name: event.asset.name,
          subAccounts: [],
          closed: false,
          ownedPercentage: 100,
          updateAt: time,
        };
        if (
          event.asset.subtype === Account.Type.SavingAccount ||
          event.asset.subtype === Account.Type.CurrentAccount
        ) {
          account.subAccounts = event.asset.subAccounts.map(
            ({ id, isDefault, balance }) => {
              const subAccount: SubAccount = {
                id,
                balance: { currency: balance.currency, value: 0 },
              };
              if (isDefault) {
                subAccount.isDefault = isDefault;
              }
              return subAccount;
            }
          );
        } else {
          account.subAccounts = [
            {
              id: event.asset.id,
              balance: { currency: event.asset.value.currency, value: 0 },
              isDefault: true,
            },
          ];
          if (event.asset.subtype === Account.Type.Cash) {
            account.ownedPercentage = event.asset.ownership?.myOwnership || 100;
          }
        }
        if (this.state.institution[event.asset.institution] === undefined) {
          throw new Error("Institution not found");
        } else {
          this.state.institution[event.asset.institution]!.accounts[
            event.asset.id
          ] = account;
          this.state.institution[event.asset.institution]!.updateAt = time;
        }
        return this;
      }
      case Event.Kind.AssetUpdated: {
        if (
          this.state.institution[this.state.account.institution] === undefined
        ) {
          throw new Error("Institution not found");
        }
        const account =
          this.state.institution[this.state.account.institution]!.accounts[
            this.state.account.id
          ];
        if ("institution" in event.asset && event.asset.institution) {
          if (this.state.institution[event.asset.institution] === undefined) {
            throw new Error("Institution not found");
          }
          delete this.state.institution[this.state.account.institution]!
            .accounts[this.state.account.id];
          this.state.institution[this.state.account.institution]!.updateAt =
            time;
          this.state.institution[event.asset.institution]!.accounts[
            this.state.account.id
          ] = account;
          this.state.institution[event.asset.institution]!.updateAt = time;
        } else {
          this.state.institution[this.state.account.institution]!.updateAt =
            time;
        }
        if (event.asset.name) account.name = event.asset.name;
        if (
          this.state.account.subtype === Account.Type.Cash &&
          (<Cash.UpdateEncrypted>event.asset).ownership
        ) {
          account.ownedPercentage =
            (<Cash.UpdateEncrypted>event.asset).ownership?.myOwnership || 100;
        } else if (
          (this.state.account.subtype === Account.Type.CurrentAccount ||
            this.state.account.subtype === Account.Type.SavingAccount) &&
          (<CurrentAccount.Encrypted>event.asset).subAccounts
        ) {
          account.subAccounts = (<CurrentAccount.Encrypted>(
            event.asset
          )).subAccounts.map(({ id, isDefault, balance }) => {
            const subAccount: SubAccount = {
              id,
              balance: { ...balance },
            };
            if (isDefault) {
              subAccount.isDefault = isDefault;
            }
            return subAccount;
          });
          if (
            this.state.account.accountType === Account.AccountType.MultiCurrency
          )
            (<CurrentAccount.Encrypted>event.asset).accountCurrency =
              account.subAccounts.find((s) => s.isDefault)!.balance!.currency;
        }
        let updateAsset = event.asset;
        if ((<Loan.Encrypted>event.asset).allocations) {
          const { allocations, ...rest } = <Loan.Encrypted>event.asset;
          updateAsset = rest;
        }
        applyUpdateToObject(this.state.account, updateAsset);
        this.state.account.updateAt = time;
        account.updateAt = this.state.account.updateAt;
        return this;
      }

      case Event.Kind.AssetDeleted: {
        const institution = this.state.account.institution;
        if (this.state.institution[institution] !== undefined) {
          delete this.state.institution[institution]!.accounts[
            this.state.account.id
          ];
          this.state.institution[institution]!.updateAt = time;
        }
        if (
          this.state.account.groupIds &&
          this.state.account.groupIds.length > 0
        ) {
          this.relatedUpdates.removedGroupIds = this.state.account.groupIds;
        }
        this.state.account = setObjectDeleted(this.state.account);
        return this;
      }
      case Event.Kind.AccountClosed: {
        const institution = this.state.account.institution;
        if (this.state.institution[institution] !== undefined) {
          this.state.institution[institution]!.accounts[
            this.state.account.id
          ].closed = true;
        }
        this.state.account.closedWith = new Date().toString();
        return this;
      }
      case Event.Kind.GroupsUpdated:
        if (event.addIds.length > 0)
          this.relatedUpdates.addedGroupIds = event.addIds;
        if (event.removedIds.length > 0)
          this.relatedUpdates.removedGroupIds = event.removedIds;
        return this;
      case Event.Kind.LinkedPropertyIdUpdated:
        // if (event.current) {
        //   if (this.relatedUpdates.addedPropertyIds) {
        //     this.relatedUpdates.addedPropertyIds.push(event.current);
        //   } else {
        //     this.relatedUpdates.addedPropertyIds = [event.current];
        //   }
        // }
        // if (event.previous) {
        //   if (this.relatedUpdates.removedPropertyIds) {
        //     this.relatedUpdates.removedPropertyIds.push(event.previous);
        //   } else {
        //     this.relatedUpdates.removedPropertyIds = [event.previous];
        //   }
        // }
        return this;
      case Event.Kind.AllocationsUpdated:
        if (event.added && event.added.length > 0)
          (<Loan.Encrypted>this.state.account).allocations.push(...event.added);
        if (event.changed && event.changed.length > 0) {
          const allocations = (<Loan.Encrypted>this.state.account).allocations;
          event.changed.forEach((changed) => {
            const allocation = allocations.find(
              (a) => a.assetId === changed.assetId
            );
            if (allocation) {
              allocation.percent = changed.percent;
            }
          });
        }
        if (event.removed && event.removed.length > 0) {
          removeItemsFromArray(
            (<Loan.Encrypted>this.state.account).allocations,
            event.removed,
            (src, removing) => src.assetId === removing.assetId
          );
        }
        return this;
      case Event.Kind.ShareholderUpdated: {
        const institution = this.state.account.institution;
        if (this.state.institution[institution] !== undefined) {
          this.state.institution[institution]!.accounts[
            this.state.account.id
          ].ownedPercentage = event.current?.myOwnership || 100;
        }
        return this;
      }
      case Event.Kind.BeneficiaryUpdated:
        return this;

      case Event.Kind.TransactionAdded:
        {
          this.state.transactions[event.id] = event.data;
          const txValueChange = event.valueChange;
          valueChange[txValueChange.currency] = txValueChange.value;
          subAccountUpdates = [
            {
              id: event.data.subAccountId || event.data.accountId,
              value: txValueChange,
            },
          ];
          this.txUpdateRelatedUpdateAt(time);
        }
        break;
      case Event.Kind.TransactionUpdated:
        {
          const tx = this.state.transactions[event.id];
          if (!tx) throw new DataPoisoned("Transaction not found");
          applyUpdateToObject(tx, event.update);
          const txValueChange = event.valueChange;
          valueChange[txValueChange.currency] = txValueChange.value;
          subAccountUpdates = [
            {
              id: tx.subAccountId || tx.accountId,
              value: txValueChange,
            },
          ];
          if (txValueChange.value !== 0) {
            this.txUpdateRelatedUpdateAt(time);
          }
        }
        break;
      case Event.Kind.TransactionDeleted:
        {
          const tx = this.state.transactions[event.id];
          if (!tx) throw new DataPoisoned("Transaction not found");
          const txValueChange = event.valueChange;
          valueChange[txValueChange.currency] = txValueChange.value;
          subAccountUpdates = [
            {
              id: tx.subAccountId || tx.accountId,
              value: txValueChange,
            },
          ];
          this.state.transactions[event.id] = null;
          this.txUpdateRelatedUpdateAt(time);
        }
        break;
      case Event.Kind.ValueUpdated:
        valueChange = event.valueChange;
        if (event.subAccountValues) {
          subAccountUpdates = event.subAccountValues;
        }
        break;
    }

    if (this.state.institution[this.state.account.institution] === undefined) {
      throw new DataPoisoned("Institution not found");
    }
    Institution.updateValue(
      this.state.institution[this.state.account.institution]!.accounts[
        this.state.account.id
      ],
      subAccountUpdates
    );
    switch (this.state.account.subtype) {
      case Account.Type.Cash:
        Cash.updateValue(<Cash.Encrypted>this.state.account, valueChange);
        return this;
      case Account.Type.CreditCardAccount:
        CreditCard.updateValue(
          <CreditCard.Encrypted>this.state.account,
          valueChange
        );
        return this;
      case Account.Type.LoanAccount:
        Loan.updateValue(<Loan.Encrypted>this.state.account, valueChange);
        return this;
      case Account.Type.MortgageAccount:
        Mortgage.updateValue(
          <Mortgage.Encrypted>this.state.account,
          valueChange
        );
        return this;
      case Account.Type.CurrentAccount:
        CurrentAccount.updateValue(
          <CurrentAccount.Encrypted>this.state.account,
          subAccountUpdates
        );
        return this;
      case Account.Type.SavingAccount:
        SavingAccount.updateValue(
          <SavingAccount.Encrypted>this.state.account,
          subAccountUpdates
        );
        return this;
    }
  }

  txUpdateRelatedUpdateAt(time: Date) {
    const updateAt = time;
    this.state.account.updateAt = updateAt;
    if (!this.state.institution[this.state.account.institution])
      throw new DataPoisoned("Institution not found");
    this.state.institution[this.state.account.institution]!.updateAt = updateAt;
    this.state.institution[this.state.account.institution]!.accounts[
      this.state.account.id
    ].updateAt = updateAt;
  }
}

export class AccountStateWriter
  implements IAggregateStateWriter<AccountState, Command, Event>
{
  transaction!: Transaction;
  accountCollectionRef: CollectionReference<Account.Encrypted>;
  txCollectionRef: Optional<CollectionReference<AccountTransaction>>;
  institutionCollectionRef: CollectionReference<Institution>;
  relationCollectionRef: CollectionReference<RelationsOfAsset>;

  constructor(
    accountRef: CollectionReference<Account.Encrypted>,
    txCollectionRef: Optional<CollectionReference<AccountTransaction>>,
    institutionCollectionRef: CollectionReference<Institution>,
    relationCollectionRef: CollectionReference<RelationsOfAsset>
  ) {
    this.accountCollectionRef = accountRef;
    this.txCollectionRef = txCollectionRef;
    this.institutionCollectionRef = institutionCollectionRef;
    this.relationCollectionRef = relationCollectionRef;
  }

  setStateTx(transaction: Transaction, aggregate: AccountAggregate): void {
    const accountDocRef = CoreFirestore.docFromCollection(
      this.accountCollectionRef,
      aggregate.state.account.id
    );
    const relationDocRef = CoreFirestore.docFromCollection(
      this.relationCollectionRef,
      aggregate.state.account.id
    );
    if (stateIsDeleted(aggregate.state.account)) {
      this.deleteStateTx(transaction, accountDocRef, relationDocRef);
    } else {
      transaction.set(accountDocRef, aggregate.state.account);
      transaction.set(
        relationDocRef,
        buildCashAndBankingRelation(aggregate.state.account)
      );
      Object.entries(aggregate.state.transactions).forEach(([id, tx]) => {
        if (tx) {
          if (!this.txCollectionRef) throw new Error("txCollectionRef not set");
          transaction.set(
            CoreFirestore.docFromCollection(this.txCollectionRef, id),
            tx
          );
        } else if (tx === null) {
          if (!this.txCollectionRef) throw new Error("txCollectionRef not set");
          transaction.delete(
            CoreFirestore.docFromCollection(this.txCollectionRef, id)
          );
        }
      });
    }
    Object.entries(aggregate.state.institution).forEach(([_, institution]) => {
      if (!institution) return;
      transaction.set(
        CoreFirestore.docFromCollection(
          this.institutionCollectionRef,
          institution.id
        ),
        institution
      );
    });
    aggregate.relatedUpdates = {};
  }

  deleteStateTx(
    transaction: Transaction,
    accountDocRef: DocumentReference<Account.Encrypted>,
    relationDocRef: DocumentReference<RelationsOfAsset>
  ): void {
    transaction.delete(accountDocRef);
    transaction.delete(relationDocRef);
  }
}
