// source: https://gist.github.com/dcollien/312bce1270a5f511bf4a?permalink_comment_id=2265490#gistcomment-2265490

const hasBlobConstructor =
  typeof Blob !== 'undefined' &&
  (function () {
    try {
      return Boolean(new Blob())
    } catch (e) {
      return false
    }
  })()

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  typeof Uint8Array !== 'undefined' &&
  (function () {
    try {
      return new Blob([new Uint8Array(100)]).size === 100
    } catch (e) {
      return false
    }
  })()

const hasToBlobSupport = typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false

const hasBlobSupport =
  hasToBlobSupport ||
  (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined')

const hasReaderSupport = typeof FileReader !== 'undefined' || typeof URL !== 'undefined'

export enum ImageSizes {
  Small = 'small',
  Large = 'large',
  Full = 'full'
}

export const ImageDimensions = {
  [ImageSizes.Small]: 40,
  [ImageSizes.Large]: 190,
  [ImageSizes.Full]: 1080
} as const

export async function resize(file: File, size: ImageSizes) {
  function toBlob(canvas: HTMLCanvasElement, type: string) {
    const dataURI = canvas.toDataURL(type)
    const dataURIParts = dataURI.split(',')
    let byteString
    if (dataURIParts[0].indexOf('base64') >= 0) {
      // Convert base64 to raw binary data held in a string:
      byteString = atob(dataURIParts[1])
    } else {
      // Convert base64/URLEncoded data component to raw binary data:
      byteString = decodeURIComponent(dataURIParts[1])
    }
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const intArray = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i += 1) {
      intArray[i] = byteString.charCodeAt(i)
    }

    const mimeString = dataURIParts[0].split(':')[1].split(';')[0]
    let blob = null

    if (hasBlobConstructor) {
      blob = new Blob([hasArrayBufferViewSupport ? intArray : arrayBuffer], { type: mimeString })
    } else {
      blob = new Blob([arrayBuffer])
    }
    return blob
  }

  function toFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob
    b.lastModifiedDate = new Date()
    b.name = fileName
    return <File>theBlob
  }

  return new Promise((resolve, reject) => {
    const isSupported = typeof HTMLCanvasElement !== 'undefined' && hasBlobSupport && hasReaderSupport
    if (!isSupported || !file.type.match(/image.*/)) {
      resolve(file)
      return
    }

    if (file.type.match(/image\/gif/)) {
      resolve(file)
      return
    }
    const dimension = ImageDimensions[size]
    const image = document.createElement('img')

    image.onload = (imgEvt) => {
      let width = image.width
      let height = image.height

      if (width <= dimension && height <= dimension) {
        // early exit; no need to resize
        resolve(file)
        return
      }

      const shorter = width < height ? width : height
      const scale = dimension / shorter

      width *= scale
      height *= scale

      const canvas = document.createElement('canvas')

      canvas.width = dimension
      canvas.height = dimension

      const ctx = canvas.getContext('2d')
      if (!ctx) {
        resolve(file)
        return
      }
      ctx.imageSmoothingEnabled = true
      ;(ctx as any).imageSmoothingQuality = 'high'
      ctx.drawImage(image, (dimension - width) / 2, (dimension - height) / 2, width, height)

      if (hasToBlobSupport) {
        canvas.toBlob((blob) => {
          if (blob === null) {
            resolve(file)
            return
          }
          const newFile = toFile(blob, file.name)
          resolve(newFile)
        }, file.type)
      } else {
        const blob = toBlob(canvas, file.type)
        const newFile = toFile(blob, file.name)
        resolve(newFile)
        return
      }
    }

    if (typeof URL === 'undefined') {
      const reader = new FileReader()
      reader.onload = function (evt) {
        image.src = (evt.target as any).result
      }
      reader.readAsDataURL(file)
    } else {
      image.src = URL.createObjectURL(file)
    }
  })
}
