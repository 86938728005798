import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22m26.083%208.665-1.135%201.134L18.148%203l-2.266%202.266%206.8%206.8-1.135%201.135q-.079.078-.133.176-.054.097-.08.206-.026.108-.02.22.005.11.04.216t.098.198q.063.092.148.164.086.071.188.117.102.045.212.06l5.03.72q.114.016.23.005.116-.01.226-.047t.21-.098q.099-.061.181-.143.082-.083.143-.181.061-.1.098-.21.037-.11.048-.226.01-.116-.006-.231l-.719-5.03q-.016-.11-.061-.212t-.117-.187q-.071-.086-.163-.149-.092-.062-.198-.098-.106-.035-.217-.04-.112-.005-.22.02-.109.026-.206.08-.097.055-.176.134Zm-21.79%205.893%2018.149%2018.15q.14.14.324.216.183.076.382.076h6.564q.098%200%20.195-.02.096-.018.187-.056.09-.038.173-.092.082-.055.151-.125.07-.07.124-.151.055-.082.093-.173.037-.09.056-.187.02-.097.02-.195v-5.412h-5.412q-.098%200-.195-.019-.096-.019-.187-.057-.091-.037-.173-.092-.082-.055-.151-.124-.07-.07-.125-.152-.054-.081-.092-.172-.038-.091-.057-.188-.019-.096-.019-.195v-4.412q0-.099-.02-.195-.018-.096-.056-.187-.038-.091-.092-.173-.055-.082-.125-.152-.07-.07-.15-.124-.083-.054-.174-.092-.09-.038-.187-.057-.096-.02-.195-.02H18.89q-.099%200-.195-.018-.097-.02-.188-.057-.09-.038-.172-.093-.082-.054-.152-.124-.07-.07-.124-.151-.055-.082-.092-.173-.038-.09-.057-.187-.02-.097-.02-.195v-4.413q0-.098-.019-.195-.019-.096-.057-.187-.037-.09-.092-.173-.055-.082-.124-.151-.07-.07-.151-.124-.082-.055-.173-.093-.091-.037-.188-.056-.096-.02-.195-.02h-4.412q-.098%200-.195-.019-.096-.02-.187-.057-.091-.037-.173-.092-.082-.055-.151-.124-.07-.07-.125-.152-.054-.082-.092-.172-.038-.091-.057-.188-.019-.096-.019-.195V8.357q0-.099-.02-.195-.018-.097-.056-.188-.038-.09-.092-.172-.055-.082-.125-.152-.07-.07-.151-.124-.082-.055-.173-.092-.09-.038-.187-.057-.097-.02-.195-.02H4v6.495q0%20.199.076.382.076.184.217.324Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BasementIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'm26.083 8.665-1.135 1.134L18.148 3l-2.266 2.266 6.8 6.8-1.135 1.135q-.079.078-.133.176-.054.097-.08.206-.026.108-.02.22.005.11.04.216t.098.198q.063.092.148.164.086.071.188.117.102.045.212.06l5.03.72q.114.016.23.005.116-.01.226-.047t.21-.098q.099-.061.181-.143.082-.083.143-.181.061-.1.098-.21.037-.11.048-.226.01-.116-.006-.231l-.719-5.03q-.016-.11-.061-.212t-.117-.187q-.071-.086-.163-.149-.092-.062-.198-.098-.106-.035-.217-.04-.112-.005-.22.02-.109.026-.206.08-.097.055-.176.134Zm-21.79 5.893 18.149 18.15q.14.14.324.216.183.076.382.076h6.564q.098 0 .195-.02.096-.018.187-.056.09-.038.173-.092.082-.055.151-.125.07-.07.124-.151.055-.082.093-.173.037-.09.056-.187.02-.097.02-.195v-5.412h-5.412q-.098 0-.195-.019-.096-.019-.187-.057-.091-.037-.173-.092-.082-.055-.151-.124-.07-.07-.125-.152-.054-.081-.092-.172-.038-.091-.057-.188-.019-.096-.019-.195v-4.412q0-.099-.02-.195-.018-.096-.056-.187-.038-.091-.092-.173-.055-.082-.125-.152-.07-.07-.15-.124-.083-.054-.174-.092-.09-.038-.187-.057-.096-.02-.195-.02H18.89q-.099 0-.195-.018-.097-.02-.188-.057-.09-.038-.172-.093-.082-.054-.152-.124-.07-.07-.124-.151-.055-.082-.092-.173-.038-.09-.057-.187-.02-.097-.02-.195v-4.413q0-.098-.019-.195-.019-.096-.057-.187-.037-.09-.092-.173-.055-.082-.124-.151-.07-.07-.151-.124-.082-.055-.173-.093-.091-.037-.188-.056-.096-.02-.195-.02h-4.412q-.098 0-.195-.019-.096-.02-.187-.057-.091-.037-.173-.092-.082-.055-.151-.124-.07-.07-.125-.152-.054-.082-.092-.172-.038-.091-.057-.188-.019-.096-.019-.195V8.357q0-.099-.02-.195-.018-.097-.056-.188-.038-.09-.092-.172-.055-.082-.125-.152-.07-.07-.151-.124-.082-.055-.173-.092-.09-.038-.187-.057-.097-.02-.195-.02H4v6.495q0 .199.076.382.076.184.217.324Z'
        }
      />
    </svg>
  )
}
