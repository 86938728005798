export interface StorageLimit {
  limit: number;
  // managed by storage trigger functions
  usage: number;
}

export interface StorageEventRecord {
  increment: number;
  processedAt: Date;
  version: number;
}

export const defaultStorageLimit: StorageLimit = {
  // MPT-17270: 5GB for testing
  limit: 5 * 1024 * 1024 * 1024, // in bytes
  usage: 0,
};
