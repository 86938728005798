import { artTypeValues } from 'core/remodel/types/arts'
import { otherCollectableTypeValues } from 'core/remodel/types/belongings'
import { AssetType } from 'core/remodel/types/enums'
import { wineTypeValues } from 'core/remodel/types/wineAndSprits'
import { allowedTypes } from '@/constants/upload'

export const checkImageUrl = async (src?: string): Promise<string | undefined> => {
  try {
    if (!src) throw new Error('Empty src.')

    return new Promise((resolve) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image.width > 0 ? src : undefined))
      image.addEventListener('error', () => resolve(undefined))
      image.src = src
    })
  } catch {
    return undefined
  }
}
// the browser do not check * type, so we need to check it here
export const isAllowedFileType = (fileType: string) => {
  return allowedTypes.some((type) => {
    if (type.endsWith('/*')) {
      return fileType.startsWith(type.slice(0, -2))
    }
    return type === fileType
  })
}

export const isValidSubtype = (assetType: AssetType, subtype: string): boolean => {
  switch (assetType) {
    case AssetType.OtherCollectables:
      return otherCollectableTypeValues.includes(subtype as any)
    case AssetType.WineAndSpirits:
      return wineTypeValues.includes(subtype as any)
    case AssetType.Art:
      return artTypeValues.includes(subtype as any)
    default:
      return false
  }
}

