import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22%23ddd%22%3E%3Cpath%20d%3D%22M9.429%2019.375c.275%201.086.866%202.186%201.636%203.163l-.179.192-1.897%201.884%201.897%201.897%201.884-1.897.179-.179c.99.77%202.09%201.361%203.176%201.636V29h2.75v-2.929c1.086-.275%202.186-.852%203.176-1.636l1.114%201.128.949.948%201.897-1.897-.948-.949-1.128-1.114c.784-.99%201.361-2.09%201.636-3.176H28.5v-2.75h-2.929c-.275-1.086-.866-2.186-1.636-3.176l.179-.179%201.897-1.884-1.897-1.897-1.884%201.897-.192.179c-.977-.77-2.077-1.361-3.163-1.636V7h-2.75v2.929c-1.086.275-2.186.866-3.176%201.636l-2.063-2.076-1.897%201.897%202.076%202.063c-.77.99-1.361%202.09-1.636%203.176H6.5v2.75zm5.321-.344V16.97l1.719-1.719h2.062l1.719%201.719v2.062l-1.719%201.719H16.47z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function GearIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        d={
          'M9.429 19.375c.275 1.086.866 2.186 1.636 3.163l-.179.192-1.897 1.884 1.897 1.897 1.884-1.897.179-.179c.99.77 2.09 1.361 3.176 1.636V29h2.75v-2.929c1.086-.275 2.186-.852 3.176-1.636l1.114 1.128.949.948 1.897-1.897-.948-.949-1.128-1.114c.784-.99 1.361-2.09 1.636-3.176H28.5v-2.75h-2.929c-.275-1.086-.866-2.186-1.636-3.176l.179-.179 1.897-1.884-1.897-1.897-1.884 1.897-.192.179c-.977-.77-2.077-1.361-3.163-1.636V7h-2.75v2.929c-1.086.275-2.186.866-3.176 1.636l-2.063-2.076-1.897 1.897 2.076 2.063c-.77.99-1.361 2.09-1.636 3.176H6.5v2.75zm5.321-.344V16.97l1.719-1.719h2.062l1.719 1.719v2.062l-1.719 1.719H16.47z'
        }
        fill={'#0F889B'}
      />
    </svg>
  )
}
