import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2035%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M29.15%208H5.85q-.094%200-.185.017-.092.017-.179.051-.086.034-.165.083-.08.05-.148.112-.068.063-.124.138-.056.074-.097.158-.04.084-.066.173-.025.09-.032.183L4.254%2016h2.524C8.005%2016%209%2017.075%209%2018.4v.6h17v-.6c0-1.325.995-2.4%202.222-2.4h2.524l-.6-7.085q-.008-.093-.032-.183-.025-.09-.066-.173-.041-.084-.097-.158-.056-.075-.124-.138-.069-.063-.148-.112-.079-.049-.165-.083-.087-.034-.178-.05Q29.244%208%2029.15%208ZM26%2020.3V22h8.651l-.78%203.8h-.939l-.19%202.2h-1.13c-.623%200-1.128-.492-1.128-1.1v-1.1H4.897v1.1c0%20.608-.506%201.1-1.13%201.1H2.64l-.19-2.2H1.13L.349%2022H9v-1.7h17Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CouchBedIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M29.15 8H5.85q-.094 0-.185.017-.092.017-.179.051-.086.034-.165.083-.08.05-.148.112-.068.063-.124.138-.056.074-.097.158-.04.084-.066.173-.025.09-.032.183L4.254 16h2.524C8.005 16 9 17.075 9 18.4v.6h17v-.6c0-1.325.995-2.4 2.222-2.4h2.524l-.6-7.085q-.008-.093-.032-.183-.025-.09-.066-.173-.041-.084-.097-.158-.056-.075-.124-.138-.069-.063-.148-.112-.079-.049-.165-.083-.087-.034-.178-.05Q29.244 8 29.15 8ZM26 20.3V22h8.651l-.78 3.8h-.939l-.19 2.2h-1.13c-.623 0-1.128-.492-1.128-1.1v-1.1H4.897v1.1c0 .608-.506 1.1-1.13 1.1H2.64l-.19-2.2H1.13L.349 22H9v-1.7h17Z'
        }
      />
    </svg>
  )
}
