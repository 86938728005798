import { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'

import { PermissionCategory } from 'core/remodel/refPaths'
import { useAuthStore } from '@/store/authStore'

type RouteConfig = {
  pattern: RegExp
  permission: PermissionCategory | 'Wishlist'
  action: 'view' | 'edit' | 'create' | 'delete'
}
const routeConfigs: RouteConfig[] = [
  // View routes
  { pattern: /^\/finances\/insurance/, permission: PermissionCategory.Insurance, action: 'view' },
  { pattern: /^\/finances/, permission: PermissionCategory.Finance, action: 'view' },
  { pattern: /^\/properties/, permission: PermissionCategory.Property, action: 'view' },
  { pattern: /^\/collectables\/art/, permission: PermissionCategory.Art, action: 'view' },
  { pattern: /^\/collectables\/wine/, permission: PermissionCategory.Wine, action: 'view' },
  { pattern: /^\/collectables\/other/, permission: PermissionCategory.OtherCollectables, action: 'view' },
  { pattern: /^\/belongings/, permission: PermissionCategory.Belonging, action: 'view' },
  { pattern: /^\/wishlist/, permission: 'Wishlist', action: 'view' },

  // Edit routes
  { pattern: /^\/finances\/insurance\/edit/, permission: PermissionCategory.Insurance, action: 'edit' },
  { pattern: /^\/finances\/[\w-]+\/edit/, permission: PermissionCategory.Finance, action: 'edit' },
  { pattern: /^\/properties\/summary\/edit/, permission: PermissionCategory.Property, action: 'edit' },
  { pattern: /^\/collectables\/art\/edit/, permission: PermissionCategory.Art, action: 'edit' },
  { pattern: /^\/collectables\/wine\/edit/, permission: PermissionCategory.Wine, action: 'edit' },
  { pattern: /^\/collectables\/other\/edit/, permission: PermissionCategory.OtherCollectables, action: 'edit' },
  { pattern: /^\/belongings\/summary\/edit/, permission: PermissionCategory.Belonging, action: 'edit' },

  // Create routes
  { pattern: /^\/finances\/insurance\/create\/$/, permission: PermissionCategory.Insurance, action: 'create' },
  { pattern: /^\/finances\/[\w-]+\/create\/$/, permission: PermissionCategory.Finance, action: 'create' },
  { pattern: /^\/properties\/summary\/create\/$/, permission: PermissionCategory.Property, action: 'create' },
  { pattern: /^\/collectables\/art\/create\/$/, permission: PermissionCategory.Art, action: 'create' },
  { pattern: /^\/collectables\/wine\/create\/$/, permission: PermissionCategory.Wine, action: 'create' },
  { pattern: /^\/collectables\/other\/create\/$/, permission: PermissionCategory.OtherCollectables, action: 'create' },
  { pattern: /^\/belongings\/summary\/create\/$/, permission: PermissionCategory.Belonging, action: 'create' },

  // Delete routes
  { pattern: /^\/finances\/insurance\/delete/, permission: PermissionCategory.Insurance, action: 'delete' },
  { pattern: /^\/finances\/[\w-]+\/delete/, permission: PermissionCategory.Finance, action: 'delete' },
  { pattern: /^\/properties\/summary\/delete/, permission: PermissionCategory.Property, action: 'delete' },
  { pattern: /^\/collectables\/art\/delete/, permission: PermissionCategory.Art, action: 'delete' },
  { pattern: /^\/collectables\/wine\/delete/, permission: PermissionCategory.Wine, action: 'delete' },
  { pattern: /^\/collectables\/other\/delete/, permission: PermissionCategory.OtherCollectables, action: 'delete' },
  { pattern: /^\/belongings\/summary\/delete/, permission: PermissionCategory.Belonging, action: 'delete' }
]

export function useRouteGuard() {
  const router = useRouter()
  const status = useAuthStore((state) => state.status)
  const { canView, canEdit, canCreate, canDelete } = useAuthStore((state) => state.permissions)

  const to = useMemo(() => {
    return canView('GlobalDashboard') ? '/' : '/documents/summary'
  }, [canView])

  const redirect = useCallback(
    (shouldRedirect: boolean) => {
      if (shouldRedirect) {
        router.replace(to)
      }
    },
    [router, to]
  )

  const checkPermission = useCallback(
    (config: RouteConfig) => {
      if (config.permission === PermissionCategory.Finance) {
        const insurancePermission =
          config.action === 'view'
            ? canView(PermissionCategory.Insurance)
            : config.action === 'edit'
            ? canEdit(PermissionCategory.Insurance)
            : config.action === 'create'
            ? canCreate(PermissionCategory.Insurance)
            : config.action === 'delete'
            ? canDelete(PermissionCategory.Insurance)
            : false

        if (insurancePermission) {
          return true
        }
      }
      switch (config.action) {
        case 'view':
          return canView(config.permission)
        case 'edit':
          return canEdit(config.permission as PermissionCategory)
        case 'create':
          return canCreate(config.permission as PermissionCategory)
        case 'delete':
          return canDelete(config.permission as PermissionCategory)
      }
    },
    [canView, canEdit, canCreate, canDelete]
  )

  useEffect(() => {
    if (status !== 'ready') return

    const path = router.asPath
    for (const config of routeConfigs) {
      if (config.pattern.test(path)) {
        const hasPermission = checkPermission(config)
        redirect(!hasPermission)
        if (!hasPermission) {
          console.log(`Redirecting from ${path} due to lack of ${config.action} permission for ${config.permission}`)
        }
        break
      }
    }
  }, [checkPermission, redirect, router.asPath, status])
}
