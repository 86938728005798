import { Valuation } from "../types/actions/valuation";
import { Optional } from "../types/common";
import {
  EncryptionField,
  EncryptionFieldKey,
  IVSaltFieldKey,
  doRemoveEncryptedFields,
} from "../encryption/utils";
import { Action, ActionTypeBundleExtend } from "../types/actions";
import { ActionType } from "../types/actions/base";
import { ActionCommand, SellCommand, ValuationCommand } from "../types/command";
import { SoldInfo } from "../types/actions/soldInfo";
import { UpdateObject } from "../utils";
import { Encryption } from "./encryption";
import {
  CollectionReference,
  CoreFirestore,
  DocumentReference,
  DocumentSnapshot,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";
import { Consignment } from "../types/actions/consignment";

export function getAction<T = any>(
  transaction: Transaction,
  collectionRef: CollectionReference<T>,
  actionId: string
): Promise<DocumentSnapshot<T>> {
  const actionRef = CoreFirestore.docFromCollection(collectionRef, actionId);
  return transaction.get(actionRef);
}

export function actionRefFromAssetDocRef<T = any>(
  assetDocRef: DocumentReference
): CollectionReference<T> {
  return <CollectionReference<T>>(
    CoreFirestore.collection(`${assetDocRef.path}/Action`)
  );
}

export async function getLatestValuation(
  collectionRef: CollectionReference<Valuation.Encrypted>,
  excludeId?: string
): Promise<Optional<Valuation.Encrypted>> {
  const result = await CoreFirestore.getDocsFromCollection(
    collectionRef,
    CoreFirestore.orderBy("createAt", "desc")
  ).then(getQueriedData);
  if (result.length > 0) {
    if (excludeId) {
      for (const action of result) {
        if (
          action.actionType == ActionType.AddValuation &&
          action.id != excludeId &&
          action.updateValue
        )
          return action;
      }
    }
  }
  return undefined;
}

//Valuation
export async function buildAddValuationCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  ownerId: string,
  executerId: string,
  createFields: Valuation.CreateFields,
  encryption: Encryption,
  updateValue: boolean
) {
  const valuation = Valuation.fromCreate(createFields, ownerId);
  Valuation.validateEncryptedPart(valuation);

  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  await getAction(transaction, actionCollectionRef, valuation.id).then(
    checkDuplicated
  );
  return ValuationCommand.addValuation(
    executerId,
    await Valuation.encrypt(valuation, encryption),
    updateValue
  );
}
export async function buildUpdateValuationCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  valuationId: string,
  updateFields: Valuation.Update,
  encryption: Encryption,
  valueSourceId?: string
) {
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const undecryptedValuation = await getAction<Valuation.Encrypted>(
    transaction,
    actionCollectionRef,
    valuationId
  ).then(checkAndGetData);
  const result = Action.assureVersion(undecryptedValuation);
  if (result === TypeResult.DataOutDated) Action.handleOutDated();
  if (undecryptedValuation.actionType !== ActionType.AddValuation)
    throw new Error(
      `Action ${undecryptedValuation.id} is not ${ActionType.AddValuation}`
    );

  const newValuation = await getLatestValuation(
    actionCollectionRef,
    valuationId
  );

  const currentValuation = await Valuation.decryptAndConvertDate(
    undecryptedValuation,
    encryption
  );
  const updates = Valuation.intoUpdate(currentValuation, updateFields);
  // #NOTE: needs `updateValue` to check if need to update `value`
  updates.updateValue = updateFields.updateValue;
  if (!currentValuation.updateValue && updates.updateValue)
    updates.value = updateFields.value;
  const encryptedFieldsUpdated = Valuation.encryptedKeysArray.reduce(
    (result, key) => result || Object.keys(updates).includes(key),
    false
  );
  const encryptedUpdate: UpdateObject<Valuation.Encrypted> =
    Valuation.removeEncryptedFields(updates);
  if (encryptedFieldsUpdated) {
    const encryptionPart: Valuation.EncryptedPart = {
      name: currentValuation.name,
    };
    if (updates.name !== null) {
      encryptionPart.name = updates.name || currentValuation.name;
    }
    if (updates.valuedByRepresentative !== null) {
      encryptionPart.valuedByRepresentative =
        updates.valuedByRepresentative ||
        currentValuation.valuedByRepresentative;
    }
    if (updates.purpose !== null) {
      encryptionPart.purpose = updates.purpose || currentValuation.purpose;
    }
    if (updates.status !== null) {
      encryptionPart.status = updates.status || currentValuation.status;
    }
    if (updates.notes !== null) {
      encryptionPart.notes = updates.notes || currentValuation.notes;
    }
    if (updates.file !== null) {
      encryptionPart.file = updates.file || currentValuation.file;
    }
    const newEncrypted = await Valuation.encryptPartial(
      encryptionPart,
      encryption
    );
    encryptedUpdate[EncryptionFieldKey] = newEncrypted[EncryptionFieldKey];
  }

  let createdAfterValueSource = false;
  if (valueSourceId) {
    const sourceValuation = await getAction<Valuation.Encrypted>(
      transaction,
      actionCollectionRef,
      valueSourceId
    ).then(checkAndGetData);
    if (
      currentValuation.createAt.getTime() >
      CoreFirestore.checkAndConvertTimestampToDate(
        sourceValuation.createAt
      )!.getTime()
    ) {
      createdAfterValueSource = true;
    }
  }

  return {
    command: ValuationCommand.updateValuation(
      executerId,
      valuationId,
      encryptedUpdate,
      createdAfterValueSource,
      newValuation
        ? {
            id: newValuation.id,
            value: newValuation.value,
          }
        : undefined
    ),
    valuation: undecryptedValuation,
  };
}
export async function buildDeleteValuationCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  valuationId: string,
  encryption: Encryption
) {
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const currentValuation = await getAction<Valuation.Encrypted>(
    transaction,
    actionCollectionRef,
    valuationId
  ).then(checkAndGetData);
  const result = Action.assureVersion(currentValuation);
  if (result === TypeResult.DataOutDated) Action.handleOutDated();
  if (currentValuation.actionType !== ActionType.AddValuation)
    throw new Error(
      `Action ${currentValuation.id} is not ${ActionType.AddValuation}`
    );

  const newValuation = await getLatestValuation(
    actionCollectionRef,
    valuationId
  );

  const valuation = await Valuation.decrypt(currentValuation, encryption);
  const iv = encryption.generateNewIVSalt();
  const encryptedValuationName = {
    [EncryptionFieldKey]: {
      data: await encryption.encryptAndStringify(
        { valuationName: valuation.name },
        iv
      ),
      [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
    },
  };
  return ValuationCommand.deleteValuation(
    executerId,
    valuationId,
    newValuation?.id,
    currentValuation.value,
    newValuation?.value,
    encryptedValuationName
  );
}
//SoldInfo
export async function buildMarkAsSoldCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  ownerId: string,
  executerId: string,
  createFields: SoldInfo.CreateFields,
  encryption: Encryption
) {
  const soldInfo = SoldInfo.fromCreate(createFields, ownerId);
  SoldInfo.validateEncryptedPart(soldInfo);

  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  await getAction(transaction, actionCollectionRef, soldInfo.id).then(
    checkDuplicated
  );
  return SellCommand.markAsSold(
    executerId,
    await SoldInfo.encrypt(soldInfo, encryption)
  );
}
export async function buildUpdateSoldInfoCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  updateFields: SoldInfo.Update,
  encryption: Encryption
): Promise<{
  command: SellCommand.UpdateSoldInfo;
  soldInfo: SoldInfo.Encrypted;
}> {
  SoldInfo.validateEncryptedPart(updateFields);
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const undecryptedSoldInfo = await getAction<SoldInfo.Encrypted>(
    transaction,
    actionCollectionRef,
    updateFields.id
  ).then(checkAndGetData);
  const result = Action.assureVersion(undecryptedSoldInfo);
  if (result === TypeResult.DataOutDated) Action.handleOutDated();
  if (undecryptedSoldInfo.actionType !== ActionType.MarkAsSold)
    throw new Error(
      `Action ${undecryptedSoldInfo.id} is not ${ActionType.MarkAsSold}`
    );

  const currentSoldInfo = await SoldInfo.decryptAndConvertDate(
    undecryptedSoldInfo,
    encryption
  );
  const updates = SoldInfo.intoUpdate(currentSoldInfo, updateFields);
  const encryptedFieldsUpdated = SoldInfo.encryptedKeysArray.reduce(
    (result, key) => result || Object.keys(updates).includes(key),
    false
  );

  const encryptedUpdate: UpdateObject<SoldInfo.Encrypted> =
    SoldInfo.removeEncryptedFields(updates);

  if (encryptedFieldsUpdated) {
    const encryptionPart: SoldInfo.EncryptedPart = {};
    if (updates.notes !== null) {
      encryptionPart.notes = updates.notes || currentSoldInfo.notes;
    }
    if (updates.file !== null) {
      encryptionPart.file = updates.file || currentSoldInfo.file;
    }
    if (updates.buyer !== null) {
      encryptionPart.buyer = updates.buyer || currentSoldInfo.buyer;
    }
    const newEncrypted = await SoldInfo.encryptPartial(
      encryptionPart,
      encryption
    );
    encryptedUpdate[EncryptionFieldKey] = newEncrypted[EncryptionFieldKey];
  }
  return {
    command: SellCommand.updateSoldInfo(
      executerId,
      updateFields.id,
      encryptedUpdate
    ),
    soldInfo: undecryptedSoldInfo,
  };
}
export async function buildDeleteSoldInfoCommand(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  soldInfoId: string
): Promise<{
  command: SellCommand.DeleteSoldInfo;
  soldInfo: SoldInfo.Encrypted;
}> {
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const soldInfo = await getAction(
    transaction,
    actionCollectionRef,
    soldInfoId
  ).then(checkAndGetData);
  if (soldInfo.actionType !== ActionType.MarkAsSold)
    throw new Error(`Action ${soldInfo.id} is not ${ActionType.MarkAsSold}`);

  return {
    command: SellCommand.deleteSoldInfo(executerId, soldInfoId),
    soldInfo,
  };
}
//Actions
export async function buildAddActionCommand<T extends ActionTypeBundleExtend>(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  ownerId: string,
  executerId: string,
  createFields: T["Create"],
  encryption: Encryption,
  fromCreate: (createFields: T["Create"], ownerId: string) => T["Original"],
  validateEncryptedPart: (data: T["EncryptedPart"]) => void,
  encrypt: (
    rawData: T["Original"],
    encryption: Encryption
  ) => Promise<T["Encrypted"]>
): Promise<ActionCommand.AddAction<T["Encrypted"]>> {
  const action = fromCreate(createFields, ownerId);
  validateEncryptedPart(action);

  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  await getAction(transaction, actionCollectionRef, action.id).then(
    checkDuplicated
  );
  return ActionCommand.addAction(executerId, await encrypt(action, encryption));
}
export async function buildUpdateActionCommand<
  T extends ActionTypeBundleExtend
>(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  updateFields: T["Update"],
  encryption: Encryption,
  actionType: T["Type"],
  encryptedKeysArray: readonly (keyof T["EncryptedPart"])[],
  validateEncryptedPart: (data: T["EncryptedPart"]) => void,
  encryptPartial: <U extends T["EncryptedPart"]>(
    rawData: U,
    encryption: Encryption
  ) => Promise<EncryptionField>,
  decrypt: (
    input: T["Encrypted"],
    encryption: Encryption
  ) => Promise<T["Original"]>,
  intoUpdate: (
    current: T["Original"],
    update: T["Update"]
  ) => UpdateObject<T["Update"]>
): Promise<{
  command: ActionCommand.UpdateAction<T["Update"]>;
  action: T["Encrypted"];
}> {
  validateEncryptedPart(updateFields);
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const undecryptedAction = await getAction<T["Encrypted"]>(
    transaction,
    actionCollectionRef,
    updateFields.id
  ).then(checkAndGetData);
  if (undecryptedAction.actionType !== actionType)
    throw new Error(`Action ${undecryptedAction.id} is not ${actionType}`);

  const currentAction = await decrypt(undecryptedAction, encryption);
  const updates = intoUpdate(currentAction, updateFields);
  const encryptedFieldsUpdated = encryptedKeysArray.reduce(
    (result, key) => result || Object.keys(updates).includes(<string>key),
    false
  );

  const encryptedUpdate = doRemoveEncryptedFields(
    updates,
    encryptedKeysArray
  ) as UpdateObject<T["Encrypted"]>;

  if (encryptedFieldsUpdated) {
    const encryptionPart: T["EncryptedPart"] = {};
    encryptedKeysArray.forEach((key) => {
      if (updates[key] !== null) {
        encryptionPart[key] = updates[key];
      }
    });
    const newEncrypted = await encryptPartial(encryptionPart, encryption);
    (<any>encryptedUpdate)[EncryptionFieldKey] =
      newEncrypted[EncryptionFieldKey];
  }
  if (currentAction.actionType === ActionType.AddConsignment) {
    // #HACK Previously, backend assume consignee is a name should be encrypted, but frontend pass a contactId. Therefore, this field does not need to be encrypted now.
    // For already encrypted data, we need to assign back decrypted consignee, so we can pass it to activity log.
    (<Consignment.Encrypted>undecryptedAction).consignee = (<Consignment>(
      currentAction
    )).consignee;
  }
  return {
    command: ActionCommand.UpdateAction(
      executerId,
      updateFields.id,
      encryptedUpdate
    ),
    action: undecryptedAction,
  };
}
export async function buildDeleteActionCommand<
  T extends ActionTypeBundleExtend
>(
  assetDocRef: DocumentReference,
  transaction: Transaction,
  executerId: string,
  actionId: string,
  actionType: T["Type"],
  encryption: Encryption,
  decrypt: (
    input: T["Encrypted"],
    encryption: Encryption
  ) => Promise<T["Original"]>
): Promise<{ command: ActionCommand.DeleteAction; action: T["Encrypted"] }> {
  const actionCollectionRef = actionRefFromAssetDocRef(assetDocRef);
  const undecryptedAction = await getAction(
    transaction,
    actionCollectionRef,
    actionId
  ).then(checkAndGetData);
  if (undecryptedAction.actionType !== actionType)
    throw new Error(`Action ${undecryptedAction.id} is not ${actionType}`);
  if (undecryptedAction.actionType === ActionType.AddConsignment) {
    // #HACK Previously, backend assume consignee is a name should be encrypted, but frontend pass a contactId. Therefore, this field does not need to be encrypted now.
    // For old data that already encrypt this field, we need to assign back decrypted consignee, so we can pass it to activity log.
    const currentAction = await decrypt(undecryptedAction, encryption);
    (<Consignment.Encrypted>undecryptedAction).consignee = (<Consignment>(
      currentAction
    )).consignee;
  }
  return {
    command: ActionCommand.deleteAction(executerId, actionId),
    action: undecryptedAction,
  };
}
