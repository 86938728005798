import { useEffect } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Account } from 'core/remodel/types/cashAndBanking'
import type { Cash } from 'core/remodel/types/cashAndBanking/cash'
import { AssetType, AttachmentKind, Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { useAuthStore } from '@/store/authStore'
import { Button, FormAutocomplete, FormInput, FormPriceInput, FormTextarea } from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, StarIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type CashValues = Cash

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<CashValues> => ({
  subtype: Account.Type.Cash,
  assetType: AssetType.CashAndBanking,
  // primary details
  name: '',
  value: { currency, value: 0 },
  groupIds: [],
  notes: '',
  // attachments
  attachments: [],
  mainImage: undefined
})

interface CashFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string
  values?: CashValues
  onSubmit: SubmitHandler<CashValues>
  onCancel: () => void
}

export function CashForm({ mode = 'Create', assetId, values, onCancel, onSubmit }: CashFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset
  } = useForm<CashValues>({ defaultValues })
  // preferences and options
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[600px] grow flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput control={control} name={'name'} label={t('Field.Name')} rules={{ required: true }} />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormPriceInput
                control={control}
                name={{ currency: 'value.currency', value: 'value.value' }}
                label={t('finances:Field.CurrentCashBalance')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
              />
            </div>
            <div className={'col-span-2'}>
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Field.Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            <AttachmentPanel
              assetId={assetId}
              control={control}
              name={{ mainImage: 'mainImage', attachments: 'attachments' }}
              widgetOptions={[AttachmentKind.PrimaryDetails]}
            />
          </TabPanel.Section>
        </div>

        <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
          <Button
            id={'finances_add_cash_and_banking_cancel'}
            className={'min-w-[130px]'}
            variant={'outline'}
            size={'md'}
            onClick={onCancel}
          >
            {t('Cancel')}
          </Button>
          <Button
            id={'finances_add_cash_and_banking_create'}
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function CashFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
