import { useEffect, useState, type ReactNode } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import LoadingBar from 'react-top-loading-bar'

import { cn } from '@/utils/classnames'
import { useIsAppMode } from '@/hooks/useIsAppMode'
import NavBar from '@/components/NavBar'
import SideBar from '@/components/SideBar'

interface LayoutProps {
  children: ReactNode
}

const checkList = ['auth', 'identity', 'setup', 'plaid-proxy']

export default function Layout({ children }: LayoutProps) {
  const router = useRouter()
  const hasBackground = checkList.some((p) => router.asPath.includes(p))
  const isAppMode = useIsAppMode()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    const routeChangeStart = () => setProgress(40)
    const routeChangeComplete = () => setProgress(100)

    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
    }
  }, [router.events])

  return hasBackground ? (
    <main className={'relative min-h-screen bg-background'}>
      {/* My Assets Logo */}
      <Image
        src={'/images/myassets-logo.svg'}
        alt={'My Assets Logo'}
        className={'absolute left-10 top-10 w-auto'}
        width={0}
        height={0}
        priority={true}
      />
      <div className={'absolute left-60 top-10'}>
        {/* Beta */}
        <div className={'flex h-[23px] w-[63px] items-center justify-center rounded-[20px] bg-white px-4 '}>
          <span className={'text-xs font-medium text-[#20253B]'}>{'BETA'}</span>
        </div>
      </div>

      {/* Circuit */}
      <Image
        src={'/images/auth/circuit.svg'}
        alt={'Ornament'}
        className={'absolute right-0 top-1/2 w-auto -translate-y-1/2'}
        width={0}
        height={0}
        priority={true}
      />

      {children}
    </main>
  ) : (
    <>
      <LoadingBar
        color={'#4AA0A8'}
        progress={progress}
        waitingTime={2000} // after bar reaches 100%, waiting for 2 seconds
        height={4}
        onLoaderFinished={() => setProgress(0)}
      />
      <NavBar className={cn(isAppMode && 'hidden')} />
      <SideBar className={cn(isAppMode && 'hidden')} />
      <div className={cn(!isAppMode && 'ml-[60px] mt-[60px] pr-[calc(100%-100vw+12px)]')}>{children}</div>
    </>
  )
}
