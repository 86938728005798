import Link from 'next/link'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'
import { Button, FormInput } from '@/components/base'

export type ForgotValues = {
  email: string
}

const defaultValues: ForgotValues = {
  email: ''
}

interface ForgotFormProps {
  onSubmit: SubmitHandler<ForgotValues>
}

export function ForgotForm({ onSubmit }: ForgotFormProps) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm<ForgotValues>({ defaultValues })

  return (
    <form className={'w-full max-w-[440px] rounded border border-grey/50 p-[50px]'} onSubmit={handleSubmit(onSubmit)}>
      {/* Title */}
      <h1 className={'my-5 text-[30px] font-bold leading-[35px] text-white'}>{t('auth:ForgotPassword')}</h1>

      <span className={'mb-5 inline-block text-sm font-medium text-white'}>{t('auth:HelpReset')}</span>

      {/* Email */}
      <FormInput
        className={'mb-5'}
        control={control}
        name={'email'}
        type={'email'}
        placeholder={t('auth:Field.Email')}
        rules={{ required: t('validation:Required') }}
      />

      <Button
        className={'group mb-4 w-full'}
        variant={'solid'}
        size={'md'}
        type={'submit'}
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
        <span className={cn({ 'opacity-0': isSubmitting })}>{t('auth:SendRecoveryLink')}</span>
      </Button>

      <Link className={'block text-center text-sm text-primary hover:text-secondary-hover'} href={'/auth/login'}>
        {t('auth:BackToLogin')}
      </Link>
    </form>
  )
}
