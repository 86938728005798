import googleAnalytics from '@analytics/google-analytics'
import perfumePlugin from '@analytics/perfumejs'
import Analytics, { type AnalyticsInstance } from 'analytics'
import { initPerfume } from 'perfume.js'

import { firebaseConfig } from '@/utils/firebase'

class Perfume {
  constructor(args: any) {
    initPerfume(args)
  }
}

export let analytics: AnalyticsInstance

export function initializeAnalytics() {
  if (typeof window !== 'undefined') {
    const plugins = [
      // Attach Google analytics
      googleAnalytics({ measurementIds: [firebaseConfig.measurementId] }),
      // Include perfume.js plugin to track page render performance
      perfumePlugin({ perfume: Perfume })
    ]

    analytics = Analytics({
      app: firebaseConfig.projectId,
      debug: process.env.NODE_ENV === 'test',
      plugins
    })
  }
}
