import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzVweCIgaGVpZ2h0PSIzNXB4IiB2aWV3Qm94PSIwIDAgMzUgMzUiIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGRlc2M+Q3JlYXRlZCB3aXRoIEx1bmFjeTwvZGVzYz4KICA8ZGVmcz4KICAgIDxwYXRoIGQ9Ik04IDBMNiAwTDYgNEw4IDRMOCAwWk0wIDVMMTkgNUwxOSAyOEwwIDI4TDAgNVpNMiAyNUwyIDI2TDE4IDI2TDE4IDdMMiA3TDIgOEwzIDhMMyAyNUwyIDI1Wk0xNyA4TDQgOEw0IDI1TDE3IDI1TDE3IDhaTTcgMTcuOTA3Mkw5LjMyMjQ3IDE3LjkwNzJMOC4yNTU0NSAyMkwxMyAxNi40MjAxTDEwLjY1NzUgMTYuNDIwMUwxMi4yNzcgMTJMNyAxNy45MDcyWk0xNSAyOUwxNyAyOUwxNyAzM0wxNSAzM0wxNSAyOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDggMSkiIGlkPSJwYXRoXzEiIC8+CiAgICA8cmVjdCB3aWR0aD0iMzUiIGhlaWdodD0iMzUiIGlkPSJhcnRib2FyZF8xIiAvPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwXzEiPgogICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhcnRib2FyZF8xIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIC8+CiAgICA8L2NsaXBQYXRoPgogICAgPGNsaXBQYXRoIGlkPSJtYXNrXzEiPgogICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoXzEiIC8+CiAgICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KICA8ZyBpZD0iaWNvbi9yb29tL2VsZWN0cmljYWxfcGxhbnRfcm9vbSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXBfMSkiPgogICAgPHBhdGggZD0iTTggMEw2IDBMNiA0TDggNEw4IDBaTTAgNUwxOSA1TDE5IDI4TDAgMjhMMCA1Wk0yIDI1TDIgMjZMMTggMjZMMTggN0wyIDdMMiA4TDMgOEwzIDI1TDIgMjVaTTE3IDhMNCA4TDQgMjVMMTcgMjVMMTcgOFpNNyAxNy45MDcyTDkuMzIyNDcgMTcuOTA3Mkw4LjI1NTQ1IDIyTDEzIDE2LjQyMDFMMTAuNjU3NSAxNi40MjAxTDEyLjI3NyAxMkw3IDE3LjkwNzJaTTE1IDI5TDE3IDI5TDE3IDMzTDE1IDMzTDE1IDI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOCAxKSIgaWQ9Imljb24iIGZpbGw9IiM0QUEwQTgiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSJub25lIiAvPgogIDwvZz4KPC9zdmc+)
 */
export default function ElectricalRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 35 35'}
      version={'1.1'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <defs>
        <path
          d={
            'M8 0L6 0L6 4L8 4L8 0ZM0 5L19 5L19 28L0 28L0 5ZM2 25L2 26L18 26L18 7L2 7L2 8L3 8L3 25L2 25ZM17 8L4 8L4 25L17 25L17 8ZM7 17.9072L9.32247 17.9072L8.25545 22L13 16.4201L10.6575 16.4201L12.277 12L7 17.9072ZM15 29L17 29L17 33L15 33L15 29Z'
          }
          transform={'translate(8 1)'}
          id={'path_1'}
        />
        <rect width={'35'} height={'35'} id={'artboard_1'} />
        <clipPath id={'clip_1'}>
          <use xlinkHref={'#artboard_1'} clipRule={'evenodd'} />
        </clipPath>
        <clipPath id={'mask_1'}>
          <use xlinkHref={'#path_1'} />
        </clipPath>
      </defs>
      <g id={'icon/room/electrical_plant_room'} clipPath={'url(#clip_1)'}>
        <path
          d={
            'M8 0L6 0L6 4L8 4L8 0ZM0 5L19 5L19 28L0 28L0 5ZM2 25L2 26L18 26L18 7L2 7L2 8L3 8L3 25L2 25ZM17 8L4 8L4 25L17 25L17 8ZM7 17.9072L9.32247 17.9072L8.25545 22L13 16.4201L10.6575 16.4201L12.277 12L7 17.9072ZM15 29L17 29L17 33L15 33L15 29Z'
          }
          transform={'translate(8 1)'}
          id={'icon'}
          fill={'#0F889B'}
          fillRule={'evenodd'}
          stroke={'none'}
        />
      </g>
    </svg>
  )
}
