import {
  Amount,
  IAggregateData,
  LocationType,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { AggregateBase, AggregateRoot } from "./aggregate";
import { ArtType, Event as ArtEvent, Art, ArtStyle } from "./arts";
import { EventEnvelope } from "./event";
import Decimal from "decimal.js";
import {
  AllowedDecimalPlaces,
  addDecimal,
  calculatePercentage,
  isPurchasedLM,
  OmitKeys,
} from "../utils";
import { AssetType } from "./enums";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  ArtSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";

export type Event = EventEnvelope<ArtEvent>;
export interface ArtSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.ArtSummary, 2>;
  id: AssetType.Art;
  items: {
    [id: string]: {
      subtype: ArtType;
      artist: string;
      artStyle: ArtStyle | "-";
      number: number;
      value: Amount;
      liability: Amount;
      ownedPercentage: number;
      locationId: string;
      locationType: LocationType;
      purchasePrice: Amount;
      purchaseDate: Date;
      mainImage?: string;
      sold: boolean;
      updateAt: Date;
    };
  };
}
export interface SubtypeItem {
  label: string;
  assetNumber: number;
  itemNumber: number;
  value: Amount;
  liability: Amount;
  percentage: number;
  mainImage?: { assetId: string; imageId: string };
}
export interface BreakdownItem<T> {
  label: ArtStyle | "-";
  data: T;
  percentage: number;
}

export namespace ArtSummary {
  export function assureVersion(
    input: ArtSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      ArtSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    purchasedLM: number;
    artistNumber: number;
    typesNumber: number;
    totalNumber: number;
    subtypeItems: SubtypeItem[];
    //breakdown by style
    itemBreakdown: BreakdownItem<number>[];
    valueBreakdown: BreakdownItem<Amount>[];
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<ArtSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    convertDate(summary);
    return new AggregateRoot(new ArtSummaryAggregate(summary));
  }

  export function defaultValue(): ArtSummary {
    return {
      "@type": ArtSummaryTypeVersion,
      id: AssetType.Art,
      items: {},
      version: 0,
    };
  }

  export function convertDate(input: ArtSummary) {
    Object.values(input.items).forEach((item) => {
      item.purchaseDate = (item.purchaseDate as any).toDate();
    });
  }

  export function toDisplay(
    input: ArtSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let totalNumber = 0;
    let purchasedLM = 0;
    let totalAssets = new Decimal(0);
    let totalLiabilities = new Decimal(0);
    const subtypesMap: { [key: string]: SubtypeItem } = {};
    const styleMap: {
      [label in ArtStyle | "-"]?: {
        item: number;
        value: Decimal;
      };
    } = {};
    const artistSet = new Set<string>();
    const subtypeMainImageMap: {
      [key: string]: { assetId: string; imageId: string; value: Amount };
    } = {};

    Object.entries(input.items).forEach(([id, item]) => {
      const value = item.sold
        ? new Decimal(0)
        : new Decimal(item.value.value)
            .mul(item.ownedPercentage)
            .div(100)
            .mul(exchangeRate.rates[item.value.currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces);
      const liability = new Decimal(item.liability.value)
        .mul(exchangeRate.rates[item.liability.currency].rate)
        .toDecimalPlaces(AllowedDecimalPlaces);
      totalAssets = totalAssets.add(value);
      totalLiabilities = totalLiabilities.add(liability);
      if (isPurchasedLM(item.purchaseDate)) purchasedLM += item.number;
      totalNumber += item.number;
      artistSet.add(item.artist);

      const subtypeItem: SubtypeItem = {
        label: item.subtype,
        assetNumber: 1,
        itemNumber: item.number,
        liability: {
          currency,
          value: liability.toNumber(),
        },
        value: { currency, value: value.toNumber() },
        percentage: 0,
      };
      if (subtypesMap[item.subtype]) {
        subtypesMap[item.subtype].assetNumber += 1;
        subtypesMap[item.subtype].itemNumber += item.number;
        subtypesMap[item.subtype].value.value = addDecimal(
          subtypesMap[item.subtype].value.value,
          value
        );
      } else {
        subtypesMap[item.subtype] = subtypeItem;
      }
      if (!item.sold && item.mainImage) {
        const currentMainImage = {
          assetId: id,
          imageId: item.mainImage,
          value: {
            currency,
            value: value.toNumber(),
          },
        };
        if (subtypeMainImageMap[item.subtype]) {
          if (
            subtypeMainImageMap[item.subtype].value.value <
            currentMainImage.value.value
          )
            subtypeMainImageMap[item.subtype] = currentMainImage;
        } else subtypeMainImageMap[item.subtype] = currentMainImage;
      }

      const styleInMap = styleMap[item.artStyle];
      if (styleInMap) {
        styleInMap.item += item.number;
        styleInMap.value = styleInMap.value.add(value);
      } else {
        styleMap[item.artStyle] = {
          item: item.number,
          value,
        };
      }
    });
    Object.entries(subtypeMainImageMap).forEach(([subtype, data]) => {
      if (!subtypesMap[subtype]) throw new Error("subtype not found");
      subtypesMap[subtype].mainImage = {
        assetId: data.assetId,
        imageId: data.imageId,
      };
    });

    const result: OmitKeys<
      Display,
      "typesNumber" | "subtypeItems" | "itemBreakdown" | "valueBreakdown"
    > = {
      assets: {
        currency,
        value: totalAssets.toNumber(),
      },
      liabilities: {
        currency,
        value: totalLiabilities.toNumber(),
      },
      netValue: {
        currency,
        value: totalAssets.sub(totalLiabilities).toNumber(),
      },
      purchasedLM,
      artistNumber: artistSet.size,
      totalNumber,
    };
    const subtypeItems: SubtypeItem[] = Object.values(subtypesMap).map(
      (item) => {
        item.percentage = calculatePercentage(
          item.value.value,
          result.assets.value
        );
        return item;
      }
    );
    subtypeItems.sort((a, b) => b.value.value - a.value.value);
    const itemBreakdown: BreakdownItem<number>[] = Object.entries(styleMap).map(
      ([label, data]) => {
        return {
          label: <any>label,
          data: data.item,
          percentage: calculatePercentage(
            data.value.toNumber(),
            result.assets.value
          ),
        };
      }
    );
    itemBreakdown.sort((a, b) => b.data - a.data);
    const valueBreakdown: BreakdownItem<Amount>[] = Object.entries(styleMap)
      .map(([label, data]) => {
        return {
          label: <any>label,
          data: { currency, value: data.value.toNumber() },
          percentage: calculatePercentage(
            data.value.toNumber(),
            result.assets.value
          ),
        };
      })
      .filter((item) => item.data.value > 0);
    valueBreakdown.sort((a, b) => b.data.value - a.data.value);
    return {
      ...result,
      typesNumber: subtypeItems.length,
      subtypeItems,
      itemBreakdown,
      valueBreakdown,
    };
  }

  export type RelatedUpdates = never;
}

const RelatedObjKeys: (keyof Art.Encrypted & string)[] = [
  "subtype",
  "artist",
  "value",
  "location",
  "purchasePrice",
  "purchaseDate",
  "number",
  // "ownership", //#NOTE update this manually
];
export class ArtSummaryAggregate extends AggregateBase<
  ArtSummary,
  never,
  ArtEvent
> {
  state: ArtSummary;
  kind: string;
  declare relatedUpdates: never;

  constructor(state: ArtSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    switch (event.data.kind) {
      case ArtEvent.Kind.AssetCreated: {
        const asset = event.data.asset;
        this.state.items[event.aggregateId] = {
          subtype: asset.subtype,
          artist: asset.artist,
          value: asset.value,
          artStyle: asset.artStyle || "-",
          number: asset.number,
          liability: Amount.defaultValue(),
          ownedPercentage: asset.ownership?.myOwnership || 100,
          locationId: asset.location.locationId,
          locationType: asset.location.locationType,
          purchasePrice: asset.purchasePrice,
          purchaseDate: asset.purchaseDate,
          sold: false,
          updateAt: event.time,
        };
        if (asset.mainImage)
          this.state.items[event.aggregateId].mainImage = asset.mainImage;
        break;
      }
      case ArtEvent.Kind.AssetUpdated: {
        const updates = event.data.asset;
        Object.keys(updates)
          .filter((key) => RelatedObjKeys.includes(<any>key))
          .forEach((key) => {
            const value = updates[key as keyof typeof updates];
            const typedKey: keyof (typeof this.state.items)[string] =
              key as any;
            if (value === null) {
              delete this.state.items[event.aggregateId][typedKey];
            } else if (value !== undefined) {
              (<any>this.state.items[event.aggregateId])[typedKey] = value;
            }
          });
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      }
      case ArtEvent.Kind.AssetDeleted:
        delete this.state.items[event.aggregateId];
        break;
      case ArtEvent.Kind.ShareholderUpdated:
        this.state.items[event.aggregateId].ownedPercentage =
          event.data.current?.myOwnership || 100;
        break;
      case ArtEvent.Kind.LocationUpdated:
        this.state.items[event.aggregateId].locationId =
          event.data.current.locationId;
        this.state.items[event.aggregateId].locationType =
          event.data.current.locationType;
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      case ArtEvent.Kind.ValueUpdated:
        this.state.items[event.aggregateId].value = event.data.current;
        this.state.items[event.aggregateId].updateAt = event.time;
        break;
      case ArtEvent.Kind.MainImageSet:
        if (event.data.current)
          this.state.items[event.aggregateId].mainImage = event.data.current;
        else delete this.state.items[event.aggregateId].mainImage;
        break;
      case ArtEvent.Kind.SoldInfoAdded:
        this.state.items[event.aggregateId].sold = true;
        break;
      case ArtEvent.Kind.SoldInfoDeleted:
        this.state.items[event.aggregateId].sold = false;
        break;
    }
    return this;
  }
}
