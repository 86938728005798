import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { ConsignmentType, consignmentTypeValues, type Consignment } from 'core/remodel/types/actions/consignment'
import { Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormCreatableAutocomplete,
  FormDateRangePicker,
  FormInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  SelectItem
} from '@/components/base'
import UploadFiles from '@/components/UploadFiles'

export type ConsignmentValues = Consignment.CreateFields

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<ConsignmentValues> => ({
  consignor: '',
  consignee: '',
  type: ConsignmentType.Incoming,
  price: { currency, value: 0 },
  startDate: new Date(),
  endDate: new Date(),
  file: [],
  notes: ''
})

interface ConsignmentFormProps {
  assetId: string | null
  mode?: 'Create' | 'Edit'
  values?: ConsignmentValues
  onSubmit: SubmitHandler<ConsignmentValues>
  onCancel: () => void
}

export function ConsignmentForm({ assetId, mode = 'Create', values, onSubmit, onCancel }: ConsignmentFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm<ConsignmentValues>({
    values,
    defaultValues
  })
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const consignmentTypeOptions = makeOptions(consignmentTypeValues, (key) => `collectables:ConsignmentType.${key}`)

  return (
    <form className={'flex h-[95vh] flex-col gap-y-4 bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-x-4 overflow-y-auto px-4 pt-4 md:flex-row'}>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormInput
            control={control}
            name={'consignor'}
            label={t('collectables:Field.Consignor')}
            rules={{ required: true }}
          />
          <FormCreatableAutocomplete
            control={control}
            name={'consignee'}
            label={t('collectables:Field.Consignee')}
            options={contactOptions}
            onCreate={async (name) => await addContact(database!, { firstName: name })}
            placeholder={t('SearchOrCreate')}
            isLoading={loadingContacts}
            rules={{ required: true }}
          />
          <FormSelect control={control} name={'type'} label={t('Field.Type')} rules={{ required: true }}>
            {consignmentTypeOptions.map(({ label, value }) => (
              <SelectItem key={value} value={value}>
                {label}
              </SelectItem>
            ))}
          </FormSelect>
          <FormPriceInput
            control={control}
            name={{ currency: 'price.currency', value: 'price.value' }}
            label={t('Field.Price')}
            rules={{
              value: {
                required: true,
                validate: { greaterThanZero: (value) => value > 0 }
              }
            }}
            format={preferences.numberFormat}
            digits={2}
          />
        </div>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormDateRangePicker
            control={control}
            name={{
              startDate: 'startDate',
              endDate: 'endDate'
            }}
            label={t('Field.StartToEndDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
          {/* TODO artist, gallery */}
          <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
          <UploadFiles assetId={assetId} control={control} name={'file'} />
        </div>
      </div>

      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
          disabled={!isValid}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
