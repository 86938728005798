import { useCallback, useEffect, useMemo } from 'react'
import add from 'date-fns/add'
import { Loader2Icon } from 'lucide-react'
import { useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import banks from 'core/remodel/types/banks.json'
import { Account } from 'core/remodel/types/cashAndBanking'
import type { Loan } from 'core/remodel/types/cashAndBanking/loan'
import { SupportLiabilityType } from 'core/remodel/types/cashAndBankingSummary'
import { AssetType, AttachmentKind, Currency, CustomizedType, Period, periodValues } from 'core/remodel/types/common'
import { allocationTypesOptions, CountryOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import {
  addCustomType,
  commonQuery,
  fetchCustomTypes,
  fetchSupportLiabilityAssets,
  typeQuery
} from '@/api/CommonService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatDate, formatNumber, makeOptions, sortAlphabetically } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type LoanValues = Loan

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<LoanValues> => ({
  subtype: Account.Type.LoanAccount,
  assetType: AssetType.CashAndBanking,
  // primary details
  name: '',
  value: { currency, value: 0 },
  initialAmount: { currency, value: 0 },
  country: '',
  institution: '',
  startDate: new Date(),
  notes: '',
  // additional information
  term: { period: Period.Month, num: 0 },
  interest: { period: Period.Month, num: 0 },
  commission: { currency, value: 0 },
  accountNumber: '',
  SWIFT_BIC: '',
  sortCode: '',
  groupIds: [],
  // allocation
  allocations: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

interface LoanFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string
  values?: LoanValues
  onSubmit: SubmitHandler<LoanValues>
  onCancel: () => void
}

export function LoanForm({ mode = 'Create', assetId, values, onSubmit, onCancel }: LoanFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<LoanValues>({ defaultValues })
  const country = watch('country')
  const value = watch('value.value')
  const currency = watch('value.currency')
  const interestPeriod = watch('interest.period')
  const interestNum = watch('interest.num')
  const termPeriod = watch('term.period')
  const termNum = watch('term.num')
  const startDate = watch('startDate')
  const allocations = watch('allocations')
  const allocationArray = useFieldArray({
    control,
    name: 'allocations',
    rules: {
      validate: {
        percent: (allocation) => allocation.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (allocation) => allocation.every((allocation) => allocation.assetId !== '')
      }
    }
  })
  const totalPercent = allocations.reduce((acc, curr) => acc + curr.percent, 0)
  const remainingPercent = (100 - totalPercent).toFixed(0)
  const dueDate = useMemo(() => {
    if (isNaN(termNum)) return
    switch (termPeriod) {
      case Period.Day:
        return add(startDate, { days: termNum })
      case Period.Week:
        return add(startDate, { weeks: termNum })
      case Period.Month:
        return add(startDate, { months: termNum })
      case Period.Quarter:
        return add(startDate, { months: termNum * 3 })
      case Period.Year:
        return add(startDate, { years: termNum })
    }
  }, [termNum, termPeriod, startDate])
  const annual = useMemo(() => {
    if (isNaN(interestNum)) return

    let result: number
    switch (interestPeriod) {
      case Period.Day:
        result = 365 * interestNum
        break
      case Period.Week:
        result = 52 * interestNum
        break
      case Period.Month:
        result = 12 * interestNum
        break
      case Period.Quarter:
        result = 4 * interestNum
        break
      case Period.Year:
        result = 1 * interestNum
        break
      default:
        return
    }

    return Number(result.toFixed(3))
  }, [interestNum, interestPeriod])
  // preferences and options
  const institutionSWR = useSWR([typeQuery.institution, CustomizedType.BankOrInstitution], fetchCustomTypes(database!))
  const { data: institutions, isValidating: loadingInstitutions } = institutionSWR
  const institutionOptions = useMemo(() => {
    const base = banks
      .filter((bank) => bank.country === country)
      .map((bank) => ({ label: bank.name, value: bank.name }))
    const added = institutions?.map((item) => ({ label: item, value: item })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [country, institutions])
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const assetSWR = useSWR([commonQuery.liabilityAssetList], fetchSupportLiabilityAssets(database!))
  const { data: allAssets, isLoading: loadingAssets } = assetSWR
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'information', label: t('Attributes') },
        { key: 'allocation', label: t('finances:Allocation') }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  useEffect(() => {
    setValue('initialAmount.currency', currency)
  }, [currency, setValue])

  const getAssetOptions = useCallback(
    (assetType: SupportLiabilityType, currentAllocationId: string) => {
      if (!allAssets) return []

      const allocatedAssetIds = new Set(
        allocations
          .filter((allocation) => allocation.assetId !== currentAllocationId)
          .map((allocation) => allocation.assetId)
      )

      const availableAssetsOptions = allAssets
        .filter((asset) => asset.assetType === assetType && !allocatedAssetIds.has(asset.id))
        .map((asset) => ({
          label: asset.name,
          value: asset.id
        }))
      return sortAlphabetically({ data: availableAssetsOptions, selector: (d) => d.label, locale: 'en' })
    },
    [allAssets, allocations]
  )

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[600px] grow flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput
                control={control}
                name={'name'}
                label={t('finances:Field.AccountName')}
                rules={{ required: true }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'value.currency', value: 'value.value' }}
                label={t('finances:Field.OutstandingAmount')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'initialAmount.currency', value: 'initialAmount.value' }}
                label={t('finances:Field.InitialAmount')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: true, value: mode === 'Edit' }}
              />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormAutocomplete
                control={control}
                name={'country'}
                label={t('Field.Country')}
                options={CountryOptions}
                placeholder={t('Placeholder.Select')}
              />
              <FormCreatableAutocomplete
                control={control}
                name={'institution'}
                label={t('finances:Field.BankInstitution')}
                options={institutionOptions}
                onCreate={async (name) => await addCustomType(database!, CustomizedType.BankOrInstitution, name)}
                placeholder={t('SearchOrCreate')}
                isLoading={loadingInstitutions}
                rules={{ required: true }}
              />
              <FormDatePicker
                control={control}
                name={'startDate'}
                label={t('Field.StartDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
                disabled={mode === 'Edit'}
              />
            </div>
            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.information'}>
            <div className={'flex flex-col gap-4'}>
              <div className={'flex gap-x-1'}>
                <div className={'grid grid-cols-1 gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.Term')}</label>
                  <div className={'flex gap-x-1'}>
                    <FormSelect className={'shrink-0 basis-24'} control={control} name={'term.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                    <FormNumberInput control={control} name={'term.num'} format={preferences.numberFormat} />
                  </div>
                </div>
                <div className={'grid shrink-0 basis-24 grid-cols-1 gap-y-1 text-xs'}>
                  <label className={'text-[#414554]'}>{t('finances:Field.DueDate')}</label>
                  <div className={'flex h-[38px] items-center justify-center rounded bg-primary text-white'}>
                    {dueDate ? formatDate(dueDate, preferences.dateFormat) : ''}
                  </div>
                </div>
              </div>
              <div className={'flex gap-x-1'}>
                <div className={'grid grid-cols-1 gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.InterestRate')}</label>
                  <div className={'flex gap-x-1'}>
                    <FormSelect className={'shrink-0 basis-24'} control={control} name={'interest.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                    <FormPercentInput control={control} name={'interest.num'} />
                  </div>
                </div>
                <div className={'grid shrink-0 basis-20 grid-cols-1 gap-y-1 text-xs'}>
                  <label className={'text-[#414554]'}>{t('finances:Field.Annual')}</label>
                  <div className={'flex h-[38px] items-center justify-end rounded bg-primary px-2 text-white'}>
                    {`${annual}%`}
                  </div>
                </div>
              </div>
              <FormPriceInput
                control={control}
                name={{ currency: 'commission.currency', value: 'commission.value' }}
                label={t('finances:Field.Commission')}
                format={preferences.numberFormat}
                digits={2}
              />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormInput control={control} name={'accountNumber'} label={t('finances:Field.AccountNumber')} />
              <FormInput control={control} name={'SWIFT_BIC'} label={t('finances:Field.SWIFTBICNumber')} />
              <FormInput control={control} name={'sortCode'} label={t('finances:Field.SortCode')} />
            </div>
            <div className={'col-span-2'}>
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Field.Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.allocation'}>
            <div className={'col-span-2 space-y-4'}>
              <div className={'flex items-center gap-x-2 text-sm'}>
                <p>{t('finances:Allocations')}</p>
                {totalPercent < 100 && (
                  <span className={'text-yellow-500'}>{t('RemainingPercent', { percent: remainingPercent })}</span>
                )}
                {totalPercent > 100 && <span className={'text-red-500'}>{t('LimitedPercent')}</span>}
                {totalPercent === 100 && <span className={'text-green-500'}>{t('SharedOutPercent')}</span>}
              </div>
              <Button
                className={'group gap-x-2'}
                onClick={() => allocationArray.append({ assetId: '', assetType: AssetType.Art, percent: 0 })}
              >
                <div className={'rounded border border-primary p-1 group-hover:bg-primary'}>
                  <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                </div>
                <span className={'text-sm text-text group-hover:text-primary'}>{t('finances:NewAllocation')}</span>
              </Button>
              <ul className={'space-y-2'}>
                {allocationArray.fields.map((field, index) => (
                  <li key={field.id} className={'grid grid-cols-[repeat(3,minmax(0,1fr))_32px] gap-x-2'}>
                    <FormSelect
                      control={control}
                      name={`allocations.${index}.assetType`}
                      label={t('Field.AssetType')}
                      onSelected={() => setValue(`allocations.${index}.assetId`, '')}
                    >
                      {allocationTypesOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {t(`AssetTypeOptions.${label}`)}
                        </SelectItem>
                      ))}
                    </FormSelect>
                    <FormAutocomplete
                      control={control}
                      name={`allocations.${index}.assetId`}
                      label={t('Field.AssetName')}
                      options={getAssetOptions(allocations[index]?.assetType, allocations[index]?.assetId)}
                      isMulti={false}
                      placeholder={''}
                      isLoading={loadingAssets}
                      rules={{ required: true }}
                    />
                    <div className={'grid grid-cols-1 gap-y-1'}>
                      <label className={'text-xs'}>{t('finances:Allocation')}</label>
                      <div className={'flex h-[38px] overflow-hidden rounded border'}>
                        <FormPercentInput
                          className={'basis-[60px]'}
                          inputClassName={'border-none focus:ring-0'}
                          control={control}
                          name={`allocations.${index}.percent`}
                        />
                        <div className={'flex-1 bg-primary px-2 text-white'}>
                          <p className={'text-right text-xs leading-[38px]'}>{`${currency} ${formatNumber(
                            (value * allocations[index]?.percent ?? 0) / 100,
                            preferences.numberFormat,
                            { isNegativeSign: true }
                          )}`}</p>
                        </div>
                      </div>
                    </div>
                    <Button className={'mt-4'} onClick={() => allocationArray.remove(index)}>
                      <XIcon className={'text-primary'} />
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            <AttachmentPanel
              assetId={assetId}
              control={control}
              name={{ mainImage: 'mainImage', attachments: 'attachments' }}
              widgetOptions={[AttachmentKind.PrimaryDetails]}
            />
          </TabPanel.Section>
        </div>
        <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function LoanFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
