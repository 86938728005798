import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M29%201.5v5H6v-5H3v2H1v3H0v2h1v3h2v2h3v-5h1v25h2v-25h17v25h2v-25h1v5h3v-2h2v-3h1v-2h-1v-3h-2v-2h-3Zm-14%2024h5v8h2v-8h1a1%201%200%200%200%201-1v-2a1%201%200%200%200-1-1H12a1%201%200%200%200-1%201v2a1%201%200%200%200%201%201h1v8h2v-8Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FitnessRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M29 1.5v5H6v-5H3v2H1v3H0v2h1v3h2v2h3v-5h1v25h2v-25h17v25h2v-25h1v5h3v-2h2v-3h1v-2h-1v-3h-2v-2h-3Zm-14 24h5v8h2v-8h1a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H12a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h1v8h2v-8Z'
        }
      />
    </svg>
  )
}
