import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230f889b%22%20d%3D%22M13%2017h7v1.47a5.665%205.665%200%200%201%201.56%201.13%205.833%205.833%200%200%201%20.44-.4V5a1%201%200%200%201%202%200%201%201%200%200%200%202%200%203%203%200%200%200-6%200v4h-7V5a1%201%200%200%201%202%200%201%201%200%200%200%202%200%203%203%200%200%200-6%200v13.03a4.866%204.866%200%200%201%202%20.67zm7-6v4h-7v-4z%22%2F%3E%3Cpath%20fill%3D%22%230f889b%22%20d%3D%22M29%2022a1.412%201.412%200%200%201-1.127-.681%203.206%203.206%200%200%200-5.184%200%201.27%201.27%200%200%201-2.251%200%203.2%203.2%200%200%200-5.179%200%201.4%201.4%200%200%201-1.122.681%201.406%201.406%200%200%201-1.122-.68%203.313%203.313%200%200%200-2.59-1.32%203.313%203.313%200%200%200-2.589%201.319A1.4%201.4%200%200%201%206.713%2022a1.4%201.4%200%200%201-1.123-.681A3.316%203.316%200%200%200%203%2020a1%201%200%200%200-1%201v9h28v-7a1%201%200%200%200-1-1z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SwimmingPoolIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 32'} width={size} height={size} {...props}>
      <path
        fill={'#0f889b'}
        d={
          'M13 17h7v1.47a5.665 5.665 0 0 1 1.56 1.13 5.833 5.833 0 0 1 .44-.4V5a1 1 0 0 1 2 0 1 1 0 0 0 2 0 3 3 0 0 0-6 0v4h-7V5a1 1 0 0 1 2 0 1 1 0 0 0 2 0 3 3 0 0 0-6 0v13.03a4.866 4.866 0 0 1 2 .67zm7-6v4h-7v-4z'
        }
      />
      <path
        fill={'#0f889b'}
        d={
          'M29 22a1.412 1.412 0 0 1-1.127-.681 3.206 3.206 0 0 0-5.184 0 1.27 1.27 0 0 1-2.251 0 3.2 3.2 0 0 0-5.179 0 1.4 1.4 0 0 1-1.122.681 1.406 1.406 0 0 1-1.122-.68 3.313 3.313 0 0 0-2.59-1.32 3.313 3.313 0 0 0-2.589 1.319A1.4 1.4 0 0 1 6.713 22a1.4 1.4 0 0 1-1.123-.681A3.316 3.316 0 0 0 3 20a1 1 0 0 0-1 1v9h28v-7a1 1 0 0 0-1-1z'
        }
      />
    </svg>
  )
}
