import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M32.31%208.75%2017.156%200%202%208.75v17.5L17.155%2035l15.156-8.75V8.75ZM3%209.327l13.655-7.884v15.768L3%2025.095V9.327Zm17.655%209.616-3-1.732V1.443l13.656%207.884v15.768l-5.656-3.265v-9.86l-5-2.97v9.943Zm1%20.578%203%201.732v-8.714l-3-1.782v8.764Zm-8-9.521-5%202.817v5l5-2.817v-5Zm-4%203.402v2.704l3-1.69V11.71l-3%201.69ZM3.5%2025.962l13.655%207.883%2013.656-7.884-13.656-7.884L3.5%2025.961Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function OtherRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M32.31 8.75 17.156 0 2 8.75v17.5L17.155 35l15.156-8.75V8.75ZM3 9.327l13.655-7.884v15.768L3 25.095V9.327Zm17.655 9.616-3-1.732V1.443l13.656 7.884v15.768l-5.656-3.265v-9.86l-5-2.97v9.943Zm1 .578 3 1.732v-8.714l-3-1.782v8.764Zm-8-9.521-5 2.817v5l5-2.817v-5Zm-4 3.402v2.704l3-1.69V11.71l-3 1.69ZM3.5 25.962l13.655 7.883 13.656-7.884-13.656-7.884L3.5 25.961Z'
        }
      />
    </svg>
  )
}
