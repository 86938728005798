import {
  ApiClientClass,
  KnowledgeApi,
  Models,
} from "purecloud-platform-client-v2";

//#NOTE: solution from https://developer.genesys.cloud/forum/t/how-to-add-purecloud-sdk-in-nextjs-typescript-react/12703/3
const platformClient = require("purecloud-platform-client-v2/dist/node/purecloud-platform-client-v2.js");

export class GenesysClient {
  private client: ApiClientClass;
  private apiInstance: KnowledgeApi;
  private sessionId: string | undefined;
  private deploymentId: string;
  private customerId: string;

  constructor(deploymentId: string, customerId: string) {
    this.client = platformClient.ApiClient.instance;
    this.client.setEnvironment("mypurecloud.de"); // Genesys Cloud region

    this.apiInstance = new platformClient.KnowledgeApi();
    this.deploymentId = deploymentId;
    this.customerId = customerId;
  }

  async generateSession() {
    const body: Models.KnowledgeGuestSession = {
      app: {
        deploymentId: this.deploymentId,
        type: "SupportCenter",
      },
      customerId: this.customerId,
    };
    try {
      const data = await this.apiInstance.postKnowledgeGuestSessions(body);
      this.sessionId = data.id;
      return data;
    } catch (e) {
      console.log("There was a failure calling postKnowledgeGuestSessions");
      console.error(e);
    }
  }

  async topics() {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const data = await this.apiInstance.getKnowledgeGuestSessionCategories(
      this.sessionId
    );

    return data;
  }

  async searchAutocomplete(query: string, pageSize: number = 10) {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const opts: KnowledgeApi.postKnowledgeGuestSessionDocumentsSearchSuggestionsOptions =
      {
        body: {
          query: query,
          pageSize: pageSize,
          includeDraftDocuments: false,
        },
      };

    const data =
      await this.apiInstance.postKnowledgeGuestSessionDocumentsSearchSuggestions(
        this.sessionId,
        opts
      );

    return data;
  }

  async search(query: string, onSearchResult: any, pageSize: number = 10) {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const opts: KnowledgeApi.postKnowledgeGuestSessionDocumentsSearchOptions = {
      body: {
        query: query,
        pageSize: pageSize,
        includeDraftDocuments: false,
      },
    };

    const data =
      await this.apiInstance.postKnowledgeGuestSessionDocumentsSearch(
        this.sessionId,
        opts
      );
    onSearchResult(data);
    return data;
  }

  async getDocumentById(documentId: string) {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const data = await this.apiInstance.getKnowledgeGuestSessionDocument(
      this.sessionId,
      documentId
    );

    return data;
  }

  async getArticle(documentId: string) {
    const doc = await this.getDocumentById(documentId);

    if (
      !doc.variations ||
      !doc.title ||
      doc.variations.length == 0 ||
      !doc.variations[0].body
    ) {
      console.log(`Invalid document: ${JSON.stringify(doc)}`);
      throw new Error("No document variation found");
    }

    return doc;
  }

  async reviewArticle(
    documentId: string,
    positive: boolean,
    reviewText: string
  ) {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const doc = await this.getDocumentById(documentId);
    if (!doc.variations || doc.variations.length == 0) {
      console.log(`Invalid document: ${JSON.stringify(doc)}`);
      throw new Error("No document variation found");
    }

    const doc_var = doc.variations[0];
    if (
      !doc_var.id ||
      !doc_var.documentVersion ||
      !doc_var.documentVersion.id
    ) {
      console.log(`Invalide doucment variation: ${JSON.stringify(doc_var)}`);
      throw new Error("Invalid document variation");
    }

    const opts: KnowledgeApi.postKnowledgeGuestSessionDocumentFeedbackOptions =
      {
        body: {
          documentVariation: {
            id: doc_var.id,
          },
          rating: positive ? "Positive" : "Negative",
          comment: reviewText,
          document: {
            versionId: doc_var.documentVersion.id,
          },
        },
      };

    const data =
      await this.apiInstance.postKnowledgeGuestSessionDocumentFeedback(
        this.sessionId,
        documentId,
        opts
      );

    return data;
  }

  //#TODO: Check if the logic is right
  async mostCommonArticles(pageSize: number = 10) {
    if (this.sessionId === undefined) {
      throw new Error("Session id is not defined");
    }

    const opts: KnowledgeApi.getKnowledgeGuestSessionDocumentsOptions = {
      pageSize: pageSize,
    };

    const data = await this.apiInstance.getKnowledgeGuestSessionDocuments(
      this.sessionId,
      opts
    );
    return data;
  }
}
