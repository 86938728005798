import Decimal from "decimal.js";
import { CoreFirestore, getQueriedData } from "../../coreFirebase";
import { Refs } from "../refs";
import { Account } from "../types/cashAndBanking";
import { Loan } from "../types/cashAndBanking/loan";
import { Mortgage } from "../types/cashAndBanking/mortgage";
import { Amount, Currency } from "../types/common";
import { AssetType } from "../types/enums";
import { DataPoisoned } from "../types/error";
import { SummaryLocationItem } from "../types/propertySummary";
import {
  ComparativeNetWorthReport,
  NetWorthReportDetails,
  ReportAssetType,
  defaultNetWorthReportDetails,
} from "../types/reports";
import { SummaryManager } from "../types/summaryManager";
import { WinePricingMethod } from "../types/wineAndSprits";
import { AllowedDecimalPlaces, addDecimal, mulAmount } from "../utils";
import { ExchangeRate } from "./exchangeRate";
import { PriceSource } from "./priceSource";
import { HoldingType } from "../types/traditionalInvestments";

function toDecimalPlacesNumber(value: Decimal) {
  return value.toDecimalPlaces(AllowedDecimalPlaces).toNumber();
}

function computePriceAndAssets<
  T extends {
    [id: string]: {
      purchasePrice: Amount;
      value: Amount;
      ownedPercentage: number;
      sold?: boolean;
      archived?: boolean;
    };
  }
>(items: T, exRate: ExchangeRate, details: NetWorthReportDetails) {
  const result = Object.values(items).reduce(
    (acc, item) => {
      // #NOTE sold and archived items are not included
      if (item.sold || item.archived) return acc;
      const purchasePrice = new Decimal(item.purchasePrice.value)
        .mul(exRate.getToBaseExchangeRate(item.purchasePrice.currency).rate)
        .mul(item.ownedPercentage)
        .div(100)
        .add(acc.totalPurchasePrice);
      const assets = new Decimal(item.value.value)
        .mul(exRate.getToBaseExchangeRate(item.value.currency).rate)
        .mul(item.ownedPercentage)
        .div(100)
        .add(acc.totalAssets);
      return { totalPurchasePrice: purchasePrice, totalAssets: assets };
    },
    { totalPurchasePrice: new Decimal(0), totalAssets: new Decimal(0) }
  );
  details.original.purchasePrice.value = toDecimalPlacesNumber(
    result.totalPurchasePrice
  );
  details.current.assets.value = toDecimalPlacesNumber(result.totalAssets);
}

function computePercentageChange(original: number, current: number): number {
  if (original == 0 && current == 0) return 0;
  const percentageChange = new Decimal(current)
    .div(original)
    .sub(1)
    .mul(100)
    .toDecimalPlaces(AllowedDecimalPlaces)
    .toNumber();
  // if (original < 0) return -percentageChange
  return percentageChange;
}

function computeNetValueAndPercentageChange({
  original,
  current,
  percentageChange,
}: NetWorthReportDetails) {
  original.netInvestment.value = addDecimal(
    original.purchasePrice.value,
    original.liabilities.value
  );
  current.netValue.value = addDecimal(
    current.assets.value,
    current.liabilities.value
  );

  percentageChange.assets = computePercentageChange(
    original.purchasePrice.value,
    current.assets.value
  );
  percentageChange.liabilities = computePercentageChange(
    original.liabilities.value,
    current.liabilities.value
  );
  percentageChange.net = computePercentageChange(
    original.netInvestment.value,
    current.netValue.value
  );
}

export async function computeComparativeNetWorthReport(
  summaryManager: SummaryManager,
  exRate: ExchangeRate,
  refs: Refs,
  priceSource: PriceSource
): Promise<ComparativeNetWorthReport> {
  const [
    CashAndBankingSummary,
    TISummary,
    OISummary,
    artSummary,
    winesSummary,
    otherCollectablesSummary,
    propertySummary,
    belongingSummary,
  ] = await Promise.all([
    summaryManager
      .get(AssetType.CashAndBanking)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.TraditionalInvestments)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.OtherInvestment)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Art)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.WineAndSpirits)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.OtherCollectables)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Property)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Belonging)
      .syncAndGetData()
      .then((v) => v.summary),
  ]);

  const currency = exRate.BaseCurrency as Currency;
  const result: ComparativeNetWorthReport = {
    assetType: {
      [AssetType.CashAndBanking]: defaultNetWorthReportDetails(currency),
      [AssetType.TraditionalInvestments]:
        defaultNetWorthReportDetails(currency),
      [AssetType.OtherInvestment]: defaultNetWorthReportDetails(currency),
      [AssetType.Art]: defaultNetWorthReportDetails(currency),
      [AssetType.WineAndSpirits]: defaultNetWorthReportDetails(currency),
      [AssetType.OtherCollectables]: defaultNetWorthReportDetails(currency),
      [AssetType.Property]: defaultNetWorthReportDetails(currency),
      [AssetType.Belonging]: defaultNetWorthReportDetails(currency),
    },
    subCategory: {},
    total: defaultNetWorthReportDetails(currency),
  };

  // *** Loop summary to get purchasePrice and assets ***

  // cash and banking
  // #NOTE: cash and banking does not have purchase price
  // const cashAndBankingResult = Object.values(
  //   CashAndBankingSummary.accounts
  // ).reduce(
  //   (acc, account) => {
  //     // needs allocation, compute later
  //     if (
  //       account.subtype === Account.Type.LoanAccount ||
  //       account.subtype === Account.Type.MortgageAccount
  //     ) {
  //       return acc;
  //     }

  //     const value = account.subAccounts
  //       .reduce((innerAcc, subAccount) => {
  //         return new Decimal(subAccount.balance.value)
  //           .mul(exRate.getToBaseExchangeRate(subAccount.balance.currency).rate)
  //           .add(innerAcc);
  //       }, new Decimal(0))
  //       .mul(account.ownedPercentage)
  //       .div(100);

  //     return account.subtype === Account.Type.CreditCardAccount
  //       ? {
  //           creditCardAccountLiabilities: value.add(
  //             acc.creditCardAccountLiabilities
  //           ),
  //           totalAssets: acc.totalAssets,
  //         }
  //       : {
  //           creditCardAccountLiabilities: acc.creditCardAccountLiabilities,
  //           totalAssets: value.add(acc.totalAssets),
  //         };
  //   },
  //   {
  //     creditCardAccountLiabilities: new Decimal(0),
  //     totalAssets: new Decimal(0),
  //   }
  // );
  // result.assetType[AssetType.CashAndBanking].current.assets.value =
  //   toDecimalPlacesNumber(cashAndBankingResult.totalAssets);

  // traditional investments
  const stockPriceMap = await priceSource.getStockPrice(
    Object.values(TISummary.portfolio).flatMap((portfolio) => {
      return Object.values(portfolio.holdings).map((holding) => {
        return holding.holdingName;
      });
    })
  );
  const TIResult = Object.values(TISummary.portfolio).reduce(
    (acc, item) => {
      const { purchasePrice, assets } = Object.values(item.holdings).reduce(
        (innerAcc, holding) => {
          const purchasePrice = new Decimal(holding.purchasePrice.value).mul(
            exRate.getToBaseExchangeRate(holding.purchasePrice.currency).rate
          );
          const assets =
            holding.holdingType === HoldingType.Holding
              ? new Decimal(holding.unit)
                  .mul(stockPriceMap[holding.holdingName].value)
                  .mul(
                    exRate.getToBaseExchangeRate(
                      stockPriceMap[holding.holdingName].currency
                    ).rate
                  )
              : new Decimal(holding.unit).mul(
                  exRate.getToBaseExchangeRate(holding.currency!).rate
                );
          return {
            purchasePrice: purchasePrice.add(innerAcc.purchasePrice),
            assets: assets.add(innerAcc.assets),
          };
        },
        {
          purchasePrice: new Decimal(0),
          assets: new Decimal(0),
        }
      );
      return {
        totalPurchasePrice: purchasePrice
          .mul(item.ownedPercentage)
          .div(100)
          .add(acc.totalPurchasePrice),
        totalAssets: assets
          .mul(item.ownedPercentage)
          .div(100)
          .add(acc.totalAssets),
      };
    },
    {
      totalPurchasePrice: new Decimal(0),
      totalAssets: new Decimal(0),
    }
  );
  result.assetType[
    AssetType.TraditionalInvestments
  ].original.purchasePrice.value = toDecimalPlacesNumber(
    TIResult.totalPurchasePrice
  );
  result.assetType[AssetType.TraditionalInvestments].current.assets.value =
    toDecimalPlacesNumber(TIResult.totalAssets);

  // other investments
  computePriceAndAssets(
    OISummary.items,
    exRate,
    result.assetType[AssetType.OtherInvestment]
  );

  // art
  computePriceAndAssets(
    artSummary.items,
    exRate,
    result.assetType[AssetType.Art]
  );

  // wine
  const wineResult = Object.values(winesSummary.wines).reduce(
    (acc, wine) => {
      const { purchasePrice, assets } = Object.values(wine.purchases).reduce(
        (innerAcc, purchase) => {
          const price =
            purchase.pricingMethod === WinePricingMethod.Lot
              ? purchase.price
              : {
                  currency: purchase.price.currency,
                  value: mulAmount(
                    purchase.price.value,
                    purchase.bottleCount.bottles
                  ),
                };
          const value: Amount = {
            currency: purchase.valuePerBottle.currency,
            value: mulAmount(
              purchase.valuePerBottle.value,
              purchase.bottleCount.bottles
            ),
          };
          const purchasePrice = new Decimal(price.value)
            .mul(exRate.getToBaseExchangeRate(price.currency).rate)
            .mul(purchase.ownedPercentage)
            .div(100)
            .add(innerAcc.purchasePrice);
          const assets = new Decimal(value.value)
            .mul(exRate.getToBaseExchangeRate(value.currency).rate)
            .mul(purchase.ownedPercentage)
            .div(100)
            .add(innerAcc.assets);
          return { purchasePrice, assets };
        },
        { purchasePrice: new Decimal(0), assets: new Decimal(0) }
      );
      return {
        totalPurchasePrice: acc.totalPurchasePrice.add(purchasePrice),
        totalAssets: acc.totalAssets.add(assets),
      };
    },
    { totalPurchasePrice: new Decimal(0), totalAssets: new Decimal(0) }
  );
  result.assetType[AssetType.WineAndSpirits].original.purchasePrice.value =
    toDecimalPlacesNumber(wineResult.totalPurchasePrice);
  result.assetType[AssetType.WineAndSpirits].current.assets.value =
    toDecimalPlacesNumber(wineResult.totalAssets);

  // other collectables
  const otherCollectablesResult = Object.values(
    otherCollectablesSummary.items
  ).reduce(
    (acc, item) => {
      const purchasePrice = new Decimal(item.purchasePrice.value)
        .mul(exRate.getToBaseExchangeRate(item.purchasePrice.currency).rate)
        .mul(item.ownedPercentage)
        .div(100);
      const assets = new Decimal(item.value.value)
        .mul(exRate.getToBaseExchangeRate(item.value.currency).rate)
        .mul(item.ownedPercentage)
        .div(100);
      if (!acc.subtypes[item.subtype]) {
        acc.subtypes[item.subtype] = {
          totalPurchasePrice: purchasePrice,
          totalAssets: assets,
        };
      } else {
        acc.subtypes[item.subtype].totalPurchasePrice =
          acc.subtypes[item.subtype].totalPurchasePrice.add(purchasePrice);
        acc.subtypes[item.subtype].totalAssets =
          acc.subtypes[item.subtype].totalAssets.add(assets);
      }
      return {
        totalPurchasePrice: purchasePrice.add(acc.totalPurchasePrice),
        totalAssets: assets.add(acc.totalAssets),
        subtypes: acc.subtypes,
      };
    },
    {
      totalPurchasePrice: new Decimal(0),
      totalAssets: new Decimal(0),
      subtypes: {} as {
        [subtype: string]: {
          totalPurchasePrice: Decimal;
          totalAssets: Decimal;
        };
      },
    }
  );
  result.assetType[AssetType.OtherCollectables].original.purchasePrice.value =
    toDecimalPlacesNumber(otherCollectablesResult.totalPurchasePrice);
  result.assetType[AssetType.OtherCollectables].current.assets.value =
    toDecimalPlacesNumber(otherCollectablesResult.totalAssets);

  for (const subtype in otherCollectablesResult.subtypes) {
    if (!result.subCategory[subtype])
      result.subCategory[subtype] = defaultNetWorthReportDetails(currency);
    result.subCategory[subtype]!.original.purchasePrice.value =
      toDecimalPlacesNumber(
        otherCollectablesResult.subtypes[subtype].totalPurchasePrice
      );
    result.subCategory[subtype]!.current.assets.value = toDecimalPlacesNumber(
      otherCollectablesResult.subtypes[subtype].totalAssets
    );
  }

  // properties
  computePriceAndAssets(
    propertySummary.properties as { [id: string]: SummaryLocationItem },
    exRate,
    result.assetType[AssetType.Property]
  );

  // belongings
  computePriceAndAssets(
    belongingSummary.items,
    exRate,
    result.assetType[AssetType.Belonging]
  );

  const totalResult = Object.values(result.assetType).reduce(
    (acc, v) => {
      const totalPurchasePrice = acc.totalPurchasePrice.add(
        v.original.purchasePrice.value
      );
      const totalAssets = acc.totalAssets.add(v.current.assets.value);
      return { totalPurchasePrice, totalAssets };
    },
    { totalPurchasePrice: new Decimal(0), totalAssets: new Decimal(0) }
  );
  result.total.original.purchasePrice.value = toDecimalPlacesNumber(
    totalResult.totalPurchasePrice
  );
  result.total.current.assets.value = toDecimalPlacesNumber(
    totalResult.totalAssets
  );

  // *** Query all loan / mortgage. Get liabilities and allocation ***
  const loans = await CoreFirestore.getDocsFromCollection<Loan>(
    refs.getAssetCollectionRef(AssetType.CashAndBanking),
    CoreFirestore.where("subtype", "==", Account.Type.LoanAccount),
    CoreFirestore.where("ownerId", "==", refs.userId)
  ).then(getQueriedData);
  const mortgages = await CoreFirestore.getDocsFromCollection<Mortgage>(
    refs.getAssetCollectionRef(AssetType.CashAndBanking),
    CoreFirestore.where("subtype", "==", Account.Type.MortgageAccount),
    CoreFirestore.where("ownerId", "==", refs.userId)
  ).then(getQueriedData);
  const accounts = [...loans, ...mortgages];

  const liabilities = accounts.reduce(
    (acc, account) => {
      if (account.closedWith) return acc;
      const accountInitialAmount = new Decimal(account.initialAmount.value)
        .mul(exRate.getToBaseExchangeRate(account.initialAmount.currency).rate)
        .mul(-1);
      const accountValue = new Decimal(account.value.value).mul(
        exRate.getToBaseExchangeRate(account.value.currency).rate
      );
      if (account.subtype == Account.Type.LoanAccount) {
        // allocated liabilities
        let leftPercentage = 100;
        account.allocations.forEach((alloc) => {
          const initialLiability = accountInitialAmount
            .mul(alloc.percent)
            .div(100);
          const currentLiability = accountValue.mul(alloc.percent).div(100);
          acc[alloc.assetType].totalInitialLiability =
            acc[alloc.assetType].totalInitialLiability.add(initialLiability);
          acc[alloc.assetType].totalCurrentLiability =
            acc[alloc.assetType].totalCurrentLiability.add(currentLiability);
          leftPercentage -= alloc.percent;
          if (alloc.assetType === AssetType.OtherCollectables) {
            const subtype =
              otherCollectablesSummary.items[alloc.assetId].subtype;
            if (!acc[alloc.assetType].subtypes![subtype]) {
              acc[alloc.assetType].subtypes![subtype] = {
                totalInitialLiability: initialLiability,
                totalCurrentLiability: currentLiability,
              };
            } else {
              acc[alloc.assetType].subtypes![subtype].totalInitialLiability =
                acc[alloc.assetType].subtypes![
                  subtype
                ].totalInitialLiability.add(initialLiability);
              acc[alloc.assetType].subtypes![subtype].totalCurrentLiability =
                acc[alloc.assetType].subtypes![
                  subtype
                ].totalCurrentLiability.add(currentLiability);
            }
          }
        });
        // Assign left percentage to cash and banking
        if (leftPercentage < 0)
          throw new DataPoisoned("Left percentage is negative");
        else if (leftPercentage > 0) {
          acc[AssetType.CashAndBanking].totalInitialLiability = acc[
            AssetType.CashAndBanking
          ].totalInitialLiability.add(
            accountInitialAmount.mul(leftPercentage).div(100)
          );
          acc[AssetType.CashAndBanking].totalCurrentLiability = acc[
            AssetType.CashAndBanking
          ].totalCurrentLiability.add(
            accountValue.mul(leftPercentage).div(100)
          );
        }
      } else if (account.subtype == Account.Type.MortgageAccount) {
        if (account.linkToPropertyId) {
          acc[AssetType.Property].totalInitialLiability =
            acc[AssetType.Property].totalInitialLiability.add(
              accountInitialAmount
            );
          acc[AssetType.Property].totalCurrentLiability =
            acc[AssetType.Property].totalCurrentLiability.add(accountValue);
        } else {
          acc[AssetType.CashAndBanking].totalInitialLiability =
            acc[AssetType.CashAndBanking].totalInitialLiability.add(
              accountInitialAmount
            );
          acc[AssetType.CashAndBanking].totalCurrentLiability =
            acc[AssetType.CashAndBanking].totalCurrentLiability.add(
              accountValue
            );
        }
      }
      return acc;
    },
    {
      [AssetType.CashAndBanking]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0), // cashAndBankingResult.creditCardAccountLiabilities,
      },
      [AssetType.Art]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
      },
      [AssetType.WineAndSpirits]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
      },
      [AssetType.Property]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
      },
      [AssetType.Belonging]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
      },
      [AssetType.OtherCollectables]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
    } as {
      [key in ReportAssetType]: {
        totalInitialLiability: Decimal;
        totalCurrentLiability: Decimal;
        subtypes?: {
          [key: string]: {
            totalInitialLiability: Decimal;
            totalCurrentLiability: Decimal;
          };
        };
      };
    }
  );

  // *** Assign liabilities to result ***
  Object.keys(liabilities).forEach((assetType) => {
    const totalInitialLiability = toDecimalPlacesNumber(
      liabilities[assetType as ReportAssetType].totalInitialLiability
    );
    const totalCurrentLiability = toDecimalPlacesNumber(
      liabilities[assetType as ReportAssetType].totalCurrentLiability
    );

    result.assetType[assetType as ReportAssetType].original.liabilities.value =
      totalInitialLiability;
    result.assetType[assetType as ReportAssetType].current.liabilities.value =
      totalCurrentLiability;

    result.total.original.liabilities.value = addDecimal(
      result.total.original.liabilities.value,
      totalInitialLiability
    );
    result.total.current.liabilities.value = addDecimal(
      result.total.current.liabilities.value,
      totalCurrentLiability
    );
  });

  Object.keys(liabilities.OtherCollectables.subtypes!).forEach((subtype) => {
    if (!result.subCategory[subtype])
      result.subCategory[subtype] = defaultNetWorthReportDetails(currency);
    result.subCategory[subtype]!.original.liabilities.value =
      toDecimalPlacesNumber(
        liabilities.OtherCollectables.subtypes![subtype].totalInitialLiability
      );
    result.subCategory[subtype]!.current.liabilities.value =
      toDecimalPlacesNumber(
        liabilities.OtherCollectables.subtypes![subtype].totalCurrentLiability
      );
  });

  // *** Compute net value and percentage change ***
  Object.values(result.assetType).forEach((v) =>
    computeNetValueAndPercentageChange(v)
  );
  Object.values(result.subCategory).forEach((v) =>
    computeNetValueAndPercentageChange(v)
  );
  computeNetValueAndPercentageChange(result.total);

  return result;
}
