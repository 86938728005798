import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cpath%20d%3D%22M10.188%2011.952c0-5.368%204.458-9.84%209.809-9.84%205.35%200%209.809%204.472%209.809%209.84v18.786c0%203.937-3.21%207.157-7.134%207.157-3.924%200-7.134-3.22-7.134-7.157V15.53c0-2.505%201.962-4.473%204.459-4.473s4.459%201.968%204.459%204.473v13.42H21.78V15.53c0-1.073-.713-1.789-1.783-1.789-1.07%200-1.784.716-1.784%201.79v15.207c0%202.505%201.962%204.473%204.459%204.473s4.459-1.968%204.459-4.473V11.952c0-3.936-3.21-7.157-7.134-7.157-3.924%200-7.134%203.22-7.134%207.157v16.997h-2.675V11.952Z%22%2F%3E%3Cpath%20d%3D%22M19.997%202.817c-4.958%200-9.103%204.155-9.103%209.135v16.291h1.263V11.952c0-4.324%203.524-7.863%207.84-7.863%204.315%200%207.84%203.539%207.84%207.863v18.786c0%202.893-2.276%205.18-5.165%205.18-2.889%200-5.165-2.287-5.165-5.18V15.53c0-.696.235-1.332.697-1.795.462-.464%201.097-.7%201.793-.7s1.33.236%201.793.7c.462.463.696%201.099.696%201.795v12.713h1.264V15.53c0-2.117-1.648-3.767-3.753-3.767-2.105%200-3.753%201.65-3.753%203.767v15.208c0%203.549%202.896%206.451%206.428%206.451s6.428-2.902%206.428-6.45V11.951c0-4.98-4.145-9.135-9.103-9.135ZM9.482%2011.952c0-5.755%204.773-10.547%2010.515-10.547%205.742%200%2010.515%204.792%2010.515%2010.547v18.786c0%204.324-3.524%207.863-7.84%207.863s-7.84-3.539-7.84-7.863V15.53c0-2.892%202.276-5.179%205.165-5.179%202.889%200%205.164%202.287%205.164%205.18v14.124h-4.087V15.53c0-.377-.122-.636-.284-.799-.162-.162-.42-.284-.793-.284-.374%200-.631.122-.793.284-.163.163-.285.422-.285.8v15.207c0%202.117%201.648%203.767%203.753%203.767%202.105%200%203.753-1.65%203.753-3.767V11.952c0-3.549-2.896-6.451-6.428-6.451s-6.428%202.902-6.428%206.45v17.704H9.482V11.952Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function AttachmentIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 40 40'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M10.188 11.952c0-5.368 4.458-9.84 9.809-9.84 5.35 0 9.809 4.472 9.809 9.84v18.786c0 3.937-3.21 7.157-7.134 7.157-3.924 0-7.134-3.22-7.134-7.157V15.53c0-2.505 1.962-4.473 4.459-4.473s4.459 1.968 4.459 4.473v13.42H21.78V15.53c0-1.073-.713-1.789-1.783-1.789-1.07 0-1.784.716-1.784 1.79v15.207c0 2.505 1.962 4.473 4.459 4.473s4.459-1.968 4.459-4.473V11.952c0-3.936-3.21-7.157-7.134-7.157-3.924 0-7.134 3.22-7.134 7.157v16.997h-2.675V11.952Z'
        }
      />
      <path
        d={
          'M19.997 2.817c-4.958 0-9.103 4.155-9.103 9.135v16.291h1.263V11.952c0-4.324 3.524-7.863 7.84-7.863 4.315 0 7.84 3.539 7.84 7.863v18.786c0 2.893-2.276 5.18-5.165 5.18-2.889 0-5.165-2.287-5.165-5.18V15.53c0-.696.235-1.332.697-1.795.462-.464 1.097-.7 1.793-.7s1.33.236 1.793.7c.462.463.696 1.099.696 1.795v12.713h1.264V15.53c0-2.117-1.648-3.767-3.753-3.767-2.105 0-3.753 1.65-3.753 3.767v15.208c0 3.549 2.896 6.451 6.428 6.451s6.428-2.902 6.428-6.45V11.951c0-4.98-4.145-9.135-9.103-9.135ZM9.482 11.952c0-5.755 4.773-10.547 10.515-10.547 5.742 0 10.515 4.792 10.515 10.547v18.786c0 4.324-3.524 7.863-7.84 7.863s-7.84-3.539-7.84-7.863V15.53c0-2.892 2.276-5.179 5.165-5.179 2.889 0 5.164 2.287 5.164 5.18v14.124h-4.087V15.53c0-.377-.122-.636-.284-.799-.162-.162-.42-.284-.793-.284-.374 0-.631.122-.793.284-.163.163-.285.422-.285.8v15.207c0 2.117 1.648 3.767 3.753 3.767 2.105 0 3.753-1.65 3.753-3.767V11.952c0-3.549-2.896-6.451-6.428-6.451s-6.428 2.902-6.428 6.45v17.704H9.482V11.952Z'
        }
      />
    </svg>
  )
}
