import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20%20width%3D%2224%22%20%20height%3D%2224%22%20%20viewBox%3D%220%200%2060%2060%22%20%20fill%3D%22%23ddd%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20%20%20%20d%3D%22M11.5387%2047.5C10.3879%2047.5%209.42708%2047.1146%208.65625%2046.3438C7.88542%2045.5729%207.5%2044.6121%207.5%2043.4613V16.5387C7.5%2015.3879%207.88542%2014.4271%208.65625%2013.6562C9.42708%2012.8854%2010.3879%2012.5%2011.5387%2012.5H48.4613C49.6121%2012.5%2050.5729%2012.8854%2051.3438%2013.6562C52.1146%2014.4271%2052.5%2015.3879%2052.5%2016.5387V43.4613C52.5%2044.6121%2052.1146%2045.5729%2051.3438%2046.3438C50.5729%2047.1146%2049.6121%2047.5%2048.4613%2047.5H11.5387ZM50%2017.2113L31.12%2029.5719C30.9438%2029.6648%2030.7652%2029.7425%2030.5844%2029.805C30.4031%2029.8675%2030.2083%2029.8988%2030%2029.8988C29.7917%2029.8988%2029.5969%2029.8675%2029.4156%2029.805C29.2348%2029.7425%2029.0562%2029.6648%2028.88%2029.5719L10%2017.2113V43.4613C10%2043.91%2010.1442%2044.2788%2010.4325%2044.5675C10.7212%2044.8558%2011.09%2045%2011.5387%2045H48.4613C48.91%2045%2049.2788%2044.8558%2049.5675%2044.5675C49.8558%2044.2788%2050%2043.91%2050%2043.4613V17.2113ZM30%2027.5L49.2306%2015H10.7694L30%2027.5ZM10%2017.7406V15.7113V15.7981V15.6706V17.7406Z%22%20%20%2F%3E%3C%2Fsvg%3E)
 */
export default function EmailIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      width={size}
      height={size}
      fill={'none'}
      stroke={'currentColor'}
      strokeWidth={1.5}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      viewBox={'0 0 24 24'}
      {...props}
    >
      <rect width={'20'} height={'16'} x={'2'} y={'4'} rx={'2'} />
      <path d={'m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7'} />
    </svg>
  )
}
