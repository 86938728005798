import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M25%206h-8a1%201%200%201%200%200%202h8a1%201%200%201%200%200-2Zm-5.077%2021.79V14.526H9.153c-1.189%200-2.153-.99-2.153-2.21V11.21C7%209.99%207.964%209%209.154%209h23.692C34.036%209%2035%209.99%2035%2011.21v1.106c0%201.22-.964%202.21-2.154%202.21h-10.77V27.79h3.232V30h-8.616v-2.21h3.231ZM9.75%2026.75h-6.5V30H1.083V19.25H.541q-.054%200-.106-.01t-.101-.031q-.05-.02-.094-.05-.044-.03-.082-.067-.037-.038-.067-.082-.03-.045-.05-.094-.02-.049-.03-.101Q0%2018.763%200%2018.709v-1.085q0-.08.008-.16.008-.079.023-.157.016-.078.039-.154.023-.077.054-.15.03-.074.068-.145.037-.07.082-.136.044-.067.095-.128.05-.062.107-.118.056-.057.118-.107.061-.051.128-.095.066-.045.136-.082.07-.038.145-.068.073-.03.15-.054.076-.023.154-.039.078-.015.158-.023T1.624%2016h9.752q.08%200%20.16.008.079.008.157.023.078.016.154.039.077.023.15.054.074.03.145.068.07.037.136.082.067.044.128.095.062.05.118.107.057.056.107.118.051.061.095.128.045.066.082.136.038.07.068.144.03.074.054.15.023.077.039.155.015.078.023.158t.008.159v1.085q0%20.054-.01.106t-.031.101q-.02.05-.05.094-.03.044-.067.082-.038.037-.082.067-.045.03-.094.05-.049.02-.101.03-.052.011-.106.011h-.542V30H9.75v-3.25ZM10%2019v6H3v-6h7Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function DiningRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M25 6h-8a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2Zm-5.077 21.79V14.526H9.153c-1.189 0-2.153-.99-2.153-2.21V11.21C7 9.99 7.964 9 9.154 9h23.692C34.036 9 35 9.99 35 11.21v1.106c0 1.22-.964 2.21-2.154 2.21h-10.77V27.79h3.232V30h-8.616v-2.21h3.231ZM9.75 26.75h-6.5V30H1.083V19.25H.541q-.054 0-.106-.01t-.101-.031q-.05-.02-.094-.05-.044-.03-.082-.067-.037-.038-.067-.082-.03-.045-.05-.094-.02-.049-.03-.101Q0 18.763 0 18.709v-1.085q0-.08.008-.16.008-.079.023-.157.016-.078.039-.154.023-.077.054-.15.03-.074.068-.145.037-.07.082-.136.044-.067.095-.128.05-.062.107-.118.056-.057.118-.107.061-.051.128-.095.066-.045.136-.082.07-.038.145-.068.073-.03.15-.054.076-.023.154-.039.078-.015.158-.023T1.624 16h9.752q.08 0 .16.008.079.008.157.023.078.016.154.039.077.023.15.054.074.03.145.068.07.037.136.082.067.044.128.095.062.05.118.107.057.056.107.118.051.061.095.128.045.066.082.136.038.07.068.144.03.074.054.15.023.077.039.155.015.078.023.158t.008.159v1.085q0 .054-.01.106t-.031.101q-.02.05-.05.094-.03.044-.067.082-.038.037-.082.067-.045.03-.094.05-.049.02-.101.03-.052.011-.106.011h-.542V30H9.75v-3.25ZM10 19v6H3v-6h7Z'
        }
      />
    </svg>
  )
}
