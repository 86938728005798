import React, { useEffect } from 'react'

declare global {
  interface Window {
    hbspt?: any
  }
}

interface HubspotContactFormProps {
  region: string
  portalId: string
  formId: string
}

export const HubSpotContactForm: React.FC<HubspotContactFormProps> = ({ region, portalId, formId }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubSpotForm'
        })
      }
    })
    return () => {
      document.body.removeChild(script)
    }
  }, [region, portalId, formId])

  return <div id={'hubSpotForm'} />
}
