import { Loader2Icon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'
import { Button, FormInput, Modal } from '@/components/base'

export type RenameFileValues = {
  fileKey: string
  newFileName: string
}

interface RenameModalProps {
  isOpen: boolean
  file: { key: string; name: string }
  onRename: (data: RenameFileValues) => void
  onCancel: () => void
}

export default function RenameModal({ isOpen, file, onRename, onCancel }: RenameModalProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit
  } = useForm<RenameFileValues>({ values: { fileKey: file.key, newFileName: file.name } })

  const onSubmit = (data: RenameFileValues) => {
    onRename(data)
    onCancel()
  }

  return isOpen ? (
    <Modal className={'max-w-md'} onBackdropClick={onCancel}>
      <Modal.Header>
        <label className={'text-sm font-medium uppercase text-primary'}>{t('Rename')}</label>
        <Modal.CloseButton className={'text-primary'} onClose={onCancel} />
      </Modal.Header>
      <Modal.Content>
        <div className={'space-y-4'}>
          <FormInput control={control} name={'newFileName'} label={t('Field.FileName')} rules={{ required: true }} />
          <fieldset className={'flex justify-end gap-2'} disabled={isSubmitting}>
            <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
              {t('Discard')}
            </Button>
            <Button
              className={'relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{t('Rename')}</span>
            </Button>
          </fieldset>
        </div>
      </Modal.Content>
    </Modal>
  ) : null
}
