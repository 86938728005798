import { GenesysClient } from 'core/genesys'
import { KnowledgeDocumentData } from '@/types/helpCenter'

export const helpCenterQuery = {
  search: 'help-center-search',
  searchSuggestions: 'help-center-search-suggestions',
  documentById: 'help-center-document-by-id',
  submitFeedback: 'help-center-submit-feedback'
} as const

let client: GenesysClient | null = null

async function getGenesysClient(): Promise<GenesysClient> {
  if (!client) {
    client = new GenesysClient(
      process.env.NEXT_PUBLIC_GENESYS_API_DEPLOYMENT_ID!,
      process.env.NEXT_PUBLIC_GENESYS_API_CUSTOMER_ID!
    )
    await client.generateSession()
  }
  return client
}

export async function fetchSearchSuggestions() {
  //random array
  function shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    return shuffled
  }

  try {
    const client = await getGenesysClient()
    const data = await client.mostCommonArticles(100)
    if (data.entities && Array.isArray(data.entities)) {
      const randomEntities = shuffleArray(data.entities).slice(0, 5)
      return randomEntities
    }
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

export async function fetchKnowledgeSearch([_key, keyword]: [typeof helpCenterQuery.search, string]) {
  try {
    const client = await getGenesysClient()
    const data = await client.search(keyword, () => {})
    return data.results
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

export async function fetchDocumentById([_key, documentId]: [typeof helpCenterQuery.documentById, string]) {
  try {
    const client = await getGenesysClient()
    const data = await client.getArticle(documentId)
    return data as KnowledgeDocumentData
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

export async function submitFeedback([_key, documentId, positive, comment]: [
  typeof helpCenterQuery.submitFeedback,
  string,
  boolean,
  string
]) {
  try {
    const client = await getGenesysClient()
    await client.reviewArticle(documentId, positive, comment)
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}
