import * as React from 'react'
import { useEffect, useState } from 'react'
import { ChevronDownIcon, CircleIcon } from 'lucide-react'

import { cn } from '@/utils/classnames'
import useIsMobile from '@/hooks/useIsMobile'
import { PanelIcon } from '@/components/icon'

export type SubTab = {
  key: string
  label: string
  hidden?: boolean
}

export type Tab = {
  key: string
  label: string
  desc?: string
  icon: JSX.Element
  subTabs?: SubTab[]
  hidden?: boolean
}

type TabState = {
  selected: string
  setSelected: (value: string) => void
}

const TabContext = React.createContext<TabState>({
  selected: '',
  setSelected: () => {}
})

interface TabProviderProps {
  defaultValue: string
  children: React.ReactNode
}

function TabProvider({ defaultValue, children }: TabProviderProps) {
  const [selected, setSelected] = React.useState(defaultValue)
  return <TabContext.Provider value={{ selected, setSelected }}>{children}</TabContext.Provider>
}

function useTab() {
  return React.useContext(TabContext)
}

interface SideNavProps {
  tabs: Tab[]
  className?: string
}

const SideNav = React.forwardRef<HTMLDivElement, SideNavProps>(function ({ tabs, className }, ref) {
  const { selected, setSelected } = useTab()
  const [key, subKey] = selected.split('.')
  const [isExpand, setIsExpand] = React.useState(false)
  const isMobile = useIsMobile()

  const handleSelect = (item: Tab) => {
    const subTabs = item.subTabs?.filter((item) => !item.hidden)
    const hasSubTabs = subTabs && subTabs.length > 0
    const key = hasSubTabs ? `${item.key}.${subTabs[0].key}` : item.key
    setSelected(key)
  }

  const handleSubSelect = (item: Tab, subItem: SubTab) => {
    const key = `${item.key}.${subItem.key}`
    setSelected(key)
  }

  return isMobile ? (
    <aside
      ref={ref}
      className={cn(
        'flex min-h-[95vh] flex-col gap-4 bg-gray-100 transition-[width]',
        isExpand ? 'absolute z-10 w-full' : 'relative w-[60px]',
        className
      )}
    >
      <button
        className={'flex h-[52px] w-full justify-start rounded-none hover:bg-gray-200'}
        onClick={() => setIsExpand((prev) => !prev)}
      >
        <div className={'flex w-[60px] items-center justify-center'}>
          <PanelIcon
            className={cn('my-4 transition-transform', isExpand ? '-scale-x-100 text-primary' : 'scale-x-100')}
            size={36}
          />
        </div>
      </button>
      <div className={'flex flex-col'}>
        {tabs.map((item) => {
          const isActive = item.key === key
          const isHidden = item.hidden
          const hasSubTabs = item.subTabs && item.subTabs.length > 0

          return (
            <React.Fragment key={item.key}>
              <button
                className={cn(
                  'flex items-center gap-2 border-b px-3 py-4',
                  isActive ? 'bg-white' : 'border-r',
                  isHidden && 'hidden'
                )}
                onClick={() => {
                  if (hasSubTabs && item.subTabs && item.subTabs.length > 0) {
                    setIsExpand(!isExpand)
                    if (!isExpand) {
                      setSelected(isActive ? item.key : `${item.key}.${item.subTabs[0].key}`)
                    }
                  } else {
                    handleSelect(item)
                    setIsExpand(false)
                  }
                }}
              >
                <span className={'flex w-[40px] shrink-0 items-center justify-center text-primary'}>{item.icon}</span>
                {isExpand && (
                  <div className={'grid grow gap-0.5 text-gray-600 hover:text-primary'}>
                    <div className={'flex items-center justify-between'}>
                      <span className={'text-center  text-sm font-medium'}>{item.label}</span>
                      {hasSubTabs && (
                        <ChevronDownIcon
                          className={cn('h-4 w-4 text-primary transition-all', isActive && 'rotate-180')}
                        />
                      )}
                    </div>
                    {item.desc && <span className={'text-start text-xs text-gray-600'}>{item.desc}</span>}
                  </div>
                )}
              </button>

              {isExpand && hasSubTabs && (
                <div className={cn('overflow-hidden transition-all', isActive ? 'max-h-96' : 'max-h-0')}>
                  {item.subTabs?.map((subItem) => {
                    const isSubActive = subItem.key === subKey
                    const isSubHidden = subItem.hidden

                    return (
                      <button
                        key={subItem.key}
                        className={cn(
                          'flex w-full shrink items-center gap-2 overflow-hidden py-2 pl-10 transition-all hover:bg-primary/20 hover:text-primary',
                          isSubActive ? 'text-primary' : 'text-gray-600'
                        )}
                        onClick={() => {
                          handleSubSelect(item, subItem)
                          setIsExpand(false)
                        }}
                      >
                        <CircleIcon className={cn('h-3 w-3', isSubActive ? 'fill-primary' : 'fill-none')} />
                        <span className={'text-sm'}>{subItem.label}</span>
                      </button>
                    )
                  })}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <div className={'flex-1 border-r'} />
    </aside>
  ) : (
    <div ref={ref} className={cn('flex shrink-0 basis-52 flex-col border-gray-200 bg-gray-100', className)}>
      {tabs.map((item) => {
        const isActive = item.key === key
        const isHidden = item.hidden
        const hasSubTabs = item.subTabs

        return (
          <React.Fragment key={item.key}>
            <button
              className={cn(
                'flex basis-16 items-center gap-2 border-b px-3',
                isActive ? 'bg-white' : 'border-r',
                isHidden && 'hidden'
              )}
              onClick={() => handleSelect(item)}
            >
              <span className={'text-primary'}>{item.icon}</span>
              <div className={'grid grow gap-0.5 text-gray-600 hover:text-primary'}>
                <div className={'flex items-center justify-between'}>
                  <span className={'text-sm font-medium'}>{item.label}</span>
                  {hasSubTabs && (
                    <ChevronDownIcon className={cn('h-4 w-4 text-primary transition-all', isActive && 'rotate-180')} />
                  )}
                </div>
                {item.desc && <span className={'text-left text-xs text-gray-600'}>{item.desc}</span>}
              </div>
            </button>

            {item.subTabs?.map((subItem) => {
              const isSubActive = subItem.key === subKey
              const isHidden = subItem.hidden

              return (
                <button
                  key={subItem.key}
                  className={cn(
                    'flex shrink items-center gap-2 overflow-hidden pl-10 transition-all hover:bg-primary/20 hover:text-primary',
                    isActive ? 'basis-10 bg-white' : 'basis-0 border-r',
                    isSubActive ? 'text-primary' : 'text-gray-600',
                    isHidden && 'hidden'
                  )}
                  onClick={() => handleSubSelect(item, subItem)}
                >
                  <CircleIcon className={cn('h-3 w-3', isSubActive ? 'fill-primary' : 'fill-none')} />
                  <span className={'text-sm'}>{subItem.label}</span>
                </button>
              )
            })}
          </React.Fragment>
        )
      })}

      {/* fill rest space */}
      <div className={'flex-1 border-r'} />
    </div>
  )
})
SideNav.displayName = 'SideNav'

interface SectionProps {
  value: string
  children: React.ReactNode
  className?: string
}

const Section = React.forwardRef<HTMLDivElement, SectionProps>(function ({ value, className, children }, ref) {
  const { selected } = useTab()
  const isSelected = selected === value

  return (
    <div ref={ref} className={cn('md:grid-col-2 grid gap-4', !isSelected && 'hidden', className)}>
      {children}
    </div>
  )
})
Section.displayName = 'Section'

interface TabPanelProps {
  defaultValue: string
  children: React.ReactNode
  className?: string
}

// https://stackoverflow.com/questions/70202711/how-to-attach-a-compound-component-when-using-react-forward-ref-property-does-n
export const TabPanel = Object.assign(
  React.forwardRef<HTMLElement, TabPanelProps>(function TabPanel({ defaultValue, children, className }, ref) {
    return (
      <TabProvider defaultValue={defaultValue}>
        <section ref={ref} className={cn('flex', className)}>
          {children}
        </section>
      </TabProvider>
    )
  }),
  { SideNav, Section }
)
