import {
  GroupInfo,
  GroupWithItems,
  Groupable,
  GroupItem,
} from "../types/groups";
import { AggregateRoot } from "../types/aggregate";
import {
  FullRefs,
  getGroupRelationsCollectionRefFromGroupCollection,
  getGroupRelationsCollectionRefFromGroupDocument,
  Refs,
} from "../refs";
import { AlreadyExist, InvalidInput } from "../types/error";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { AsyncTask, AsyncTaskExecutor } from "../types/asyncTask";
import { AssetType } from "../types/enums";
import { ArtsRepo } from "./arts";
import { BelongingsRepo } from "./belongings";
import { PropertiesRepo } from "./properties";
import { CashAndBankingRepo } from "./cashAndBanking";
import { TraditionalInvestmentRepo } from "./traditionalInvestments";
import { OtherInvestmentRepo } from "./otherInvestments";
import { InsuranceRepo } from "./insurance";
import { SummaryManager } from "../types/summaryManager";
import { TypeResult } from "../types/typeVersion";
import { EncryptionManager } from "./encryption";

export class GroupsRepo {
  // Note that while groups are not supported in delegates, we are still using delegate refs for consistency.
  protected readonly refs: FullRefs;
  protected readonly encryption: EncryptionManager;
  protected readonly summaryManager: SummaryManager;

  constructor(
    refs: FullRefs,
    encryption: EncryptionManager,
    summaryManager: SummaryManager
  ) {
    this.refs = refs;
    this.encryption = encryption;
    this.summaryManager = summaryManager;
  }

  //#NOTE this could take long time
  async getAll(): Promise<GroupWithItems[]> {
    let result: GroupWithItems[] = (await this.getAllInfo()).map((info) => ({
      info,
      items: [],
    }));

    for (const group of result) {
      group.items = await this.getGroupItems(group.info.id);
    }

    return result;
  }

  async getAllInfo(): Promise<GroupInfo[]> {
    const collectionRef = this.refs.currentRefs.Groups;
    const groups = (
      await CoreFirestore.getDocsFromCollection(
        collectionRef,
        CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
      ).then(getQueriedData)
    ).map((v) => GroupInfo.convertDate(v));
    return groups.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getGroupInfoById(groupId: string): Promise<GroupInfo> {
    return await this.getGroupInfo(groupId);
  }

  async getById(groupId: string): Promise<GroupWithItems> {
    const groupInfo = await this.getGroupInfo(groupId);
    const items = await this.getGroupItems(groupId);
    return { info: groupInfo, items };
  }

  async getGroupInfoByIds(ids: string[]): Promise<GroupInfo[]> {
    return await CoreFirestore.getDocsByIdsPure(
      this.refs.currentRefs.Groups,
      ids
    );
  }

  async getByIds(ids: string[]): Promise<GroupWithItems[]> {
    const groupInfos = await this.getGroupInfoByIds(ids);
    let result: GroupWithItems[] = groupInfos.map((info) => ({
      info,
      items: [],
    }));

    for (const group of result) {
      group.items = await this.getGroupItems(group.info.id);
    }

    return result;
  }

  async isGroupNameExists(groupName: string) {
    const groups = await CoreFirestore.getDocsFromCollection(
      this.refs.currentRefs.Groups,
      CoreFirestore.where("name", "==", groupName)
    );
    return !groups.empty;
  }

  async add(req: GroupInfo.CreateFields, newItems: GroupItem[]) {
    const newDocRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      req.id
    );
    GroupInfo.validate(req);
    const isGroupNameExists = await this.isGroupNameExists(req.name);
    if (isGroupNameExists) {
      throw new AlreadyExist("Group name already exists");
    }

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);
      const groupInfo = GroupInfo.fromCreate(req, this.refs.currentRefs.userId);
      GroupInfo.validate(groupInfo, true);

      await this.handleARsAndWriteGroup(transaction, groupInfo, newItems, []);
    });
  }

  async update(
    req: GroupInfo,
    itemsAdded: GroupItem[],
    itemsRemoved: GroupItem[]
  ) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      req.id
    );
    GroupInfo.validate(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction
        .get(docRef)
        .then(checkAndGetData)
        .then(GroupInfo.convertDate);
      const result = GroupInfo.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) GroupInfo.handleOutDated();

      await this.handleARsAndWriteGroup(
        transaction,
        req,
        itemsAdded,
        itemsRemoved
      );
    });
  }

  private getAsyncRemoveTask(item: GroupItem, groupId: string): Promise<void> {
    switch (item.assetType) {
      case AssetType.Art:
        return ArtsRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.Belonging:
        return BelongingsRepo.removeGroup(
          this.refs,
          item.assetId,
          AssetType.Belonging,
          groupId
        );
      case AssetType.OtherCollectables:
        return BelongingsRepo.removeGroup(
          this.refs,
          item.assetId,
          AssetType.OtherCollectables,
          groupId
        );
      case AssetType.Property:
        return PropertiesRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.CashAndBanking:
        return CashAndBankingRepo.removeGroup(this.refs, item.assetId, groupId);
      case AssetType.TraditionalInvestments:
        return TraditionalInvestmentRepo.removeGroup(
          this.refs,
          item.assetId,
          groupId
        );
      case AssetType.OtherInvestment:
        return OtherInvestmentRepo.removeGroup(
          this.refs,
          item.assetId,
          groupId
        );
      case AssetType.Insurance:
        return InsuranceRepo.removeGroup(this.refs, item.assetId, groupId);
    }
  }

  private doDelete(
    docRef: DocumentReference<GroupInfo>,
    id: string
  ): AsyncTask {
    return AsyncTask.retry(async () => {
      const result = await CoreFirestore.runTransaction(async (transaction) => {
        const currentData = await transaction.get(docRef).then(checkAndGetData);
        const result = GroupInfo.assureVersion(currentData);
        if (result === TypeResult.DataOutDated) GroupInfo.handleOutDated();
        // * check any item left, yes => return items, no => delete
        const items = await this.getGroupItems(id);
        if (items.length > 0) {
          return items;
        } else {
          transaction.delete(docRef);
          return undefined;
        }
      });

      if (result) {
        for (const item of result) {
          await this.getAsyncRemoveTask(item, id);
        }
        return true;
      } else {
        return false;
      }
    });
  }

  async delete(id: string) {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      id
    );
    const items = (await this.getById(id)).items;
    const tasks = items.reduce((tasks, item) => {
      tasks.push(AsyncTask.once(() => this.getAsyncRemoveTask(item, id)));
      return tasks;
    }, [] as AsyncTask[]);

    return new AsyncTaskExecutor([...tasks, this.doDelete(docRef, id)]);
  }

  private async getGroupInfo(id: string): Promise<GroupInfo> {
    const docRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => GroupInfo.convertDate(v));
  }

  private async getGroupItems(id: string): Promise<GroupItem[]> {
    const groupCollectionRef =
      getGroupRelationsCollectionRefFromGroupCollection(
        this.refs.currentRefs.Groups,
        id
      );
    return await CoreFirestore.getDocsFromCollection(groupCollectionRef).then(
      getQueriedData
    );
  }

  //   private async deleteGroup(id: string) {
  //     const groupRef = CoreFirestore.docFromCollection(
  //       this.refs.currentRefs.Groups,
  //       id
  //     );
  //     let items = await this.getGroupItems(id);
  //     const groupRelationsCollectionRef =
  //       getGroupRelationsCollectionRefFromGroupDocument(groupRef);

  //     //need querier
  //     const querier = buildGroupItemQuerier(groupRelationsCollectionRef);

  //     let result = await querier.getNext();
  //     while (result.length > 0) {
  //       await CoreFirestore.runTransaction(async (transaction) => {
  //         result.forEach((item) => {
  //           transaction.delete(
  //             CoreFirestore.docFromCollection(
  //               groupRelationsCollectionRef,
  //               item.assetId
  //             )
  //           );
  //         });
  //       });

  //       querier.setCursor();
  //       result = await querier.getNext();
  //     }
  //   }

  private async handleARsAndWriteGroup(
    transaction: Transaction,
    info: GroupInfo,
    itemsAdded: GroupItem[],
    itemsRemoved: GroupItem[]
  ) {
    await this.handleRelatedAggregates(
      transaction,
      info.id,
      itemsAdded,
      itemsRemoved
    );

    const groupRef = CoreFirestore.docFromCollection(
      this.refs.currentRefs.Groups,
      info.id
    );

    const groupCollectionRef =
      getGroupRelationsCollectionRefFromGroupDocument(groupRef);

    if (itemsAdded.length > 0) {
      for (const item of itemsAdded) {
        transaction.set(
          CoreFirestore.docFromCollection(groupCollectionRef, item.assetId),
          item
        );
      }
    }

    if (itemsRemoved.length > 0) {
      for (const item of itemsRemoved) {
        transaction.delete(
          CoreFirestore.docFromCollection(groupCollectionRef, item.assetId)
        );
      }
    }

    transaction.set(groupRef, {
      ...info,
      updateAt: CoreFirestore.serverTimestamp(),
    });
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    groupId: string,
    itemsAdded: GroupItem[],
    itemsRemoved: GroupItem[]
  ) {
    const data: GroupInfo.RelatedUpdates = {
      [AssetType.CashAndBanking]: [],
      [AssetType.TraditionalInvestments]: [],
      [AssetType.OtherInvestment]: [],
      [AssetType.Insurance]: [],
      [AssetType.Property]: [],
      [AssetType.Art]: [],
      [AssetType.OtherCollectables]: [],
      [AssetType.Belonging]: [],
    };
    const repoAndAggregates: GroupInfo.RelatedAggregates = {};

    for (const item of itemsAdded) {
      if (itemsRemoved.some((v) => v.assetId === item.assetId)) {
        throw new InvalidInput("Found same item in both added and removed");
      }
      data[item.assetType].push({ id: item.assetId, action: "add" });
    }

    for (const item of itemsRemoved) {
      data[item.assetType].push({ id: item.assetId, action: "remove" });
    }

    // * read
    if (data[AssetType.CashAndBanking].length > 0) {
      repoAndAggregates[AssetType.CashAndBanking] = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.CashAndBanking].map(({ id, action }) =>
              CashAndBankingRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.TraditionalInvestments].length > 0) {
      repoAndAggregates[AssetType.TraditionalInvestments] = {
        repo: await TraditionalInvestmentRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.TraditionalInvestments].map(({ id, action }) =>
              TraditionalInvestmentRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.OtherInvestment].length > 0) {
      repoAndAggregates[AssetType.OtherInvestment] = {
        repo: await OtherInvestmentRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.OtherInvestment].map(({ id, action }) =>
              OtherInvestmentRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Insurance].length > 0) {
      repoAndAggregates[AssetType.Insurance] = {
        repo: await InsuranceRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Insurance].map(({ id, action }) =>
              InsuranceRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Property].length > 0) {
      repoAndAggregates[AssetType.Property] = {
        repo: await PropertiesRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Property].map(({ id, action }) =>
              PropertiesRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Art].length > 0) {
      repoAndAggregates[AssetType.Art] = {
        repo: await ArtsRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: (
          await Promise.all(
            data[AssetType.Art].map(({ id, action }) =>
              ArtsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.OtherCollectables].length > 0) {
      repoAndAggregates[AssetType.OtherCollectables] = {
        repo: await BelongingsRepo.newRepo(
          this.refs.currentRefs,
          transaction,
          AssetType.OtherCollectables
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.OtherCollectables].map(({ id, action }) =>
              BelongingsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                AssetType.OtherCollectables,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    if (data[AssetType.Belonging].length > 0) {
      repoAndAggregates[AssetType.Belonging] = {
        repo: await BelongingsRepo.newRepo(
          this.refs.currentRefs,
          transaction,
          AssetType.Belonging
        ),
        aggregates: (
          await Promise.all(
            data[AssetType.Belonging].map(({ id, action }) =>
              BelongingsRepo.newArAndUpdateGroup(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                id,
                AssetType.Belonging,
                groupId,
                action == "add"
              )
            )
          )
        ).filter((v) => v !== undefined) as AggregateRoot<any, any, any>[],
      };
    }
    // * update
    for (const repoAndAggregate of Object.values(repoAndAggregates)) {
      const groupData =
        repoAndAggregate as GroupInfo.RelatedAggregates[Groupable];
      if (groupData) {
        groupData.aggregates.forEach((aggregate) => {
          groupData.repo.commitWithState(aggregate);
        });
      }
    }
  }
}

export namespace GroupsRepo {
  export async function getGroupsInTransaction(
    refs: Refs,
    transaction: Transaction,
    groupIds: string[]
  ): Promise<GroupInfo[]> {
    const idSet = new Set(groupIds);
    const idRefs = Array.from(idSet).map((id) => {
      return CoreFirestore.docFromCollection(refs.Groups, id);
    });
    let groupInfos = [];
    for (const idRef of idRefs) {
      groupInfos.push(await transaction.get(idRef).then(checkAndGetData));
    }
    return groupInfos;
  }

  export function addOneItemToGroup(
    refs: Refs,
    transaction: Transaction,
    groupId: string,
    assetId: string,
    assetType: Groupable,
    subtype: string
  ) {
    const groupRef = CoreFirestore.docFromCollection(refs.Groups, groupId);
    const groupCollectionRef =
      getGroupRelationsCollectionRefFromGroupDocument(groupRef);
    const itemRef = CoreFirestore.docFromCollection(
      groupCollectionRef,
      assetId
    );
    transaction.set(itemRef, { assetId, assetType, subtype });
    transaction.update(groupRef, {
      updateAt: CoreFirestore.serverTimestamp(),
    });
  }
  export function deleteOneItemFromGroup(
    refs: Refs,
    transaction: Transaction,
    groupId: string,
    assetId: string
  ) {
    const groupRef = CoreFirestore.docFromCollection(refs.Groups, groupId);
    const groupCollectionRef =
      getGroupRelationsCollectionRefFromGroupDocument(groupRef);
    const itemRef = CoreFirestore.docFromCollection(
      groupCollectionRef,
      assetId
    );
    transaction.delete(itemRef);
    transaction.update(groupRef, {
      updateAt: CoreFirestore.serverTimestamp(),
    });
  }
}
