import Link from 'next/link'
import { ArrowLeft } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { validation } from '@/constants/validation'
import { Button, FormInput } from '@/components/base'

export type ResetValues = {
  email: string
  oobCode: string
  newPassword: string
  confirmPassword: string
}

const defaultValues: ResetValues = {
  email: '',
  oobCode: '',
  newPassword: '',
  confirmPassword: ''
}

interface ResetFormProps {
  values?: ResetValues
  onSubmit: SubmitHandler<ResetValues>
}

export function ResetForm({ values, onSubmit }: ResetFormProps) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<ResetValues>({ values, defaultValues })
  const email = watch('email')

  return (
    <form className={'w-full max-w-[440px] rounded border border-grey/50 p-[50px]'} onSubmit={handleSubmit(onSubmit)}>
      {/* Title */}
      <div className={'mb-2 mt-5 space-y-5'}>
        <h1 className={'text-[30px] font-bold leading-[35px] text-white'}>{t('auth:ResetYourPassword')}</h1>
        <span className={'inline-block text-sm font-medium text-white'}>{t('auth:ForEmail', { email })}</span>
        {/* Password */}
        <FormInput
          control={control}
          name={'newPassword'}
          type={'password'}
          placeholder={t('auth:Field.Password')}
          displayErrorMode={'none'}
          rules={{
            required: validation.requiredPassword.message(),
            validate: {
              checkMinLength: (value) => value.length >= 12 || t('auth:ResetFormCheckNewPasswordMin'),
              checkMaxLength: (value) => value.length <= 64 || t('auth:ResetFormCheckNewPasswordMax'),
              checkUppercaseLetter: (value) =>
                validation.uppercase.pattern.test(value) || t('auth:ResetFormCheckNewPassword'),
              checkContainSpecialChar: (value) =>
                validation.specialCharacters.pattern.test(value) || t('auth:ResetFormCheckNewPassword'),
              checkContainNumber: (value) =>
                validation.numericCharacter.pattern.test(value) || t('auth:ResetFormCheckNewPassword')
            }
          }}
        />

        {/* Confirm Password */}
        <FormInput
          control={control}
          name={'confirmPassword'}
          type={'password'}
          placeholder={t('auth:Field.ConfirmPassword')}
          displayErrorMode={'none'}
          rules={{
            required: validation.requiredConfirmPassword.message(),
            validate: {
              confirmPassword: (value, values) =>
                validation.confirmPassword.pattern(value, values.newPassword) || validation.confirmPassword.message()
            }
          }}
        />

        {errors && (
          <p className={'text-xs text-red-500'}>{errors.newPassword?.message || errors.confirmPassword?.message}</p>
        )}

        <Button className={'w-full'} variant={'solid'} size={'md'} type={'submit'} disabled={isSubmitting}>
          {t('auth:ResetPassword')}
        </Button>
      </div>

      <Button asChild={true} className={'w-full text-primary hover:text-secondary-hover'}>
        <Link href={'/auth/login'}>
          <ArrowLeft className={'h-6 w-6'} />
          <span>{t('auth:BackToLogin')}</span>
        </Link>
      </Button>
    </form>
  )
}
