import { EncryptionField } from "../encryption/utils";
import { UpdateObject } from "../utils";
import { Action } from "./actions";
import { SoldInfo } from "./actions/soldInfo";
import { Valuation } from "./actions/valuation";
import { Amount, Optional } from "./common";
import { LocationInfo } from "./relations/locationInfo";

export interface CommandBase {
  kind: string;
  executerId: string;
}

export namespace SharedCommand {
  export enum Kind {
    CreateAsset = "CreateAsset",
    UpdateAsset = "UpdateAsset",
    RelocateAsset = "RelocateAsset",
    DeleteAsset = "DeleteAsset",
    ArchiveAsset = "ArchiveAsset",
    RestoreArchivedAsset = "RestoreArchivedAsset",
    CloseAsset = "CloseAsset",
    AddInsurance = "AddInsurance",
    RemoveInsurance = "RemoveInsurance",
  }

  export interface CreateAsset<T extends object> extends CommandBase {
    kind: Kind.CreateAsset;
    asset: T;
    valuation?: Valuation.Encrypted;
  }
  export function createAsset<T extends object>(
    executerId: string,
    asset: T,
    valuation?: Valuation.Encrypted
  ): CreateAsset<T> {
    const command: CreateAsset<T> = {
      kind: Kind.CreateAsset,
      executerId,
      asset,
    };
    if (valuation) command.valuation = valuation;
    return command;
  }

  export interface UpdateAsset<T extends object> extends CommandBase {
    kind: Kind.UpdateAsset;
    asset: T;
    addedToGroup?: string[];
    removedFromGroup?: string[];
    newImages?: string[];
    newMainImage?: string;
    removedImages?: string[];
    locationPrimaryDetailsUpdated?: boolean;
  }
  export function updateAsset<T extends object>(
    executerId: string,
    asset: T,
    addedToGroup: Optional<string[]> = undefined,
    removedFromGroup: Optional<string[]> = undefined,
    newImages: Optional<string[]> = undefined,
    newMainImage: Optional<string> = undefined,
    removedImages: Optional<string[]> = undefined,
    locationPrimaryDetailsUpdated: Optional<boolean> = undefined
  ): UpdateAsset<T> {
    const command: UpdateAsset<T> = {
      kind: Kind.UpdateAsset,
      executerId,
      asset,
    };
    if (addedToGroup) command.addedToGroup = addedToGroup;
    if (removedFromGroup) command.removedFromGroup = removedFromGroup;
    if (newImages) command.newImages = newImages;
    if (newMainImage) command.newMainImage = newMainImage;
    if (removedImages) command.removedImages = removedImages;
    if (locationPrimaryDetailsUpdated)
      command.locationPrimaryDetailsUpdated = locationPrimaryDetailsUpdated;
    return command;
  }

  export interface RelocateAsset extends CommandBase {
    kind: Kind.RelocateAsset;
    fromLocationId: string;
    toLocation: LocationInfo.Encrypted;
  }
  export function relocateAsset(
    executerId: string,
    fromLocationId: string,
    toLocation: LocationInfo.Encrypted
  ): RelocateAsset {
    return {
      kind: Kind.RelocateAsset,
      executerId,
      fromLocationId,
      toLocation,
    };
  }
  export interface DeleteAsset extends CommandBase {
    kind: Kind.DeleteAsset;
  }
  export function deleteAsset(executerId: string): DeleteAsset {
    return {
      kind: Kind.DeleteAsset,
      executerId,
    };
  }

  export interface ArchiveAsset extends CommandBase {
    kind: Kind.ArchiveAsset;
  }
  export function archiveAsset(executerId: string): ArchiveAsset {
    return {
      kind: Kind.ArchiveAsset,
      executerId,
    };
  }
  export interface RestoreArchivedAsset extends CommandBase {
    kind: Kind.RestoreArchivedAsset;
  }
  export function restoreArchivedAsset(
    executerId: string
  ): RestoreArchivedAsset {
    return {
      kind: Kind.RestoreArchivedAsset,
      executerId,
    };
  }

  export interface CloseAsset extends CommandBase {
    kind: Kind.CloseAsset;
  }
  export function closeAsset(executerId: string): CloseAsset {
    return {
      kind: Kind.CloseAsset,
      executerId,
    };
  }

  export interface AddInsurance extends CommandBase {
    kind: Kind.AddInsurance;
    id: string;
  }
  export function addInsurance(executerId: string, id: string): AddInsurance {
    return {
      kind: Kind.AddInsurance,
      executerId,
      id,
    };
  }
  export interface RemoveInsurance extends CommandBase {
    kind: Kind.RemoveInsurance;
    id: string;
  }
  export function removeInsurance(
    executerId: string,
    id: string
  ): RemoveInsurance {
    return {
      kind: Kind.RemoveInsurance,
      executerId,
      id,
    };
  }
}

export namespace TxCommand {
  export enum Kind {
    AddTransaction = "AddTransaction",
    UpdateTransaction = "UpdateTransaction",
    DeleteTransaction = "DeleteTransaction",
  }

  export interface AddTransaction<Tx> extends CommandBase {
    kind: Kind.AddTransaction;
    parentId: string;
    id: string;
    data: Tx;
  }
  export function addTransaction<Tx>(
    executerId: string,
    parentId: string,
    id: string,
    data: Tx
  ): AddTransaction<Tx> {
    return {
      kind: Kind.AddTransaction,
      executerId,
      parentId,
      id,
      data,
    };
  }

  export interface UpdateTransaction<TxUpdate> extends CommandBase {
    kind: Kind.UpdateTransaction;
    parentId: string;
    id: string;
    update: TxUpdate;
  }
  export function updateTransaction<TxUpdate>(
    executerId: string,
    parentId: string,
    id: string,
    update: TxUpdate
  ): UpdateTransaction<TxUpdate> {
    return {
      kind: Kind.UpdateTransaction,
      executerId,
      parentId,
      id,
      update,
    };
  }

  export interface DeleteTransaction extends CommandBase {
    kind: Kind.DeleteTransaction;
    parentId: string;
    id: string;
  }
  export function deleteTransaction(
    executerId: string,
    parentId: string,
    id: string
  ): DeleteTransaction {
    return {
      kind: Kind.DeleteTransaction,
      executerId,
      parentId,
      id,
    };
  }
}

export namespace ValuationCommand {
  export enum Kind {
    AddValuation = "AddValuation",
    UpdateValuation = "UpdateValuation",
    DeleteValuation = "DeleteValuation",
  }

  export interface AddValuation extends CommandBase {
    kind: Kind.AddValuation;
    data: Valuation.Encrypted;
    updateAssetValue: boolean;
  }
  export function addValuation(
    executerId: string,
    data: Valuation.Encrypted,
    updateAssetValue: boolean
  ): AddValuation {
    return {
      kind: Kind.AddValuation,
      executerId,
      data,
      updateAssetValue,
    };
  }

  export interface UpdateValuation extends CommandBase {
    kind: Kind.UpdateValuation;
    id: string;
    update: UpdateObject<Valuation.UpdateEncrypted>;
    createdAfterValueSource?: boolean;
    latestValuation?: {
      id: string;
      value: Amount;
    };
  }
  export function updateValuation(
    executerId: string,
    id: string,
    update: UpdateObject<Valuation.UpdateEncrypted>,
    createdAfterValueSource?: boolean,
    latestValuation?: {
      id: string;
      value: Amount;
    }
  ): UpdateValuation {
    const command: UpdateValuation = {
      kind: Kind.UpdateValuation,
      executerId,
      id,
      update,
    };
    if (createdAfterValueSource)
      command.createdAfterValueSource = createdAfterValueSource;
    if (latestValuation) command.latestValuation = latestValuation;
    return command;
  }

  export interface DeleteValuation extends CommandBase {
    kind: Kind.DeleteValuation;
    id: string;
    linkedToId?: string;
    previousValue?: Amount;
    currentValue?: Amount;
    previousValuationName?: EncryptionField;
  }
  export function deleteValuation(
    executerId: string,
    id: string,
    linkedToId?: string,
    previousValue?: Amount,
    currentValue?: Amount,
    previousValuationName?: EncryptionField
  ): DeleteValuation {
    const command: DeleteValuation = {
      kind: Kind.DeleteValuation,
      executerId,
      id,
      previousValuationName,
    };
    if (linkedToId) command.linkedToId = linkedToId;
    if (previousValue) command.previousValue = previousValue;
    if (currentValue) command.currentValue = currentValue;
    return command;
  }
}

export namespace SellCommand {
  export enum Kind {
    MarkAsSold = "MarkAsSold",
    UpdateSoldInfo = "UpdateSoldInfo",
    DeleteSoldInfo = "DeleteSoldInfo",
  }

  export interface MarkAsSold extends CommandBase {
    kind: Kind.MarkAsSold;
    data: SoldInfo.Encrypted;
  }
  export function markAsSold(
    executerId: string,
    data: SoldInfo.Encrypted
  ): MarkAsSold {
    return {
      kind: Kind.MarkAsSold,
      executerId,
      data,
    };
  }

  export interface UpdateSoldInfo extends CommandBase {
    kind: Kind.UpdateSoldInfo;
    id: string;
    update: UpdateObject<SoldInfo.UpdateEncrypted>;
  }
  export function updateSoldInfo(
    executerId: string,
    id: string,
    update: UpdateObject<SoldInfo.UpdateEncrypted>
  ): UpdateSoldInfo {
    return {
      kind: Kind.UpdateSoldInfo,
      executerId,
      id,
      update,
    };
  }

  export interface DeleteSoldInfo extends CommandBase {
    kind: Kind.DeleteSoldInfo;
    id: string;
  }
  export function deleteSoldInfo(
    executerId: string,
    id: string
  ): DeleteSoldInfo {
    return {
      kind: Kind.DeleteSoldInfo,
      executerId,
      id,
    };
  }
}

export namespace ActionCommand {
  export enum Kind {
    AddAction = "AddAction",
    UpdateAction = "UpdateAction",
    DeleteAction = "DeleteAction",
  }

  type SupportedActionsEncrypted = Exclude<
    Action.Encrypted,
    Valuation.Encrypted | SoldInfo.Encrypted
  >;
  type SupportedActionsUpdate = Exclude<Action.Update, SoldInfo.Update>;
  export type AddData<T extends Action.Encrypted> = Extract<
    SupportedActionsEncrypted,
    T
  >;
  export type UpdateData<T extends Action.Update> = UpdateObject<
    Extract<SupportedActionsUpdate, T>
  >;

  export interface AddAction<T extends Action.Encrypted> extends CommandBase {
    kind: Kind.AddAction;
    data: AddData<T>;
  }
  export function addAction<T extends Action.Encrypted>(
    executerId: string,
    data: AddData<T>
  ): AddAction<T> {
    return {
      kind: Kind.AddAction,
      executerId,
      data,
    };
  }

  export interface UpdateAction<T extends Action.Update> extends CommandBase {
    kind: Kind.UpdateAction;
    id: string;
    update: UpdateData<T>;
  }
  export function UpdateAction<T extends Action.Update>(
    executerId: string,
    id: string,
    update: UpdateData<T>
  ): UpdateAction<T> {
    return {
      kind: Kind.UpdateAction,
      executerId,
      id,
      update,
    };
  }

  export interface DeleteAction extends CommandBase {
    kind: Kind.DeleteAction;
    id: string;
  }
  export function deleteAction(executerId: string, id: string): DeleteAction {
    return {
      kind: Kind.DeleteAction,
      executerId,
      id,
    };
  }
}
