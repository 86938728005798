import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M35%202H0v24h12V3h11v15h1V3h10v15.17a3%203%200%200%201%201%20.594V2ZM11%203v22H1V3h10ZM7.657%207l.707.707-5.657%205.657L2%2012.657%207.657%207Zm1.586%203.707L8.536%2010%205%2013.536l.707.707%203.536-3.536ZM30.657%2010l.707.707-5.657%205.657-.707-.707L30.657%2010Zm1.586%203.707L31.535%2013%2028%2016.535l.707.708%203.536-3.536ZM15%2034v-8h9v8h1v-8h8v8h2V21a2%202%200%200%200-2-2H15a2%202%200%200%200-2%202v13h2Zm10-10h8v-3h-8v3Zm-1-3h-9v3h9v-3Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function DanceRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M35 2H0v24h12V3h11v15h1V3h10v15.17c.373.132.712.336 1 .594V2ZM11 3v22H1V3h10ZM7.657 7l.707.707-5.657 5.657L2 12.657 7.657 7Zm1.586 3.707L8.536 10 5 13.536l.707.707 3.536-3.536ZM30.657 10l.707.707-5.657 5.657-.707-.707L30.657 10Zm1.586 3.707L31.535 13 28 16.535l.707.708 3.536-3.536ZM15 34v-8h9v8h1v-8h8v8h2V21a2 2 0 0 0-2-2H15a2 2 0 0 0-2 2v13h2Zm10-10h8v-3h-8v3Zm-1-3h-9v3h9v-3Z'
        }
      />
    </svg>
  )
}
