import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2035%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M10.992%203.893h14.199v4.733h-9.466V6.259h-4.733V3.893Zm14.134%2023.664h9.53v-2.514c0-5.146-5.502-9.318-10.648-9.318V9.81h-8.283v3.55h-1.183v4.795c5.2.543%209.975%204.581%2010.584%209.403Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function BootRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M10.992 3.893h14.199v4.733h-9.466V6.259h-4.733V3.893Zm14.134 23.664h9.53v-2.514c0-5.146-5.502-9.318-10.648-9.318V9.81h-8.283v3.55h-1.183v4.795c5.2.543 9.975 4.581 10.584 9.403Z'
        }
      />
    </svg>
  )
}
