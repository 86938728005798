import { useEffect } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { LikeRating, likeRatingValues, TastingNote } from 'core/remodel/types/actions/tastingNote'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormSelect,
  FormTextarea,
  SelectItem
} from '@/components/base'

export type TastingNoteValues = TastingNote.CreateFields

const defaultValues: Partial<TastingNoteValues> = {
  consumptionNotes: '',
  likeRating: LikeRating.Like,
  rating: 0,
  dateConsumed: new Date(),
  notes: ''
}

interface TastingFormProps {
  values?: TastingNoteValues
  onSubmit: SubmitHandler<TastingNoteValues>
  onCancel: () => void
}

export function TastingForm({ values, onSubmit, onCancel }: TastingFormProps) {
  const { t } = useTranslation()
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset
  } = useForm<TastingNoteValues>({ defaultValues })
  // preferences and options
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const likeRatingOptions = makeOptions(likeRatingValues, (key) => `collectables:LikeRatingOptions.${key}`)

  useEffect(() => {
    if (values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [values, reset])

  return (
    <form className={'space-y-4'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'grid grid-cols-1 gap-4 md:grid-cols-3'}>
        <FormInput
          className={'md:col-span-3'}
          control={control}
          name={'consumptionNotes'}
          label={t('collectables:Field.PrivateConsumptionNotes')}
          rules={{ required: true }}
        />
        <FormSelect control={control} name={'likeRating'} label={t('collectables:Field.DoYouLikeThisWine')}>
          {likeRatingOptions.map(({ label, value }) => (
            <SelectItem key={value} value={value}>
              {label}
            </SelectItem>
          ))}
        </FormSelect>

        <FormNumberInput
          control={control}
          name={'rating'}
          label={t('collectables:Field.Rating')}
          format={preferences.numberFormat}
          isAllowed={(values) => {
            const { floatValue } = values
            if (floatValue === undefined) return true
            return floatValue >= 0 && floatValue <= 100
          }}
          rules={{ required: true }}
        />

        <FormDatePicker
          control={control}
          name={'dateConsumed'}
          label={t('collectables:Field.DateConsumed')}
          format={preferences.dateFormat}
          timeZone={preferences.timeZone}
        />

        <FormTextarea className={'md:col-span-3'} control={control} name={'notes'} label={t('Field.Notes')} />
      </div>

      <fieldset className={'flex items-center justify-end gap-x-2 [&>button]:min-w-[130px]'} disabled={isSubmitting}>
        <Button variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative'} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{t('Submit')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
