export enum AssetType {
  CashAndBanking = "CashAndBanking",
  TraditionalInvestments = "TraditionalInvestments",
  OtherInvestment = "OtherInvestment",
  Cryptocurrency = "Cryptocurrency",
  Insurance = "Insurance",
  Property = "Property",
  Art = "Art",
  WineAndSpirits = "WineAndSpirits",
  OtherCollectables = "OtherCollectables",
  Belonging = "Belonging",
  //#NOTE it's a type not displayed on page
  WinePurchases = "WinePurchases",
  BankOrInstitution = "BankOrInstitution",
}

export enum CustomizedType { // for fields that shows search
  // Cash and Banking
  BankOrInstitution = "BankOrInstitution",
  InsuranceCompany = "InsuranceCompany",
  InsurancePolicyName = "InsurancePolicyName",
  // Art
  ArtArtistName = "ArtistName",
  ArtMedium = "ArtMedium",
  // WineAndSpirits
  WineAndSpiritsProducer = "WineAndSpiritsProducer",
  // OtherCollectables
  OtherCollectablesType = "OtherCollectablesType",
  OtherCollectablesBrand = "OtherCollectablesBrand",
  OtherCollectablesModel = "OtherCollectablesModel",
  // Belonging
  BelongingType = "BelongingType",
  BelongingBrand = "BelongingBrand",
  // Address Book
  ContactType = "ContactType",
}
