import { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ChevronRightIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { WithId } from 'core/remodel/types/common'
import type { Delegate, DelegateInvite } from 'core/remodel/types/delegates'
import type { Profile } from 'core/remodel/types/user'
import { fetchProfile, userQuery } from '@/api/AccountService'
import { delegateQuery, fetchDelegatesOf, fetchInvitations } from '@/api/DelegateService'
import { cn } from '@/utils/classnames'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Input,
  Modal,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import { GlobalSearch } from '@/components/GlobalSearch'
import {
  BillingIcon,
  CollapseIcon,
  ContactIcon,
  DelegateIcon,
  ExpandIcon,
  SignOutIcon,
  SupportIcon,
  UserIcon,
  WishlistIcon,
  XIcon
} from '@/components/icon'

import HelpCenter from './HelpCenter'

export default function NavBar({ className }: { className?: string }) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { canView } = useAuthStore((state) => state.permissions)
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))
  const { data: invitations } = useSWR([delegateQuery.invitations], fetchInvitations(database!))
  const { data: delegatesOf } = useSWR([delegateQuery.of], fetchDelegatesOf(database!))
  const [isSupportOpen, setIsSupportOpen] = useState(false)

  return (
    <>
      <header
        className={cn(
          'fixed left-0 top-0 z-40 w-full bg-background pl-4 pr-[calc(100%-100vw+16px)] shadow-lg',
          className
        )}
      >
        <div className={'flex h-[60px] items-center justify-between'}>
          <div className={'hidden items-center sm:flex'}>
            <Link className={'hidden sm:block'} href={'/'}>
              <Image src={'/images/myassets-logo.svg'} alt={'My Assets'} width={147} height={20} priority={true} />
            </Link>
            {/* Beta */}
            <div className={'ml-4 flex h-6 w-[63px] items-center justify-center rounded-[20px] bg-white px-4 py-1'}>
              <span className={'text-xs font-medium text-[#20253B]'}>{'BETA'}</span>
            </div>
          </div>

          <div className={'flex h-full w-full items-center justify-between gap-x-1.5 sm:justify-end sm:gap-x-4'}>
            {/* Global Search */}
            <div className={'max-w-[65%] sm:max-w-none'}>
              <GlobalSearch />
            </div>
            {/* Wishlist */}
            {canView('Wishlist') && (
              <Tooltip>
                <TooltipTrigger asChild={true}>
                  <Link href={'/wishlist'}>
                    <WishlistIcon className={'text-grey transition-colors hover:text-primary-hover'} />
                  </Link>
                </TooltipTrigger>
                <TooltipContent side={'bottom'} sideOffset={12}>
                  {t('Wishlist')}
                </TooltipContent>
              </Tooltip>
            )}

            {/* User Menu */}
            <UserMenu
              profile={profile}
              delegatesOf={delegatesOf}
              invitations={invitations}
              setIsSupportOpen={setIsSupportOpen}
            />
          </div>
        </div>
      </header>
      <HelpCenter isSupportOpen={isSupportOpen} setIsSupportOpen={setIsSupportOpen} />
    </>
  )
}

interface UserMenuProps {
  profile: Profile | undefined
  delegatesOf: WithId<Delegate.EncryptedDelegator>[] | undefined
  invitations: WithId<DelegateInvite>[] | undefined
  setIsSupportOpen: (isOpen: boolean) => void
}

function UserMenu({ profile, delegatesOf, invitations, setIsSupportOpen }: UserMenuProps) {
  const { t } = useTranslation()
  const { toast } = useToast()
  const router = useRouter()
  const logout = useAuthStore((state) => state.logout)
  const { delegatorId, changeToSelf, changeToDelegate } = useAuthStore((state) => state.permissions)
  const hasDelegates = delegatesOf && delegatesOf.length > 0
  const hasInvitations = invitations && invitations.length > 0
  const items = [
    {
      label: t('Profile'),
      url: '/account/profile',
      icon: <UserIcon className={'mr-2'} size={16} />,
      disabled: delegatorId !== null
    },
    {
      label: t('Delegates'),
      url: '/account/delegates',
      icon: <DelegateIcon className={'mr-2'} size={16} />,
      disabled: delegatorId !== null
    },
    {
      label: t('Subscription'),
      url: '/account/subscription',
      icon: <BillingIcon className={'mr-2'} size={16} />,
      disabled: delegatorId !== null
    },
    {
      label: t('Contacts'),
      url: '/account/contacts',
      icon: <ContactIcon className={'mr-2'} size={16} />
      // disabled: delegatorId !== null
    }

    // TODO: activity
    // {
    //   label: t('Activity'),
    //   url: '/account/activity',
    //   icon: <ActivityIcon className={'mr-2'} size={16} />,
    //   disabled: delegatorId !== null
    // }
  ]

  const handleLogout = async () => {
    try {
      await logout(false)
      toast({ variant: 'success', description: t('auth:Toast.Success.SignOut') })
      router.replace('/auth/login/')
    } catch (e) {
      if (e instanceof Error) {
        toast({ variant: 'error', description: e.message })
      }
    }
  }

  const handleChangeSelf = async () => {
    try {
      await changeToSelf()
      router.push('/setup')
      toast({ variant: 'success', description: 'Now viewing as yourself' })
    } catch (e) {
      if (e instanceof Error) {
        toast({ variant: 'error', description: e.message })
      }
    }
  }

  const handleChangeDelegate = async (delegate: WithId<Delegate.EncryptedDelegator>) => {
    try {
      await changeToDelegate(delegate.id)
      router.push('/setup')
      toast({ variant: 'success', description: `Now viewing as ${delegate.delegatorName}` })
    } catch (e) {
      if (e instanceof Error) {
        toast({ variant: 'error', description: e.message })
      }
    }
  }

  return (
    <div className={'relative'}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild={true}>
          <Avatar>
            <AvatarImage src={profile?.photo} alt={'avatar'} />
            <AvatarFallback className={'bg-[#6B7EA9]'}>
              <UserIcon />
            </AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>

        <DropdownMenuContent className={'mt-4 w-64 bg-background text-grey'} sideOffset={8} align={'end'}>
          <DropdownMenuItem
            className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
            onSelect={handleChangeSelf}
          >
            <div className={'relative shrink-0'}>
              <Avatar>
                <AvatarImage src={profile?.photo} alt={'avatar'} />
                <AvatarFallback className={'bg-[#6B7EA9]'}>
                  <UserIcon />
                </AvatarFallback>
              </Avatar>
              {delegatorId === null && (
                <div className={'absolute bottom-0 right-0 h-3 w-3 rounded-full bg-[#79CF1B]'} />
              )}
            </div>
            <div className={'flex flex-col items-stretch overflow-hidden'}>
              <TruncatedText as={'span'} className={' text-xl text-white'}>
                {profile?.name ?? t('Unknown')}
              </TruncatedText>
              <span className={'text-xs font-medium text-grey'}>{t('PersonalAccount')}</span>
            </div>
          </DropdownMenuItem>
          {hasDelegates && (
            <>
              <p className={'my-1 pl-2 text-xs'}>{t('DelegatesFor')}</p>
              {delegatesOf.map((delegate) => (
                <DropdownMenuItem
                  key={delegate.id}
                  className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
                  onSelect={() => handleChangeDelegate(delegate)}
                >
                  {/* FIXME avatar */}
                  <div className={'relative'}>
                    <Avatar>
                      <AvatarImage src={undefined} alt={'avatar'} />
                      <AvatarFallback className={'bg-[#6B7EA9]'}>
                        <UserIcon />
                      </AvatarFallback>
                    </Avatar>
                    {delegatorId === delegate.id && (
                      <div className={'absolute bottom-0 right-0 h-3 w-3 rounded-full bg-[#79CF1B]'} />
                    )}
                  </div>
                  <TruncatedText as={'span'} className={'text-sm text-white'}>
                    {delegate.delegatorName}
                  </TruncatedText>
                </DropdownMenuItem>
              ))}
            </>
          )}
          {hasInvitations && (
            <>
              <p className={'my-1 pl-2 text-xs'}>{'Invitation from:'}</p>
              {invitations.map((delegate) => (
                <DropdownMenuItem
                  key={delegate.id}
                  className={'gap-x-2 focus:bg-background-hover focus:text-secondary-hover'}
                  asChild={true}
                >
                  <Link href={{ pathname: '/identity/invitation/', query: { id: delegate.id } }}>
                    {/* FIXME avatar */}
                    <Avatar>
                      <AvatarImage src={undefined} alt={'avatar'} />
                      <AvatarFallback className={'bg-[#6B7EA9]'}>
                        <UserIcon />
                      </AvatarFallback>
                    </Avatar>
                    <TruncatedText as={'span'} className={'text-sm text-white'}>
                      {delegate.permissions.delegatorName}
                    </TruncatedText>
                  </Link>
                </DropdownMenuItem>
              ))}
            </>
          )}
          <DropdownMenuSeparator />
          {items.map((item, index) => (
            <DropdownMenuItem
              key={index}
              className={'focus:bg-background-hover focus:text-secondary-hover'}
              asChild={true}
              disabled={item.disabled}
            >
              <Link href={item.url}>
                {item.icon}
                <span>{item.label}</span>
              </Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem
            className={'focus:bg-background-hover focus:text-secondary-hover'}
            onSelect={() => setIsSupportOpen(true)}
          >
            <SupportIcon className={'mr-2'} size={16} />
            <span>{t('Support')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem className={'focus:bg-background-hover focus:text-secondary-hover'} onSelect={handleLogout}>
            <SignOutIcon className={'mr-2'} size={16} />
            <span>{t('SignOut')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
