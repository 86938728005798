import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { pageNames } from '@/constants/pageNames'

const siteConfig = {
  name: 'MyAssets',
  description: 'The Best Life Organizational Tool'
}

export function usePageInfo() {
  const router = useRouter()
  const { t, ready } = useTranslation()

  const pageMap: Record<string, string> = useMemo(() => {
    const translatedPageNames: Record<string, string> = {}
    for (const [path, elements] of Object.entries(pageNames)) {
      translatedPageNames[path] = elements.map((name) => t(name)).join(' ')
    }
    return translatedPageNames
  }, [t])

  const title: string = useMemo(() => {
    try {
      if (!ready) throw new Error('i18n not ready')
      const pageName = pageMap[router.pathname]
      if (!pageName) throw new Error(`not matched name '${router.pathname}'`)

      return router.pathname !== '/_error' ? `${pageName} - ${siteConfig.name}` : pageName
    } catch (e) {
      e instanceof Error && console.warn(e.message)
      return siteConfig.name
    }
  }, [ready, pageMap, router])

  return { title, desc: siteConfig.description }
}
