import { useEffect } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type Delegate, type PermissionLevel } from 'core/remodel/types/delegates'
import { PermissionLevelOptions } from 'core/remodel/types/options'
import { useAuthStore } from '@/store/authStore'
import { Button, FormInput, FormSelect, SelectItem } from '@/components/base'

type MapToString<T, U> = {
  [P in keyof T]: T[P] extends U ? `${number}` : T[P]
}

export type DelegateValues = MapToString<Delegate, PermissionLevel>
export type DelegateViewData = {
  [userID: string]: Delegate
}

const defaultValues: DelegateValues = {
  Finance: '0',
  Insurance: '0',
  Property: '0',
  Art: '0',
  WineAndSpirits: '0',
  OtherCollectables: '0',
  Belonging: '0',
  listProperty: '0',
  maxPermission: '0',
  status: 'inactive',
  email: '',
  role: '',
  name: '',
  delegatorName: ''
}

interface DelegateFormProps {
  mode?: 'Create' | 'Edit'
  values?: DelegateValues
  onSubmit: SubmitHandler<DelegateValues>
  onCancel: () => void
}

export function DelegateForm({ mode = 'Create', values, onSubmit, onCancel }: DelegateFormProps) {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    setValue
  } = useForm<DelegateValues>({ values, defaultValues })
  const database = useAuthStore((state) => state.database)
  const permissions = watch([
    'Finance',
    'Insurance',
    'Property',
    'Art',
    'WineAndSpirits',
    'OtherCollectables',
    'Belonging'
  ])

  useEffect(() => {
    const maxLevel = Math.max(...permissions.map((v) => +v))
    setValue('maxPermission', `${maxLevel}`)
    if (maxLevel > 0) {
      setValue('listProperty', '1')
    }
  }, [permissions, setValue])

  useEffect(() => {
    if (!database?.Account) return

    setValue('delegatorName', database?.Account.getUser().name ?? '')
  }, [database?.Account, setValue])

  return (
    <form className={'p-4'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'mb-4 space-y-8'}>
        {/* Name and Email */}
        <div className={'space-y-3'}>
          <h3 className={'font-bold text-[#20253B]'}>{t('delegates:Account')}</h3>
          <div className={'grid grid-cols-2 gap-x-4'}>
            <FormInput
              control={control}
              name={'name'}
              label={t('delegates:Field.DelegateName')}
              rules={{
                required: t('validation:SpecifyField', { field: '$t(delegates:Field.DelegateName)' })
              }}
            />
            <FormInput
              control={control}
              name={'email'}
              label={t('delegates:Field.DelegateEmail')}
              type={'email'}
              rules={{
                required: t('validation:SpecifyField', { field: '$t(delegates:Field.DelegateEmail)' })
              }}
              disabled={mode === 'Edit'}
            />
          </div>
        </div>

        {/* Role */}
        <div className={'space-y-3'}>
          <h3 className={'font-bold text-[#20253B]'}>{t('delegates:Role')}</h3>
          <p className={'text-[13px]'}>{t('delegates:RoleDesc')}</p>
          <FormInput
            control={control}
            name={'role'}
            label={t('delegates:Field.RoleName')}
            rules={{
              required: t('validation:SpecifyField', { field: '$t(delegates:Field.RoleName)' }),
              maxLength: {
                value: 50,
                message: t('validation:SymbolsLimit', {
                  field: '$t(delegates:Field.RoleName)',
                  number: 50
                })
              }
            }}
          />
        </div>

        {/* Permissions */}
        <div className={'space-y-3'}>
          <h3 className={'font-bold text-[#20253B]'}>{t('delegates:Permissions')}</h3>
          <div className={'grid grid-cols-2 gap-x-4 gap-y-8'}>
            <FormSelect control={control} name={'Finance'} label={t('delegates:Field.MyFinances')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'Insurance'} label={t('delegates:Field.Insurance')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'Property'} label={t('delegates:Field.MyProperties')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'Art'} label={t('delegates:Field.Art')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'WineAndSpirits'} label={t('delegates:Field.WineAndSpirits')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'OtherCollectables'} label={t('delegates:Field.OtherCollectables')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
            <FormSelect control={control} name={'Belonging'} label={t('delegates:Field.MyBelongings')}>
              {PermissionLevelOptions.map((option) => (
                <SelectItem
                  key={option.value}
                  value={`${option.value}`}
                  subText={t(`delegates:PermissionLevelSubText.${option.label}`)}
                >
                  {t(`delegates:PermissionLevel.${option.label}`)}
                </SelectItem>
              ))}
            </FormSelect>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <fieldset className={'flex justify-end gap-x-2'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative min-w-[130px]'} variant={'solid'} size={'md'} type={'submit'}>
          <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
          <span className={'group-disabled:opacity-0'}>
            {mode === 'Create' ? t('delegates:InviteDelegate') : t('Update')}
          </span>
        </Button>
      </fieldset>
    </form>
  )
}
