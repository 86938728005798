import { useCallback, useEffect, useMemo, useState } from 'react'
import { add } from 'date-fns'
import { Loader2Icon, Search } from 'lucide-react'
import { useController, useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AssetType, AttachmentKind, Currency, Period, periodValues } from 'core/remodel/types/common'
import { investmentStatusOptions, investmentTypeOptions } from 'core/remodel/types/options'
import { InvestmentStatus, InvestmentType, type OtherInvestment } from 'core/remodel/types/otherInvestments'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatDate, makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Autocomplete,
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  Input,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import NewAddress from '@/components/NewAddress'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type OtherInvestValues = OtherInvestment

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<OtherInvestValues> => ({
  assetType: AssetType.OtherInvestment,
  // primary details
  name: '',
  subtype: InvestmentType.Other,
  personalRefNo: '',
  investmentAmount: { currency, value: 0 },
  value: { currency, value: 0 },
  investmentDate: new Date(),
  groupIds: [],
  notes: '',
  // attributes
  interestRate: 0,
  maturityDate: new Date(),
  contactIds: [],
  attributeNotes: '',
  status: InvestmentStatus.Active,
  website: '',
  estimatedYield: 0,
  estimatedReturn: { currency, value: 0 },
  // ownership
  ownership: { myOwnership: 100, shareholder: [] },
  beneficiary: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

interface OtherInvestFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  values?: OtherInvestValues
  onSubmit: SubmitHandler<OtherInvestValues>
  onCancel: () => void
}

// FIXME Loan Investment Type
export function OtherInvestForm({ mode = 'Create', assetId, values, onCancel, onSubmit }: OtherInvestFormProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    setValue,
    reset,
    watch
  } = useForm<OtherInvestValues>({ defaultValues })
  const interestPeriod = watch('interestPeriod')
  const interestRate = watch('interestRate')
  const termPeriod = watch('term.period')
  const termNum = watch('term.num')
  const startDate = watch('startDate')
  const investmentAmount = watch('investmentAmount.value')
  const investmentType = watch('subtype')
  const myOwnership = watch('ownership.myOwnership')
  const shareholder = watch('ownership.shareholder')
  const beneficiary = watch('beneficiary')
  const contactArray = useController({ control, name: 'contactIds' })
  const shareholderArray = useFieldArray({
    control,
    name: 'ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  const dueDate = useMemo(() => {
    if (isNaN(termNum)) return
    switch (termPeriod) {
      case Period.Day:
        return add(startDate, { days: termNum })
      case Period.Week:
        return add(startDate, { weeks: termNum })
      case Period.Month:
        return add(startDate, { months: termNum })
      case Period.Quarter:
        return add(startDate, { months: termNum * 3 })
      case Period.Year:
        return add(startDate, { years: termNum })
    }
  }, [startDate, termNum, termPeriod])
  const annual = useMemo(() => {
    if (isNaN(interestRate)) return
    let result: number
    switch (interestPeriod) {
      case Period.Day:
        result = 365 * interestRate
        break
      case Period.Week:
        result = 52 * interestRate
        break
      case Period.Month:
        result = 12 * interestRate
        break
      case Period.Quarter:
        result = 4 * interestRate
        break
      case Period.Year:
        result = 1 * interestRate
        break
      default:
        return
    }

    return Number(result.toFixed(3))
  }, [interestPeriod, interestRate])
  const isCompany = investmentType === InvestmentType.PercentOfCompany
  const isShare = investmentType === InvestmentType.Share
  const isLoan = investmentType === InvestmentType.Loan
  const isWarrant = investmentType === InvestmentType.Warrant
  const isOption = investmentType === InvestmentType.Option
  const isOther = investmentType === InvestmentType.Other
  // preferences and options
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const displayContacts = useMemo(
    () => contactOptions?.filter((option) => contactArray.field.value?.includes(option.value)),
    [contactArray.field.value, contactOptions]
  )
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'attributes', label: t('Attributes') },
        { key: 'ownership', label: t('Ownership') }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  useEffect(() => {
    setValue('value.value', investmentAmount)
  }, [investmentAmount, setValue])

  useEffect(() => {
    const shouldReset = mode === 'Edit' && values !== undefined
    if (shouldReset) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  return (
    <>
      <TabPanel defaultValue={tabs[0].key}>
        {/* Left */}
        <TabPanel.SideNav tabs={tabs} />
        {/* Right */}
        <form
          className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'grow overflow-auto px-4 pt-4'}>
            {/* Primary */}
            <TabPanel.Section value={'primary'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormInput control={control} name={'name'} label={t('Field.Name')} rules={{ required: true }} />
                  <FormSelect control={control} name={'subtype'} label={t('finances:Field.InvestmentType')}>
                    {investmentTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(`finances:InvestmentTypeOptions.${label}`)}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  {isCompany && (
                    <>
                      <FormNumberInput
                        control={control}
                        name={'units'}
                        label={t('finances:Field.NumberOfUnits')}
                        format={preferences.numberFormat}
                        defaultValue={0}
                      />
                      <FormPercentInput
                        control={control}
                        name={'percentage'}
                        label={t('finances:InvestmentTypeOptions.PercentOfCompany')}
                        defaultValue={0}
                      />
                    </>
                  )}
                  {isShare && (
                    <>
                      <FormInput
                        control={control}
                        name={'shareType'}
                        label={t('finances:Field.ShareType')}
                        defaultValue={''}
                      />
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'pricePerShare.currency', value: 'pricePerShare.value' }}
                        label={t('finances:Field.PricePerShare')}
                        format={preferences.numberFormat}
                        digits={2}
                        defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
                      />
                      <FormNumberInput
                        control={control}
                        name={'shares'}
                        label={t('finances:Field.NumberOfShares')}
                        format={preferences.numberFormat}
                        defaultValue={0}
                      />
                    </>
                  )}
                  {(isWarrant || isOption) && (
                    <>
                      <FormInput
                        control={control}
                        name={'shareType'}
                        label={t('finances:Field.ShareType')}
                        defaultValue={''}
                      />
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'pricePerShare.currency', value: 'pricePerShare.value' }}
                        label={t('finances:Field.StrikePrice')}
                        format={preferences.numberFormat}
                        digits={2}
                        defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
                      />
                      <FormNumberInput
                        control={control}
                        name={'shares'}
                        label={t('finances:Field.NumberOfShares')}
                        format={preferences.numberFormat}
                        defaultValue={0}
                      />
                    </>
                  )}
                  {isLoan && (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'initialAmount.currency', value: 'initialAmount.value' }}
                        label={t('finances:Field.InitialAmount')}
                        format={preferences.numberFormat}
                        defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
                      />
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'value.currency', value: 'value.value' }}
                        label={t('finances:Field.OutstandingAmount')}
                        format={preferences.numberFormat}
                        defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
                      />
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'personalRefNo'}
                    label={t('Field.PersonalRefNo')}
                    placeholder={assetId ?? ''}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  {isLoan ? (
                    <>
                      <FormAutocomplete
                        control={control}
                        name={'borrowerId'}
                        label={t('finances:Field.Borrower')}
                        options={contactOptions}
                        isMulti={false}
                      />
                      <FormDatePicker
                        control={control}
                        name={'startDate'}
                        label={t('finances:Field.StartDate')}
                        format={preferences.dateFormat}
                        timeZone={preferences.timeZone}
                        defaultValue={new Date()}
                      />
                    </>
                  ) : (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'investmentAmount.currency', value: 'investmentAmount.value' }}
                        label={t('finances:Field.InvestmentAmount')}
                        format={preferences.numberFormat}
                        digits={2}
                      />
                      <FormPriceInput
                        control={control}
                        name={{ currency: 'value.currency', value: 'value.value' }}
                        label={t('Field.CurrentValuation')}
                        format={preferences.numberFormat}
                        digits={2}
                      />
                    </>
                  )}
                  {isOption && (
                    <FormDatePicker
                      control={control}
                      name={'vestingDate'}
                      label={t('finances:Field.VestingDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                      defaultValue={new Date()}
                    />
                  )}
                  {(isCompany || isShare || isOther) && (
                    <FormDatePicker
                      control={control}
                      name={'investmentDate'}
                      label={t('finances:Field.InvestmentDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                      defaultValue={new Date()}
                    />
                  )}
                  {isWarrant && (
                    <FormDatePicker
                      control={control}
                      name={'expirationDate'}
                      label={t('finances:Field.ExpirationDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                      defaultValue={new Date()}
                    />
                  )}
                  <FormAutocomplete
                    control={control}
                    name={'groupIds'}
                    label={t('Field.Groups')}
                    placeholder={t('TypeToSearch')}
                    options={groupOptions}
                    isMulti={true}
                    isLoading={loadingGroups}
                  />
                </div>
              </div>

              <div className={'col-span-2'}>
                <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
              </div>
            </TabPanel.Section>
            {/* Attributes */}
            <TabPanel.Section value={'additional.attributes'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  {isLoan ? (
                    <div className={'flex gap-x-1'}>
                      <div className={'grid grid-cols-1 gap-y-1'}>
                        <label className={'text-xs text-[#414554]'}>{t('finances:Field.InterestRate')}</label>
                        <div className={'flex gap-x-1'}>
                          <FormSelect
                            className={'shrink-0 basis-24'}
                            control={control}
                            name={'interestPeriod'}
                            defaultValue={Period.Month}
                          >
                            {periodOptions.map(({ label, value }) => (
                              <SelectItem key={value} value={value}>
                                {t(`PeriodOptions.${label}`)}
                              </SelectItem>
                            ))}
                          </FormSelect>
                          <FormPercentInput control={control} name={'interestRate'} defaultValue={0} />
                        </div>
                      </div>
                      <div className={'grid shrink-0 basis-20 grid-cols-1 gap-y-1 text-xs'}>
                        <label className={'text-[#414554]'}>{t('finances:Field.Annual')}</label>
                        <div className={'flex h-[38px] items-center justify-end rounded bg-primary px-2 text-white'}>
                          {`${annual}%`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FormPercentInput control={control} name={'interestRate'} label={t('finances:Field.Interest')} />
                  )}
                  <FormDatePicker
                    control={control}
                    name={'maturityDate'}
                    label={t('finances:Field.MaturityDate')}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  {isLoan && (
                    <div className={'flex gap-x-1'}>
                      <div className={'grid grid-cols-1 gap-y-1'}>
                        <label className={'text-xs text-[#414554]'}>{t('finances:Field.Term')}</label>
                        <div className={'flex gap-x-1'}>
                          <FormSelect
                            className={'shrink-0 basis-24'}
                            control={control}
                            name={'term.period'}
                            defaultValue={Period.Month}
                          >
                            {periodOptions.map(({ label, value }) => (
                              <SelectItem key={value} value={value}>
                                {t(`PeriodOptions.${label}`)}
                              </SelectItem>
                            ))}
                          </FormSelect>
                          <FormNumberInput
                            control={control}
                            name={'term.num'}
                            format={preferences.numberFormat}
                            defaultValue={0}
                          />
                        </div>
                      </div>
                      <div className={'grid shrink-0 basis-24 grid-cols-1 gap-y-1 text-xs'}>
                        <label className={'text-[#414554]'}>{t('finances:Field.DueDate')}</label>
                        <div className={'flex h-[38px] items-center justify-center rounded bg-primary text-white'}>
                          {dueDate ? formatDate(dueDate, preferences.dateFormat) : '-'}
                        </div>
                      </div>
                    </div>
                  )}
                  {(isShare || isOption) && (
                    <FormDatePicker
                      control={control}
                      name={'expirationDate'}
                      label={t('finances:Field.ExpirationDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                      defaultValue={new Date()}
                    />
                  )}
                  <div className={'grid grid-cols-1 gap-y-1'}>
                    <label className={'text-xs text-[#414554]'} htmlFor={'add-contacts'}>
                      {t('finances:AddContact')}
                    </label>
                    <Autocomplete
                      inputId={'add-contacts'}
                      instanceId={'add-contacts'}
                      components={{ IndicatorsContainer: () => <Search className={'mr-2 h-5 w-5 text-primary'} /> }}
                      options={contactOptions?.filter(({ value }) => !contactArray.field.value?.includes(value))}
                      value={{ label: '', value: '' }}
                      onChange={(option) => {
                        if (contactArray.field.value && option) {
                          const value = contactArray.field.value
                          const newValue = [...new Set([...value, option.value])]
                          contactArray.field.onChange(newValue)
                        }
                      }}
                      placeholder={t('finances:SearchContact')}
                      isLoading={loadingContacts}
                      isMulti={false}
                    />
                  </div>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => setIsOpen(true)}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('finances:CreateNewContact')}
                    </span>
                  </Button>
                  <ul className={'space-y-2'}>
                    {displayContacts?.map((option) => (
                      <li key={option.value} className={'flex items-center justify-between'}>
                        <p className={'text-sm'}>{option.label}</p>
                        <Button
                          className={'rounded-full p-1 hover:bg-grey/20'}
                          onClick={() => {
                            const value = contactArray.field.value ?? []
                            const newState = value.filter((val) => val !== option.value)
                            contactArray.field.onChange(newState)
                          }}
                        >
                          <XIcon className={'h-5 w-5 text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect control={control} name={'status'} label={t('Field.Status')}>
                    {investmentStatusOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {t(`finances:InvestmentStatus.${label}`)}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  {(isWarrant || isOption) && (
                    <FormDatePicker
                      control={control}
                      name={'investmentDate'}
                      label={t('finances:Field.InvestmentDate')}
                      format={preferences.dateFormat}
                      timeZone={preferences.timeZone}
                    />
                  )}
                  <FormInput control={control} name={'website'} label={t('finances:Field.Website')} />
                  <FormPercentInput
                    max={100000000000}
                    control={control}
                    name={'estimatedYield'}
                    label={t('finances:Field.EstimatedYield')}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'estimatedReturn.currency', value: 'estimatedReturn.value' }}
                    label={t('finances:Field.EstimatedReturn')}
                    format={preferences.numberFormat}
                    digits={2}
                  />
                </div>
              </div>

              <div className={'col-span-2'}>
                <FormTextarea control={control} name={'attributeNotes'} label={t('Field.Notes')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.ownership'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'space-y-4'}>
                  <div className={'grid gap-1'}>
                    <div className={'flex items-center justify-between pr-10'}>
                      <span className={'text-sm'}>{t('Field.Ownership')}</span>
                      {remainingOwnership === 0 && (
                        <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                      )}
                      {remainingOwnership > 0 && (
                        <span className={'text-xs text-[#d89423]'}>
                          {t('RemainingPercent', { percent: remainingOwnership })}
                        </span>
                      )}
                      {remainingOwnership < 0 && (
                        <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                      )}
                    </div>
                    <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <Input className={'h-[38px] border'} defaultValue={t('Field.MyOwnership')} disabled={true} />
                      <FormPercentInput control={control} name={`ownership.myOwnership`} />
                    </div>
                  </div>
                  <div className={'grid gap-1'}>
                    <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                    <ul className={'space-y-2 empty:hidden'}>
                      {shareholderArray.fields.map((shareholder, index) => (
                        <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                          <FormCreatableAutocomplete
                            control={control}
                            name={`ownership.shareholder.${index}.contactId`}
                            options={ShareholderUnselectContact(index)}
                            onCreate={addNewContact}
                            isLoading={loadingContacts}
                            placeholder={t('SearchOrCreate')}
                          />
                          <FormPercentInput control={control} name={`ownership.shareholder.${index}.percent`} />
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => shareholderArray.remove(index)}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className={'group justify-start gap-x-3 justify-self-start py-2'}
                      onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                    >
                      <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                        <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                      </div>
                      <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                        {t('AddShareholders')}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className={'space-y-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                    {remainingBeneficiary === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingBeneficiary > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingBeneficiary })}
                      </span>
                    )}
                    {remainingBeneficiary < 0 && (
                      <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                    )}
                  </div>
                  <ul className={'space-y-2 empty:hidden'}>
                    {beneficiaryArray.fields.map((beneficiary, index) => (
                      <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`beneficiary.${index}.contactId`}
                          options={beneficiaryUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => beneficiaryArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddBeneficiaries')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
              {assetId && (
                <AttachmentPanel
                  assetId={assetId}
                  control={control}
                  name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                  widgetOptions={[AttachmentKind.PrimaryDetails]}
                />
              )}
            </TabPanel.Section>
          </div>
          <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
            <Button
              id={'finances_add_other_investment_cancel'}
              className={'min-w-[130px]'}
              variant={'outline'}
              size={'md'}
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              id={'finances_add_other_investment_create'}
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
            </Button>
          </fieldset>
        </form>
      </TabPanel>

      <NewAddress
        isOpen={isOpen}
        onSubmitted={(id) => {
          const value = contactArray.field.value ?? []
          const newValue = [...new Set([...value, id])]
          contactArray.field.onChange(newValue)
        }}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export function OtherInvestFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
