import { useCallback, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AssetType } from 'core/remodel/types/enums'
import { commonQuery, fetchGlobalSearchResult, fetchMainImageUrlAndIv } from '@/api/CommonService'
import { infoPathnameMap, placeholderImageMap } from '@/constants/assets'
import { cn } from '@/utils/classnames'
import { useDebounce } from '@/hooks/useDebounce'
import { useAuthStore } from '@/store/authStore'
import { Avatar, AvatarImage, Button, Input } from '@/components/base'
import TruncatedText from '@/components/base/TruncatedText'
import { SearchIcon, XIcon } from '@/components/icon'

const newLocal = 'h-8 w-60 rounded-none bg-transparent p-0 text-black focus:ring-0'

const hasCreateButtonRoutes = [
  '/account/contacts',
  '/account/contacts/create',
  '/account/contacts/edit',
  '/account/delegates',
  '/account/delegates/create',
  '/account/delegates/edit',
  '/belongings/summary',
  '/belongings/summary/create',
  '/belongings/summary/list',
  '/collectables/art',
  '/collectables/art/create',
  '/collectables/art/list',
  '/collectables/other',
  '/collectables/other/create',
  '/collectables/other/list',
  '/collectables/wine',
  '/collectables/wine/create',
  '/collectables/wine/list',
  '/documents/summary',
  '/documents/summary/create',
  '/finances/accounts',
  '/finances/accounts/create',
  '/finances/crypto',
  '/finances/crypto/create',
  '/finances/insurance',
  '/finances/insurance/create',
  '/finances/other-invest',
  '/finances/other-invest/create',
  '/finances/tradit-invest',
  '/finances/tradit-invest/create',
  '/groups/summary',
  '/properties/summary',
  '/properties/summary/create',
  '/properties/summary/list',
  '/wishlist'
]

interface GlobalSearchProps {
  onClose?: () => void
}

export function GlobalSearch({ onClose }: GlobalSearchProps) {
  const router = useRouter()
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLDivElement>(null)
  const keyword = useDebounce(value, 500)
  const database = useAuthStore((state) => state.database)
  const { data = [] } = useSWR(
    keyword.length > 0 && [commonQuery.globalSearch, keyword],
    fetchGlobalSearchResult(database!)
  )
  const hasCreateButton = hasCreateButtonRoutes.includes(router.pathname)

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value
    setValue(keyword)
    setIsOpen(keyword.length > 0)
  }, [])

  const toggleSearch = () => {
    setShowSearch(!showSearch)
    if (!showSearch) {
      setValue('')
      setIsOpen(false)
    } else {
      onClose?.()
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowSearch(false)
        setIsOpen(false)
        setValue('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div ref={searchRef} className={cn('relative', hasCreateButton && 'mr-16')}>
      <div className={cn('transition-all duration-300 ease-in-out', showSearch ? 'w-60' : 'w-8')}>
        {showSearch ? (
          <div
            className={
              'relative flex h-8 w-full items-center self-center rounded-sm bg-white p-2 transition-opacity duration-300'
            }
          >
            <Input
              className={newLocal}
              value={value}
              onChange={handleInputChange}
              placeholder={t('TypeToSearch')}
              onFocus={() => setIsOpen(true)}
            />
            <div className={'absolute right-1 flex'}>
              {value ? (
                <Button onClick={() => setValue('')}>
                  <XIcon className={'text-text-gray hover:text-text/40'} size={22} />
                </Button>
              ) : (
                <div className={'w-6'} />
              )}
              <Button onClick={toggleSearch}>
                <SearchIcon className={'text-primary hover:text-primary-hover'} strokeWidth={3} size={20} />
              </Button>
            </div>
          </div>
        ) : (
          <Button
            onClick={toggleSearch}
            className={'text-grey transition-opacity duration-300 hover:text-primary-hover'}
          >
            <SearchIcon className={'mt-1 hover:text-primary-hover'} strokeWidth={3} size={24} />
          </Button>
        )}
      </div>
      {isOpen && showSearch && (
        <menu
          ref={menuRef}
          className={'absolute top-9 w-full overflow-y-auto rounded-sm border-grey bg-gray-100 text-sm shadow-lg'}
          onClick={() => setIsOpen(false)}
        >
          {data.length > 0
            ? data.map((item, index) => (
                <SearchItem key={item.id} className={cn(index > 0 && 'border-t border-grey')} {...item} />
              ))
            : value && <div className={'py-4 text-center font-medium text-text/70'}>{t('NoData')}</div>}
        </menu>
      )}
    </div>
  )
}

export interface SearchItemProps {
  id: string
  assetType: AssetType
  subType: string
  name: string
  className?: string
}

function SearchItem({ id, name, assetType, className, subType }: SearchItemProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data } = useSWR([commonQuery.mainImageUrlIv, assetType, id], fetchMainImageUrlAndIv(database!))
  const { url = placeholderImageMap[assetType], iv } = data ?? {}

  const query = assetType === AssetType.CashAndBanking ? { id, type: subType } : { id }


  return (
    <Link href={{ pathname: infoPathnameMap[assetType], query: query }}>
      <div className={cn('group flex gap-2 bg-transparent py-2 pl-4 hover:bg-grey/30', className)}>
        <Avatar>
          <AvatarImage src={url} base64IV={iv} alt={'Thumbnail'} className={'object-cover'} />
        </Avatar>
        <div className={'ml-1 grid justify-center'}>
          <TruncatedText className={'text-sm font-medium text-black'}>{name}</TruncatedText>
          <TruncatedText className={'text-xs font-medium text-text/60'}>
            {t(`AssetTypeOptions.${assetType}`)}
          </TruncatedText>
        </div>
      </div>
    </Link>
  )
}
