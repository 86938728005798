import { useEffect } from 'react'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, fetchProfile, userQuery } from '@/api/AccountService'
import { useAuthStore } from '@/store/authStore'

if (typeof window !== 'undefined') {
  // window.AppcuesSettings = { enableURLDetection: true }
}

/**
 * @see https://studio.appcues.com to manage Appcues account
 * @see https://docs.appcues.com to implement
 * @see https://myassets.atlassian.net/browse/MPT-8022 to check data of identify()
 */
export default function AppcuesIdentifier() {
  const database = useAuthStore((state) => state.database)
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))

  useEffect(() => {
    if (!database || !profile) return

    const userId = database.userId
    const identifyData = {
      name: profile.name,
      email: profile.email,
      language: preferences.baseLanguage,
      baseCurrency: preferences.baseCurrency
    }

    const identifyUser = (userId: string, data: Record<string, any>) => {
      const Appcues = window['Appcues' as keyof (Window & typeof globalThis)]

      if (Appcues != undefined && Appcues != null) {
        Appcues.identify(userId, data)
      } else {
        const appcuesId = process.env.NEXT_PUBLIC_APPCUES_ID ?? ''
        newScript(`//fast.appcues.com/${appcuesId}.js`)
          .then(() => {
            // then recursively call identifyUser to initialize the identity of the user
            identifyUser(userId, data)
          })
          .catch((e) => console.log('identifyUser: error on loading script'))
      }
    }

    identifyUser(userId, identifyData)
  }, [database, profile, preferences.baseCurrency, preferences.baseLanguage])

  return <></>
}

function newScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve('')
    script.onerror = () => reject('')
    document.body.appendChild(script)
  })
}
