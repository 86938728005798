import type { ActionCodeInfo, ApplicationVerifier, MultiFactorResolver, PhoneInfoOptions, User } from 'firebase/auth'
import { produce } from 'immer'
import { mutate } from 'swr'
import { create } from 'zustand'

import { Database } from 'core/remodel/database'
import { PermissionCategory } from 'core/remodel/refPaths'
import type { WithId } from 'core/remodel/types/common'
import { Delegate, PermissionLevel } from 'core/remodel/types/delegates'
import { userQuery } from '@/api/AccountService'
import { delegateQuery, fetchDelegatesOf } from '@/api/DelegateService'
import type { PopupMethod } from '@/types/common'
import type { ForgotValues, LoginValues, ResetValues, SignUpValues } from '@/components/form'
import { firebase } from '@/utils/firebase'

type PickByType<T, U> = {
  [K in keyof T as T[K] extends U ? K : never]: T[K]
}
type Permissions = Omit<PickByType<Delegate, PermissionLevel>, 'listProperty' | 'maxPermission'>
type DelegateType = keyof Permissions
type ViewDelegateType =
  | DelegateType
  | 'GlobalDashboard'
  | 'MyFinance'
  | 'MyCollectables'
  | 'Groups'
  | 'DocumentVault'
  | 'Wishlist'

interface PermissionState {
  delegatorId: string | null
  activeList: WithId<Delegate.EncryptedDelegator>[]
  changeToSelf: () => Promise<void>
  changeToDelegate: (id: string) => Promise<void>
  canView: (type: ViewDelegateType) => boolean
  canEdit: (type: DelegateType) => boolean
  canCreate: (type: DelegateType) => boolean
  canDelete: (type: DelegateType) => boolean
}

interface AuthState {
  status: 'uninitialized' | 'initializing' | 'failed' | 'ready' | 'unverified' | 'unauthenticated' | 'sessionExpired'
  error: string | null
  database: Database | null
  permissions: PermissionState
  isSetupDone: boolean
  login: (data: LoginValues, redirectTo?: string) => Promise<User>
  loginWithPopup: (method: 'Google' | 'Apple') => Promise<User>
  loginWithSms: (verificationId: string, verificationCode: string) => Promise<User>
  loginWithBackupCode: (token: string) => Promise<User>
  getMultiFactorResolver: () => MultiFactorResolver | undefined
  generateRecaptcha: (elementId: string) => ApplicationVerifier
  sendVerificationCode: (options: PhoneInfoOptions, verifier: ApplicationVerifier) => Promise<string>
  logout: (isExpired: boolean) => Promise<void>
  signUp: (data: SignUpValues) => Promise<User>
  resend: (email: string, name: string) => Promise<void>
  forgot: (data: ForgotValues) => Promise<void>
  reset: (data: ResetValues) => Promise<void>
  checkCodeValid: (oobCode: string) => Promise<ActionCodeInfo>
  checkUserExist: (email: string) => Promise<boolean>
  verifyEmail: (oobCode: string) => Promise<void>
  verifyInvite: (inviteId: string, email: string) => Promise<boolean>
}

export const useAuthStore = create<AuthState>((set, get) => ({
  status: 'uninitialized',
  error: null,
  database: null,
  permissions: {
    delegatorId: null,
    activeList: [],
    changeToSelf: async () => {
      if (!['initializing', 'ready'].includes(get().status)) return undefined

      const delegatesOf = await fetchDelegatesOf(get().database!)([delegateQuery.of])
      const activeList = delegatesOf.filter(({ status }) => status === 'active')

      sessionStorage.removeItem(delegateStorageKey(get().database!.userId))
      await get().database!.switchToDelegate(null)
      await clearCache()
      set(
        produce((state) => {
          state.isSetupDone = false
          state.permissions.delegatorId = null
          state.permissions.activeList = activeList
        })
      )
    },
    changeToDelegate: async (delegatorId: string) => {
      if (!['initializing', 'ready'].includes(get().status)) return undefined

      const delegatesOf = await fetchDelegatesOf(get().database!)([delegateQuery.of])
      const activeList = delegatesOf.filter(({ status }) => status === 'active')
      const delegate = activeList.find(({ id }) => id === delegatorId)

      if (!delegate) {
        set(
          produce((state) => {
            state.isSetupDone = false
            state.permissions.activeList = activeList
          })
        )
        throw new Error('Invalid delegate')
      } else {
        sessionStorage.setItem(delegateStorageKey(get().database!.userId), JSON.stringify(delegatorId))
        await get().database!.switchToDelegate({ id: delegatorId, permissions: delegate })
        await clearCache()
        set(
          produce((state) => {
            state.isSetupDone = false
            state.permissions.delegatorId = delegatorId
            state.permissions.activeList = activeList
          })
        )
      }
    },
    canView: (kind: ViewDelegateType) => {
      const delegatorId = get().permissions.delegatorId
      const delegator = get().permissions.activeList.find(({ id }) => id === delegatorId)

      if (get().status !== 'ready') return false
      if (delegatorId === null) return true
      if (!delegator) return false
      return checkView(kind, delegator)
    },
    canEdit: (kind: DelegateType) => {
      const delegatorId = get().permissions.delegatorId
      const delegator = get().permissions.activeList.find(({ id }) => id === delegatorId)

      if (get().status !== 'ready') return false
      if (delegatorId === null) return true
      if (!delegator) return false
      return delegator[kind] > PermissionLevel.View
    },
    canCreate: (kind: DelegateType) => {
      const delegatorId = get().permissions.delegatorId
      const delegator = get().permissions.activeList.find(({ id }) => id === delegatorId)

      if (get().status !== 'ready') return false
      if (delegatorId === null) return true
      if (!delegator) return false
      return delegator[kind] > PermissionLevel.Edit
    },
    canDelete: (kind: DelegateType) => {
      const delegatorId = get().permissions.delegatorId
      const delegator = get().permissions.activeList.find(({ id }) => id === delegatorId)

      if (get().status !== 'ready') return false
      if (delegatorId === null) return true
      if (!delegator) return false
      return delegator[kind] > PermissionLevel.Create
    }
  },
  isSetupDone: false,
  login: async (data) => {
    const { user } = await firebase.signIn(data.email, data.password)
    return user
  },
  loginWithPopup: async (method: PopupMethod) => {
    switch (method) {
      case 'Google': {
        const { user } = await firebase.signInWithGoogle()
        return user
      }
      case 'Apple': {
        const { user } = await firebase.signInWithApple()
        return user
      }
    }
  },
  loginWithSms: async (verificationId, verificationCode) => {
    const { user } = await firebase.signInWithSms(verificationId, verificationCode)
    return user
  },
  loginWithBackupCode: async (backupCode) => {
    const { user } = await firebase.signInWithBackupCode(backupCode)
    return user
  },
  getMultiFactorResolver: () => firebase.getMultiFactorResolver(),
  generateRecaptcha: (elementId) => firebase.generateRecaptcha(elementId),
  sendVerificationCode: async (options, verifier) => firebase.sendVerificationCode(options, verifier),
  logout: async (expired: boolean) => {
    if (expired) {
      set(
        produce((state) => {
          state.status = 'sessionExpired'
        })
      )
    }
    if (get().database) {
      sessionStorage.removeItem(delegateStorageKey(get().database!.userId))
    }
    await firebase.signOut()
    await mutate(() => true, undefined, { revalidate: false })
  },
  signUp: async ({ email, password, name }) => {
    const { user } = await firebase.signUp(email, password, name)
    return user
  },
  resend: async (email, name) => {
    await firebase.resend(email, name)
  },
  forgot: async ({ email }) => firebase.forgot(email),
  reset: async ({ oobCode, newPassword }) => firebase.reset(oobCode, newPassword),
  checkCodeValid: async (oobCode) => firebase.checkCodeValid(oobCode),
  checkUserExist: async (email) => firebase.checkUserExist(email),
  verifyEmail: async (oobCode) => firebase.verifyEmail(oobCode),
  verifyInvite: async (inviteId, email) => firebase.verifyDelegateInvite(inviteId, email),
}))

firebase.onAuthChanged(async (user) => {
  if (!user) {
    useAuthStore.setState(
      produce((state: AuthState) => {
        if (state.status !== 'sessionExpired') {
          state.status = 'unauthenticated'
        }
        state.database = null
        state.permissions.delegatorId = null
        state.permissions.activeList = []
      })
    )
  } else {
    //HACK For show userID
    console.log('userID: ', user!.uid)
    try {
      // database
      const database = await firebase.newDatabase()
      await database.prepareData()
      useAuthStore.setState((_state) => ({ status: 'initializing', database }))

      if (!user.emailVerified) {
        useAuthStore.setState((state) => ({ ...state, status: 'unverified' }))
      } else {
        // delegate
        const { changeToSelf, changeToDelegate, delegatorId } = useAuthStore.getState().permissions
        const raw = sessionStorage.getItem(delegateStorageKey(user!.uid))
        const prevDelegator = raw ? (JSON.parse(raw) as string) : null

        if (!prevDelegator) {
          await changeToSelf()
        } else {
          await changeToDelegate(prevDelegator)
        }
        firebase.setAnalyticsUserProperties({ delegatorId })
        useAuthStore.setState((state) => ({ ...state, status: 'ready' }))
      }
    } catch (e: any) {
      useAuthStore.setState((_state) => ({ status: 'failed', error: e.toString(), database: null }))
    }
  }
})

function delegateStorageKey(userId: string): string {
  return `${userId}:delegateId`
}

function checkView(kind: ViewDelegateType, delegator: WithId<Delegate.EncryptedDelegator>): boolean {
  switch (kind) {
    case 'GlobalDashboard':
    case 'Groups': {
      const level = Math.max(
        delegator.Finance,
        delegator.Property,
        delegator.Art,
        delegator.WineAndSpirits,
        delegator.OtherCollectables,
        delegator.Belonging
      )
      return level > PermissionLevel.None
    }
    case 'MyFinance': {
      const level = Math.max(delegator.Finance, delegator.Insurance)
      return level > PermissionLevel.None
    }
    case 'MyCollectables': {
      const level = Math.max(delegator.Art, delegator.WineAndSpirits, delegator.OtherCollectables)
      return level > PermissionLevel.None
    }
    case 'DocumentVault': {
      return true
    }
    case 'Wishlist': {
      return false
    }
    case PermissionCategory.Finance:
    case PermissionCategory.Insurance:
    case PermissionCategory.Property:
    case PermissionCategory.Art:
    case PermissionCategory.Wine:
    case PermissionCategory.OtherCollectables:
    case PermissionCategory.Belonging: {
      return delegator[kind] > PermissionLevel.None
    }
  }
}

async function clearCache() {
  const queries = [...Object.values(userQuery), ...Object.values(delegateQuery)]
  await mutate(
    (k) => {
      const isArray = Array.isArray(k)
      return isArray && !queries.includes(k[0])
    },
    undefined,
    { revalidate: true }
  )
}
