import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M28.487%207.931c.507%200%20.971.517%201.037%201.155l.237%202.31a1.024%201.024%200%200%201-1.037%201.156h-2.31a1.024%201.024%200%200%201-1.037-1.155l.237-2.31c.066-.639.53-1.156%201.037-1.156h-3.125L25.366%201h4.405l1.841%206.931h-3.125Zm-10.16%2010.166c0-.638-.517-1.156-1.155-1.156h-2.31v-4.62h1.155c.638%200%201.155-.518%201.155-1.155V4.233c0-.638-.517-1.155-1.155-1.155H3.31c-.638%200-1.155.517-1.155%201.155v6.931c0%20.639.517%201.156%201.155%201.156h1.156v4.62h-2.31c-.639%200-1.156.518-1.156%201.156v2.31c0%20.638.517%201.155%201.155%201.155V31.96c0%20.638.517%201.155%201.155%201.155h1.156c.638%200%201.155-.517%201.155-1.155V21.562h8.086V31.96c0%20.638.517%201.155%201.155%201.155h1.155c.638%200%201.155-.517%201.155-1.155V21.562c.638%200%201.156-.517%201.156-1.155v-2.31Zm-5.775-5.776v4.62H6.776v-4.62h5.776Zm13.862%2018.482V16.941h-4.62a1.155%201.155%200%200%201-1.156-1.155v-1.155c0-.638.517-1.155%201.155-1.155h11.552c.638%200%201.155.517%201.155%201.155v1.155c0%20.638-.517%201.155-1.155%201.155h-4.62v13.862h3.465v2.31h-9.242v-2.31h3.466Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function ParlourIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M28.487 7.931c.507 0 .971.517 1.037 1.155l.237 2.31a1.024 1.024 0 0 1-1.037 1.156h-2.31a1.024 1.024 0 0 1-1.037-1.155l.237-2.31c.066-.639.53-1.156 1.037-1.156h-3.125L25.366 1h4.405l1.841 6.931h-3.125Zm-10.16 10.166c0-.638-.517-1.156-1.155-1.156h-2.31v-4.62h1.155c.638 0 1.155-.518 1.155-1.155V4.233c0-.638-.517-1.155-1.155-1.155H3.31c-.638 0-1.155.517-1.155 1.155v6.931c0 .639.517 1.156 1.155 1.156h1.156v4.62h-2.31c-.639 0-1.156.518-1.156 1.156v2.31c0 .638.517 1.155 1.155 1.155V31.96c0 .638.517 1.155 1.155 1.155h1.156c.638 0 1.155-.517 1.155-1.155V21.562h8.086V31.96c0 .638.517 1.155 1.155 1.155h1.155c.638 0 1.155-.517 1.155-1.155V21.562c.638 0 1.156-.517 1.156-1.155v-2.31Zm-5.775-5.776v4.62H6.776v-4.62h5.776Zm13.862 18.482V16.941h-4.62a1.155 1.155 0 0 1-1.156-1.155v-1.155c0-.638.517-1.155 1.155-1.155h11.552c.638 0 1.155.517 1.155 1.155v1.155c0 .638-.517 1.155-1.155 1.155h-4.62v13.862h3.465v2.31h-9.242v-2.31h3.466Z'
        }
      />
    </svg>
  )
}
