import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ddd%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cpath%20d%3D%22M29.025%2015.06C28.005%209.885%2023.46%206%2018%206c-4.335%200-8.1%202.46-9.975%206.06A8.991%208.991%200%200%200%200%2021c0%204.965%204.035%209%209%209h19.5c4.14%200%207.5-3.36%207.5-7.5%200-3.96-3.075-7.17-6.975-7.44ZM21%2019.5v6h-6v-6h-4.5L18%2012l7.5%207.5H21Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function UploadCloudIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      fill={'currentColor'}
      viewBox={'0 0 36 36'}
      width={size}
      height={size}
      {...props}
    >
      <path
        d={
          'M29.025 15.06C28.005 9.885 23.46 6 18 6c-4.335 0-8.1 2.46-9.975 6.06A8.991 8.991 0 0 0 0 21c0 4.965 4.035 9 9 9h19.5c4.14 0 7.5-3.36 7.5-7.5 0-3.96-3.075-7.17-6.975-7.44ZM21 19.5v6h-6v-6h-4.5L18 12l7.5 7.5H21Z'
        }
      />
    </svg>
  )
}
