import { useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { contactTypeValues, type Contact } from 'core/remodel/types/contact'
import { CustomizedType } from 'core/remodel/types/enums'
import { CountryOptions } from 'core/remodel/types/options'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { cn } from '@/utils/classnames'
import { makeOptions, sortAlphabetically } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, FormAutocomplete, FormCreatableAutocomplete, FormInput } from '@/components/base'
import { StarIcon } from '@/components/icon'
import { Place, PlacesAutocomplete } from '@/components/PlacesAutocomplete'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type ContactValues = Contact.CreateFields

const defaultValues: Partial<ContactValues> = {
  firstName: '',
  lastName: '',
  contactType: undefined,
  contactCompany: '',
  position: '',
  email: '',
  phone: '',
  address: '',
  addressLine1: '',
  addressLine2: '',
  town: '',
  state: '',
  zipCode: '',
  country: undefined
}

interface ContactFormProps {
  mode?: 'create' | 'edit'
  values?: ContactValues
  onCancel: () => void
  onSubmit: SubmitHandler<ContactValues>
}

export function ContactForm({ mode = 'create', values, onCancel, onSubmit }: ContactFormProps) {
  const { t } = useTranslation()
  // form
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    setValue
  } = useForm<ContactValues>({ values, defaultValues })

  const database = useAuthStore((state) => state.database)
  const contactTypeSWR = useSWR([typeQuery.contactType, CustomizedType.ContactType], fetchCustomTypes(database!))
  const { data: contactTypes, isValidating: loadingContactTypes } = contactTypeSWR
  const contactTypeOptions = useMemo(() => {
    const base = makeOptions(contactTypeValues, (key) => `account:ContactTypeOptions.${key}`)
    const added = contactTypes?.map((contactType) => ({ label: contactType, value: contactType })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [contactTypes])

  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('account:PrimaryContactDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    }
  ]

  const handleSelectPlace = (place: Place) => {
    setValue('address', place.address)
    setValue('center', place.center)
    setValue('addressLine1', place.addressLine1)
    setValue('addressLine2', place.addressLine2)
    setValue('town', place.town)
    setValue('state', place.state)
    setValue('zipCode', place.zipCode)
    setValue('country', place.country)
  }

  const cleanAddress = () => {
    setValue('address', '')
    setValue('center', undefined)
  }

  return (
    <TabPanel defaultValue={'primary'}>
      <TabPanel.SideNav tabs={tabs} />
      <form
        className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormInput
                  control={control}
                  name={'firstName'}
                  label={t('account:Field.FirstName')}
                  type={'text'}
                  rules={{ required: t('validation:Required') }}
                />
                <FormInput control={control} name={'lastName'} label={t('account:Field.LastName')} type={'text'} />
                <FormCreatableAutocomplete
                  control={control}
                  name={'contactType'}
                  label={t('account:Field.ContactType')}
                  placeholder={t('account:SearchOrCreateContactType')}
                  options={contactTypeOptions}
                  onCreate={async (type) => await addCustomType(database!, CustomizedType.ContactType, type)}
                  isLoading={loadingContactTypes}
                />
                <FormInput
                  control={control}
                  name={'contactCompany'}
                  label={t('account:Field.ContactCompany')}
                  type={'text'}
                />
                <FormInput control={control} name={'position'} label={t('account:Field.Position')} type={'text'} />
                <FormInput control={control} name={'email'} label={t('account:Field.Email')} type={'email'} />
                <FormInput control={control} name={'phone'} label={t('account:Field.PhoneNumber')} type={'tel'} />
              </div>
              <div className={'flex flex-col gap-4'}>
                <PlacesAutocomplete
                  control={control}
                  name={'address'}
                  label={t('account:Field.AddressSearch')}
                  onSelected={handleSelectPlace}
                />
                <FormInput
                  control={control}
                  name={'addressLine1'}
                  label={t('account:Field.AddressLine1')}
                  type={'text'}
                />
                <FormInput
                  control={control}
                  name={'addressLine2'}
                  label={t('account:Field.AddressLine2')}
                  type={'text'}
                />
                <FormInput
                  control={control}
                  name={'town'}
                  label={t('account:Field.Town')}
                  type={'text'}
                  onChanged={cleanAddress}
                />
                <FormInput
                  control={control}
                  name={'state'}
                  label={t('account:Field.State')}
                  type={'text'}
                  onChanged={cleanAddress}
                />
                <FormInput control={control} name={'zipCode'} label={t('account:Field.ZipCode')} type={'text'} />
                <FormAutocomplete
                  control={control}
                  name={'country'}
                  label={t('account:Field.Country')}
                  placeholder={t('account:Placeholder.ChooseCountry')}
                  options={CountryOptions}
                  onChanged={cleanAddress}
                />
              </div>
            </div>
          </TabPanel.Section>
        </div>
        <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button className={'group relative min-w-[130px]'} variant={'solid'} size={'md'} type={'submit'}>
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function ContactFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
