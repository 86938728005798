import { useEffect } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Account } from 'core/remodel/types/cashAndBanking'
import type { PlaidAccount } from 'core/remodel/types/postgres'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { formatNumber, makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, FormSelect, SelectItem } from '@/components/base'
import { ArrowRightIcon } from '@/components/icon'

export type PlaidValues = {
  institution: string
  accounts: {
    data: PlaidAccount
    type: Account.Type
  }[]
}

const defaultValues: PlaidValues = {
  institution: '',
  accounts: []
}

const options = makeOptions(
  Account.typeValues.filter((type) => type !== Account.Type.Cash),
  (key) => `finances:AccountType.${key}`
)

interface PlaidFormProps {
  values?: PlaidValues
  onSubmit: SubmitHandler<PlaidValues>
  onCancel: () => void
}

export function PlaidForm({ values, onSubmit, onCancel }: PlaidFormProps) {
  const { t } = useTranslation()
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset
  } = useForm<PlaidValues>({ defaultValues })
  const institution = watch('institution')
  const fields = watch('accounts')
  // preferences and options
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))

  useEffect(() => {
    if (values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [reset, values])

  return (
    <form className={'space-y-4'} onSubmit={handleSubmit(onSubmit)}>
      <p className={'text-sm'}>{t('finances:MapImportedBank')}</p>
      {/* bank info */}
      <div className={'space-y-1 rounded border border-[#DFE1E5] px-5 py-2'}>
        <p className={'text-xs text-[#6D83AC]'}>{t('finances:Field.BankName')}</p>
        <p className={'text-sm font-medium'}>{institution ?? '-'}</p>
      </div>
      {/* header */}
      <div className={'grid grid-cols-[1fr_240px] gap-x-8 border-x border-transparent px-4 pt-4'}>
        <p className={'text-sm text-[#20253B]'}>{t('finances:ImportedBankInfo')}</p>
        <p className={'col-[-1/-2] text-sm text-[#20253B]'}>{t('finances:MapAccountType')}</p>
      </div>
      {/* accounts */}
      <ul className={'max-h-[270px] flex-1 space-y-4 overflow-y-auto'}>
        {fields.map((account, index) => {
          const currency = account.data.balances.iso_currency_code
          const amount = formatNumber(account.data.balances.available ?? 0, preferences.numberFormat, { digits: 2 })
          return (
            <li
              key={account.data.account_id}
              className={'grid grid-cols-[repeat(3,1fr)_24px_240px] items-center gap-x-8 rounded border p-4'}
            >
              <div className={'space-y-1 text-sm'}>
                <p>{account.data.name}</p>
                <p>{`**** **** **** ${account.data.mask}`}</p>
              </div>
              <div className={'space-y-1'}>
                <p className={'text-xs text-[#6D83AC]'}>{t('finances:Field.Subtype')}</p>
                <p className={'text-sm'}>{account.data.subtype}</p>
              </div>
              <div className={'space-y-1'}>
                <p className={'text-xs text-[#6D83AC]'}>{t('finances:Field.Balance')}</p>
                <p className={'text-sm'}>{`${currency} ${amount}`}</p>
              </div>
              <ArrowRightIcon />
              <FormSelect control={control} name={`accounts.${index}.type`}>
                {options.map(({ label, value }) => (
                  <SelectItem key={value} value={value}>
                    {label}
                  </SelectItem>
                ))}
              </FormSelect>
            </li>
          )
        })}
      </ul>
      <fieldset className={'flex items-center justify-end gap-x-2 [&>button]:basis-[130px]'} disabled={isSubmitting}>
        <Button variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative'} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{t('finances:Connect')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
