import { DummyTracking, ITracking } from '../../tracking'
import { EncryptionLib } from '../types/encryptionLib'

function str2ab(str: string) {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i)
    }
    return buf
}

function ab2str(buf: ArrayBuffer) {
    return String.fromCharCode.apply(null, Array.from(new Uint8Array(buf)))
}

export class Encryption implements EncryptionLib {
    protected readonly ekasEndpoint: string;
    public tracking: ITracking = new DummyTracking();

    constructor(ekasEndpoint: string) {
        this.ekasEndpoint = ekasEndpoint;
    }

    async encryptWithDEK(data: string, dek: string, iv: Uint8Array): Promise<string> {
        if (typeof window === 'undefined') return ''

        // console.log('Encrypting with DEK')
        const subtleCrypto = window.crypto.subtle
        const dekStr = window.atob(dek)

        const encryptedData = await subtleCrypto.encrypt(
            { name: 'AES-CBC', iv },
            await subtleCrypto.importKey('raw', str2ab(dekStr), { name: 'AES-CBC', length: 256 }, false, ['encrypt']),
            new TextEncoder().encode(data)
        )
        return Buffer.from(encryptedData).toString('hex')
    }

    async encryptBytesWithDEK(data: Blob | Uint8Array | ArrayBuffer, dek: string, iv: Uint8Array): Promise<ArrayBuffer> {
        if (typeof window === 'undefined') throw new Error('encryptBytesWithDEK is not supported in this environment')

        const trackId = this.tracking.trackStart('e2e_encrypt_bytes')
        try {
            let dataArrayBuffer: ArrayBuffer | Uint8Array
            if (data instanceof Blob) {
                dataArrayBuffer = await data.arrayBuffer()
            } else {
                dataArrayBuffer = data
            }
            // console.log('Encrypting Bytes with DEK')
            const subtleCrypto = window.crypto.subtle
            const dekStr = window.atob(dek)

            const encryptedData = await subtleCrypto.encrypt(
                { name: 'AES-CBC', iv },
                await subtleCrypto.importKey('raw', str2ab(dekStr), { name: 'AES-CBC', length: 256 }, false, ['encrypt']),
                dataArrayBuffer
            )

            this.tracking.trackSuccess(trackId, { size: encryptedData.byteLength })
            return encryptedData
        } catch (e) {
            this.tracking.trackError(trackId, e as Error)
            throw e
        }
    }

    async decryptWithDEK(encryptedData: string, dek: string, iv: Uint8Array): Promise<string> {
        if (typeof window === 'undefined') return ''
        // console.log('Decrypting with DEK')
        const subtleCrypto = window.crypto.subtle
        const dekStr = window.atob(dek)

        const decipher = await subtleCrypto.decrypt(
            { name: 'AES-CBC', iv },
            await subtleCrypto.importKey('raw', str2ab(dekStr), { name: 'AES-CBC', length: 256 }, false, ['decrypt']),
            new Uint8Array(Buffer.from(encryptedData, 'hex'))
        )
        return new TextDecoder().decode(decipher)
    }

    async decryptBytesWithDEK(encryptedData: ArrayBuffer, dek: string, iv: Uint8Array): Promise<ArrayBuffer> {
        if (typeof window === 'undefined') throw new Error('decryptBytesWithDEK is not supported in this environment')

        const trackId = this.tracking.trackStart('e2e_decrypt_bytes', { size: encryptedData.byteLength })
        try {
            // console.log('Decrypting with DEK')
            const subtleCrypto = window.crypto.subtle
            const dekStr = window.atob(dek)

        const decipher = await subtleCrypto.decrypt(
            { name: 'AES-CBC', iv },
            await subtleCrypto.importKey('raw', str2ab(dekStr), { name: 'AES-CBC', length: 256 }, false, ['decrypt']),
            encryptedData
            )

            this.tracking.trackSuccess(trackId)

            return decipher
        } catch (e) {
            this.tracking.trackError(trackId, e as Error)
            throw e
        }
    }

    async generateDEK(): Promise<string> {
        const subtleCrypto = window.crypto.subtle
        const key = await subtleCrypto.generateKey(
            {
                name: 'AES-CBC',
                length: 256
            },
            true,
            ['encrypt', 'decrypt']
        )

        const keyBuffer = await subtleCrypto.exportKey('raw', key)
        const keyString = ab2str(keyBuffer)
        return window.btoa(keyString)
    }

    async loadDEKFromStorage(userId: string): Promise<string | null> {
        // return localStorage.getItem(`/deks/${userId}`)
        return null
    }

    async storeDEKIntoStorage(dek: string, userId: string): Promise<void> {
        // localStorage.setItem(`/deks/${userId}`, dek)
    }

    async generateNCK (): Promise<{ publicKey: string; privateKey: string }> {
        if (typeof window === 'undefined') return { publicKey: '', privateKey: '' }

        const subtleCrypto = window.crypto.subtle
        const keyPair = await subtleCrypto.generateKey(
            {
                name: 'RSA-OAEP',
                modulusLength: 2048,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: { name: 'SHA-256' }
            },
            true,
            ['encrypt', 'decrypt']
        )

        const publicKey = await subtleCrypto.exportKey('spki', keyPair.publicKey)
        const privateKey = await subtleCrypto.exportKey('pkcs8', keyPair.privateKey)
        const pubArray = new Uint8Array(publicKey)
        const privArray = new Uint8Array(privateKey)

        const pemHeader = '-----BEGIN PUBLIC KEY-----\n'
        const pemFooter = '\n-----END PUBLIC KEY-----'
        const publicKeyPEM = pemHeader + btoa(String.fromCharCode.apply(null, Array.from(pubArray))) + pemFooter

        return {
            publicKey: publicKeyPEM,
            privateKey: btoa(String.fromCharCode.apply(null, Array.from(privArray)))
        }
    }

    async decryptWithNCK(data: string, privateKey: string): Promise<string> {
        if (typeof window === 'undefined') return ''

        const subtleCrypto = window.crypto.subtle
        const privateKeyBuffer = await subtleCrypto.importKey(
            'pkcs8',
            Uint8Array.from(atob(privateKey), (c) => c.charCodeAt(0)),
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            false,
            ['decrypt']
        )

        const decryptedData = await subtleCrypto.decrypt(
            { name: 'RSA-OAEP' },
            privateKeyBuffer,
            Uint8Array.from(atob(data), (c) => c.charCodeAt(0))
        )

        return new TextDecoder().decode(decryptedData)
    }

    async encryptWithRSK(data: string, publicKey: string): Promise<string> {
        if (typeof window === 'undefined') return ''

        const pemHeader = '-----BEGIN PUBLIC KEY-----'
        const pemFooter = '-----END PUBLIC KEY-----'
        const pemContents = publicKey.substring(pemHeader.length, publicKey.length - pemFooter.length - 1)

        // base64 decode the string to get the binary data
        const binaryDerString = window.atob(pemContents)
        // convert from a binary string to an ArrayBuffer
        const binaryDer = str2ab(binaryDerString)
        const publicKeyBuffer = await window.crypto.subtle.importKey(
            'spki',
            binaryDer,
            {
                name: 'RSA-OAEP',
                hash: 'SHA-256'
            },
            true,
            ['encrypt']
        )

        const subtleCrypto = window.crypto.subtle

        const encryptedData = await subtleCrypto.encrypt(
            { name: 'RSA-OAEP' },
            publicKeyBuffer,
            new TextEncoder().encode(data)
        )

        return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedData))))
    }

    async encryptWithNCK(data: string, publicKey: string): Promise<string> {
        if (typeof window === 'undefined') return ''

        const pemHeader = '-----BEGIN PUBLIC KEY-----'
        const pemFooter = '-----END PUBLIC KEY-----'
        const pemContents = publicKey.substring(pemHeader.length, publicKey.length - pemFooter.length - 1)

        const subtleCrypto = window.crypto.subtle

        const publicKeyBuffer = await subtleCrypto.importKey(
            'spki',
            Uint8Array.from(atob(pemContents), (c) => c.charCodeAt(0)),
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            false,
            ['encrypt']
        )

        const encryptedData = await subtleCrypto.encrypt(
            { name: 'RSA-OAEP' },
            publicKeyBuffer,
            new TextEncoder().encode(data)
        )

        return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(encryptedData))))
    }

    async getPublicKeyForEncrypt(idToken: string): Promise<string> {
        // console.log('getPublicKeyForEncrypt')

        const resp = await fetch(`${this.ekasEndpoint}/gpkfe`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
                'firebase-jwt': idToken
            }
        })

        const { publicKey } = await resp.json()

        return publicKey
    }

    async saveDEKForUser (encryptedDEK: string, idToken: string): Promise<void> {
        // console.log('saveDEKForUser')

        const resp = await fetch(`${this.ekasEndpoint}/sdfu`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
                'firebase-jwt': idToken
            },
            body: JSON.stringify({ encryptedDEK })
        })

        if (!resp.ok) {
            throw new Error('Error getting DEK for user')
        }

        return
    }

    async getDEKForUser(pubKey: string, idToken: string, uid: string): Promise<string> {
        // console.log('getDEKForUser')

        const resp = await fetch(`${this.ekasEndpoint}/gdfu`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
                'firebase-jwt': idToken
            },
            body: JSON.stringify({ publicKey: pubKey, uid })
        })

        if (!resp.ok) {
            throw new Error('Error getting DEK for user')
        }

        const key = await resp.text()

        return key
    }

    generateNewIVSalt(): Uint8Array {
        const iv = new Uint8Array(16)
        return window.crypto.getRandomValues(iv)
    }

    convertIVSaltToBase64(iv: Uint8Array): string {
        let base64String = ''
        for (let i = 0; i < iv.byteLength; i++) {
            base64String += String.fromCharCode(iv[i])
        }
        return window.btoa(base64String)
    }

    convertBase64ToIVSalt(base64: string): Uint8Array {
        const base64String = window.atob(base64)
        const binaryDer = str2ab(base64String)
        return new Uint8Array(binaryDer)
    }
}

export class MockEncryption extends Encryption {
    private key = 'O8bH9Oq8Gz9FR89mFhwY1zkJtD5MwTDcD6sa11UcdKk='

    async generateDEK(): Promise<string> {
        return this.key
    }

    async getDEKForUser(pubKey: string, idToken: string, uid: string): Promise<string> {
        console.log('getDEKForUser')

        return this.key
    }
    async getPublicKeyForEncrypt(idToken: string): Promise<string> {
        console.log('getPublicKeyForEncrypt')
        return ''
    }
    async encryptWithRSK(data: string, publicKey: string): Promise<string> {
        return data
    }
    async saveDEKForUser(encryptedDEK: string, idToken: string): Promise<void> { }
}
