import { useCallback, useState } from 'react'
import { Path } from 'react-hook-form'

export function useManualEditTracker<T extends object>() {
  const [manuallyEditedFields, setManuallyEditedFields] = useState<Set<string>>(new Set())

  const trackManualEdit = useCallback((fieldName: Path<T>) => {
    setManuallyEditedFields((prev) => new Set(prev).add(fieldName))
  }, [])

  const isManuallyEdited = useCallback(
    (fieldName: Path<T>) => {
      return manuallyEditedFields.has(fieldName)
    },
    [manuallyEditedFields]
  )

  return { trackManualEdit, isManuallyEdited }
}
