import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M28.54%2033.135v-7a1%201%200%201%200%200-2h-17a1%201%200%201%200%200%202v1.666c-1.2-1.595-2.48-4.026-2.106-6.469.397-2.594%201.961-5.063%203.898-6.423l4.442-1.19%2010.516%203.138c.187.092.384.152.573.174.645.074%201.042-.34.886-.924L25.585.568a.84.84%200%200%200-.24-.413c-.38-.336-1.004-.104-1.398.494l-7.31%208.825s-1.669.254-5.517%201.832c-1.946.797-5.625%203.839-6.08%208.83-.463%205.072%203.213%208.45%206.5%209.872v3.127a1%201%200%201%200%200%202h17a1%201%200%201%200%200-2Zm-7-14v2h-7a1%201%200%201%200%200%202h11a1%201%200%201%200%200-2h-2v-2h-2Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function MusicRoomIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M28.54 33.135v-7a1 1 0 1 0 0-2h-17a1 1 0 1 0 0 2v1.666c-1.2-1.595-2.48-4.026-2.106-6.469.397-2.594 1.961-5.063 3.898-6.423l4.442-1.19 10.516 3.138c.187.092.384.152.573.174.645.074 1.042-.34.886-.924L25.585.568a.84.84 0 0 0-.24-.413c-.38-.336-1.004-.104-1.398.494l-7.31 8.825s-1.669.254-5.517 1.832c-1.946.797-5.625 3.839-6.08 8.83-.463 5.072 3.213 8.45 6.5 9.872v3.127a1 1 0 1 0 0 2h17a1 1 0 1 0 0-2Zm-7-14v2h-7a1 1 0 1 0 0 2h11a1 1 0 1 0 0-2h-2v-2h-2Z'
        }
      />
    </svg>
  )
}
