import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { addContact } from '@/api/ContactService'
import { useToast } from '@/hooks/useToast'
import { useAuthStore } from '@/store/authStore'
import { Modal } from '@/components/base'
import Confirm from '@/components/Confirm'
import { ContactForm, type ContactValues } from '@/components/form'

interface NewAddressProps {
  isOpen: boolean
  onSubmitted: (id: string) => void
  onClose: () => void
}

export default function NewAddress({ isOpen, onSubmitted, onClose }: NewAddressProps) {
  const [isEscape, setIsEscape] = useState(false)
  const { t } = useTranslation()
  const { toast } = useToast()
  const database = useAuthStore((state) => state.database)

  const handleClose = () => {
    setIsEscape(false)
    onClose()
  }
  const handleSubmit = async (data: ContactValues) => {
    try {
      const id = await addContact(database!, data)
      toast({ variant: 'success', description: t('account:Toast.Success.CreateContact') })
      onSubmitted(id)
      onClose()
    } catch (e) {
      e instanceof Error && toast({ variant: 'error', description: e.message })
    }
  }

  return (
    <>
      {isOpen && (
        <Modal className={'max-w-3xl'} onBackdropClick={() => setIsEscape(true)}>
          <Modal.Header className={'bg-primary'}>
            <label className={'text-sm font-medium uppercase text-white'}>{t('account:AddressBook')}</label>
            <Modal.CloseButton className={'text-white'} onClose={() => setIsEscape(true)} />
          </Modal.Header>
          <ContactForm onCancel={handleClose} onSubmit={handleSubmit} />
        </Modal>
      )}

      {isOpen && isEscape && (
        <Confirm
          title={t('UnsavedChanges')}
          content={t('CloseDialog')}
          onCancel={() => setIsEscape(false)}
          cancelLabel={t('KeepEditing')}
          onConfirm={handleClose}
          confirmLabel={t('DiscardChanges')}
        />
      )}
    </>
  )
}
