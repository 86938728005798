import { CoreFirestore } from "../../coreFirebase";
import { Encrypted } from "../database/encryption";
import { PermissionCategory } from "../refPaths";
import { PermissionLevel } from "../types/delegates";
import { FieldDecorator } from "./fieldDecorator";

export type FirestoreOperation = 'create' | 'update' | 'delete' | 'list' | 'get'
export const firestoreOperations: FirestoreOperation[] = ['create', 'update', 'delete', 'list', 'get'];

export type FirestoreOperationRules = { [k in FirestoreOperation]: string[] }

export enum AccessRules {
  CollectionRef = 'CollectionRef',
  AllowAccessDocument = 'AllowAccessDocument',
  AllowAccessFields = 'AllowAccessFields',
  UpdateNotAllowed = 'UpdateNotAllowed',
}

export function FirestoreCollectionRef(collectionPath: string) {
  return function (target: object): void {
    Reflect.defineMetadata(AccessRules.CollectionRef, collectionPath, target);
  }
}

export function getFirestoreCollection<T extends object>(cls: new () => T) {
  const ref = getFirestoreCollectionRef(cls)
  return CoreFirestore.collection<Encrypted<T>>(ref);
}

export function getFirestoreCollectionRef<T extends object>(cls: new () => T) {
  return Reflect.getMetadata(AccessRules.CollectionRef, cls) as string
}

export function AllowAccess(operation: FirestoreOperation, ruleCondition: string) {
  return function (target: any, propertyKey?: string): void {
    if (propertyKey) {
      const rules = Reflect.getMetadata(AccessRules.AllowAccessFields, target) || {}

      const operations: FirestoreOperationRules = rules[propertyKey] || {}
      rules[propertyKey] = operations

      const conditions = operations[operation] || []
      operations[operation] = conditions
      conditions.push(ruleCondition)

      Reflect.defineMetadata(AccessRules.AllowAccessFields, rules, target);
    } else {
      const rules = Reflect.getOwnMetadata(AccessRules.AllowAccessDocument, target) || {}

      const conditions = rules[operation] || []
      rules[operation] = conditions
      conditions.push(ruleCondition)
      Reflect.defineMetadata(AccessRules.AllowAccessDocument, rules, target);
    }
  }
}

export function getAllowRules<T extends object>(cls: new () => T): FirestoreOperationRules {
  return Reflect.getOwnMetadata(AccessRules.AllowAccessDocument, cls)
}

export type OwnedDocumentModel = new () => {
  ownerId: string
}

/**
 * Grants full read/write access to the owner of this document
 * @param target The document model class
 */
export function OwnerAccess(target: OwnedDocumentModel): void {
  AllowAccess(`create`, `request.auth != null && request.auth.uid == request.resource.data.ownerId`)(target)
  AllowAccess(`get`, `ownerAccessOnly()`)(target)
  AllowAccess(`update`, `ownerAccessOnly()`)(target)
  AllowAccess(`delete`, `ownerAccessOnly()`)(target)
  AllowAccess(`list`, `checkParentOwnerId()`)(target)
}

export function OwnerAccessInUserData(target: any): void {
  AllowAccess(`create`, `idMatchesAuthId(userId)`)(target)
  AllowAccess(`get`, `idMatchesAuthId(userId)`)(target)
  AllowAccess(`update`, `idMatchesAuthId(userId)`)(target)
  AllowAccess(`delete`, `idMatchesAuthId(userId)`)(target)
  AllowAccess(`list`, `idMatchesAuthId(userId)`)(target)
}

/**
 * Grants full read/write access to delegates of the owner of this document
 * @param target The document model class
 */
export function FullDelegateAccess(permissionCategory: PermissionCategory | string) {
  return (target: OwnedDocumentModel): void => {
    AllowAccess(`get`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`list`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`update`, `checkDelegatePermission(${PermissionLevel.Edit}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`create`, `checkDelegatePermission(${PermissionLevel.Create}, "${permissionCategory}", request.resource.data.ownerId)`)(target)
    AllowAccess(`delete`, `checkDelegatePermission(${PermissionLevel.Delete}, "${permissionCategory}", resource.data.ownerId)`)(target)
  }
}

/**
 * Grants full read/write access to delegates of the owner of this document.  Only needs MaxPermission for edit
 * @param target The document model class
 */
export function FullDelegateAccessMaxPermissionEdit(permissionCategory: PermissionCategory | string) {
  return (target: OwnedDocumentModel): void => {
    AllowAccess(`get`, `checkDelegatePermission(${PermissionLevel.View}, "maxPermission", resource.data.ownerId)`)(target)
    AllowAccess(`list`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`update`, `checkDelegatePermission(${PermissionLevel.Edit}, "maxPermission", resource.data.ownerId)`)(target)
    AllowAccess(`create`, `checkDelegatePermission(${PermissionLevel.Create}, "${permissionCategory}", request.resource.data.ownerId)`)(target)
    AllowAccess(`delete`, `checkDelegatePermission(${PermissionLevel.Delete}, "${permissionCategory}", resource.data.ownerId)`)(target)
  }
}

/**
 * Grants full read/write access to delegates of the owner of this document
 * @param target The document model class
 */
export function FullDelegateAccessInAction(permissionCategory: PermissionCategory | string) {
  return (target: OwnedDocumentModel): void => {
    AllowAccess(`get`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`list`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`update`, `checkDelegatePermission(${PermissionLevel.Edit}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`create`, `checkDelegatePermission(${PermissionLevel.Edit}, "${permissionCategory}", request.resource.data.ownerId)`)(target)
    AllowAccess(`delete`, `checkDelegatePermission(${PermissionLevel.Edit}, "${permissionCategory}", resource.data.ownerId)`)(target)
  }
}

/**
 * Grants full read/write access to delegates of the owner of this document
 * @param target The document model class
 */
export function FullDelegateAccessInUserData(permissionCategory: PermissionCategory | string) {
  return (target: any): void => {
    AllowAccess(`get`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}",userId)`)(target)
    AllowAccess(`list`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}",userId)`)(target)
    AllowAccess(`update`, `checkDelegatePermission(${PermissionLevel.Edit}, "${permissionCategory}",userId)`)(target)
    AllowAccess(`create`, `checkDelegatePermission(${PermissionLevel.Create}, "${permissionCategory}",userId)`)(target)
    AllowAccess(`delete`, `checkDelegatePermission(${PermissionLevel.Delete}, "${permissionCategory}",userId)`)(target)
  }
}

/**
 * Grants read only access to delegates of the owner of this document
 * @param target The document model class
 */
export function ReadOnlyDelegateAccess(permissionCategory: PermissionCategory | string) {
  return (target: OwnedDocumentModel): void => {
    AllowAccess(`get`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
    AllowAccess(`list`, `checkDelegatePermission(${PermissionLevel.View}, "${permissionCategory}", resource.data.ownerId)`)(target)
  }
}

/**
 * Grants read access to any user and any document in the collection
 * @warn This decorator should only be used for public information
 * @param target The document model class
 */
export function ReadOnlyPublicAccess(target: object): void {
  AllowAccess(`get`, `request.auth != null`)(target)
  AllowAccess(`list`, `request.auth != null`)(target)
}

/**
 * Grants full read/write access to any user and any document in the collection
 * @warn This decorator should only be used for testing as it allows any logged in user to alter and create these documents
 * @param target The document model class
 */
export function FullPublicAccess(target: object): void {
  AllowAccess(`get`, `request.auth != null`)(target)
  AllowAccess(`list`, `request.auth != null`)(target)
  AllowAccess(`create`, `request.auth != null`)(target)
  AllowAccess(`update`, `request.auth != null`)(target)
  AllowAccess(`delete`, `request.auth != null`)(target)
}

/**
 * This field can not be changed after the object has been created
 */
export const UpdateNotAllowed = FieldDecorator(AccessRules.UpdateNotAllowed)
