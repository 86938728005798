import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { Loader2Icon } from 'lucide-react'

import { DocumentBodyBlock, DocumentContentBlock, KnowledgeDocumentData } from '@/types/helpCenter'

interface JsonViewerProps {
  data: KnowledgeDocumentData
}

const JsonViewer: React.FC<JsonViewerProps> = ({ data }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [imageDimensions, setImageDimensions] = useState<{ [url: string]: { width: number; height: number } }>({})

  useEffect(() => {
    const loadImageDimensions = async (url: string) => {
      return new Promise<{ width: number; height: number }>((resolve, reject) => {
        const img = new window.Image()
        img.onload = () => resolve({ width: img.width, height: img.height })
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`))
        img.src = url
      })
    }

    const fetchAllImageDimensions = async () => {
      const dimensions: { [url: string]: { width: number; height: number } } = {}
      const processBlock = async (block: DocumentBodyBlock | DocumentContentBlock) => {
        if (block.type === 'Image' && block.image?.url) {
          try {
            dimensions[block.image.url] = await loadImageDimensions(block.image.url)
          } catch (error) {
            console.error(error)
            dimensions[block.image.url] = { width: 1920, height: 1080 }
          }
        }
        if ((block.type === 'OrderedList' || block.type === 'UnorderedList') && block.list?.blocks) {
          for (const subBlock of block.list.blocks) {
            await Promise.all(subBlock.blocks.map(processBlock))
          }
        }
      }

      try {
        await Promise.all(data.variations.flatMap((v) => v.body.blocks.map(processBlock)))
        setImageDimensions(dimensions)
        setIsImageLoaded(true)
      } catch (error) {
        console.error('Error fetching image dimensions:', error)
      }
    }

    fetchAllImageDimensions()
  }, [data])

  const renderBlock = (block: DocumentBodyBlock) => {
    switch (block.type) {
      case 'Paragraph':
        return (
          <p className={block.paragraph?.properties?.fontType === 'Heading2' ? 'text-xl font-bold' : ''}>
            {block.paragraph?.blocks.map((textBlock, index) => renderTextBlock(textBlock, index))}
          </p>
        )
      case 'OrderedList':
        return (
          <ol className={'list-inside list-decimal'}>
            {block.list?.blocks.map((item, index) => (
              <li key={index}>{item.blocks.map((subBlock, subIndex) => renderTextBlock(subBlock, subIndex))}</li>
            ))}
          </ol>
        )
      default:
        return null
    }
  }

  const renderTextBlock = (block: DocumentContentBlock, index: number) => {
    switch (block.type) {
      case 'Text':
        let content: string | JSX.Element = block.text.text
        if (block.text.marks?.includes('Bold')) {
          content = <strong>{content}</strong>
        }
        if (block.text.hyperlink) {
          content = (
            <a href={block.text.hyperlink} className={'text-blue-500 hover:underline'}>
              {content}
            </a>
          )
        }
        return <span key={index}>{content}</span>
      case 'Image':
        const isSmallImage =
          imageDimensions[block.image.url]?.width <= 50 && imageDimensions[block.image.url]?.height <= 50
        return (
          <>
            {block.image.url && isImageLoaded ? (
              <Image
                key={index}
                src={block.image.url}
                alt={'DocumentImage'}
                width={imageDimensions[block.image.url]?.width || 1920}
                height={imageDimensions[block.image.url]?.height || 1080}
                loading={'eager'}
                className={isSmallImage ? 'mx-1 inline-block align-middle' : 'my-2 block'}
                onError={() => console.error(`Failed to load image: ${block.image.url}`)}
              />
            ) : (
              <Loader2Icon className={'mx-auto my-20 animate-spin text-primary'} size={40} />
            )}
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <h1 className={'mb-4 text-2xl font-bold'}>{data.title}</h1>
      {data.variations[0].body.blocks.map((block, index) => (
        <div key={index} className={'mb-4'}>
          {renderBlock(block)}
        </div>
      ))}
    </>
  )
}

export default JsonViewer
