import {
  Amount,
  AssetType,
  Attachment,
  IAggregateData,
  compareGroupUpdate,
} from "../common";
import { AssetV2 } from "../common";
import { OmitKeys, UpdateObject, buildUpdate } from "../../utils";
import { AggregateRoot, RepoAndAggregates } from "../aggregate";
import { Valuation } from "../actions/valuation";
import { Offer } from "../actions/offer";
import { LocationItem } from "../relations";
import { SoldInfo } from "../actions/soldInfo";
import { Encrypted, Encryption } from "../../database/encryption";
import { CoreFirestore, WithFieldValue } from "../../../coreFirebase";
import { ContactLocation } from "./contactLocation";
import { RentalDetail } from "./rentalDetail";
import { OwnerDetail } from "./ownerDetail";
import { OwnershipType } from "./ownershipType";
import { Type } from "./type";

import { Property } from "./property";
import { Validations, getFieldsByDecorator } from "../../decorators";
import {
  ContactLocationTypeVersion,
  PropertyTypeVersion,
  VersionedType,
  validateTypeUpToDate,
} from "../typeVersion";
import { ErrorDataOutDated } from "../error";
import {
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  IVSaltFieldDefaultValue,
  IVSaltFieldKey,
} from "../../encryption/utils";
import { PropertyAggregate } from "./propertyAggregate";
import { RentInfo } from "../actions/rentInfo";
import { AcquisitionType } from "../common/acquisition";

export namespace PropertyUtils {
  export function assureVersion(
    input: Encrypted<Property>,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input as Property,
      PropertyTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Property);
  }

  export const datePaths = getFieldsByDecorator(Validations.isDate, Property);
  export const amountPaths = getFieldsByDecorator(
    Validations.isAmount,
    Property
  );
  export async function decryptAndConvertDate(
    input: Encrypted<Property>,
    encryption: Encryption
  ): Promise<Property> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestoreNotStrict(decrypted, datePaths);
    return decrypted;
  }
  type Items = { items: LocationItem[] };
  export type State = Encrypted<Property> & Items & IAggregateData;
  export type WithItems = Property & Items;

  export type CreateFields = OmitKeys<
    Property,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt" | "valueSourceId"
  >;

  export function fromCreate(from: CreateFields, ownerId: string): Property {
    const property: WithFieldValue<Property> = {
      ...from,
      version: 0,
      ownerId,
      createAt: new Date(),
      updateAt: new Date(),
      "@type": PropertyTypeVersion,
    };
    return property as Property;
  }

  export function intoUpdate(
    current: Property,
    update: Property
  ): {
    updates: UpdateObject<Property>;
    metadata: {
      addedToGroup: AssetV2["groupIds"];
      removedFromGroup: AssetV2["groupIds"];
      newImages?: string[];
      newMainImage?: string;
      removedImages?: string[];
    };
  } {
    const metadata: any = {};

    const baseUpdateFields = buildUpdate(current, update, Property);

    if (!ContactLocation.equal(current.location, update.location)) {
      baseUpdateFields.location = update.location;
    }

    const { fieldUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (fieldUpdate !== undefined) {
      baseUpdateFields.groupIds = fieldUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;

    if (update.configuration) {
      baseUpdateFields.configuration = update.configuration;
    }
    if (update.ownershipType === OwnershipType.Rent) {
      update.value = Amount.defaultValue();
    }
    if (!Amount.equal(current.value, update.value)) {
      baseUpdateFields.value = update.value;
    }
    if (
      // #NOTE if type is updated from rent to own, summary need to update purchase price
      baseUpdateFields.ownershipType === OwnershipType.Own ||
      !Amount.equal(current.price, update.price)
    ) {
      baseUpdateFields.price = update.price;
    }
    if (!Amount.equal(current.totalCost, update.totalCost)) {
      baseUpdateFields.totalCost = update.totalCost;
    }
    if (current.startDate.getTime() !== update.startDate.getTime()) {
      baseUpdateFields.startDate = update.startDate;
    }

    if (baseUpdateFields.ownershipType !== undefined) {
      if (update.detail) baseUpdateFields.detail = update.detail;
    } else {
      if (current.ownershipType === OwnershipType.Own) {
        if (
          !OwnerDetail.optionalEqual(
            <OwnerDetail>current.detail,
            <OwnerDetail>update.detail
          )
        ) {
          baseUpdateFields.detail = update.detail;
        }
      } else {
        if (
          !RentalDetail.optionalEqual(
            <RentalDetail>current.detail,
            <RentalDetail>update.detail
          )
        ) {
          baseUpdateFields.detail = update.detail;
        }
      }
    }

    const { attachments, newImages, removedImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (removedImages.length > 0) metadata.removedImages = removedImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;
    if (baseUpdateFields.mainImage !== undefined)
      metadata.newMainImage = baseUpdateFields.mainImage;

    return { updates: baseUpdateFields, metadata };
  }

  export async function encrypt(
    input: Property,
    encryption: Encryption
  ): Promise<Encrypted<Property>> {
    return await encryption.encryptObject(input, Property);
  }

  export async function decrypt(
    data: Encrypted<Property>,
    encryption: Encryption
  ): Promise<Property> {
    return await encryption.decryptObject(data, Property);
  }

  export function newARWithState(
    property: Encrypted<Property> & IAggregateData,
    items: LocationItem[],
    relatedReads?: RelatedReads
  ) {
    return new AggregateRoot(
      new PropertyAggregate({ ...property, items }, relatedReads)
    );
  }

  export function defaultStateValue(): Encrypted<Property> {
    return {
      "@type": PropertyTypeVersion,
      assetType: AssetType.Property,
      subtype: Type.House,
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      startDate: new Date(0),
      ownershipType: OwnershipType.Own,
      price: Amount.defaultValue(),
      value: Amount.defaultValue(),
      totalCost: Amount.defaultValue(),
      location: {
        "@type": ContactLocationTypeVersion,
        zipCode: "",
        country: "",
        [EncryptionFieldKey]: {
          data: EncryptionFieldDefaultValue, //`{address:"",center: { lat: 0, lng: 0 }}`
          [IVSaltFieldKey]: IVSaltFieldDefaultValue,
        },
      },
      detail: {
        acquisition: {
          acquisitionType: AcquisitionType.Direct,
        },
      },
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  export type SupportActions = Offer | RentInfo;
  export type SupportActionsEncrypted = Offer.Encrypted | RentInfo.Encrypted;
  export type SupportActionsUpdate = Offer.Update | RentInfo.Update;
  export type WriteActionEncrypted =
    | Valuation.Encrypted
    | SoldInfo.Encrypted
    | Offer.Encrypted
    | RentInfo.Encrypted;

  export interface RelatedReads {
    actions: { [id: string]: WriteActionEncrypted };
  }
  export type RelatedUpdates = {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
    addedInsuranceIds?: string[];
    removedInsuranceIds?: string[];
    setActions?: WriteActionEncrypted[];
    removedActionIds?: string[];
  };

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
    insurance?: RepoAndAggregates<any, any, any>;
    cashAndBanking?: RepoAndAggregates<any, any, any>;
  }
}
