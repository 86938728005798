export enum BelongingType {
  ArtworkAndPhotos = "ArtworkAndPhotos",
  AutomotiveAndIndustrial = "AutomotiveAndIndustrial",
  BeautyAndHealth = "BeautyAndHealth",
  BoatsBoatingAndOtherMarine = "BoatsBoatingAndOtherMarine",
  ClothingAndShoes = "ClothingAndShoes",
  CollectablesAndMemorabilia = "CollectablesAndMemorabilia",
  ComputersAndHomeOffice = "ComputersAndHomeOffice",
  Electronics = "Electronics",
  FurnitureAndFurnishings = "FurnitureAndFurnishings",
  GardenPets = "GardenPets",
  Handmade = "Handmade",
  JewelleryAndWatches = "JewelleryAndWatches",
  MiscellaneousHomeItems = "MiscellaneousHomeItems",
  MoviesMusicAndGames = "MoviesMusicAndGames",
  Other = "Other",
  PhotographyEquipment = "PhotographyEquipment",
  SportsAndOutdoors = "SportsAndOutdoors",
  Tools = "Tools",
  ToysKidsAndBaby = "ToysKidsAndBaby",
}

export enum OtherCollectableType {
  Americana = "Americana",
  Antiques = "Antiques",
  ArmourAndHelmets = "ArmourAndHelmets",
  Autographs = "Autographs",
  AutomotiveCollectables = "AutomotiveCollectables",
  BaseballCards = "BaseballCards",
  BeerSteins = "BeerSteins",
  BooksAndManuscripts = "BooksAndManuscripts",
  CamerasAndPhotographicEquipment = "CamerasAndPhotographicEquipment",
  Cars = "Cars",
  CarsVintageClassic = "CarsVintageClassic",
  CigarBoxesAndCigaretteCases = "CigarBoxesAndCigaretteCases",
  Cigars = "Cigars",
  ClocksAndBarometers = "ClocksAndBarometers",
  ClothingVintage = "ClothingVintage",
  CoinsAndCurrency = "CoinsAndCurrency",
  CollectableCards = "CollectableCards",
  ComicBooks = "ComicBooks",
  FilmMemorabilia = "FilmMemorabilia",
  FirearmsAndWeaponary = "FirearmsAndWeaponary",
  FossilsAndGeology = "FossilsAndGeology",
  FurnitureAndLighting = "FurnitureAndLighting",
  Glassware = "Glassware",
  Handbags = "Handbags",
  Jewelry = "Jewelry",
  Lighters = "Lighters",
  LuxuryBrandItems = "LuxuryBrandItems",
  Magazines = "Magazines",
  Maps = "Maps",
  MedalsAndBadges = "MedalsAndBadges",
  MilitaryCollectables = "MilitaryCollectables",
  ModelShips = "ModelShips",
  ModernCollectables = "ModernCollectables",
  MusicalInstruments = "MusicalInstruments",
  NFTS = "NFTS",
  Other = "Other",
  Pens = "Pens",
  Photographs = "Photographs",
  PorcelainFigures = "PorcelainFigures",
  Posters = "Posters",
  SeaShells = "SeaShells",
  ShippingAndMarineCollectables = "ShippingAndMarineCollectables",
  Shoes = "Shoes",
  ShotGlasses = "ShotGlasses",
  Silver = "Silver",
  SneakersRunningShoes = "SneakersRunningShoes",
  SpoonsAndCutlery = "SpoonsAndCutlery",
  SportingMemorabilia = "SportingMemorabilia",
  Stamps = "Stamps",
  StockAndBondCertificates = "StockAndBondCertificates",
  StuffedAnimalsTaxidermy = "StuffedAnimalsTaxidermy",
  Sunglasses = "Sunglasses",
  SwordsAndKnives = "SwordsAndKnives",
  Telescopes = "Telescopes",
  ToysAndDolls = "ToysAndDolls",
  TradingCards = "TradingCards",
  Victoriana = "Victoriana",
  VideoGames = "VideoGames",
  VinylRecordsAndCDs = "VinylRecordsAndCDs",
  Watches = "Watches",
}

export const belongingTypeValues = Object.values(BelongingType);
export const otherCollectableTypeValues = Object.values(OtherCollectableType);
