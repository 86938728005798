export async function heicToPng(files: File[]) {
  if (typeof window !== 'undefined') {
    try {
      const otherFiles = files.filter((file) => file.type !== 'image/heic')
      const heicFiles = files.filter((file) => file.type === 'image/heic')
      const heic2any = (await import('heic2any')).default
      const convertedFiles = await Promise.all(
        heicFiles.map(async (file) => {
          const convertedBlob = await heic2any({ blob: file, toType: 'image/png' })
          if (Array.isArray(convertedBlob)) {
            const fileParts = convertedBlob.map(
              (blob, index) => new File([blob], `${file.name.split('.')[0]}_${index}.png`, { type: 'image/png' })
            )
            return fileParts
          } else {
            return new File([convertedBlob], `${file.name.split('.')[0]}.png`, { type: 'image/png' })
          }
        })
      )

      async function blobsToFiles(blobs: Blob[]): Promise<File[]> {
        const files: File[] = []
        for (const blob of blobs) {
          const file = new File([await blob.arrayBuffer()], blob.name, { type: blob.type })
          files.push(file)
        }
        return files
      }

      const allFiles = [...(await blobsToFiles([...convertedFiles.flat()])), ...otherFiles]
      return allFiles
    } catch (error) {
      throw new Error('Conversion failed')
    }
  } else {
    return []
  }
}
