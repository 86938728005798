import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230f889b%22%20d%3D%22M5%2021h22v4H5zm3-5h16v4H8zm3-4h10v3H11zM2%2026h28v4H2z%22%2F%3E%3Cpath%20fill%3D%22%230f889b%22%20d%3D%22M12.316%202.052A1%201%200%200%200%2011.2%202.4l-9%2012a.997.997%200%200%200-.2.6v10h2v-9.667L5%2014v6h2v-8.667L8%2010v5h2V7.333L11%206v5h2V3a.999.999%200%200%200-.684-.948zm7.368%200A1%201%200%200%201%2020.8%202.4l9%2012c.13.173.2.383.2.6v10h-2v-9.667L27%2014v6h-2v-8.667L24%2010v5h-2V7.333L21%206v5h-2V3c0-.43.275-.813.684-.948z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function StairwellIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 32'} width={size} height={size} {...props}>
      <path fill={'#0f889b'} d={'M5 21h22v4H5zm3-5h16v4H8zm3-4h10v3H11zM2 26h28v4H2z'} />
      <path
        fill={'#0f889b'}
        d={
          'M12.316 2.052A1 1 0 0 0 11.2 2.4l-9 12a.997.997 0 0 0-.2.6v10h2v-9.667L5 14v6h2v-8.667L8 10v5h2V7.333L11 6v5h2V3a.999.999 0 0 0-.684-.948zm7.368 0A1 1 0 0 1 20.8 2.4l9 12c.13.173.2.383.2.6v10h-2v-9.667L27 14v6h-2v-8.667L24 10v5h-2V7.333L21 6v5h-2V3c0-.43.275-.813.684-.948z'
        }
      />
    </svg>
  )
}
