import { useMemo } from 'react'
import { endOfDay, isBefore } from 'date-fns'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import {
  ValuationBasis,
  valuationBasisValues,
  ValuationMethod,
  valuationMethodValues,
  type Valuation
} from 'core/remodel/types/actions/valuation'
import { Amount, Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchExchangeRates } from '@/api/CommonService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatNumber, makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCheckbox,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormPriceInput,
  FormTextarea
} from '@/components/base'
import UploadFiles from '@/components/UploadFiles'

export type ValuationValues = Valuation.CreateFields

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<ValuationValues> => ({
  name: '',
  value: { currency, value: 0 },
  updateValue: true,
  method: ValuationMethod.AuctionEstimate,
  basis: ValuationBasis.AuctionEstimates,
  valuedById: '',
  valuedByRepresentative: '',
  inspectionDate: new Date(),
  issueDate: new Date(),
  purpose: '',
  status: '',
  notes: '',
  file: []
})

interface ValuationFormProps {
  assetId: string | null
  mode?: 'Create' | 'Edit'
  values?: ValuationValues
  onSubmit: SubmitHandler<ValuationValues>
  onCancel: () => void
}

export function ValuationForm({ assetId, mode = 'Create', values, onCancel, onSubmit }: ValuationFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch
  } = useForm<ValuationValues>({
    values,
    defaultValues
  })
  const valueValuation = watch('value.value')
  const currencyValuation = watch('value.currency')
  const inspectionDate = watch('inspectionDate') ?? new Date()
  // preferences and options
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const valuationMethodOptions = makeOptions(valuationMethodValues, (key) => `ValuationMethodOptions.${key}`)
  const valuationBasisOptions = makeOptions(valuationBasisValues, (key) => `ValuationBasisOptions.${key}`)

  const { data: targetToBaseRate } = useSWR(
    [commonQuery.exchangeRates, currencyValuation, preferences.baseCurrency!],
    fetchExchangeRates(database!)
  )
  const baseValuation = useMemo(() => {
    if (targetToBaseRate?.rate === undefined) return { rate: '', result: '' }
    const exchangeRate = targetToBaseRate?.rate
    const valuationRaw: Amount = { value: valueValuation, currency: currencyValuation }
    const { currency: currencyBase, value: valueBase } = database!.ExRate.amountToBase(valuationRaw)

    const rateAmount = formatNumber(exchangeRate, preferences.numberFormat, { isNegativeSign: false, digits: 2 })
    const resultAmount = formatNumber(valueBase, preferences.numberFormat, { isNegativeSign: true, digits: 2 })
    const rate = `1 ${currencyValuation} = ${rateAmount} ${currencyBase}`
    const result = `${currencyBase} ${resultAmount}`
    return { rate, result }
  }, [targetToBaseRate?.rate, valueValuation, currencyValuation, preferences, database])

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  return (
    <form className={'flex h-[95vh] flex-col gap-y-4 bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-x-4 overflow-y-auto px-4 pt-4 md:flex-row'}>
        <div className={'flex min-w-0 flex-1 flex-col gap-y-4'}>
          <FormInput control={control} name={'name'} label={t('Field.ValuationName')} rules={{ required: true }} />
          <FormPriceInput
            control={control}
            name={{ currency: 'value.currency', value: 'value.value' }}
            label={t('Value')}
            rules={{
              value: {
                required: true,
                validate: {
                  greaterThanZero: (value) => value > 0
                }
              }
            }}
            format={preferences.numberFormat}
            digits={2}
          />
          <FormAutocomplete
            control={control}
            name={'method'}
            label={t('Field.Method')}
            options={valuationMethodOptions}
            rules={{ required: true }}
          />
          <FormAutocomplete control={control} name={'basis'} label={t('Field.Basis')} options={valuationBasisOptions} />
          <FormCreatableAutocomplete
            control={control}
            name={'valuedById'}
            label={t('Field.ValuedBy')}
            placeholder={t('SearchOrCreate')}
            options={contactOptions}
            onCreate={addNewContact}
            isLoading={loadingContacts}
          />
          <FormInput control={control} name={'valuedByRepresentative'} label={t('Field.ValuedByRepresentative')} />
        </div>
        <div className={'flex min-w-0 flex-1 flex-col gap-y-4'}>
          <FormDatePicker
            control={control}
            name={'inspectionDate'}
            label={t('Field.InspectionDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
          <FormDatePicker
            control={control}
            name={'issueDate'}
            label={t('Field.IssueDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
            rules={{
              validate: {
                validDate: (date) => {
                  const issueDate = endOfDay(date ?? new Date())
                  return isBefore(inspectionDate, issueDate) || t('validation:ValidIssueDate')
                }
              }
            }}
          />
          <FormInput control={control} name={'purpose'} label={t('Field.Purpose')} />
          <FormInput control={control} name={'status'} label={t('Field.Status')} />
          <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
          <UploadFiles assetId={assetId} control={control} name={'file'} />
          <div className={'flex flex-col gap-y-1'}>
            <label className={'text-xs text-[#414554]'}>{t('Field.ValuationResult')}</label>
            <div className={'rounded border border-primary-hover bg-primary p-2'}>
              <p className={'text-right text-sm text-white'}>
                {`${currencyValuation} ${formatNumber(valueValuation, preferences.numberFormat, {
                  isNegativeSign: true,
                  digits: 2
                })}`}
              </p>
            </div>
          </div>
          <div className={'flex flex-col gap-y-1'}>
            <label className={'text-xs text-[#414554]'}>{t('Field.ValuationResultInBaseCurrency')}</label>
            <div className={'flex overflow-hidden rounded border border-primary-hover text-right'}>
              <p className={'flex-1 whitespace-nowrap bg-white p-2 text-sm'} title={baseValuation.rate}>
                {baseValuation.rate}
              </p>
              <p className={'flex-1 whitespace-nowrap bg-primary p-2 text-sm text-white'} title={baseValuation.result}>
                {baseValuation.result}
              </p>
            </div>
          </div>
          <FormCheckbox control={control} name={'updateValue'} label={t('IncludeValue')} />
        </div>
      </div>
      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative min-w-[130px]'} variant={'solid'} size={'md'} type={'submit'}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
