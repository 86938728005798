import { FirebaseV2 } from "./firestore";

export interface ITracking {
  setup: (firebase: FirebaseV2) => void;
  track: (name: string, context?: Record<string, unknown>) => void;
  trackStart: (name: string, context?: Record<string, unknown>, timeout?: number) => string;
  trackSuccess: (trackId: string, context?: Record<string, unknown>) => void;
  trackError: (trackIdOrName: string, error?: Error, context?: Record<string, unknown>) => void;
}
export class DummyTracking implements ITracking {
  setup () {}
  track () {}
  trackStart () {return ""}
  trackSuccess () {}
  trackError() {}
}