import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI2Ljc4NTcgMEg2LjA3MTQzQzUuNSAwIDUgMC41IDUgMS4zNTcxNFYzOS4yODU3QzUgMzkuNSA1LjUgNDAgNi4wNzE0MyA0MEgzNC42NDI5QzM1LjIxNDMgNDAgMzUuNzE0MyAzOS41IDM1LjcxNDMgMzkuMjg1N1Y5LjI4NTcxQzM1LjcxNDMgOC43ODU3MSAzNS42NDI5IDguNjQyODYgMzUuNSA4LjVMMjcuMjE0MyAwLjIxNDI4NkMyNy4wNzE0IDAuMDcxNDI4NiAyNi45Mjg2IDAgMjYuNzg1NyAwWiIgZmlsbD0iI0I3QkJDNCIvPgo8cGF0aCBkPSJNMjcuMTQwNiAwLjE0MjU3OFY4LjU3MTE1SDM1LjU2OTJMMjcuMTQwNiAwLjE0MjU3OFoiIGZpbGw9IiM4RTkwOUMiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi44NDUyIDE5LjQ4ODJDMTYuMTk0MyAxOC44MzczIDE1LjEzOSAxOC44MzczIDE0LjQ4ODIgMTkuNDg4MkMxMy44MzczIDIwLjEzOSAxMy44MzczIDIxLjE5NDMgMTQuNDg4MiAyMS44NDUyTDE3LjQ3NjMgMjQuODMzM0wxNC40ODgyIDI3LjgyMTVDMTMuODM3MyAyOC40NzI0IDEzLjgzNzMgMjkuNTI3NiAxNC40ODgyIDMwLjE3ODVDMTUuMTM5IDMwLjgyOTQgMTYuMTk0MyAzMC44Mjk0IDE2Ljg0NTIgMzAuMTc4NUwxOS44MzMzIDI3LjE5MDRMMjIuODIxNSAzMC4xNzg1QzIzLjQ3MjQgMzAuODI5NCAyNC41Mjc2IDMwLjgyOTQgMjUuMTc4NSAzMC4xNzg1QzI1LjgyOTQgMjkuNTI3NiAyNS44Mjk0IDI4LjQ3MjQgMjUuMTc4NSAyNy44MjE1TDIyLjE5MDQgMjQuODMzM0wyNS4xNzg1IDIxLjg0NTJDMjUuODI5NCAyMS4xOTQzIDI1LjgyOTQgMjAuMTM5IDI1LjE3ODUgMTkuNDg4MkMyNC41Mjc2IDE4LjgzNzMgMjMuNDcyNCAxOC44MzczIDIyLjgyMTUgMTkuNDg4MkwxOS44MzMzIDIyLjQ3NjNMMTYuODQ1MiAxOS40ODgyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)
 */
export default function FileCorrupted({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg width={size} height={size} viewBox={'0 0 40 40'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <path
        d={
          'M26.7857 0H6.07143C5.5 0 5 0.5 5 1.35714V39.2857C5 39.5 5.5 40 6.07143 40H34.6429C35.2143 40 35.7143 39.5 35.7143 39.2857V9.28571C35.7143 8.78571 35.6429 8.64286 35.5 8.5L27.2143 0.214286C27.0714 0.0714286 26.9286 0 26.7857 0Z'
        }
        fill={'#B7BBC4'}
      />
      <path d={'M27.1406 0.142578V8.57115H35.5692L27.1406 0.142578Z'} fill={'#8E909C'} />
      <path
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={
          'M16.8452 19.4882C16.1943 18.8373 15.139 18.8373 14.4882 19.4882C13.8373 20.139 13.8373 21.1943 14.4882 21.8452L17.4763 24.8333L14.4882 27.8215C13.8373 28.4724 13.8373 29.5276 14.4882 30.1785C15.139 30.8294 16.1943 30.8294 16.8452 30.1785L19.8333 27.1904L22.8215 30.1785C23.4724 30.8294 24.5276 30.8294 25.1785 30.1785C25.8294 29.5276 25.8294 28.4724 25.1785 27.8215L22.1904 24.8333L25.1785 21.8452C25.8294 21.1943 25.8294 20.139 25.1785 19.4882C24.5276 18.8373 23.4724 18.8373 22.8215 19.4882L19.8333 22.4763L16.8452 19.4882Z'
        }
        fill={'white'}
      />
    </svg>
  )
}
