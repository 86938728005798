import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2035%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M27.079%208.504H34V5.14h-6.876c-1.464%200-2.86-.571-3.834-1.57C22.314%202.571%2020.92%202%2019.455%202H2v9.532h17.5c1.44%200%202.812-.549%203.79-1.514.977-.965%202.35-1.514%203.789-1.514ZM6.414%202.681h12.322v5.617H6.414V2.68Zm21.334%202.808h-.92v2.553h.92V5.49ZM34%2019.74h-6.921c-1.439%200-2.812.548-3.79%201.513-.977.965-2.35%201.514-3.788%201.514H2v-9.532h17.455c1.465%200%202.86.571%203.835%201.57.975.999%202.37%201.57%203.834%201.57H34v3.364Zm-15.264-5.824H6.414v5.617h12.322v-5.617Zm8.092%202.894h.92v2.383h-.92V16.81Zm.25%2014.163H34v-3.364h-6.876c-1.464%200-2.86-.571-3.834-1.57-.976-.999-2.37-1.57-3.835-1.57H2V34h17.5c1.44%200%202.812-.549%203.79-1.514.977-.965%202.35-1.514%203.789-1.514ZM6.415%2025.15h12.322v5.617H6.414V25.15Zm21.334%202.893h-.92v2.554h.92v-2.553Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CellarIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M27.079 8.504H34V5.14h-6.876c-1.464 0-2.86-.571-3.834-1.57C22.314 2.571 20.92 2 19.455 2H2v9.532h17.5c1.44 0 2.812-.549 3.79-1.514.977-.965 2.35-1.514 3.789-1.514ZM6.414 2.681h12.322v5.617H6.414V2.68Zm21.334 2.808h-.92v2.553h.92V5.49ZM34 19.74h-6.921c-1.439 0-2.812.548-3.79 1.513-.977.965-2.35 1.514-3.788 1.514H2v-9.532h17.455c1.465 0 2.86.571 3.835 1.57.975.999 2.37 1.57 3.834 1.57H34v3.364Zm-15.264-5.824H6.414v5.617h12.322v-5.617Zm8.092 2.894h.92v2.383h-.92V16.81Zm.25 14.163H34v-3.364h-6.876c-1.464 0-2.86-.571-3.834-1.57-.976-.999-2.37-1.57-3.835-1.57H2V34h17.5c1.44 0 2.812-.549 3.79-1.514.977-.965 2.35-1.514 3.789-1.514ZM6.415 25.15h12.322v5.617H6.414V25.15Zm21.334 2.893h-.92v2.554h.92v-2.553Z'
        }
      />
    </svg>
  )
}
