import { Environment, FirebaseV2 } from 'core/firestore'
import { NoEncryption } from 'core/remodel/encryption/node'
import { Encryption, MockEncryption } from 'core/remodel/encryption/web'
import tracking from '@/utils/track'

const env = process.env.NEXT_PUBLIC_ENV as Environment

const ekasEndpoint = process.env.NEXT_PUBLIC_EKAS_ENDPOINT!
const encryption =
  env === 'development'
    ? process.env.NEXT_PUBLIC_NO_ENCRYPTION === 'true'
      ? NoEncryption
      : new MockEncryption(ekasEndpoint)
    : new Encryption(ekasEndpoint)

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

export const firebase = new FirebaseV2(env, encryption, firebaseConfig, {
  cfEndpoint: process.env.NEXT_PUBLIC_CF_ENDPOINT,
  apiEndpoint: process.env.NEXT_PUBLIC_API_ENDPOINT,
  emulatorEndpoint: process.env.NEXT_PUBLIC_EMULATOR_ENDPOINT
})

tracking.setup(firebase)
if (encryption instanceof Encryption) {
  encryption.tracking = tracking
}
