import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { useShallow } from 'zustand/react/shallow'

import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { useAuthStore } from '@/store/authStore'

export default function LanguageSync() {
  const { i18n } = useTranslation()
  const { status, database } = useAuthStore(
    useShallow((state) => ({
      status: state.status,
      database: state.database
    }))
  )
  const { data } = useSWR(database && [userQuery.currentPreferences], fetchCurrentPreferences(database!))

  useEffect(() => {
    if (status !== 'sessionExpired') {
      i18n.changeLanguage(data?.baseLanguage ?? 'en')
    }
  }, [data?.baseLanguage, i18n, status])

  return <></>
}
