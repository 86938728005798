import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M36%203.273v29.454h-2.182v-5.454H13.091v.663c-.381.044-1.836.102-2.182.172V3.273H36Zm-22.91%201.09h20.728v6.546H13.091V4.364Zm20.728%2014.182V12H13.091v6.545h20.727Zm-20.727%207.637v-6.546h20.727v6.546H13.091ZM0%2027.272h6.546q.054%200%20.107.006.054.005.106.016.053.01.104.026.051.015.1.036.05.02.097.045.047.026.092.055.045.03.086.064.041.034.08.072.037.038.071.08.034.04.064.085.03.045.055.092t.045.097q.02.049.036.1.016.052.026.104.01.053.016.106.005.053.005.107v.002q0%20.053-.005.106-.005.054-.016.106-.01.053-.026.104-.015.051-.036.1-.02.05-.045.097-.026.048-.055.092-.03.045-.064.086-.034.041-.072.08-.038.037-.08.071-.04.034-.085.064-.045.03-.092.055t-.096.046q-.05.02-.101.036-.051.015-.104.026-.052.01-.106.015-.053.006-.107.006v3.272H0v-5.454Zm19.09%204.364h-4.908v-3.272q-3.546%200-5.455%201.09-1.121.641-1.678%201.659a1.091%201.091%200%200%200%20.957%201.614h11.085a.545.545%200%201%200%200-1.09Zm-2.834-3.272v2.727h-1.527V28.42q.72-.056%201.527-.056Zm4.909%204.363h-1.128a1.085%201.085%200%200%200%200-1.09h1.128a.546.546%200%200%201%200%201.09Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function GenkanIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M36 3.273v29.454h-2.182v-5.454H13.091v.663c-.381.044-1.836.102-2.182.172V3.273H36Zm-22.91 1.09h20.728v6.546H13.091V4.364Zm20.728 14.182V12H13.091v6.545h20.727Zm-20.727 7.637v-6.546h20.727v6.546H13.091ZM0 27.272h6.546q.054 0 .107.006.054.005.106.016.053.01.104.026.051.015.1.036.05.02.097.045.047.026.092.055.045.03.086.064.041.034.08.072.037.038.071.08.034.04.064.085.03.045.055.092t.045.097q.02.049.036.1.016.052.026.104.01.053.016.106.005.053.005.107v.002q0 .053-.005.106-.005.054-.016.106-.01.053-.026.104-.015.051-.036.1-.02.05-.045.097-.026.048-.055.092-.03.045-.064.086-.034.041-.072.08-.038.037-.08.071-.04.034-.085.064-.045.03-.092.055t-.096.046q-.05.02-.101.036-.051.015-.104.026-.052.01-.106.015-.053.006-.107.006v3.272H0v-5.454Zm19.09 4.364h-4.908v-3.272q-3.546 0-5.455 1.09-1.121.641-1.678 1.659a1.091 1.091 0 0 0 .957 1.614h11.085a.545.545 0 1 0 0-1.09Zm-2.834-3.272v2.727h-1.527V28.42q.72-.056 1.527-.056Zm4.909 4.363h-1.128a1.085 1.085 0 0 0 0-1.09h1.128a.546.546 0 0 1 0 1.09Z'
        }
      />
    </svg>
  )
}
