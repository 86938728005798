import { SupportActivityType } from "../common";
import { UpdateObject } from "../../utils";
import { ActionEvent, SellEvent, SharedEvent, ValuationEvent } from "../event";
import {
  ActionCommand,
  SellCommand,
  SharedCommand,
  ValuationCommand,
} from "../command";
import {
  EventToActivitiesFunction,
  validateActMapFuncKnowEventKind,
} from "../activities";
import { Encrypted } from "../../database/encryption";
import { Property } from "./property";
import { PropertyUtils } from "./propertyUtils";
import { genActivityLogs } from "../activityUtils";

export namespace Command {
  type Kind =
    | SharedCommand.Kind
    | ValuationCommand.Kind
    | SellCommand.Kind
    | ActionCommand.Kind;
  export const Kind = {
    ...SharedCommand.Kind,
    ...ValuationCommand.Kind,
    ...SellCommand.Kind,
    ...ActionCommand.Kind,
  };

  export interface CreateAsset
    extends SharedCommand.CreateAsset<Encrypted<Property>> {}
  export const createAsset = SharedCommand.createAsset<Encrypted<Property>>;
  export interface UpdateAsset
    extends SharedCommand.UpdateAsset<UpdateObject<Encrypted<Property>>> {}
  export const updateAsset = SharedCommand.updateAsset<
    UpdateObject<Encrypted<Property>>
  >;
  export interface DeleteAsset extends SharedCommand.DeleteAsset {}
  export const deleteAsset = SharedCommand.deleteAsset;
  export interface ArchiveAsset extends SharedCommand.ArchiveAsset {}
  export const archiveAsset = SharedCommand.archiveAsset;
  export interface RestoreArchivedAsset
    extends SharedCommand.RestoreArchivedAsset {}
  export const restoreArchivedAsset = SharedCommand.restoreArchivedAsset;
  export interface AddInsurance extends SharedCommand.AddInsurance {}
  export const addInsurance = SharedCommand.addInsurance;
  export interface RemoveInsurance extends SharedCommand.RemoveInsurance {}
  export const removeInsurance = SharedCommand.removeInsurance;

  export interface AddValuation extends ValuationCommand.AddValuation {}
  export const addValuation = ValuationCommand.addValuation;
  export interface UpdateValuation extends ValuationCommand.UpdateValuation {}
  export const updateValuation = ValuationCommand.updateValuation;
  export interface DeleteValuation extends ValuationCommand.DeleteValuation {}
  export const deleteValuation = ValuationCommand.deleteValuation;

  export interface MarkAsSold extends SellCommand.MarkAsSold {}
  export const markAsSold = SellCommand.markAsSold;
  export interface UpdateSoldInfo extends SellCommand.UpdateSoldInfo {}
  export const updateSoldInfo = SellCommand.updateSoldInfo;
  export interface DeleteSoldInfo extends SellCommand.DeleteSoldInfo {}
  export const deleteSoldInfo = SellCommand.deleteSoldInfo;

  //Offer / Rent
  export interface AddAction
    extends ActionCommand.AddAction<PropertyUtils.SupportActionsEncrypted> {}
  export interface UpdateAction
    extends ActionCommand.UpdateAction<PropertyUtils.SupportActionsUpdate> {}
  export interface DeleteAction extends ActionCommand.DeleteAction {}
}
export type Command =
  | Command.CreateAsset
  | Command.UpdateAsset
  | Command.DeleteAsset
  | Command.ArchiveAsset
  | Command.RestoreArchivedAsset
  | Command.AddInsurance
  | Command.RemoveInsurance
  | Command.AddValuation
  | Command.UpdateValuation
  | Command.DeleteValuation
  | Command.MarkAsSold
  | Command.UpdateSoldInfo
  | Command.DeleteSoldInfo
  | Command.AddAction
  | Command.UpdateAction
  | Command.DeleteAction;

export namespace Event {
  export type Kind =
    | SharedEvent.Kind
    | ValuationEvent.Kind
    | SellEvent.Kind
    | ActionEvent.Kind;
  export const Kind = {
    ...SharedEvent.Kind,
    ...ValuationEvent.Kind,
    ...SellEvent.Kind,
    ...ActionEvent.Kind,
  };

  export interface AssetCreated
    extends SharedEvent.AssetCreated<Encrypted<Property>> {}
  export interface AssetUpdated
    extends SharedEvent.AssetUpdated<UpdateObject<Encrypted<Property>>> {}
  export interface AssetDeleted extends SharedEvent.AssetDeleted {}
  export interface AssetArchived extends SharedEvent.AssetArchived {}
  export interface ArchivedAssetRestored
    extends SharedEvent.ArchivedAssetRestored {}
  export interface ShareholderUpdated extends SharedEvent.ShareholderUpdated {}
  export interface BeneficiaryUpdated extends SharedEvent.BeneficiaryUpdated {}
  export interface ValueUpdated extends SharedEvent.ValueUpdated {}
  export interface InsuranceUpdated extends SharedEvent.InsuranceUpdated {}
  export interface ImageAdded extends SharedEvent.ImageAdded {}
  export interface ImageRemoved extends SharedEvent.ImageRemoved {}
  export interface MainImageSet extends SharedEvent.MainImageSet {}
  export interface GroupsUpdated extends SharedEvent.GroupsUpdated {}

  export interface ValuationAdded extends ValuationEvent.ValuationAdded {}
  export interface ValuationUpdated extends ValuationEvent.ValuationUpdated {}
  export interface ValuationDeleted extends ValuationEvent.ValuationDeleted {}

  export interface SoldInfoAdded extends SellEvent.SoldInfoAdded {}
  export interface SoldInfoUpdated extends SellEvent.SoldInfoUpdated {}
  export interface SoldInfoDeleted extends SellEvent.SoldInfoDeleted {}

  export interface ActionAdded
    extends ActionEvent.ActionAdded<PropertyUtils.SupportActionsEncrypted> {}
  export interface ActionUpdated
    extends ActionEvent.ActionUpdated<PropertyUtils.SupportActionsUpdate> {}
  export interface ActionDeleted extends ActionEvent.ActionDeleted {}
  export const toActivities: EventToActivitiesFunction<Event> = (
    event: Event,
    assetType: SupportActivityType,
    assetId: string,
    ownerId: string,
    time: Date
  ) => {
    validateActMapFuncKnowEventKind(assetType, [event]);
    const needProcessEventKinds: Event.Kind[] = [
      Event.Kind.AssetCreated,
      Event.Kind.AssetDeleted,
      Event.Kind.ValueUpdated,
      Event.Kind.ShareholderUpdated,
      Event.Kind.BeneficiaryUpdated,
      Event.Kind.ImageAdded,
      Event.Kind.ImageRemoved,
      Event.Kind.MainImageSet,
      Event.Kind.GroupsUpdated,
      // actions
      Event.Kind.SoldInfoAdded,
      Event.Kind.SoldInfoUpdated,
      Event.Kind.SoldInfoDeleted,
    ];
    return genActivityLogs(
      needProcessEventKinds,
      event,
      assetType,
      assetId,
      ownerId,
      time
    );
  };
}
export type Event =
  | Event.AssetCreated
  | Event.AssetUpdated
  | Event.AssetDeleted
  | Event.AssetArchived
  | Event.ArchivedAssetRestored
  | Event.ShareholderUpdated
  | Event.BeneficiaryUpdated
  | Event.ValueUpdated
  | Event.InsuranceUpdated
  | Event.ImageAdded
  | Event.ImageRemoved
  | Event.MainImageSet
  | Event.GroupsUpdated
  | Event.ValuationAdded
  | Event.ValuationUpdated
  | Event.ValuationDeleted
  | Event.SoldInfoAdded
  | Event.SoldInfoUpdated
  | Event.SoldInfoDeleted
  | Event.ActionAdded
  | Event.ActionUpdated
  | Event.ActionDeleted;
