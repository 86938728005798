import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M22.966%2012a6.5%206.5%200%201%200-6.931%200h-5.372a5.5%205.5%200%201%200-6.326%200H2.999q-.098%200-.195.02-.096.018-.187.056-.091.038-.173.092-.082.055-.151.125-.07.07-.125.151-.054.082-.092.173-.038.09-.057.187Q2%2012.901%202%2013v14.002q0%20.098.02.195.018.096.056.187.038.091.092.173.055.082.125.151.07.07.151.125.082.054.173.092.09.038.187.057Q2.901%2028%203%2028h9.346L9%2033.794l1.732%201%203.384-5.861%203.384%205.861%201.732-1L15.887%2028H25q.098%200%20.195-.02.096-.018.187-.056.091-.038.173-.092.082-.055.151-.125.07-.07.125-.151.054-.082.092-.173.038-.09.057-.187.02-.097.02-.196v-3.896L33%2025V15l-7%201.895v-3.896q0-.098-.02-.195-.018-.096-.056-.187-.038-.091-.092-.173-.055-.082-.125-.151-.07-.07-.151-.125-.082-.054-.173-.092-.09-.038-.187-.057Q25.099%2012%2025%2012h-2.035Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function MovieTheatreIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M22.966 12a6.5 6.5 0 1 0-6.931 0h-5.372a5.5 5.5 0 1 0-6.326 0H2.999q-.098 0-.195.02-.096.018-.187.056-.091.038-.173.092-.082.055-.151.125-.07.07-.125.151-.054.082-.092.173-.038.09-.057.187Q2 12.901 2 13v14.002q0 .098.02.195.018.096.056.187.038.091.092.173.055.082.125.151.07.07.151.125.082.054.173.092.09.038.187.057Q2.901 28 3 28h9.346L9 33.794l1.732 1 3.384-5.861 3.384 5.861 1.732-1L15.887 28H25q.098 0 .195-.02.096-.018.187-.056.091-.038.173-.092.082-.055.151-.125.07-.07.125-.151.054-.082.092-.173.038-.09.057-.187Q26 27.099 26 27v-3.896L33 25V15l-7 1.895v-3.896q0-.098-.02-.195-.018-.096-.056-.187-.038-.091-.092-.173-.055-.082-.125-.151-.07-.07-.151-.125-.082-.054-.173-.092-.09-.038-.187-.057Q25.099 12 25 12h-2.035Z'
        }
      />
    </svg>
  )
}
