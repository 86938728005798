'use client'

import { TrackingContext, TrackingContextProps, TrackingEventType, useTracking, useTrackingFor } from "@/hooks/useTracking"
import { createRef, useEffect, useMemo } from "react"
import { useRouter } from "next/router"

/**
 * Provides a context and functions to track events within a specific domain.
 * @template C - The type of the context.
 * @param {string} name - The name of the event to track.
 * @param {C} [context] - Optional context for the event.
 * @param {React.RefObject<HTMLElement>} [loadedRef] - Optional ref used to determine when the 
 * component is loaded. Tracking send a 'loaded' event when the ref is mounted.
 * @param {boolean} [isLoaded] - Optional boolean to determine when the component is loaded.
 * @returns {JSX.Element} - The provider component for the tracking context.
 *  * @example
 * ```
 * const loadedRef = useRef<HTMLDivElement>(null)
 * <Tracking name="Properties" context={{ uid : 1234 }} loadedRef={loadedRef}>
 *   loading? <Loading /> : <PropertySummary ref={loadedRef} />
 * </Tracking>
 * 
 * PropertySummary = () => {
 *   const tracking = useTracking({ domain : 'Summary', context : { propertyId : 1234 } })
 *   tracking.track('view')
 * }
 * ```
 * Will track event as Properties/Summary/view with context { uid : 1234, propertyId : 1234 }
 *
 */
export const Tracking:
  React.FC<{
    name: string;
    context?: Record<string, unknown>,
    trackRef?: React.RefObject<unknown>,
    trackChildren?: boolean,
    isLoaded?: boolean,
    children?: React.ReactNode,
    eventType?: string
  }>
  = ({ name, context, trackRef, trackChildren, isLoaded, children, eventType }) => {
    const parentContext = useTracking()

    const value = useMemo<TrackingContextProps>(() =>
      new TrackingContextProps(eventType, name, context, parentContext)
      , [eventType, name, context, parentContext]);

    // If 'isLoaded' is not provided, then we don't need to track the loading of the component internally
    const managedLoadedRef = (isLoaded !== undefined) ? createRef<HTMLDivElement>() : undefined

    return (
      <TrackingContext.Provider value={value}>
        <RenderObserver trackRef={trackRef || managedLoadedRef} trackChildren={trackChildren}>
          {(!managedLoadedRef || (isLoaded === false)) ?
            children
            : <div ref={managedLoadedRef}>{children}</div>
          }
        </RenderObserver>
      </TrackingContext.Provider>
    )
  };

let routeTrackingEventId: string | undefined

export const RouterTracking: React.FC<{}> = () => {
  const router = useRouter()
  const { track, trackStart, trackSuccess, trackError } = useTracking({ eventType: TrackingEventType.PAGE_VIEW })

  useEffect(() => {
    // Track the initial page load
    track('app_load')

    // Subscribe to route changes
    const start = (page_path: string) => {
      if (!routeTrackingEventId) {
        routeTrackingEventId = trackStart('route_change')
      }
    }
    const success = (page_path: string) => {
      routeTrackingEventId && trackSuccess(routeTrackingEventId, { page_path })
      routeTrackingEventId = undefined
    }
    const error = (error: any, page_path: string) => {
      routeTrackingEventId && trackError(routeTrackingEventId, error, { cancelled: error.cancelled, page_path })
      routeTrackingEventId = undefined
    }
    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', success)
    router.events.on('routeChangeError', error)

    // Clean up the subscription on unmount
    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', success)
      router.events.off('routeChangeError', error)
    }
  }, [router.events, track, trackError, trackStart, trackSuccess])

  return <></>
}

function RenderObserver ({ children, trackRef, trackChildren = false }:
  {
    children?: React.ReactNode,
    trackRef?: React.RefObject<unknown>,
    trackChildren?: boolean
  }) {

  const [start, success, fail, track] = useTrackingFor('loaded')

  useEffect(() => {
    //If component is already loaded, then don't track
    const childrenComplete = (trackChildren && children == null) ? false : true
    const refComplete = (trackRef && trackRef.current) || !trackRef

    //If component is already loaded or not tracking, then dont send a tracking event
    if (refComplete && childrenComplete) {
      return
    }

    // Start tracking when the parent component mounts
    const trackingId = start();
    let complete = false;

    // Set up a MutationObserver to detect when the target component is added to the DOM
    const observer = new MutationObserver(() => {
      const childrenComplete = (trackChildren && children == null) ? false : true
      const refComplete = (trackRef && trackRef.current) || !trackRef

      if (refComplete && childrenComplete) {
        complete = true
        success(trackingId); // End tracking when the target is rendered
        observer.disconnect(); // Stop observing once the target is found
      }
    });

    // Start observing the document body for changes
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (!complete) {
        observer.disconnect();
        success(trackingId, { cancelled: true })
      }
    }
  }, [start, success, trackRef, trackChildren, children, track])

  return <>{children}</>
}