import { CashAndBankingSummary } from "./cashAndBankingSummary";
import { ArtSummary } from "./artSummary";
import { BelongingSummary, SubtypeItem } from "./belongingSummary";
import {
  Amount,
  AssetType,
  Categories,
  FinanceTypes,
  Optional,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { CryptocurrencySummary } from "./cryptocurrencySummary";
import { InsuranceSummary } from "./insuranceSummary";
import { OtherInvestmentSummary } from "./otherInvestmentSummary";
import { PropertySummary, SummaryLocationItem } from "./propertySummary";
import { TraditionalInvestmentSummary } from "./traditionalInvestmentSummary";
import { WineSummary } from "./wineSummary";
import {
  AllowedDecimalPlaces,
  OmitKeys,
  calculatePercentage,
  subDecimal,
} from "../utils";
import Decimal from "decimal.js";
import { RelationsOfAsset, RoleToAsset } from "./relations";
import { Encryption } from "../database/encryption";
import { PriceSource } from "../database/priceSource";
import { SummaryPermissions } from "./summaryManager";
import { PermissionCategory } from "../refPaths";
import { DataPoisoned } from "./error";

interface BalanceSheet {
  assets: Amount;
  liabilities: Amount;
  netValue: Amount;
}

type WithAssetType<T> = T & { assetType: AssetType };
type ValueDistribution<Label> = AssetsAndLiabilities<ValueWithLabel<Label>>;
interface AssetsAndLiabilities<Data> {
  assets: Data[];
  liabilities: Data[];
}

interface ValueWithLabel<T> {
  label: T;
  value: Amount;
  percentage: number;
}

export interface GlobalDashboard {
  //#NOTE we can't access stock price and coin price here, the final calculation should be done outside
  // valueDistribution: ValueDistribution<Categories>;
  // myFinances: BalanceSheet & ValueDistribution<FinanceTypes>;
  myFinancesData: {
    cashAndBanking?: OmitKeys<
      CashAndBankingSummary.GlobalDashboardData,
      "categoryLiabilities"
    >;
    traditionalInvestment?: TraditionalInvestmentSummary.GlobalDashboardData;
    otherInvestment?: OtherInvestmentSummary.GlobalDashboardData;
    cryptocurrency?: CryptocurrencySummary.GlobalDashboardData;
    insurance?: InsuranceSummary.GlobalDashboardData;
  };
  myProperties: BalanceSheet & {
    properties: (OmitKeys<SummaryLocationItem, "ownedPercentage"> & {
      id: string;
      percentage: number;
      assetValue: Amount;
    })[];
  };
  //#NOTE wine, art and subtypeItems of collectables are mixed and displayed together
  // - we can't really count on the name to locate art and wineAndSprits
  myCollectables: BalanceSheet & {
    subtypeItems: WithAssetType<SubtypeItem>[];
  };
  myBelongings: BalanceSheet & { distribution: ValueDistribution<string> };
}
export namespace GlobalDashboard {
  export interface Display extends BalanceSheet {
    valueDistribution: ValueDistribution<Categories>;
    myFinances: BalanceSheet & {
      distribution: ValueDistribution<FinanceTypes>;
    };
    myProperties: BalanceSheet & {
      properties: (OmitKeys<SummaryLocationItem, "ownedPercentage"> & {
        id: string;
        percentage: number;
        assetValue: Amount;
      })[];
    };
    //#NOTE wine, art and subtypeItems of collectables are mixed and displayed together
    // - we can't really count on the name to locate art and wineAndSprits
    myCollectables: BalanceSheet & {
      subtypeItems: WithAssetType<SubtypeItem>[];
    };
    myBelongings: BalanceSheet & { distribution: ValueDistribution<string> };
  }

  export async function fromSummaries(
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    encryption: Encryption,
    cashAndBanking: Optional<CashAndBankingSummary>,
    traditionalInvestment: Optional<TraditionalInvestmentSummary>,
    otherInvestment: Optional<OtherInvestmentSummary>,
    cryptocurrency: Optional<CryptocurrencySummary>,
    insurance: Optional<InsuranceSummary>,
    property: Optional<PropertySummary>,
    art: Optional<ArtSummary>,
    wine: Optional<WineSummary>,
    otherCollectable: Optional<BelongingSummary>,
    belonging: Optional<BelongingSummary>,
    locationRelations: Optional<RelationsOfAsset[]>,
    liabilityRelations: Optional<RelationsOfAsset[]>
  ): Promise<GlobalDashboard> {
    const currency = exchangeRate.targetCurrency;
    const ignoreAssetTypes: AssetType[] = [];
    if (art === undefined) ignoreAssetTypes.push(AssetType.Art);
    if (wine === undefined) ignoreAssetTypes.push(AssetType.WineAndSpirits);
    if (otherCollectable === undefined)
      ignoreAssetTypes.push(AssetType.OtherCollectables);
    const cashAndBankingResult =
      cashAndBanking && liabilityRelations
        ? CashAndBankingSummary.toGlobalDashboardData(
            cashAndBanking,
            exchangeRate,
            liabilityRelations,
            ignoreAssetTypes
          )
        : undefined;
    const categoryLiabilities = cashAndBankingResult?.categoryLiabilities || {
      MyFinances: Amount.zero(currency),
      MyProperties: Amount.zero(currency),
      MyCollectables: Amount.zero(currency),
      MyBelongings: Amount.zero(currency),
    };
    const myFinancesData: GlobalDashboard["myFinancesData"] = {
      cashAndBanking: cashAndBankingResult
        ? {
            assets: cashAndBankingResult.assets,
            liabilities: cashAndBankingResult.liabilities,
            netValue: cashAndBankingResult.netValue,
          }
        : undefined,
      traditionalInvestment: traditionalInvestment
        ? TraditionalInvestmentSummary.toGlobalDashboardData(
            traditionalInvestment,
            exchangeRate
          )
        : undefined,
      otherInvestment: otherInvestment
        ? OtherInvestmentSummary.toGlobalDashboardData(
            otherInvestment,
            exchangeRate
          )
        : undefined,
      cryptocurrency: cryptocurrency
        ? CryptocurrencySummary.toGlobalDashboardData(cryptocurrency)
        : undefined,
      insurance: insurance
        ? InsuranceSummary.toGlobalDashboardData(insurance, exchangeRate)
        : undefined,
    };

    const processedData = {
      property: property
        ? await PropertySummary.toDisplayAndDecrypted(
            property,
            exchangeRate,
            encryption
          )
        : undefined,
      art: art ? ArtSummary.toDisplay(art, exchangeRate) : undefined,
      wine: wine ? WineSummary.toDisplay(wine, exchangeRate) : undefined,
      otherCollectable: otherCollectable
        ? BelongingSummary.toDisplay(otherCollectable, exchangeRate)
        : undefined,
      belonging: belonging
        ? BelongingSummary.toDisplay(belonging, exchangeRate)
        : undefined,
    };

    const propertyAssetValues =
      processedData.property && locationRelations
        ? ((locationRelations) => {
            const propertyAssetValues: Record<string, number> = {};
            processedData.property.locations.forEach((location) => {
              propertyAssetValues[location.id] = locationRelations
                .filter((relation) => relation[location.id] !== undefined)
                .reduce((sum, relation) => {
                  const { id, secondaryId, assetType } = relation;
                  let value: Amount;
                  switch (assetType) {
                    case AssetType.Art:
                      const asset = art!.items[id];
                      if (asset.sold) return sum;
                      value = asset.value;
                      break;
                    case AssetType.Belonging:
                      value = belonging!.items[id].value;
                      break;
                    case AssetType.OtherCollectables:
                      value = otherCollectable!.items[id].value;
                      break;
                    case AssetType.WineAndSpirits:
                      const numOfBottles =
                        relation[location.id].relations[
                          RoleToAsset.AssetLocation
                        ]!.bottles!.length;
                      const valuePerBottle = wine!.wines[
                        secondaryId!
                      ].purchases.find(
                        (purchase) => purchase.id === id
                      )?.valuePerBottle;
                      if (!valuePerBottle)
                        throw new DataPoisoned(
                          "Purchase not found in wine summary"
                        );
                      value = {
                        currency: valuePerBottle.currency,
                        value: new Decimal(valuePerBottle.value)
                          .mul(numOfBottles)
                          .toNumber(),
                      };
                      break;
                    default:
                      throw new DataPoisoned("Unsupported asset type");
                  }
                  return new Decimal(value.value)
                    .mul(exchangeRate.rates[value.currency].rate)
                    .toDecimalPlaces(AllowedDecimalPlaces)
                    .add(sum);
                }, new Decimal(0))
                .toNumber();
            });
            return propertyAssetValues;
          })(locationRelations)
        : {};

    const myProperties: GlobalDashboard["myProperties"] = processedData.property
      ? {
          assets: processedData.property.assets,
          liabilities: categoryLiabilities.MyProperties,
          netValue: {
            currency,
            value: processedData.property.assets.value,
          },
          properties: processedData.property.locations.map((v) => {
            const { ownedPercentage, ...rest } = v;
            return {
              ...rest,
              assetValue: { currency, value: propertyAssetValues[v.id] || 0 },
            };
          }),
        }
      : {
          assets: Amount.zero(currency),
          liabilities: Amount.zero(currency),
          netValue: Amount.zero(currency),
          properties: [],
        };

    let collectableAssetsValue = new Decimal(0);

    const collectableSubtypeItems: WithAssetType<SubtypeItem>[] = [];
    if (processedData.art && processedData.art.subtypeItems.length > 0) {
      collectableAssetsValue = collectableAssetsValue.add(
        processedData.art.assets.value
      );
      collectableSubtypeItems.push({
        assetType: AssetType.Art,
        label: AssetType.Art,
        value: processedData.art.assets,
        percentage: 100,
        assetNumber: processedData.art.subtypeItems.reduce((acc, v) => {
          return acc + v.assetNumber;
        }, 0),
        itemNumber: processedData.art.subtypeItems.reduce((acc, v) => {
          return acc + v.itemNumber;
        }, 0),
        liability: {
          currency,
          value: 0,
        },
      });
    }
    if (processedData.wine && processedData.wine.subtypeItems.length > 0) {
      collectableAssetsValue = collectableAssetsValue.add(
        processedData.wine.assets.value
      );
      collectableSubtypeItems.push({
        assetType: AssetType.WineAndSpirits,
        label: AssetType.WineAndSpirits,
        value: processedData.wine.assets,
        percentage: 100,
        assetNumber: processedData.wine.subtypeItems.reduce((acc, v) => {
          return acc + v.assetNumber;
        }, 0),
        itemNumber: processedData.wine.subtypeItems.reduce((acc, v) => {
          return acc + v.itemNumber;
        }, 0),
        liability: {
          currency,
          value: 0,
        },
      });
    }

    const otherCollectableSubtypeItems: {
      [label: string]: WithAssetType<SubtypeItem>;
    } = {};
    if (
      processedData.otherCollectable &&
      processedData.otherCollectable.subtypeItems.length > 0
    ) {
      collectableAssetsValue = collectableAssetsValue.add(
        processedData.otherCollectable.assets.value
      );
      processedData.otherCollectable.subtypeItems.forEach((v) => {
        if (otherCollectableSubtypeItems[v.label]) {
          if (
            otherCollectableSubtypeItems[v.label].value.currency !==
            v.value.currency
          )
            throw new Error("Currency mismatch");
          otherCollectableSubtypeItems[v.label].value.value += v.value.value;
          otherCollectableSubtypeItems[v.label].itemNumber += v.itemNumber;
        } else {
          otherCollectableSubtypeItems[v.label] = {
            assetType: AssetType.OtherCollectables,
            label: v.label,
            value: v.value,
            percentage: v.percentage,
            assetNumber: v.assetNumber,
            itemNumber: v.itemNumber,
            liability: v.liability,
          };
        }
      });
      collectableSubtypeItems.push(
        ...Object.values(otherCollectableSubtypeItems)
      );
    }
    collectableSubtypeItems.map((v) => {
      v.percentage = calculatePercentage(v.value.value, collectableAssetsValue);
    });
    collectableSubtypeItems.sort((a, b) => b.value.value - a.value.value);
    const collectableAssetsValueNumber = collectableAssetsValue.toNumber();
    const myCollectables: GlobalDashboard["myCollectables"] = {
      assets: {
        currency,
        value: collectableAssetsValueNumber,
      },
      liabilities: categoryLiabilities.MyCollectables,
      netValue: {
        currency,
        value: collectableAssetsValueNumber,
      },
      subtypeItems: collectableSubtypeItems,
    };

    const myBelongings: GlobalDashboard["myBelongings"] =
      processedData.belonging
        ? {
            assets: processedData.belonging.assets,
            liabilities: categoryLiabilities.MyBelongings,
            netValue: {
              currency,
              value: processedData.belonging.assets.value,
            },
            distribution: {
              assets: processedData.belonging.subtypeItems.map((v) => {
                return {
                  label: v.label,
                  value: v.value,
                  percentage: v.percentage,
                };
              }),
              liabilities: [],
            },
          }
        : {
            assets: Amount.zero(currency),
            liabilities: Amount.zero(currency),
            netValue: Amount.zero(currency),
            distribution: {
              assets: [],
              liabilities: [],
            },
          };

    const result: GlobalDashboard = {
      myFinancesData,
      myProperties,
      myCollectables,
      myBelongings,
    };
    return result;
  }

  export async function calculateCurrentValues(
    data: GlobalDashboard,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    priceSource: PriceSource,
    permissions: SummaryPermissions
  ): Promise<GlobalDashboard.Display> {
    const currency = exchangeRate.targetCurrency;
    const { myFinancesData, myProperties, myCollectables, myBelongings } = data;

    // get current price
    let myFinancesAssetsValue = new Decimal(
      myFinancesData.insurance?.value || 0
    );
    const myFinancesDistribution: ValueDistribution<FinanceTypes> = {
      assets: [],
      liabilities: [],
    };
    if (permissions[PermissionCategory.Finance].read) {
      if (
        myFinancesData.cashAndBanking === undefined ||
        myFinancesData.traditionalInvestment === undefined ||
        myFinancesData.otherInvestment === undefined ||
        myFinancesData.cryptocurrency === undefined
      )
        throw new Error("Missing finance data");

      const stocks = myFinancesData.traditionalInvestment!.holdings.map(
        (v) => v.name
      );
      const stockPriceMap = await priceSource.getStockPrice(stocks);
      const coinNames = Object.keys(myFinancesData.cryptocurrency!);
      const cryptoPriceMap = await priceSource.getCryptoPrice(coinNames);

      // calculate myFinances current value
      const traditionalInvestmentAssetsValue = myFinancesData
        .traditionalInvestment!.holdings.reduce((acc, v) => {
          return new Decimal(v.ownedUnit)
            .mul(stockPriceMap[v.name].value)
            .mul(exchangeRate.rates[stockPriceMap[v.name].currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .add(acc);
        }, new Decimal(0))
        .add(myFinancesData.traditionalInvestment!.cashInBaseOwned.value)
        .toNumber();

      const cryptocurrencyAssetsValue = Object.entries(
        myFinancesData.cryptocurrency!
      )
        .reduce((acc, [coinName, v]) => {
          return new Decimal(v.ownedUnit)
            .mul(cryptoPriceMap[coinName].value)
            .mul(exchangeRate.rates[cryptoPriceMap[coinName].currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .add(acc);
        }, new Decimal(0))
        .toNumber();
      myFinancesAssetsValue = myFinancesAssetsValue
        .add(myFinancesData.cashAndBanking!.assets.value)
        .add(myFinancesData.otherInvestment!.value)
        .add(traditionalInvestmentAssetsValue)
        .add(cryptocurrencyAssetsValue);
      myFinancesDistribution.assets = [
        {
          label: FinanceTypes.CashAndBanking,
          value: myFinancesData.cashAndBanking!.assets,
          percentage: calculatePercentage(
            myFinancesData.cashAndBanking!.assets.value,
            myFinancesAssetsValue
          ),
        },
        {
          label: FinanceTypes.TraditionalInvestments,
          value: {
            currency,
            value: traditionalInvestmentAssetsValue,
          },
          percentage: calculatePercentage(
            traditionalInvestmentAssetsValue,
            myFinancesAssetsValue
          ),
        },
        {
          label: FinanceTypes.OtherInvestments,
          value: myFinancesData.otherInvestment!,
          percentage: calculatePercentage(
            myFinancesData.otherInvestment!.value,
            myFinancesAssetsValue
          ),
        },
        {
          label: FinanceTypes.Cryptocurrencies,
          value: {
            currency,
            value: cryptocurrencyAssetsValue,
          },
          percentage: calculatePercentage(
            cryptocurrencyAssetsValue,
            myFinancesAssetsValue
          ),
        },
      ];
      myFinancesDistribution.liabilities = [
        {
          label: FinanceTypes.CashAndBanking,
          value: myFinancesData.cashAndBanking!.liabilities,
          percentage: 100,
          // calculatePercentage(
          //   myFinancesData.cashAndBanking.liabilities.value,
          //   myFinancesLiabilitiesValue
          // ),
        },
        {
          label: FinanceTypes.TraditionalInvestments,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
        {
          label: FinanceTypes.OtherInvestments,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
        {
          label: FinanceTypes.Cryptocurrencies,
          value: {
            currency,
            value: 0,
          },
          percentage: 0,
        },
      ];
    }
    if (permissions[AssetType.Insurance].read) {
      myFinancesDistribution.assets.push({
        label: FinanceTypes.Insurance,
        value: myFinancesData.insurance!,
        percentage: calculatePercentage(
          myFinancesData.insurance!.value,
          myFinancesAssetsValue
        ),
      });
      myFinancesDistribution.liabilities.push({
        label: FinanceTypes.Insurance,
        value: {
          currency,
          value: 0,
        },
        percentage: 0,
      });
    }

    const myFinancesAssetsValueNumber = myFinancesAssetsValue.toNumber();

    const myFinancesLiabilitiesValue =
      myFinancesData.cashAndBanking?.liabilities.value || 0;

    const myFinances: GlobalDashboard.Display["myFinances"] = {
      assets: {
        currency,
        value: myFinancesAssetsValueNumber,
      },
      liabilities: {
        currency,
        value: myFinancesLiabilitiesValue,
      },
      netValue: {
        currency,
        value: subDecimal(myFinancesAssetsValue, myFinancesLiabilitiesValue),
      },
      distribution: myFinancesDistribution,
    };
    const totalAssetsValue = new Decimal(myFinancesAssetsValue)
      .add(myProperties.assets.value)
      .add(myCollectables.assets.value)
      .add(myBelongings.assets.value)
      .toNumber();
    const totalLiabilitiesValue = myFinancesLiabilitiesValue;
    const valueDistribution: GlobalDashboard.Display["valueDistribution"] = {
      assets: [],
      liabilities: [],
    };
    if (
      permissions[PermissionCategory.Finance].read ||
      permissions[PermissionCategory.Insurance].read
    ) {
      valueDistribution.assets.push({
        label: Categories.MyFinances,
        value: myFinances.assets,
        percentage: calculatePercentage(
          myFinances.assets.value,
          totalAssetsValue
        ),
      });
      valueDistribution.liabilities.push({
        label: Categories.MyFinances,
        value: myFinances.liabilities,
        percentage: calculatePercentage(
          myFinances.liabilities.value,
          totalLiabilitiesValue
        ),
      });
    }
    if (permissions[PermissionCategory.Property].read) {
      valueDistribution.assets.push({
        label: Categories.MyProperties,
        value: myProperties.assets,
        percentage: calculatePercentage(
          myProperties.assets.value,
          totalAssetsValue
        ),
      });
    }
    if (permissions[PermissionCategory.OtherCollectables].read) {
      valueDistribution.assets.push({
        label: Categories.MyCollectables,
        value: myCollectables.assets,
        percentage: calculatePercentage(
          myCollectables.assets.value,
          totalAssetsValue
        ),
      });
    }
    if (permissions[PermissionCategory.Belonging].read) {
      valueDistribution.assets.push({
        label: Categories.MyBelongings,
        value: myBelongings.assets,
        percentage: calculatePercentage(
          myBelongings.assets.value,
          totalAssetsValue
        ),
      });
    }

    return <GlobalDashboard.Display>{
      assets: {
        currency,
        value: totalAssetsValue,
      },
      liabilities: {
        currency,
        value: totalLiabilitiesValue,
      },
      netValue: {
        currency,
        value: subDecimal(totalAssetsValue, totalLiabilitiesValue),
      },
      valueDistribution,
      myFinances,
      myProperties,
      myCollectables,
      myBelongings,
    };
  }
}
