import {
  getAssetUserDataPath,
  getBillingHistoryPath,
  getCustomizedTypeCollectionPath,
  getIndexedAssetsPath,
  getIntegrationsPath,
  getTTPricesPath,
  getUncategorizedAttachmentPath,
  DELEGATE_INVITES_PATH,
  DELEGATE_PATH,
  getRelationsUserDataPath,
  getSummaryPath,
  getAddressBookPath,
  getGroupPath,
  getStorageLimitPath,
} from "./refPaths";
import {
  BillingHistory,
  Integration,
  NewSubscriptionInfo,
} from "./types/billing";
import { Contact } from "./types/contact";
import { DelegateData, DelegateInvite } from "./types/delegates";
import { DailyTimeTickerPrice } from "./types/postgres";
import { Notification, Preferences } from "./types/user";
import { ArtSummary } from "./types/artSummary";
import { BelongingSummary } from "./types/belongingSummary";
import { CashAndBankingSummary } from "./types/cashAndBankingSummary";
import {
  AssetType,
  CustomizedType,
  FavoriteFile,
  FileAssetType,
} from "./types/common";
import { CryptocurrencySummary } from "./types/cryptocurrencySummary";
import { GroupInfo, GroupItem } from "./types/groups";
import { InsuranceSummary } from "./types/insuranceSummary";
import { OtherInvestmentSummary } from "./types/otherInvestmentSummary";
import { PropertySummary } from "./types/propertySummary";
import { RelationsOfAsset } from "./types/relations";
import { TraditionalInvestmentSummary } from "./types/traditionalInvestmentSummary";
import { WineSummary } from "./types/wineSummary";
import {
  CoreFirestore,
  CollectionReference,
  DocumentReference,
  StorageReference,
} from "../coreFirebase";
import { StorageLimit } from "./types/storage";

export type FullRefs = { currentRefs: Refs; selfRefs: Refs };

export class Refs {
  readonly userId: string;

  readonly Dek: DocumentReference<any>;
  readonly Delegates: CollectionReference<DelegateData>;
  readonly UserDelegates: DocumentReference<DelegateData>;
  readonly UserDelegateInvites: CollectionReference<DelegateInvite>;

  //from V1
  readonly UserPreferencesDoc: DocumentReference<Preferences>;
  readonly UserSubscriptionInfoDoc: DocumentReference<NewSubscriptionInfo>;
  readonly UserStorageLimitDoc: DocumentReference<StorageLimit>;
  readonly UserIntegrations: CollectionReference<Integration>;
  readonly UserNotifications: CollectionReference<Notification>;
  readonly UserBillingHistory: CollectionReference<BillingHistory>;
  readonly GlobalSetting: CollectionReference<any>;

  readonly AttachReference: StorageReference;
  readonly UserPhotoReference: StorageReference;
  readonly TimeTickerPrices: CollectionReference<DailyTimeTickerPrice>;
  //new
  readonly AddressBook: CollectionReference<Contact.Encrypted>;
  readonly Groups: CollectionReference<GroupInfo>;
  readonly CashAndBankingSummary: DocumentReference<CashAndBankingSummary>;
  readonly TraditionalInvestmentSummary: DocumentReference<TraditionalInvestmentSummary>;
  readonly OtherInvestmentSummary: DocumentReference<OtherInvestmentSummary>;
  readonly CryptocurrencySummary: DocumentReference<CryptocurrencySummary>;
  readonly InsuranceSummary: DocumentReference<InsuranceSummary>;
  readonly PropertySummary: DocumentReference<PropertySummary>;
  readonly ArtSummary: DocumentReference<ArtSummary>;
  readonly WineSummary: DocumentReference<WineSummary>;
  readonly OtherCollectableSummary: DocumentReference<BelongingSummary>;
  readonly BelongingSummary: DocumentReference<BelongingSummary>;

  readonly Relations: CollectionReference<RelationsOfAsset>;

  constructor(userId: string) {
    this.userId = userId;
    this.Groups = CoreFirestore.collection<GroupInfo>(getGroupPath(userId));

    this.Dek = CoreFirestore.doc(`deks/${userId}`);
    this.Delegates = CoreFirestore.collection<DelegateData>(DELEGATE_PATH);
    this.UserDelegates = CoreFirestore.doc(`${DELEGATE_PATH}/${userId}`);
    this.UserDelegateInvites = CoreFirestore.collection<DelegateInvite>(
      DELEGATE_INVITES_PATH
    );

    this.UserSubscriptionInfoDoc = CoreFirestore.doc<Preferences>(
      `UserSubscriptionInfo/${userId}`
    );
    this.UserPreferencesDoc = CoreFirestore.doc<Preferences>(
      `UserPreferences/${userId}`
    );
    this.UserStorageLimitDoc = CoreFirestore.doc<StorageLimit>(
      getStorageLimitPath(userId)
    );
    this.UserIntegrations = CoreFirestore.collection<Integration>(
      getIntegrationsPath(userId)
    );
    //#QUESTION can this be queried ? I still don't fully understand the indexing rules
    //the most common query will be "give me all notifications that are not read"
    this.UserNotifications = CoreFirestore.collection<Notification>(
      `UserData/${userId}/Notifications`
    );
    this.UserBillingHistory = CoreFirestore.collection<Notification>(
      getBillingHistoryPath(userId)
    );
    this.AddressBook = CoreFirestore.collection<Contact.Encrypted>(
      getAddressBookPath(userId)
    );
    this.GlobalSetting = CoreFirestore.collection("Share");
    this.AttachReference = CoreFirestore.ref(`UserFile/${userId}`);
    this.UserPhotoReference = CoreFirestore.ref(`UserFile/${userId}/userPhoto`);
    this.TimeTickerPrices = CoreFirestore.collection(getTTPricesPath());

    this.CashAndBankingSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.CashAndBanking)
    );
    this.TraditionalInvestmentSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.TraditionalInvestments)
    );
    this.OtherInvestmentSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.OtherInvestment)
    );
    this.CryptocurrencySummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.Cryptocurrency)
    );
    this.InsuranceSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.Insurance)
    );
    this.PropertySummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.Property)
    );
    this.ArtSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.Art)
    );
    this.WineSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.WineAndSpirits)
    );
    this.OtherCollectableSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.OtherCollectables)
    );
    this.BelongingSummary = CoreFirestore.doc(
      getSummaryPath(this.userId, AssetType.Belonging)
    );

    this.Relations = CoreFirestore.collection<RelationsOfAsset>(
      getRelationsUserDataPath(this.userId)
    );
  }

  getStorageRef(assetId: string, attachmentKey: string): StorageReference {
    return CoreFirestore.refExtend(
      this.AttachReference,
      `${assetId}/${attachmentKey}`
    );
  }

  getAssetCollectionRef<T>(assetType: AssetType) {
    return CoreFirestore.collection<T>(getIndexedAssetsPath(assetType));
  }

  getAssetDocRef<T>(assetType: AssetType, docId?: string) {
    const assetCollection = this.getAssetCollectionRef<T>(assetType);
    return CoreFirestore.docFromCollection(
      assetCollection,
      docId ?? CoreFirestore.genAssetId()
    );
  }
  getActionCollectionRef<T>(assetType: AssetType, assetId: string) {
    return CoreFirestore.collection<T>(
      `${getIndexedAssetsPath(assetType)}/${assetId}/Action`
    );
  }

  getAssetTransactionCollectionRef<T>(assetType: AssetType, assetId: string) {
    return CoreFirestore.collection<T>(
      `${getIndexedAssetsPath(assetType)}/${assetId}/Transaction`
    );
  }
  getAssetTransactionDocRef<T>(
    assetType: AssetType,
    assetId: string,
    txId: string
  ) {
    return CoreFirestore.docFromCollection<T>(
      this.getAssetTransactionCollectionRef<T>(assetType, assetId),
      txId
    );
  }

  getPortfolioTransactionCollectionRef<T>(holdingId: string) {
    return CoreFirestore.collection<T>(
      `${getIndexedAssetsPath("PortfolioTransaction")}/${holdingId}/Transaction`
    );
  }
  getPortfolioTransactionDocRef<T>(holdingId: string, txId: string) {
    return CoreFirestore.docFromCollection(
      this.getPortfolioTransactionCollectionRef<T>(holdingId),
      txId
    );
  }
  getPortfolioTransactionRootDocRef<T>(holdingId: string) {
    return CoreFirestore.doc<T>(
      `${getIndexedAssetsPath("PortfolioTransaction")}/${holdingId}`
    );
  }
  getPortfolioTransactionRootCollectionRef<T>() {
    return CoreFirestore.collection<T>(
      getIndexedAssetsPath("PortfolioTransaction")
    );
  }

  getCustomizedTypeRef(type: CustomizedType) {
    return CoreFirestore.doc(
      `${getCustomizedTypeCollectionPath(this.userId, type)}/${type}`
    );
  }

  getFavoriteFileDocRef(
    fileAssetType: FileAssetType
  ): DocumentReference<FavoriteFile> {
    if (fileAssetType === "Uncategorized") {
      return CoreFirestore.doc(
        `${getUncategorizedAttachmentPath(this.userId)}/FavoriteFile`
      );
    } else {
      return CoreFirestore.doc(
        `${getAssetUserDataPath(
          this.userId,
          <AssetType>fileAssetType
        )}/FavoriteFile`
      );
    }
  }

  getRelationDocRef(assetId: string) {
    return CoreFirestore.doc<RelationsOfAsset>(
      `${getRelationsUserDataPath(this.userId)}/${assetId}`
    );
  }

  getExportMetadataCollection() {
    return CoreFirestore.collection(`UserData/${this.userId}/ExportMetadata`);
  }

  getGroupRelationsCollectionRef(groupId: string) {
    return CoreFirestore.collection<RelationsOfAsset>(
      `${getGroupPath(this.userId)}/${groupId}/GroupRelations`
    );
  }
}

//get all assets in ids, not sorted
export async function getAssetsByIds<T>(
  refs: Refs,
  assetType: AssetType,
  ids: string[]
): Promise<T[]> {
  return CoreFirestore.getDocsByIdsPure(
    refs.getAssetCollectionRef<T>(assetType),
    ids
  );
}

export const GROUP_RELATIONS_PATH = "GroupRelations";
export function getGroupRelationsCollectionRefFromGroupDocument(
  groupDocumentRef: DocumentReference<GroupInfo>
) {
  return CoreFirestore.collection<GroupItem>(
    `${groupDocumentRef.path}/${GROUP_RELATIONS_PATH}`
  );
}

export function getGroupRelationsCollectionRefFromGroupCollection(
  groupCollectionRef: CollectionReference<GroupInfo>,
  groupId: string
) {
  return CoreFirestore.collection<GroupItem>(
    `${groupCollectionRef.path}/${groupId}/${GROUP_RELATIONS_PATH}`
  );
}
