'use client'

import { createContext, DependencyList, useCallback, useContext, useMemo } from 'react'
import { Middleware, SWRHook } from 'swr'

import tracking from '@/utils/track'

export enum TrackingEventType {
  UI_EVENT = 'ui_event',
  USER_ACTION = 'user_action',
  PAGE_VIEW = 'page_view'
}

export class TrackingContextProps {
  private _eventType: string
  private _name: string
  private _context: Record<string, unknown>
  private _parentContext?: TrackingContextProps
  constructor(
    eventType: string = TrackingEventType.UI_EVENT,
    name?: string,
    context?: Record<string, unknown>,
    parentContext?: TrackingContextProps
  ) {
    this._eventType = eventType
    this._name = name || ''
    this._context = context || {}
    this._parentContext = parentContext
    this.track = this.track.bind(this)
    this.trackAsync = this.trackAsync.bind(this)
    this.trackStart = this.trackStart.bind(this)
    this.trackSuccess = this.trackSuccess.bind(this)
    this.trackError = this.trackError.bind(this)
  }

  public withEventType(eventType?: string): TrackingContextProps {
    if (!eventType) return this
    return new TrackingContextProps(eventType, this._name, this._context, this._parentContext)
  }

  public get domain(): string {
    return this._parentContext ? resolveName(this._parentContext.domain, this._name) : this._name
  }

  public get context(): Record<string, unknown> {
    return resolveContext(this._parentContext?.context, this._context, { page_path: window?.location.pathname })
  }

  /**
   * Tracks an event with a given name and optional context.
   *
   * @template C - The type of the context.
   * @param {string} name - The name of the event to track.
   * @param {C} [context] - Optional context for the event.
   * @returns {void}
   */
  public track<C extends Record<string, unknown>>(name: string, context?: C): void {
    tracking.track(this._eventType, resolveContext(this.context, context, { action: name, domain: this.domain }))
  }

  /**
   * Starts tracking an event with a given name and optional context.
   *
   * @template C - The type of the context.
   * @param {string} name - The name of the event to start tracking.
   * @param {C} [context] - Optional context for the event.
   * @returns {void}
   */
  trackStart<C extends Record<string, unknown>>(name: string, context?: C): string {
    return tracking.trackStart(
      this._eventType,
      resolveContext(this.context, context, { action: name, domain: this.domain })
    )
  }

  /**
   * Marks the success of an event with a given name and optional context.
   *
   * @template C - The type of the context.
   * @param {string} eventId - The id of the event to mark as successful.
   * @param {C} [context] - Optional context for the event.
   * @returns {void}
   */
  trackSuccess<C extends Record<string, unknown>>(eventId: string, context?: C): void {
    tracking.trackSuccess(eventId, context)
  }

  /**
   * Marks the failure of an event with a given name and optional context.
   *
   * @template C - The type of the context.
   * @param {string} eventId - The id of the event to mark as failed.
   * @param {C} [context] - Optional context for the event.
   * @returns {void}
   */
  public trackError<C extends Record<string, unknown>>(eventId: string, error?: Error, context?: C): void {
    tracking.trackError(eventId, error, context)
  }

  /**
   * Tracks an asynchronous event with a given name, promise, and optional context.
   *
   * @template P - The type of the promise result.
   * @template C - The type of the context.
   * @param {string} name - The name of the event to track.
   * @param {() => Promise<P>} promiseFunc - The function that returns a promise representing the asynchronous event.
   * @param {C} [context] - Optional context for the event.
   * @returns {Promise<P>} - The result of the promise.
   * @example
```
const loginFunc = useCallback(() => trackAsync('login',
  async () => {
    await signInWithEmailAndPassword(auth, email, password)
    await reload()
    await reloadPrefs()
  }), [reload, reloadPrefs, trackAsync])
```
   */
  public async trackAsync<P, C extends Record<string, unknown>>(
    name: string,
    promiseFunc: () => Promise<P>,
    context?: C
  ): Promise<P> {
    const eventId = this.trackStart(name, context)
    try {
      const result = await promiseFunc()
      this.trackSuccess(eventId)
      return result
    } catch (error) {
      this.trackError(eventId, error as Error)
      throw error
    }
  }
}

export const TrackingContext = createContext<TrackingContextProps>(new TrackingContextProps())

/**
 * Custom hook to track an event with a given name and optional context,
 * will use parent TrackingDomains to populate name and context if provided.
 *
 * @template C - The type of the context.
 * @param {eventType, domain, context} - All optional. The event type, domain, and any context params to use for the tracking.
 * Typically the domain refers to the component name that the tracking is in
 * @returns {void}
 */
export const useTracking = ({
  eventType,
  domain,
  context
}: { eventType?: string; domain?: string; context?: Record<string, unknown> } = {}) => {
  const parentContext = useContext(TrackingContext)
  const trackingContext = useMemo(
    () => new TrackingContextProps(eventType, domain, context, parentContext),
    [eventType, domain, context, parentContext]
  )

  return trackingContext
}

/**
 * Custom hook that wraps a callback function with tracking functionality.
 * It automatically tracks the start, success, and failure of the callback execution.
 *
 * @template A - The type of the arguments passed to the callback function.
 * @template R - The return type of the callback function.
 * @param {(...args: A[]) => Promise<R>} callback - The async callback function to be tracked.
 * @param {DependencyList} dependencies - An array of dependencies for the useCallback hook.
 * @param {string} eventName - The name of the event to be tracked.
 * @param {TrackingContextProps} [trackingContext] - Optional tracking context to be used.
 * @returns {(...args: A[]) => Promise<R>} A memoized version of the callback that includes tracking.
 *
 * @example
 * const tracking = useTracking({domain: 'MyForm'}) // Optional
 * const handleSubmit = useTrackedCallback(
 *   async (formData) => {
 *     // Submit form logic here
 *   },
 *   [someDependency],
 *   'formSubmission',
 *   tracking
 * );
 */
export function useTrackedCallback<P extends any[], R>(
  callback: (...args: P) => Promise<R>,
  dependencies: DependencyList,
  eventName: string,
  trackingContext?: TrackingContextProps
) {
  const tracking = useTracking({ eventType: TrackingEventType.USER_ACTION, ...trackingContext })

  return useCallback(
    (...args: P) => tracking.trackAsync(eventName, () => callback(...args)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, eventName, tracking, ...dependencies]
  )
}

type Fetcher<Data> = (...args: any[]) => Promise<Data>

export const trackSWRMiddleware: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, options) => {
    const [start, success, fail] = useTrackingFor('data_load')

    // Add logger to the original fetcher.
    const extendedFetcher = fetcher
      ? async (...args: unknown[]) => {
          const santizedKey = args[0] || key
          const trackingId = start({ key: santizedKey })
          try {
            const data = await fetcher(...args)
            success(trackingId)
            return data
          } catch (e) {
            fail(trackingId, e as Error)
            throw e
          }
        }
      : null

    // Execute the hook with the new fetcher.
    return useSWRNext(key, extendedFetcher, options)
  }
}

/**
 * Returns functions to start, mark success, and mark failure of an event with a given name and optional context.
 *
 * @template C - The type of the context.
 * @param {string} name - The name of the event to track.
 * @param {C} [context] - Optional context for the event.
 * @returns {[start : (context?: C) => void, success : (context?: C) => void, fail : (error? : Error, context?: C) => void]} - The functions to start, mark success, and mark failure of the event.
 */
export const useTrackingFor: (
  name: string,
  context?: Record<string, unknown>
) => [
  (context?: Record<string, unknown>) => string,
  (eventId: string, context?: Record<string, unknown>) => void,
  (eventId: string, error?: Error, context?: Record<string, unknown>) => void,
  (context?: Record<string, unknown>) => void
] = (name, context) => {
  const tracking = useTracking()
  const start = useCallback(
    (newContext?: Record<string, unknown>) => tracking.trackStart(name, Object.assign({}, context, newContext)),
    [name, context, tracking]
  )
  const success = useCallback(
    (eventId: string, newContext?: Record<string, unknown>) => tracking.trackSuccess(eventId, newContext),
    [tracking]
  )
  const fail = useCallback(
    (eventId: string, error?: Error, newContext?: Record<string, unknown>) =>
      tracking.trackError(eventId, error, newContext),
    [tracking]
  )
  const track = useCallback(
    (newContext?: Record<string, unknown>) => tracking.track(name, newContext),
    [name, tracking]
  )
  return [start, success, fail, track]
}

function resolveName(...names: string[]): string {
  return names.filter((name) => name?.length > 0).join('.')
}

function resolveContext(...contexts: (Record<string, unknown> | undefined)[]): Record<string, unknown> {
  return Object.assign({}, ...contexts)
}
