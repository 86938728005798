import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M23.245%207H11.699q-.098%200-.195.02-.096.018-.187.056-.091.038-.173.092-.082.055-.151.125-.07.07-.125.151-.054.082-.092.173-.038.09-.057.187-.019.097-.019.195v1.388q0%20.098.02.195.018.096.056.187.038.091.092.173.055.082.125.151.07.07.151.125.082.054.173.092.09.038.187.057.097.019.195.019h11.546q.098%200%20.195-.02.096-.018.187-.056.09-.038.173-.092.082-.055.151-.125.07-.07.124-.151.055-.082.093-.173.037-.09.056-.187.02-.097.02-.195V7.999q0-.098-.02-.195-.019-.096-.056-.187-.038-.091-.093-.173-.054-.082-.124-.151-.07-.07-.151-.125-.082-.054-.173-.092-.09-.038-.187-.057Q23.343%207%2023.245%207Zm2.75%205.643H9.006q-.098%200-.194.02-.097.019-.188.056-.09.038-.172.093-.082.054-.152.124-.07.07-.124.151-.055.082-.092.173-.038.09-.057.187-.02.097-.02.195V23.93H8v2.387q0%20.098.02.194.018.097.056.188.038.09.092.172.055.082.125.152.07.07.151.124.082.055.173.093.09.037.187.056.097.02.195.02h.668q.082%200%20.163-.014.082-.014.16-.04.078-.027.15-.066.073-.04.138-.09.066-.05.122-.11.056-.061.101-.13.045-.07.079-.145.033-.075.054-.155l.68-2.636H23.68l.68%202.636q.021.08.055.155.033.076.079.145.045.069.101.13.056.06.121.11t.138.09q.073.039.15.066.079.026.16.04.081.014.164.014h.668q.098%200%20.195-.02.096-.019.187-.056.09-.038.173-.093.081-.054.151-.124.07-.07.124-.151.055-.082.093-.173.037-.091.056-.188.02-.096.02-.194V13.642q0-.098-.02-.195-.019-.096-.056-.187-.038-.091-.093-.173-.054-.082-.124-.151-.07-.07-.151-.124-.082-.055-.173-.093-.091-.037-.188-.057-.096-.019-.194-.019Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function SingleBedIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M23.245 7H11.699q-.098 0-.195.02-.096.018-.187.056-.091.038-.173.092-.082.055-.151.125-.07.07-.125.151-.054.082-.092.173-.038.09-.057.187-.019.097-.019.195v1.388q0 .098.02.195.018.096.056.187.038.091.092.173.055.082.125.151.07.07.151.125.082.054.173.092.09.038.187.057.097.019.195.019h11.546q.098 0 .195-.02.096-.018.187-.056.09-.038.173-.092.082-.055.151-.125.07-.07.124-.151.055-.082.093-.173.037-.09.056-.187.02-.097.02-.195V7.999q0-.098-.02-.195-.019-.096-.056-.187-.038-.091-.093-.173-.054-.082-.124-.151-.07-.07-.151-.125-.082-.054-.173-.092-.09-.038-.187-.057Q23.343 7 23.245 7Zm2.75 5.643H9.006q-.098 0-.194.02-.097.019-.188.056-.09.038-.172.093-.082.054-.152.124-.07.07-.124.151-.055.082-.092.173-.038.09-.057.187-.02.097-.02.195V23.93H8v2.387q0 .098.02.194.018.097.056.188.038.09.092.172.055.082.125.152.07.07.151.124.082.055.173.093.09.037.187.056.097.02.195.02h.668q.082 0 .163-.014.082-.014.16-.04.078-.027.15-.066.073-.04.138-.09.066-.05.122-.11.056-.061.101-.13.045-.07.079-.145.033-.075.054-.155l.68-2.636H23.68l.68 2.636q.021.08.055.155.033.076.079.145.045.069.101.13.056.06.121.11t.138.09q.073.039.15.066.079.026.16.04.081.014.164.014h.668q.098 0 .195-.02.096-.019.187-.056.09-.038.173-.093.081-.054.151-.124.07-.07.124-.151.055-.082.093-.173.037-.091.056-.188.02-.096.02-.194V13.642q0-.098-.02-.195-.019-.096-.056-.187-.038-.091-.093-.173-.054-.082-.124-.151-.07-.07-.151-.124-.082-.055-.173-.093-.091-.037-.188-.057-.096-.019-.194-.019Z'
        }
      />
    </svg>
  )
}
