import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M14.219%208.577h-3.281V7.462h2.187V4.115c0-.616-.49-1.115-1.094-1.115H6.562c-.604%200-1.093.5-1.093%201.115v3.347h2.187v1.115H4.375c-.604%200-1.094.5-1.094%201.115v6.693H1.094C.49%2016.385%200%2016.884%200%2017.5v11.154c0%20.616.49%201.115%201.094%201.115V32h4.375v-2.23H29.53V32h4.375v-2.23c.604%200%201.094-.5%201.094-1.116V17.5c0-.616-.49-1.115-1.094-1.115H15.313V9.692c0-.616-.49-1.115-1.094-1.115ZM25%209.545v4.364h-2.5c-.276%200-.5.244-.5.545%200%20.302.224.546.5.546h6c.276%200%20.5-.244.5-.546%200-.3-.224-.545-.5-.545H26V9.545h3L27.648%203h-4.296L22%209.545h3Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function OfficeIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M14.219 8.577h-3.281V7.462h2.187V4.115c0-.616-.49-1.115-1.094-1.115H6.562c-.604 0-1.093.5-1.093 1.115v3.347h2.187v1.115H4.375c-.604 0-1.094.5-1.094 1.115v6.693H1.094C.49 16.385 0 16.884 0 17.5v11.154c0 .616.49 1.115 1.094 1.115V32h4.375v-2.23H29.53V32h4.375v-2.23c.604 0 1.094-.5 1.094-1.116V17.5c0-.616-.49-1.115-1.094-1.115H15.313V9.692c0-.616-.49-1.115-1.094-1.115ZM25 9.545v4.364h-2.5c-.276 0-.5.244-.5.545 0 .302.224.546.5.546h6c.276 0 .5-.244.5-.546 0-.3-.224-.545-.5-.545H26V9.545h3L27.648 3h-4.296L22 9.545h3Z'
        }
      />
    </svg>
  )
}
