import { useEffect, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import banks from 'core/remodel/types/banks.json'
import { Account } from 'core/remodel/types/cashAndBanking'
import type { CurrentAccount } from 'core/remodel/types/cashAndBanking/currentAccount'
import { AssetType, AttachmentKind, Currency, CustomizedType } from 'core/remodel/types/common'
import { accountTypeOptions, CountryOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { sortAlphabetically } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  RadioGroup,
  RadioGroupItem,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type CurrentValues = CurrentAccount

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<CurrentValues> => ({
  subtype: Account.Type.CurrentAccount,
  assetType: AssetType.CashAndBanking,
  // primary details
  name: '',
  accountType: Account.AccountType.SingleCurrency,
  country: '',
  institution: '',
  subAccounts: [{ id: '', balance: { currency, value: 0 }, isDefault: true }],
  // attributes
  accountNumber: '',
  SWIFT_BIC: '',
  openingDate: new Date(),
  sortCode: '',
  groupIds: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

interface CurrentFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string
  values?: CurrentValues
  onSubmit: SubmitHandler<CurrentValues>
  onCancel: () => void
}

export function CurrentForm({ mode = 'Create', assetId, values, onSubmit, onCancel }: CurrentFormProps) {
  const { t } = useTranslation()
  const subAccountIds = values?.subAccounts.map((sub) => sub.id)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset
  } = useForm<CurrentValues>({ defaultValues })
  const country = watch('country')
  const accountType = watch('accountType')
  const subAccountsArray = useFieldArray({ control, name: 'subAccounts', keyName: 'key' })
  // preferences and options
  const institutionSWR = useSWR([typeQuery.institution, CustomizedType.BankOrInstitution], fetchCustomTypes(database!))
  const { data: institutions, isValidating: loadingInstitutions } = institutionSWR
  const institutionOptions = useMemo(() => {
    const base = banks
      .filter((bank) => bank.country === country)
      .map((bank) => ({ label: bank.name, value: bank.name }))
    const added = institutions?.map((item) => ({ label: item, value: item })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [country, institutions])
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [{ key: 'attributes', label: t('Attributes') }]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  useEffect(() => {
    const isEmptyId = subAccountsArray.fields[0].id === ''
    const baseCurrency = preferences.baseCurrency
    if (mode === 'Create' && isEmptyId && baseCurrency !== undefined) {
      subAccountsArray.update(0, {
        ...subAccountsArray.fields[0],
        id: database!.genAssetId(),
        balance: { currency: baseCurrency, value: 0 }
      })
    }
  }, [database, mode, preferences.baseCurrency, subAccountsArray])

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  useEffect(() => {
    const isNoDefault = subAccountsArray.fields.find((subAccount) => subAccount.isDefault) === undefined
    if (isNoDefault) {
      subAccountsArray.update(0, { ...subAccountsArray.fields[0], isDefault: true })
    }
  }, [subAccountsArray])

  useEffect(() => {
    const hasMultiAccounts = subAccountsArray.fields.length > 1
    if (accountType === Account.AccountType.SingleCurrency && hasMultiAccounts) {
      const subAccounts = subAccountsArray.fields.filter((sub) => sub.isDefault)
      reset((data) => ({ ...data, subAccounts }))
    }
  }, [accountType, subAccountsArray, reset])

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[600px] grow flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormInput
                  control={control}
                  name={'name'}
                  label={t('finances:Field.AccountName')}
                  rules={{ required: true }}
                />
                <FormSelect
                  control={control}
                  name={'accountType'}
                  label={t('finances:Field.AccountType')}
                  disabled={mode === 'Edit'}
                >
                  {accountTypeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {t(`finances:AccountCurrencyType.${label}`)}
                    </SelectItem>
                  ))}
                </FormSelect>
                {accountType === Account.AccountType.SingleCurrency && (
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'subAccounts.0.balance.currency', value: 'subAccounts.0.balance.value' }}
                    label={t('finances:Field.Balance')}
                    format={preferences.numberFormat}
                    digits={2}
                    disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
                  />
                )}
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormAutocomplete
                  control={control}
                  name={'country'}
                  label={t('Field.Country')}
                  options={CountryOptions}
                  placeholder={t('Placeholder.Select')}
                />
                <FormCreatableAutocomplete
                  control={control}
                  name={'institution'}
                  label={t('finances:Field.BankInstitution')}
                  options={institutionOptions}
                  onCreate={async (name) => await addCustomType(database!, CustomizedType.BankOrInstitution, name)}
                  placeholder={t('SearchOrCreate')}
                  isLoading={loadingInstitutions}
                  rules={{ required: true }}
                />
              </div>
            </div>
            {accountType === Account.AccountType.MultiCurrency && (
              <div className={'col-span-2'}>
                <RadioGroup
                  value={`${subAccountsArray.fields.findIndex((curr) => curr.isDefault)}`}
                  onValueChange={(value) => {
                    const index = +value
                    const newBalances = subAccountsArray.fields.map((subAccount) => ({
                      ...subAccount,
                      isDefault: false
                    }))
                    newBalances[index].isDefault = true
                    subAccountsArray.replace(newBalances)
                  }}
                >
                  <ul className={'mb-4 space-y-4 empty:mb-0'}>
                    {subAccountsArray.fields.map((subAccount, index) => (
                      <li key={subAccount.key} className={'grid grid-cols-2 gap-4'}>
                        <FormPriceInput
                          control={control}
                          name={{
                            currency: `subAccounts.${index}.balance.currency`,
                            value: `subAccounts.${index}.balance.value`
                          }}
                          label={t('finances:Field.Balance')}
                          format={preferences.numberFormat}
                          digits={2}
                          disabled={{
                            currency: subAccountIds?.includes(subAccount.id) && mode === 'Edit',
                            value: subAccountIds?.includes(subAccount.id) && mode === 'Edit'
                          }}
                        />
                        <div className={'flex items-center gap-x-2 pt-4'}>
                          <RadioGroupItem id={`radio-${index}`} value={`${index}`} />
                          <label className={'text-sm'} htmlFor={`radio-${index}`}>
                            {t('Default')}
                          </label>
                          {subAccountsArray.fields.length === 1 ? (
                            <Button disabled={true}>
                              <XIcon className={'text-primary'} />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => subAccountsArray.remove(index)}
                              disabled={subAccountIds?.includes(subAccount.id) && subAccount.balance.value > 0}
                            >
                              <XIcon className={'text-primary'} />
                            </Button>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </RadioGroup>
                <Button
                  className={'group gap-x-2'}
                  onClick={() =>
                    subAccountsArray.append({
                      id: database!.genAssetId(),
                      balance: { currency: preferences.baseCurrency ?? Currency.USD, value: 0 },
                      isDefault: subAccountsArray.fields.length === 0
                    })
                  }
                >
                  <div className={'rounded border border-primary p-1 group-hover:bg-primary'}>
                    <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                  </div>
                  <span className={'text-sm text-text group-hover:text-primary'}>{t('Currency')}</span>
                </Button>
              </div>
            )}
            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.attributes'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput control={control} name={'accountNumber'} label={t('finances:Field.AccountNumber')} />
              <FormInput control={control} name={'SWIFT_BIC'} label={t('finances:Field.SWIFTBICNumber')} />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormDatePicker
                control={control}
                name={'openingDate'}
                label={t('finances:Field.OpeningDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
              />
              <FormInput control={control} name={'sortCode'} label={t('finances:Field.SortCode')} />
            </div>
            <div className={'col-span-2'}>
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Field.Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            <AttachmentPanel
              assetId={assetId}
              control={control}
              name={{ mainImage: 'mainImage', attachments: 'attachments' }}
              widgetOptions={[AttachmentKind.PrimaryDetails]}
            />
          </TabPanel.Section>
        </div>

        <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function CurrentFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}
