import { Fragment, useEffect, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { SaleType, saleTypeValues, type SoldInfo } from 'core/remodel/types/actions/soldInfo'
import { Currency } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatNumber, makeOptions } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  SelectItem
} from '@/components/base'
import UploadFiles from '@/components/UploadFiles'

export type SoldValues = SoldInfo.CreateFields

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<SoldValues> => ({
  title: '',
  saleDate: new Date(),
  detail: {
    saleType: SaleType.DirectSale,
    salePrice: { currency, value: 0 },
    discount: { currency, value: 0 }
  },
  taxPayable: { currency, value: 0 },
  buyer: '',
  invoiceNumber: '',
  invoiceDate: new Date(),
  inventoryNumber: '',
  file: []
})

interface MarkAsSoldFormProps {
  assetId: string | null
  values?: SoldValues
  onSubmit: SubmitHandler<SoldValues>
  onCancel: () => void
}

export function MarkAsSoldForm({ assetId, values, onCancel, onSubmit }: MarkAsSoldFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR([userQuery.currentPreferences], fetchCurrentPreferences(database!))
  const defaultValues = getDefaultValues(preferences.baseCurrency)
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    setValue
  } = useForm<SoldValues>({ values, defaultValues })
  const saleType = watch('detail.saleType')
  const taxValue = watch('taxPayable.value')
  const discountValue = watch('detail.discount.value')
  const saleValue = watch('detail.salePrice.value')
  const hammerValue = watch('detail.totalHammerPrice.value')
  const saleCurrency = watch('detail.salePrice.currency')
  const hammerCurrency = watch('detail.totalHammerPrice.currency')
  // preferences and options
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const saleTypeOptions = makeOptions(saleTypeValues, (key) => `SaleTypeOptions.${key}`)
  // computed fields
  const isDirectSale = useMemo(() => saleType === SaleType.DirectSale, [saleType])
  const salePrice = useMemo(() => {
    const value = isDirectSale ? saleValue + taxValue - discountValue : hammerValue + taxValue
    return formatNumber(value, preferences.numberFormat, { digits: 2, isNegativeSign: true })
  }, [isDirectSale, saleValue, taxValue, discountValue, hammerValue, preferences.numberFormat])
  const currency = useMemo(() => {
    return isDirectSale ? saleCurrency : hammerCurrency
  }, [hammerCurrency, isDirectSale, saleCurrency])

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  useEffect(() => {
    if (isDirectSale) {
      setValue('taxPayable.currency', saleCurrency)
      setValue('detail.discount.currency', saleCurrency)
    } else {
      setValue('taxPayable.currency', hammerCurrency)
    }
  }, [saleType, saleCurrency, hammerCurrency, setValue, isDirectSale])

  return (
    <form className={'flex h-[95vh] flex-col bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-x-4 overflow-y-auto p-4 md:flex-row'}>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormInput control={control} name={'title'} label={t('Field.SaleTitle')} rules={{ required: true }} />
          <FormSelect control={control} name={'detail.saleType'} label={t('Field.SaleType')}>
            {saleTypeOptions.map(({ label, value }) => (
              <SelectItem key={value} value={value}>
                {label}
              </SelectItem>
            ))}
          </FormSelect>
          <FormDatePicker
            control={control}
            name={'saleDate'}
            label={t('Field.SaleDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
          {saleType === SaleType.DirectSale ? (
            <Fragment key={SaleType.DirectSale}>
              <FormPriceInput
                control={control}
                name={{ currency: 'detail.salePrice.currency', value: 'detail.salePrice.value' }}
                label={t('Field.SalePrice')}
                rules={{
                  value: {
                    required: true,
                    validate: { greaterThanZero: (value) => value > 0 }
                  }
                }}
                format={preferences.numberFormat}
                digits={2}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'taxPayable.currency', value: 'taxPayable.value' }}
                label={t('Field.TaxPayable')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: true }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'detail.discount.currency', value: 'detail.discount.value' }}
                label={t('Field.Discount')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: true }}
              />
              <FormCreatableAutocomplete
                control={control}
                name={'buyer'}
                label={t('Field.Buyer')}
                placeholder={t('SearchOrCreate')}
                options={contactOptions}
                onCreate={addNewContact}
                isLoading={loadingContacts}
              />
            </Fragment>
          ) : (
            <Fragment key={SaleType.AuctionSale}>
              <FormPriceInput
                control={control}
                name={{ currency: 'detail.totalHammerPrice.currency', value: 'detail.totalHammerPrice.value' }}
                label={t('Field.TotalHammerPrice')}
                format={preferences.numberFormat}
                digits={2}
                defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'taxPayable.currency', value: 'taxPayable.value' }}
                label={t('Field.TaxPayable')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: true }}
                defaultValue={{ currency: preferences.baseCurrency, value: 0 }}
              />
              <FormInput
                control={control}
                name={'detail.auctionName'}
                label={t('Field.AuctionNameNumber')}
                defaultValue={''}
              />
              <FormDatePicker
                control={control}
                name={'detail.auctionDate'}
                label={t('Field.AuctionDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
              />
              <FormInput control={control} name={'detail.lotNumber'} label={t('Field.LotNumber')} defaultValue={''} />
              <FormInput
                control={control}
                name={'detail.paddleNumber'}
                label={t('Field.PaddleNumber')}
                defaultValue={''}
              />
            </Fragment>
          )}
        </div>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          {saleType === SaleType.AuctionSale && <FormInput control={control} name={'buyer'} label={t('Field.Buyer')} />}
          <FormInput control={control} name={'invoiceNumber'} label={t('Field.InvoiceNumber')} />
          <FormDatePicker
            control={control}
            name={'invoiceDate'}
            label={t('Field.InvoiceDate')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
          <FormInput control={control} name={'inventoryNumber'} label={t('Field.InventoryNumber')} />
          <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
          <UploadFiles assetId={assetId} control={control} name={'file'} />
          <div className={'flex flex-col gap-y-1'}>
            <label className={'text-xs text-[#414554]'}>{t('Field.SalePrice')}</label>
            <div className={'rounded border border-primary-hover bg-primary p-2'}>
              <p className={'text-right text-sm text-white'}>{`${currency} ${salePrice}`}</p>
            </div>
          </div>
        </div>
      </div>
      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
          disabled={!isValid}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{t('SaveAndClose')}</span>
        </Button>
      </fieldset>
    </form>
  )
}
