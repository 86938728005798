import { PerformanceTrace } from 'firebase/performance'
import { v4 as uuid, validate as validateUuid } from 'uuid'

import { FirebaseV2 } from 'core/firestore'
import { ITracking } from 'core/tracking'
import { initializeAnalytics } from '@/utils/tracking/analytics'

// Default timeout duration in milliseconds for tracking events
const TIMEOUT_DURATION = 30000
const isDebug = process.env.NEXT_PUBLIC_ENV === 'development'

const timers: {
  [key: string]: {
    start: number
    name: string
    context?: Record<string, unknown>
    timeoutId: NodeJS.Timeout
    perfEvent?: PerformanceTrace
  }
} = {}

export class Tracking implements ITracking {
  firebase!: FirebaseV2

  public setup(firebase: FirebaseV2) {
    this.firebase = firebase
    initializeAnalytics()
  }

  public track(name: string, context?: Record<string, unknown>): void {
    if (isDebug) {
      console.info('Tracking Event:', name, JSON.stringify(context))
    }
    this.firebase.logEvent(name, context)
  }

  public trackStart(name: string, context?: Record<string, unknown>, timeout: number = TIMEOUT_DURATION): string {
    const now = performance.now()
    const trackId = uuid()
    const timeoutId = setTimeout(() => {
      this.trackError(trackId, new Error('Tracking Timeout'), { timedOut: true })
    }, timeout)

    const perfEvent = this.firebase.trace(name)
    this.addContextToTrace(perfEvent, context)
    perfEvent?.start()
    timers[trackId] = { start: now, name, context, timeoutId, perfEvent }

    return trackId
  }

  public trackSuccess(trackId: string, context?: Record<string, unknown>): void {
    if (!trackId) {
      throw new Error('trackId is required (from trackStart) to mark the event as success')
    }

    const event = timers[trackId]
    if (!event) {
      console.warn(`Tracking event corresponding to provided trackId (${trackId}) is not found, 
      either it was not started, or it has already been finished`)
      return
    }

    clearTimeout(event.timeoutId)

    const duration = performance.now() - event.start
    this.addContextToTrace(event.perfEvent, context)
    event.perfEvent?.stop()

    this.track(event.name, { ...event.context, ...context, duration })
    delete timers[trackId]
  }

  public trackError(trackIdOrName: string, error?: Error, context?: Record<string, unknown>): void {
    if (!trackIdOrName) {
      throw new Error('A trackId (from trackStart) or event name is required to track the error')
    }

    let event: Partial<(typeof timers)[keyof typeof timers]>
    if (validateUuid(trackIdOrName)) {
      event = timers[trackIdOrName]

      if (event) {
        clearTimeout(event.timeoutId)
      } else {
        console.warn(`Tracking event corresponding to provided trackId (${trackIdOrName}) is not found, 
        either it was not started, or it has already been finished`)
        return
      }
    } else {
      event = { name: trackIdOrName, context }
    }
    const combinedContext: Record<string, unknown> = {
      ...event.context,
      ...context,
      error: error?.message || 'Unknown Error'
    }

    if (event.start) {
      const duration = performance.now() - event.start
      combinedContext.duration = duration
    }
    this.track(event.name!, combinedContext)

    if (event.perfEvent) {
      this.addContextToTrace(event.perfEvent, context)
      event.perfEvent.stop()
    }

    delete timers[trackIdOrName]
  }

  addContextToTrace(trace?: PerformanceTrace, context?: Record<string, unknown>): void {
    if (!trace || !context) {
      return
    }

    for (const key in context) {
      try {
        if (typeof context[key] === 'string') {
          trace.putAttribute(key, context[key] as string)
        }
        if (typeof context[key] === 'number') {
          trace.putMetric(key, context[key] as number)
        }
        if (typeof context[key] === 'boolean') {
          trace.putMetric(key, (context[key] as boolean) ? 1 : 0)
        }
      } catch (e) {
        console.error(`error in addContextToTrace, skip adding: (${key}, ${context[key]})`)
        console.error(e)
      }
    }
  }
}

const tracking = new Tracking()
export default tracking
