import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20fill%3D%22%230F889B%22%20d%3D%22M24.085%208H19.02a6.5%206.5%200%200%201%2012.96%200h-5.066a1.5%201.5%200%200%200-2.83%200ZM3%206.7H1.936v-1H2.3v-1h-.5a.5.5%200%200%200-.47.673l-.607.28L.5%208h1.436v-.3H3V9H0v2h1v4h1v20h2V15h27v20h2V15h1v-4h1V9H7V8h-.4l1-3H3.758a.5.5%200%200%200-.458-.3h-.5v1H3v1ZM16%2016H5v5h11v-5Zm-5.5%203a.5.5%200%201%200%200-1%20.5.5%200%200%200%200%201Zm5.5%207.001V22H5v5h10.001q.098%200%20.195-.02.096-.018.187-.056.091-.038.173-.092.082-.055.151-.125.07-.07.125-.151.054-.082.092-.173.038-.09.057-.187Q16%2026.099%2016%2026ZM10.5%2025a.5.5%200%201%200%200-1%20.5.5%200%200%200%200%201Z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function WorkshopIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M24.085 8H19.02A6.5 6.5 0 0 1 31.98 8h-5.066a1.5 1.5 0 0 0-2.83 0ZM3 6.7H1.936v-1H2.3v-1h-.5a.5.5 0 0 0-.47.673l-.607.28L.5 8h1.436v-.3H3V9H0v2h1v4h1v20h2V15h27v20h2V15h1v-4h1V9H7V8h-.4l1-3H3.758a.5.5 0 0 0-.458-.3h-.5v1H3v1ZM16 16H5v5h11v-5Zm-5.5 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm5.5 7.001V22H5v5h10.001q.098 0 .195-.02.096-.018.187-.056.091-.038.173-.092.082-.055.151-.125.07-.07.125-.151.054-.082.092-.173.038-.09.057-.187Q16 26.099 16 26ZM10.5 25a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z'
        }
      />
    </svg>
  )
}
