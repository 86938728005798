import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2035%2035%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20%20%20%20fill%3D%22%230F889B%22%20%20%20%20d%3D%22M31%2031v-5.445L24.329%2014h-13.66L4%2025.551V31H2V19H0L17.5%205%2035%2019h-2v12h-2ZM20.95%2010.321l-3.45-2.76-3.449%202.76L15.598%2013h3.806l1.546-2.679Zm.79.632L20.558%2013h3.74l-2.558-2.047ZM10.702%2013l2.56-2.048L14.442%2013h-3.741Zm-1.188%201h-.062L4%2018.361v5.19L9.514%2014Zm16.034%200L31%2018.361v5.194L25.483%2014h.065Z%22%20%20%2F%3E%3C%2Fsvg%3E)
 */
export default function AtticIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 35 35'} width={size} height={size} {...props}>
      <path
        fill={'#0F889B'}
        d={
          'M31 31v-5.445L24.329 14h-13.66L4 25.551V31H2V19H0L17.5 5 35 19h-2v12h-2ZM20.95 10.321l-3.45-2.76-3.449 2.76L15.598 13h3.806l1.546-2.679Zm.79.632L20.558 13h3.74l-2.558-2.047ZM10.702 13l2.56-2.048L14.442 13h-3.741Zm-1.188 1h-.062L4 18.361v5.19L9.514 14Zm16.034 0L31 18.361v5.194L25.483 14h.065Z'
        }
      />
    </svg>
  )
}
